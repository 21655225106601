import React from "react";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import GoBackButton from "src/Components/ui/GoBack";
import { useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdHelpBuoy } from "react-icons/io";
import { userProfileStore } from "src/Routing/useProfileStore";

const EnterpriseProfile = () => {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const navigate = useNavigate();
  const profileType = userProfileStore((s) => s.profileTypeToken);

  return (
    <div>
      <div
        className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer text-azulBonico hover:underline"
        onClick={() => {
          if (profileType === "employer") {
            navigate("/dashboard");
          } else {
            navigate("/dashboard-worker");
          }
        }}
      >
        <GoBackButton />
      </div>
      <div className="flex items-start space-x-10 mt-8">
        <div className="flex flex-col mt-8 ">
          <h1 className="text-xl font-semibold mb-4">Profile</h1>
          <div
            onClick={() => navigate("/entrepise-profile/reviews")}
            className="flex flex-col cursor-pointer  w-80 bg-white  border rounded dark:bg-background dark:border-border dark:text-whiteish dark:border"
          >
            <div className="flex justify-between pt-6 px-4 lg:px-8 pb-8 border-b gap-10 text-sm border-gray-200 ">
              <div className="flex">
                <div className="flex flex-col w-full">
                  <div className="flex  items-start justify-between w-full">
                    <div className="text-white bg-azulOscuro h-24 w-24 rounded-full flex items-center justify-center">
                      <p className="text-5xl font-medium">
                        {userInfo.first_name[0]}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="text-lg lg:text-2xl font-medium">
                        {userInfo.first_name} {userInfo.last_name}
                      </p>
                      <p className="text-sm font-medium mt-2 text-gray-400">
                        Go to Profile
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 w-full">
          <div className="flex flex-col flex-grow">
            <h1 className="text-xl font-semibold ">Ajustes de cuenta</h1>

            <div className="flex flex-col w-full bg-white mt-4 p-4 border rounded dark:bg-background dark:border-border dark:text-whiteish dark:border">
              <div className="flex items-center space-x-4">
                <div className="h-10 w-10 items-center justify-center bg-[#f8f8f8] flex rounded-lg">
                  <FaUser className="h-6 w-6 " />
                </div>

                <p className="text-lg font-semibold">Datos personales</p>
              </div>
            </div>
            <div className="flex flex-col w-full bg-white mt-8 p-4 border rounded dark:bg-background dark:border-border dark:text-whiteish dark:border">
              <div className="flex items-center space-x-4">
                <div className="h-10 w-10 items-center justify-center bg-[#f8f8f8] flex rounded-lg">
                  <FaLocationDot className="h-6 w-6 " />
                </div>

                <p className="text-lg font-semibold">Mis direcciones</p>
              </div>
            </div>
            <h1 className="text-xl font-semibold mt-8">Centro de soporte</h1>

            <div className="flex flex-col w-full bg-white mt-4  p-4 border rounded dark:bg-background dark:border-border dark:text-whiteish dark:border">
              <div className="flex items-center space-x-4">
                <div className="h-10 w-10 items-center justify-center bg-[#f8f8f8] flex rounded-lg">
                  <IoMdHelpBuoy className="h-6 w-6 " />
                </div>

                <p className="text-lg font-semibold">Ayuda</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseProfile;
