import React, { lazy, useRef } from "react";
import { useEffect, useState, useCallback } from "react";

import { BASE_URL } from "../../../Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { ReactComponent as Banner } from "../../../assets/img/myJobsWorkerBanner.svg";
import filters from "../../../assets/img/filters.png";
import ModalSendRating from "../../../Components/Modals/ModalSendRating";
import ModalEditEndEarlyDate from "../../../Components/Modals/ModalEditEndEarlyDate";
import DisplayJobs from "../../../Components/Worker/AcceptedJobs/DisplayJobs";
import ReviewsScreen from "../../../Components/HiredWorkers/ReviewsScreen";
import { useTranslation } from "react-i18next";
import { debounce } from "../../../Utils/debounce";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

import autoAnimate from "@formkit/auto-animate";
import { useNavigate } from "react-router-dom";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import DisplayJobsNew from "src/Components/Worker/AcceptedJobs/DisplayJobsNew";
import { HireTypes } from "src/types/types";
import GoBackButton from "src/Components/ui/GoBack";
import { useQuery } from "react-query";
import { userProfileStore } from "src/Routing/useProfileStore";

const LIMIT = 10;
const PAGE_INITIAL = 1;
const ROWS_INITIAL = 1;

const AcceptedJobs = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [acceptedJobsList, setAcceptedJobsList] = useState({
    all: [],
    finished: [],
    active: [],
    pending: [],
  });
  const [query, setQuery] = useState("");
  const [clickedConnection, setClickedConnection] = useState<any>([]);
  const [refreshData, setRefreshData] = useState(false);
  const [showModal, setShowModal] = useState({ rating: false, end: false });
  const [selectedSection, setSelectedSection] = useState("active");
  const [reviews, setReviews] = useState<any>([]);
  const ref = useRef<HTMLInputElement>(null);
  const [allContracts, setAllContracts] = useState<HireTypes[]>([]);
  const parent = useRef(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleChangeSearch = (e: string) => {
    setQuery(e);
  };
  const handleSearch = (e: string) => {
    setQuery(e);
  };
  const [headerVisible, setHeaderVisible] = useState(true);
  const [sortParams, setSortParams] = useState("date_created");
  const [limit, setLimit] = useState(LIMIT);
  const [page, setPage] = useState(PAGE_INITIAL);
  const [rows, setRows] = useState(ROWS_INITIAL);
  const [totalPages, setTotalPages] = useState(1);
  const optimisedSearch = useCallback(debounce(handleChangeSearch), []);
  const navigate = useNavigate();
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const getAcceptedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}worker.employer.connection?client_id=${userID}${
        query ? `&find=${query}` : ""
      }&order=${sortParams}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data;
  };

  const {
    data: acceptedJobsData,
    refetch: myAcceptedJobs,
    isLoading,
  } = useQuery(["accepted_jobs"], getAcceptedJobs);

  useEffect(() => {
    setAllContracts(acceptedJobsData?.result);
    setRows(acceptedJobsData?.paginate.rows);
    setTotalPages(acceptedJobsData?.paginate.pages);
    setAcceptedJobsList((prev) => ({
      ...prev,
      all: acceptedJobsData?.result,
      finished: acceptedJobsData?.result.filter(
        (item: {
          date_early_end: string | null;
          server_date: string;
          date_end: string;
        }) => item.date_early_end !== null || item.server_date > item.date_end
      ),
      active: acceptedJobsData?.result.filter(
        (item: {
          date_early_end: string | null;
          server_date: string;
          date_end: string;
        }) =>
          item.date_early_end === null &&
          // item.date_start <= item.server_date &&
          // item.server_date <= item.date_end &&
          // item.date_start > item.server_date
          item.server_date <= item.date_end
      ),
      // pending: acceptedJobsData?.result.filter(
      //   (item) =>
      //     item.date_early_end === null && item.date_start > item.server_date
      // ),
    }));
    setReviews({
      received: acceptedJobsData?.result.filter(
        (item: { score_from_employer: string }) =>
          item.score_from_employer !== null &&
          item.score_from_employer !== "0.00"
      ),
      given: acceptedJobsData?.result.filter(
        (item: { score_from_worker: string }) =>
          item.score_from_worker !== null && item.score_from_worker !== "0.00"
      ),
    });
  }, [acceptedJobsData]);

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  const breakWorkerConnection = (
    connection: string,
    reason: string,
    date: string
  ) => {
    data.append("connection_id", connection);
    data.append("quit_work_reason_id", reason);
    data.append("date_early_end", date);
    fetch(`${BASE_URL}worker.employer.connection`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setRefreshData(true);
          myAcceptedJobs();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <HeaderBanner
        setHeaderVisible={setHeaderVisible}
        headerVisible={headerVisible}
        className="bg-[#2abeeb]"
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("worker.accepted_jobs.header_banner")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div
        className="flex items-center gap-2 mb-4 fill-azulBonico cursor-pointer mt-8 text-azulBonico hover:underline"
        onClick={() => {
          if (profileType === "employer") {
            navigate("/dashboard");
          } else {
            navigate("/dashboard-worker");
          }
        }}
      >
        <GoBackButton />
      </div>

      <div
        ref={parent}
        className="rounded w-full  bg-white dark:bg-background dark:text-whiteish dark:border-border dark:bordermt-12"
      >
        <div className="flex flex-col ">
          <div className="flex pt-8 px-2 lg:px-8 pb-3 border-b gap-10 text-xs lg:text-sm font-medium">
            <p
              className={`${
                selectedSection === "active"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("active")}
            >
              {t("worker.findwork.body.card.layer1")}
            </p>

            <p
              className={`${
                selectedSection === "finished"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("finished")}
            >
              {t("worker.findwork.body.card.layer3")}
            </p>
            <p
              className={`${
                selectedSection === "feedback"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("feedback")}
            >
              {t("worker.findwork.body.card.layer4")}
            </p>
            <p
              className={`${
                selectedSection === "allcontracts"
                  ? "underline decoration-[3px] underline-offset-[15px] text-azulBonico"
                  : "hover:text-azulOscuro"
              } cursor-pointer`}
              onClick={() => setSelectedSection("allcontracts")}
            >
              {t("employer.hires.diplay_hires.all_contracts")}
            </p>
          </div>
        </div>
        {selectedSection !== "feedback" &&
          selectedSection !== "allcontracts" && (
            <>
              <div className="mx-8 my-12 flex items-center gap-4">
                <div
                  className="relative w-full"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") myAcceptedJobs();
                  }}
                >
                  <input
                    disabled={loading}
                    type="text"
                    ref={ref}
                    className={`w-full border-2 dark:bg-input dark:text-whiteish dark:border-border border-blue-100 h-10 pl-4 outline-none text-sm rounded hover:border-blue-200 focus:border-blue-200 transition duration-300 `}
                    placeholder={`${t(
                      "worker.myjobs.body.search.findjobplaceholder"
                    )}`}
                    onChange={(e) => {
                      optimisedSearch(e.target.value);
                    }}
                  />
                </div>
                <div className="cursor-pointer flex items-center">
                  <img src={filters} alt="filter" className="h-4 mt-[1px]" />
                  <p className="text-azulBonico text-sm font-bold">
                    {t("worker.findwork.body.search.rightlink")}
                  </p>
                </div>
              </div>
            </>
          )}
        {selectedSection === "active" && (
          <DisplayJobs
            acceptedJobsList={acceptedJobsList.active}
            setClickedConnection={setClickedConnection}
            setShowModal={setShowModal}
            section={"active"}
          />
        )}

        {selectedSection === "finished" && (
          <DisplayJobs
            section={"finished"}
            acceptedJobsList={acceptedJobsList.finished}
            setClickedConnection={setClickedConnection}
            setShowModal={setShowModal}
          />
        )}

        {selectedSection === "feedback" && (
          <ReviewsScreen reviews={reviews} view={"worker"} />
        )}
        {selectedSection === "allcontracts" && (
          <DisplayJobsNew
            setClickedConnection={setClickedConnection}
            setShowModal={setShowModal}
            allContracts={allContracts}
            handleSearch={handleSearch}
            myAcceptedJobs={myAcceptedJobs}
            setSortParams={setSortParams}
            setPage={setPage}
            limit={limit}
            page={page}
            rows={rows}
            totalPages={totalPages}
          />
        )}
      </div>
      {showModal.rating && (
        <ModalSendRating
          setShowModal={setShowModal}
          showModal={showModal}
          employer={clickedConnection}
          setRefreshData={setRefreshData}
          setSelectedSection={setSelectedSection}
        />
      )}
      {showModal.end && (
        <ModalEditEndEarlyDate
          hire={clickedConnection}
          showModal={showModal}
          setShowModal={setShowModal}
          employer={clickedConnection}
          breakWorkerConnection={breakWorkerConnection}
        />
      )}
    </>
  );
};

export default AcceptedJobs;
