import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { options } from "src/Utils/content/Years";

interface YearPickerProps {
  setYear: Dispatch<SetStateAction<string>>;
  year: string;
  checking?: boolean;
  timespan?: string;
  job?: string;
  startingYearOptions?: { value: string; label: string }[];
  endingYearOptions?: { value: string; label: string }[];
}

function YearPicker({
  setYear,
  year,
  checking,
  timespan,
  job,
  startingYearOptions,
  endingYearOptions,
}: YearPickerProps) {
  const { t } = useTranslation();
  const [definedYear, setDefinedYear] = useState("");

  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const colourStyles = {
    control: () => ({
      border: "1px solid #A4211B",
      // "&:hover": {
      //   border
      // Color: "#2abeeb",
      // },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (job) {
      setDefinedYear(job?.split("-")[0]);
    }
  }, [job]);

  return (
    <div>
      <div className="relative" ref={ref}>
        {definedYear && (
          <Select
            styles={checking === true && !year ? colourStyles : colourStyles2}
            className="w-full cursor-pointer"
            options={!endingYearOptions ? options : endingYearOptions}
            defaultValue={{ label: definedYear, value: definedYear }}
            onFocus={handleMouseEnter}
            onBlur={handleMouseLeave}
            onChange={(e: any) => setYear(e.value)}
            placeholder={`${t(
              "createprofile.employment.placeholder.subtitle5_4.card.sortbyDropdown"
            )}`}
          />
        )}
        <>
          {timespan === "From" ? (
            <>
              {!definedYear && (
                <Select
                  styles={
                    checking === true && !year ? colourStyles : colourStyles2
                  }
                  className="w-full cursor-pointer"
                  options={
                    !startingYearOptions?.length ? options : startingYearOptions
                  }
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                  onChange={(e: any) => setYear(e.value)}
                  placeholder={`${t(
                    "createprofile.employment.placeholder.subtitle5_4.card.sortbyDropdown"
                  )}`}
                />
              )}
            </>
          ) : (
            <>
              {!definedYear && (
                <Select
                  styles={
                    checking === true && !year ? colourStyles : colourStyles2
                  }
                  className="w-full cursor-pointer"
                  options={
                    !endingYearOptions?.length ? options : endingYearOptions
                  }
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                  onChange={(e: any) => setYear(e.value)}
                  placeholder={`${t(
                    "createprofile.employment.placeholder.subtitle5_4.card.sortbyDropdown"
                  )}`}
                />
              )}
            </>
          )}
        </>
      </div>
      {/* <div
          className={`px-2 border cursor-pointer text-slate-600 text-sm mb-4 border-blue-100 w-full rounded h-10 outline-none transition duration-300 ${
            checking === true && !year
              ? "border-[#A4211B]"
              : "hover:border-azulBonico focus:border-azulBonico"
          }`}
          placeholder={`${timespan}, year`}
          value={year ? year : job?.substring(0, 4)}
          // onChange={(e) => searchItems(e.target.value)}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <p className="mt-[10px]">
            {year
              ? year
              : job?.substring(0, 4)
              ? job?.substring(0, 4)
              : `${timespan}, year`}
          </p>
        </div>
        <ArrowDown
          className="h-3 absolute right-4 top-4 cursor-pointer"
          onClick={() => setShowDropdown(!showDropdown)}
        /> */}
      {/* {showDropdown === true && (
          <div className="absolute top-10 overflow-auto border jobpost text-sm border border-blue-100 border-t-azulBonico rounded z-10 bg-white w-full max-h-52">
            <ul>
              {/* {skillsSearch?.length === 0 && (
                <div className="wrong-container">
                  <img className="exclamation" src={exclamation} alt="hey" /> No
                  results found... Please, try again!
                </div>
              )} */}
      {/* <li>
                <input
                  className="px-2 border-azulBonico border !w-full text-sm  pl-9 rounded !h-[34px] outline-none focus:border-azulBonico transition duration-300"
                  placeholder={`${timespan}, year`}
                  onChange={(e) => handleSearch(e.target.value)}
                ></input>
                <Search
                  className="h-4 absolute left-3 top-2 cursor-pointer"
                  // onClick={(e) =>
                  //   handleSearch(e.target.value)
                  // }
                />
              </li>
              {searchedDate
                ? searchedDate?.map((date) => {
                    return (
                      <li
                        className="hover:bg-blue-100 rounded pl-9 p-1 cursor-pointer"
                        onClick={(e) => {
                          setYear(e.target.textContent);
                          setShowDropdown(false);
                          setSearchedDate();
                        }}
                      >
                        {date.value}
                      </li>
                    );
                  })
                : options?.map((iear) => {
                    return (
                      <li
                        // className="hover:bg-blue-100 rounded p-1 cursor-pointer"
                        className="hover:bg-blue-100 rounded p-1 pl-9 cursor-pointer"
                        key={iear.value}
                        onClick={(e) => {
                          setYear(e.target.textContent);
                          setShowDropdown(false);
                          setSearchedDate();
                          //   whatIDGlobal(e);
                          //   setSkillsSearch();
                          //   onAddBtnClick(e);
                        }}
                      >
                        {iear.value}
                      </li>
                    );
                  })}
            </ul>
          </div> */}
      {/* )} } */}
      {/* </div> */}
    </div>
  );
}

export default YearPicker;
