import React, { useEffect, useState } from "react";
import { ReactComponent as Awesome } from "../../../assets/img/awesome.svg";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { VerticalLayout } from "../Layout/VerticalLayout";
import { useTranslation } from "react-i18next";
import { useModalStore } from "src/Hooks/useModalStore";
import ModalFeaturedProfile from "../Profile/ModalFeaturedProfile";
import { toast } from "react-hot-toast";
import { BASE_URL, BOOST_PROFILE } from "src/Config/api.config";
import { Button } from "src/Components/ui/Button";
import {
  useActionTypesStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { decryptArray } from "src/Hooks/decryptString";
import { useFormatActions } from "src/Routing/useCreditsActions";
import { useQuery } from "react-query";

function ProfileReady() {
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [featureStatus, setFeatureStatus] = useState(false);
  const navigate = useNavigate();
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [paramsActions, setParamsActions] = useFormatActions((state) => [
    state.formatTypes,
    state.setFormatTypes,
  ]);
  const [showModalFeaturedProfile, setShowModalFeaturedProfile] = useModalStore(
    (state) => [
      state.showModalFeaturedProfile,
      state.setShowModalFeaturedProfile,
    ]
  );
  const { t } = useTranslation();
  const actions = useActionTypesStore((s: any) => s.actions);
  const [availableValueCreditsLap, setAvailableValueCreditsLap] = useState("");
  const [featureValueCreditsLap, setFeatureValueCreditsLap] = useState("");

  useEffect(() => {
    if (typeof paramsActions === "string") {
      setAvailableValueCreditsLap(
        JSON.parse(paramsActions)?.filter(
          (param: { id: string; name: string; value: string }) =>
            param.name === "WORKER_PROFILE_LAP_BADGE"
        )[0]?.value
      );
      setFeatureValueCreditsLap(
        JSON.parse(paramsActions)?.filter(
          (param: { id: string; name: string; value: string }) =>
            param.name === "WORKER_PROFILE_LAP_BOOST"
        )[0]?.value
      );
    }
  }, [paramsActions]);
  const { updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "18"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "18"
    )[0]?.id
  );
  const featureWorkerData = new FormData();
  featureWorkerData.append("_method", "POST");
  featureWorkerData.append("client_id_worker", userInfo.client_id);
  const featureWorker = (laps: string) => {
    featureWorkerData.append("laps", laps);
    fetch(`${BASE_URL}${BOOST_PROFILE}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: featureWorkerData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowModalFeaturedProfile(showModalFeaturedProfile);
          updateCredits();
        } else if (data.status === "error") {
          toast.error(`${data?.result.error_msg}`);
        }
      })
      .catch((err) => console.log(err));
  };
  const isFeatured = async () => {
    const data = await fetch(
      `${BASE_URL}${BOOST_PROFILE}?client_id_worker=${userInfo.client_id}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: featuredData, refetch: refetchFeatured } = useQuery(
    ["featured", userInfo.client_id],
    isFeatured
  );
  useEffect(() => {
    if (featuredData?.boostProfile.length > 0) {
      setFeatureStatus(true);
    } else if (featuredData?.boostProfile.length === 0) {
      setFeatureStatus(false);
    }
  }, [featuredData]);
  return (
    // <div className="absolute top-0 left-0 h-screen w-full !bg-white overflow-y-hidden">
    <VerticalLayout>
      <div className="lg:max-w-xl px-10 lg:px-0 pt-20 mx-auto h-full flex flex-col justify-center items-center">
        <Awesome className="h-48 mt-10" />
        <div className="text-4xl font-semibold mt-12">
          {" "}
          <p>
            {t("worker.profile_ready.congratulations_profile")},{" "}
            {userInfo.first_name}!
          </p>
          {/* <p>{t("worker.profile_ready.congratulations_profile")}.</p> */}
        </div>
        <div className="flex flex-col items-center justify-center mt-6 text-[15px]">
          <p>{t("worker.profile_ready.congratulations_profile.desc1")}</p>
          <p>{t("worker.profile_ready.congratulations_profile.desc2")}</p>
        </div>
        <div className="flex items-center gap-4 mt-6 font-semibold tracking-wide">
          <button
            className="px-4 lg:px-8 py-[5px] text-azulBonico hover:bg-[#f7f4f4] border border-blue-100  bg-white  rounded-full transition duration-300"
            onClick={() => navigate("/profile")}
          >
            {t("worker.profile_ready.congratulations_profile.view")}
          </button>
          <button
            className="px-4 lg:px-8 py-[6px] text-white bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
            onClick={() => {
              if (profileType === "employer") {
                navigate("/dashboard");
              } else {
                navigate("/dashboard-worker");
              }
            }}
          >
            {t("worker.profile_ready.congratulations_profile.browse")}
          </button>
        </div>
        <div className="mt-10">
          <Button
            className="border bg-white px-4 py-1"
            onClick={() =>
              setShowModalFeaturedProfile(showModalFeaturedProfile)
            }
          >
            Upgrade to Premium
          </Button>
        </div>
      </div>
      {showModalFeaturedProfile && (
        <ModalFeaturedProfile
          onClick={featureWorker}
          featureStatus={featureStatus}
          featureValue={
            decryptArray(actions)?.filter(
              (action: { id: string; name: string; credits: string }) =>
                action.id === "18"
            )[0]?.credits
          }
          featureBadge={featureValueCreditsLap}
        />
      )}
    </VerticalLayout>
    // </div>
  );
}

export default ProfileReady;
