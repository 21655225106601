import React, { useEffect, useState } from "react";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";

import ProgressRegistration from "../../../Components/Worker/Registration/ProgressRegistration";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { BASE_URL, BASE_URL_IMG } from "../../../Config/api.config";
import ModalAddProfilePhoto from "../../../Components/Modals/ModalAddProfilePhoto";

import { ReactComponent as Spain } from "../../../assets/img/spain.svg";
import { ReactComponent as Australia } from "../../../assets/img/australia-rounded.svg";
import { ReactComponent as Plus } from "../../../assets/img/plus.svg";
import exclamation from "../../../assets/img/exclamation.png";
import account from "../../../assets/img/account-photo.png";

import "react-phone-input-2/lib/style.css";
import RegistrationHeader from "../../../Components/Worker/Registration/Header/RegistrationHeader";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useAllCountries } from "src/Hooks/useCountries";

function AddDetails() {
  const { t } = useTranslation();
  const [isHovering, setIsHovering] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showAddProfilePhotoModal, setShowAddProfilePhotoModal] =
    useState(false);
  const [imageUrlPlayer, setImageUrlPlayer] = useState("");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [countries, setCountries] = useState([]);
  const [preview, setPreview] = useState("");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", []);
  const [prefix, setPrefix] = useLocalStorage("prefix phone", "");
  const [citiesList, setCitiesList] = useState([]);
  const [selectedCitiesList, setSelectedCitiesList] = useState([]);
  const [postcodesList, setPostcodesList] = useState([]);
  const [selectedPostcodesList, setSelectedPostcodesList] = useState<any>([]);
  const [fullPhoneNumber, setFullPhoneNumber] = useLocalStorage("phone", "");
  const navigate = useNavigate();

  const workType = [
    {
      value: "In-Person",
      label: `${t(
        "createprofile.details.modelofwork.sortbyDropdown.textPlaceholder.text1"
      )}`,
      id: 1,
    },
    {
      value: "Remote",
      label: `${t(
        "createprofile.details.modelofwork.sortbyDropdown.textPlaceholder.text2"
      )}`,
      id: 2,
    },
    {
      value: "Hybrid",
      label: `${t(
        "createprofile.details.modelofwork.sortbyDropdown.textPlaceholder.text3"
      )}`,
      id: 3,
    },
    {
      value: "It does not matter",
      label: `${t(
        "createprofile.details.modelofwork.sortbyDropdown.textPlaceholder.text4"
      )}`,
      id: 4,
    },
  ];

  const getAllCities = (props: string) => {
    if (props !== "") {
      fetch(`${BASE_URL}cities?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setCitiesList(data.result))
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    const append: any = citiesList?.map(function (row: {
      name: string;
      id: string;
    }) {
      return {
        label: row.name,
        value: row.name,
        id: row.id,
      };
    });
    setSelectedCitiesList(append);
  }, [citiesList]);

  const getPostcodesFromCountry = (props: string) => {
    if (props !== "") {
      fetch(`${BASE_URL}postcodes?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setPostcodesList(data.result))
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const append = postcodesList?.map(function (row: {
      locality: string;
      state: string;
      postcode: string;
      id: string;
    }) {
      return {
        label: row.locality + "," + row.state + "," + row.postcode,
        value: row.locality + "," + row.state + "," + row.postcode,
        id: row.id,
      };
    });
    setSelectedPostcodesList(append);
  }, [postcodesList]);

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("address_street", userInfo.address_street);
  data.append("prefix_phone", `${prefix ? prefix : userInfo?.prefix_phone}`);

  const availableCountries = [
    {
      value: "Spain",
      label: (
        <div className="flex items-center gap-4">
          <Spain className="h-4" />
          {t("available_countries.spain")}{" "}
        </div>
      ),
    },
    {
      value: "Australia",
      label: (
        <div className="flex items-center gap-4">
          <Australia className="h-4" />
          Australia
        </div>
      ),
    },
  ];

  useEffect(() => {
    setUserInfo((prev: any) => ({
      ...prev,
      country_code_name: "AU",
      job_type: {
        value: "In-Person",
        label: "In-person",
        id: 1,
      },
    }));
  }, []);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const saveDetails = async () => {
    const response = await fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err))
      .catch((err) => {
        console.log(err);
      });
    return response;
  };

  const { countries: countriesData, refetchCountries } = useAllCountries();

  useEffect(() => {
    setCountries(countriesData);
  }, [countriesData]);

  useEffect(() => {
    if (countries.length < 1) {
      refetchCountries();
    }
  }, []);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },

      display: "flex",
      width: "200px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },

      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const onNextClick = () => {
    console.log("userInfo", userInfo);
    setUserInfo((prev: any) => ({
      ...prev,
      job_type: {
        id: 1,
        value: "In-Person",
        label: "In-person",
      },
    }));
    if (
      (userInfo.profile_image || preview) &&
      // userInfo.country_code_name &&
      userInfo.city &&
      userInfo.postcode &&
      // userInfo.job_type &&
      fullPhoneNumber
    ) {
      data.append("job_type", "1");
      data.append("city_id", userInfo?.city_id);
      data.append("post_code_id", userInfo?.postcode_id);
      data.append("country_code", "ES");
      data.append("phone", fullPhoneNumber?.slice(prefix?.length));
      setTimeout(() => {
        saveDetails();
        navigate("/create-profile/submit");
        setPreview("");
      }, 100);
    } else {
      setChecked(true);
    }
  };
  const removeImage = () => {
    setImageUrlPlayer("");
    setPreview("");
  };

  useEffect(() => {
    if (userInfo?.phone_num) {
      setFullPhoneNumber(userInfo?.phone_num);
    }
  }, [userInfo.phone_num]);
  const getData = async () => {
    const data = await fetch(`${BASE_URL}worker.profile?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: workerInfo } = useQuery(["workerInfo"], getData);

  useEffect(() => {
    setUserInfo((info: any) => ({
      ...info,
      profile_image: workerInfo?.workerProfile?.profile_image,
    }));
  }, [workerInfo]);

  // useEffect(() => {
  //   getData();
  // }, []);
  const handleOnChange = (value: string, data: { dialCode: string }) => {
    setFullPhoneNumber(value);
    setPrefix(data.dialCode);
  };

  return (
    <div className=" h-screen w-full !bg-white ">
      <RegistrationHeader />
      <div className="pt-12 mx-auto max-w-4xl place-items-center px-10 lg:px-0 flex flex-col min-h-[75vh] ">
        <div className="text-xl lg:text-4xl font-bold">
          {t("createprofile.details.title")}
        </div>
        <p className="pt-8 text-sm text-[#6e7081]">
          {t("createprofile.details.subtitle")}
        </p>{" "}
        <div className="mt-4">
          <p className="text-xs text-gray-500 font-medium">
            {t("createprofile.details.information_required")} *
          </p>
        </div>
        <div className="flex flex-col lg:flex-row mt-16">
          <div className="flex flex-col items-center lg:flex-[0_0_30%] lg:max-w-[30%]">
            {userInfo?.profile_image ? (
              <div
                onClick={removeImage}
                className="flex flex-col items-end filter hover:brightness-110 transition duration-150 transform hover:scale-105 cursor-pointer  p-4 animate__animated animate__slideInDown "
              >
                <img
                  src={`${BASE_URL_IMG}${userInfo?.profile_image}`}
                  className="h-32 w-32  object-cover -mb-4 rounded-full "
                  alt="profile"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                  }}
                />
              </div>
            ) : (
              <>
                <img src={account} alt="account" className="h-[80px] w-auto" />
              </>
            )}
            <div
              className=" relative  cursor-pointer top-0 right-0"
              onClick={() => setShowAddProfilePhotoModal(true)}
            >
              <div className="relative">
                <Plus
                  fill={"#2abeeb"}
                  className="h-3 absolute top-[22px] left-[14px] w-auto"
                />
                <button className="px-4 text-sm font-bold w-fit py-1 pl-8 text-azulBonico mt-3 hover:bg-[#f7f4f4] border border-blue-100 bg-white  rounded-full transition duration-300">
                  {t("createprofile.details.uploadPhotoBtn")}
                </button>
              </div>
            </div>
            {checked === true && !userInfo.profile_image ? (
              <div className="wrong-container">
                <img className="exclamation" src={exclamation} alt="hey" />{" "}
                <p>{t("createprofile.details.uploadPhotoBtn")}</p>
              </div>
            ) : null}
          </div>
          <div className="lg:flex-[0_0_70%] lg:max-w-[70%] flex-col border-l border-slate-300 pl-12 text-sm">
            <label>
              {t("createprofile.details.country.text")} *
              <Select
                styles={colourStyles}
                className="mt-2 w-fit"
                isSearchable={false}
                isDisabled={true}
                value={{
                  value: "Spain",
                  label: (
                    <div className="flex items-center gap-4">
                      <Spain className="h-4" />
                      {t("available_countries.spain")}{" "}
                    </div>
                  ),
                }}
                options={availableCountries}
                onFocus={handleMouseEnter}
                onBlur={handleMouseLeave}
                onChange={(e: any) =>
                  setUserInfo((info: any) => ({
                    ...info,
                    country_code_name: e.value,
                  }))
                }
              />
            </label>
            <div className="flex mt-4 text-sm">
              <label>
                {t("createprofile.details.streetAdress.text")}{" "}
                <span className="text-[#6e7081]">
                  {t("createprofile.details.streetAdress.subtitle.text")}
                </span>
                <input
                  type="text"
                  value={userInfo?.address_street}
                  className="w-full border h-10 pl-4 mt-2 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
                  onChange={(e) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      address_street: e.target.value,
                    }))
                  }
                />
              </label>
            </div>
            <div className="flex lg:flex-row flex-col mt-4 text-sm gap-4">
              <label className="relative">
                {t("createprofile.details.city")} *
                <Select
                  onInputChange={(e) => getAllCities(e)}
                  options={selectedCitiesList}
                  className="mt-2"
                  styles={colourStyles}
                  placeholder="Search Cities..."
                  value={{ label: userInfo?.city, value: userInfo?.city }}
                  onChange={(e: any) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      city: e.value,
                      city_id: e.id,
                    }))
                  }
                  noOptionsMessage={() =>
                    t("createprofile.details.ZIP/PostalCode.sortbyDropdown")
                  }
                />
                {checked === true && !userInfo.city ? (
                  <div className="wrong-container flex items-center space-x-2 mt-2">
                    <img
                      className="exclamation h-4 w-4"
                      src={exclamation}
                      alt="hey"
                    />{" "}
                    <p>{t("createprofile.details.city.NoCityAdded.Error")}</p>
                  </div>
                ) : null}
              </label>
              <label className="relative">
                {t("createprofile.details.ZIP/PostalCode")} *
                <Select
                  onInputChange={(e) => getPostcodesFromCountry(e)}
                  options={selectedPostcodesList}
                  className="mt-2"
                  styles={colourStyles}
                  placeholder="Search Postcodes..."
                  value={{
                    label: userInfo?.postcode,
                    value: userInfo?.postcode,
                  }}
                  onChange={(e: any) =>
                    setUserInfo((info: any) => ({
                      ...info,
                      postcode: e.value,
                      postcode_id: e.id,
                    }))
                  }
                  noOptionsMessage={() =>
                    t("createprofile.details.ZIP/PostalCode.sortbyDropdown")
                  }
                />
                {checked === true && !userInfo.postcode ? (
                  <div className="wrong-container flex items-center space-x-2 mt-2">
                    <img
                      className="exclamation h-4 w-4"
                      src={exclamation}
                      alt="hey"
                    />{" "}
                    <p>
                      {t(
                        "createprofile.details.city.NoZIP/PostalCodeAdded.Error"
                      )}
                    </p>
                  </div>
                ) : null}
              </label>
            </div>
            <div className="mt-4 text-sm">
              <label>
                {t("createprofile.details.Teléfono")} *
                <PhoneInput
                  country="es"
                  value={fullPhoneNumber}
                  onChange={handleOnChange}
                  inputStyle={{
                    border: "1px solid lightgrey",
                    // "&:hover": {
                    //   border: "1px solid #2abeeb",
                    // },
                    width: "inherit",
                    height: "2.5rem",
                  }}
                />
                {checked === true && !fullPhoneNumber ? (
                  <div className="wrong-container flex items-center space-x-2 mt-2">
                    <img
                      className="exclamation h-4 w-4"
                      src={exclamation}
                      alt="hey"
                    />{" "}
                    <p>
                      {t("createprofile.details.city.NoTelephoneAdded.Error")}
                    </p>
                  </div>
                ) : null}
              </label>
            </div>

            {/* <div className="mt-12 text-sm">
              <p className="">
                {parse(t("createprofile.details.modelofwork.text"))} *
              </p>
              <label>
                <Select
                  styles={colourStyles2}
                  className="mt-2 w-full"
                  options={workType}
                  placeholder={t(
                    "createprofile.details.modelofwork.sortbyDropdown.textPlaceholder"
                  )}
                  value={userInfo?.job_type ? userInfo?.job_type : null}
                  onFocus={handleMouseEnter}
                  onBlur={handleMouseLeave}
                  onChange={(e) => {
                    setUserInfo((info: any) => ({
                      ...info,
                      job_type: e,
                    }));
                    console.log(e);
                  }}
                />
                {checked === true && !userInfo.job_type ? (
                  <div className="wrong-container">
                    <img className="exclamation" src={exclamation} alt="hey" />{" "}
                    <p>Add your preferable work model</p>
                  </div>
                ) : null}
              </label>
            </div> */}
          </div>
        </div>
      </div>
      <ProgressRegistration
        onNextClick={onNextClick}
        backButton={"create-profile/schedule"}
        nextButtonText={t("createprofile.details.rightBtn")}
        progress="99"
      />
      {showAddProfilePhotoModal === true && (
        <ModalAddProfilePhoto
          setShowAddProfilePhotoModal={setShowAddProfilePhotoModal}
          // setPicturePreview={setPicturePreview}
          // picturePreview={picturePreview}
          refetchWorkerInfo={() => console.log("add profile")}
          showAddProfilePhotoModal={showAddProfilePhotoModal}
        />
      )}
    </div>
  );
}

export default AddDetails;
