import React, { Dispatch, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import Modal from "react-modal";

import { BASE_URL, DECLINE_REASONS } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { ModalProps } from "./ModalWorkerCard";

interface ModalDeclineApplicantProps {
  showModal: { decline: boolean };
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
  declineReason: string;
  setDeclineReason: Dispatch<SetStateAction<string>>;
  // declineOffer: (string: string) => void;
  setDeclineExplanation: Dispatch<SetStateAction<string>>;
  handleDeclineApplicant: () => void;
}

const ModalDeclineApplicant = ({
  showModal,
  setShowModal,
  setDeclineReason,
  setDeclineExplanation,
  declineReason,
  handleDeclineApplicant,
}: ModalDeclineApplicantProps) => {
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [optionsFetched, setOptionsFetched] =
    useState<{ name: string; id: string }[]>();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const getDeclinedOptions = () => {
    fetch(`${BASE_URL}${DECLINE_REASONS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setOptionsFetched(data.result))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getDeclinedOptions();
  }, []);

  const options = optionsFetched?.map(function (row) {
    return {
      value: row.name,
      label: row.name,
      id: row.id,
    };
  });

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "40px",
      transition: "300ms",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.decline && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.decline]);

  const closeModal = () => {
    setDeclineReason("");
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev) => ({ ...prev, decline: false }));
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal.decline} style={StylesModal} ariaHideApp={false}>
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black  z-[170]"> */}
      <div
        className={`fixed ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-auto   border translate-x-[-50%] left-[50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold px-8 py-6">
          {t("employer.modal.decline_applicant.title")}
        </div>
        <div className="text-sm px-8 py-3">
          <p>{t("employer.modal.decline_offers.desc")}</p>
          <div className="flex flex-col my-6 gap-2">
            <p className="font-bold">
              {t("employer.modal.decline_applicant.reason")}
            </p>
            <Select
              styles={colourStyles}
              className="w-full cursor-pointer text-sm"
              options={options}
              onChange={(e: any) => setDeclineReason(e.id)}
              placeholder={`${t("employer.select.please_select")}...`}
            />
          </div>
          <div className="flex flex-col gap-2 mb-6">
            <p className="font-bold">
              {t("employer.modal.decline_applicant.message")}
            </p>
            <textarea
              onChange={(e) => setDeclineExplanation(e.target.value)}
              className="w-full h-32 resize-none border border-blue-200 rounded hover:border-azulBonico outline-none px-4 py-2 transition duration-300"
            ></textarea>
          </div>
          <div className="flex gap-4 justify-end ">
            <Button
              className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100  bg-white  "
              onClick={() => closeModal()}
            >
              {t("employer.modal.decline_applicant.cancel")}
            </Button>
            <Button
              className={`px-8 py-2 ${
                declineReason
                  ? "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
                  : "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
              }   `}
              disabled={!declineReason}
              onClick={() => {
                setShowModal((prev) => ({
                  ...prev,
                  decline: false,
                  worker: false,
                }));
                handleDeclineApplicant();
              }}
            >
              {t("employer.modal.decline_applicant.decline")}
            </Button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default ModalDeclineApplicant;
