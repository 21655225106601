import { create } from "zustand";

interface ModalState {
    showModalInvite: boolean;
    setShowModalInvite: (showModalInvite: boolean) => void

    showModalFeatured: boolean;
    setShowModalFeatured: (showModalFeatured: boolean) => void;

    showModalOpenChat: boolean;
    setShowModalOpenChat: (showModalOpenChat: boolean) => void;

    showCreditsMenu: boolean;
    setShowCreditsMenu: (showCreditsMenu: boolean) => void

    showModalFeaturedProfile: boolean;
    setShowModalFeaturedProfile: (showModalFeatured: boolean) => void
    showModalAvailableProfile: boolean;
    setShowModalAvailableProfile: (showModalFeatured: boolean) => void

    showWalletMenu: boolean;
    setShowWalletMenu: (showWalletMenu: boolean) => void

    showInviteOptionsMenu: boolean;
    setShowInviteOptionsMenu: (showInviteOptionsMenu: boolean) => void

    showCloneModal: boolean;
    setShowCloneModal: (showCloneModal: boolean) => void

    showDeleteJob: boolean;
    setShowDeleteJob: (showDeleteJob: boolean) => void


    showNewOffer: boolean;
    setShowNewOffer: (showNewOffer: boolean) => void
    showPayForOffer: boolean;
    setShowPayForOffer: (showNewOffer: boolean) => void
}


export const useModalStore = create<ModalState>((set) => ({
    showModalInvite: false,
    setShowModalInvite: (modal) => set({ showModalInvite: !modal }),

    showModalFeatured: false,
    setShowModalFeatured: (modal) => set({ showModalFeatured: !modal }),

    showModalOpenChat: false,
    setShowModalOpenChat: (modal) => set({ showModalOpenChat: !modal }),

    showCreditsMenu: false,
    setShowCreditsMenu: (modal) => set({ showCreditsMenu: !modal }),

    showModalFeaturedProfile: false,
    setShowModalFeaturedProfile: (modal) => set({ showModalFeaturedProfile: !modal }),

    showModalAvailableProfile: false,
    setShowModalAvailableProfile: (modal) => set({ showModalAvailableProfile: !modal }),

    showWalletMenu: false,
    setShowWalletMenu: (modal) => set({ showWalletMenu: !modal }),

    showInviteOptionsMenu: false,
    setShowInviteOptionsMenu: (modal) => set({ showInviteOptionsMenu: !modal }),

    showCloneModal: false,
    setShowCloneModal: (modal) => set({ showCloneModal: !modal }),

    showDeleteJob: false,
    setShowDeleteJob: (modal) => set({ showDeleteJob: !modal }),

    showNewOffer: false,
    setShowNewOffer: (modal) => set({ showNewOffer: !modal }),

    showPayForOffer: false,
    setShowPayForOffer: (modal) => set({ showPayForOffer: !modal })


}))