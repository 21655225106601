import React, { useEffect, useState } from "react";
import { ReviewTypes } from "src/types/types";
import RatingStars from "../Rating/RatingStars";
import { useTranslation } from "react-i18next";

interface ReviewClientCardProps {
  review: ReviewTypes;
  showReviewsFirst: string;
  showModal: any;
}

const ReviewClientCard = ({
  review,
  showModal,
  showReviewsFirst,
}: ReviewClientCardProps) => {
  const { t, i18n } = useTranslation();
  const [reviewStartDate, setReviewStartDate] = useState<any>([]);
  const [reviewEndDate, setReviewEndDate] = useState<any>([]);
  const [formattedStartDate, setFormattedStartDate] = useState<any>("");
  const [formattedEndDate, setFormattedEndDate] = useState<any>("");

  useEffect(() => {
    setReviewStartDate(review.date_start.split("-"));

    if (review.date_early_end !== null) {
      setReviewEndDate(review.date_early_end.split("-"));
    } else {
      setReviewEndDate(review.date_end.split("-"));
    }
  }, [review]);

  useEffect(() => {
    setFormattedStartDate(
      `${new Date(
        reviewStartDate[0],
        Number(reviewStartDate[1]?.replace("0", "") - 1),
        reviewStartDate[2]
      )}`
    );
  }, [reviewStartDate]);

  useEffect(() => {
    setFormattedEndDate(
      `${new Date(
        reviewEndDate[0],
        Number(reviewEndDate[1]?.replace("0", "") - 1),
        reviewEndDate[2]
      )}`
    );
  }, [reviewEndDate]);
  return (
    <div
      key={review.worker_first_name}
      className="flex flex-col gap-1 border-b border-blue-100 px-6 py-3"
    >
      <div className="flex items-start gap-2">
        <p className="border font-bold rounded-full text-xs border-black text-black h-6 w-6 p-1 flex mr-2 justify-center items-center">
          {review.profile_image}
        </p>
        <p className="font-semibold text-[#333]">{review.job_title}</p>
        {/* <p className="font-bold">
          {showReviewsFirst === "worker"
            ? review.employer_first_name + " " + review.employer_last_name
            : review.worker_first_name + " " + review.worker_last_name}
        </p> */}
      </div>
      <div className="flex items-center font-bold gap-3">
        <div className=" flex flex-[0_0_16%] ">
          {review.score_from_employer !== "0.00" &&
          review.score_from_employer ? (
            <div className="flex gap-3">
              <div className="mt-[3px]">
                <RatingStars
                  showModal={false}
                  activeStar={
                    showReviewsFirst === "worker"
                      ? review.score_from_employer
                      : review.score_from_worker
                  }
                  evaluation={false}
                  score={"dont"}
                  size={14}
                  showHalf={true}
                />
              </div>
              {/* <p className="text-xs text-slate-500 mt-[3px]">
                                    ({review.score_from_employer})
                                  </p> */}
            </div>
          ) : (
            <div className="flex flex-col justify-between w-full">
              <div className="mt-[3px]">
                <RatingStars
                  showModal={false}
                  activeStar={0}
                  evaluation={false}
                  score={"dont"}
                  size={14}
                />
              </div>
              <p className="text-xs text-slate-500 mt-[3px]">
                ({t("worker.modal_jobcard.not_rated")})
              </p>
            </div>
          )}
        </div>
        {/* <div className="flex gap-3">
                              <p>
                                {showReviewsFirst === "worker"
                                  ? review.employer_first_name +
                                    " " +
                                    review.employer_last_name
                                  : review.worker_first_name +
                                    " " +
                                    review.worker_last_name}
                              </p>
                              <p>-</p> */}
        {/* <p>{review.job_title}</p> */}
        {/* </div> */}
      </div>
      <div className="flex items-center gap-1 justify-between">
        <p className="text-sm ">
          {/* {t("employer.send_offer.salary")}:  */}
          <span className="font-medium">{review.budget ?? "0"}€</span> /h{" "}
        </p>
        <p className="text-[13px] text-slate-500">
          {formattedStartDate?.slice(4, 10)} - {formattedEndDate?.slice(4, 10)}
        </p>
        {/* {!review.date_early_end &&
          review.date_start <= review.server_date &&
          review.server_date <= review.date_end && (
            <p className="text-sm text-slate-400">
              {i18n.language === "en" ? (
                <>
                  {t("worker.modal_jobcard.working_date")}{" "}
                  {review.date_start?.slice(8, 10)}
                  <span className="text-xs">{endingStart}</span>{" "}
                  {HandleMonthName(review.date_start?.slice(5, 7))} &apos;
                  {review.date_start?.slice(2, 4)}{" "}
                  {t("worker.modal_jobcard.until")}{" "}
                  {review.date_end?.slice(8, 10)}
                  <span className="text-xs">{endingEnd}</span>{" "}
                  {HandleMonthName(review.date_end?.slice(5, 7))} &apos;
                  {review.date_end?.slice(2, 4)}
                </>
              ) : (
                <>
                  {t("worker.modal_jobcard.working_date")}{" "}
                  {review.date_start?.slice(8, 10)} de{" "}
                  {HandleMonthNameES(review.date_start?.slice(5, 7))} &apos;
                  {review.date_start?.slice(2, 4)}{" "}
                  {t("worker.modal_jobcard.until")} el{" "}
                  {review.date_end?.slice(8, 10)} de{" "}
                  {HandleMonthNameES(review.date_end?.slice(5, 7))} &apos;
                  {review.date_end?.slice(2, 4)}
                </>
              )}
            </p>
          )}
        {review.date_early_end && (
          <p className="text-sm text-slate-400">
            {i18n.language === "en" ? (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)}
                <span className="text-xs">{endingStart}</span>{" "}
                {HandleMonthName(review.date_start?.slice(5, 7))} &apos;{" "}
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")}{" "}
                {review.date_early_end?.slice(8, 10)}
                <span className="text-xs">{endingEarlyEnd}</span>{" "}
                {HandleMonthName(review.date_early_end?.slice(5, 7))} &apos;{" "}
                {review.date_end?.slice(2, 4)}
              </>
            ) : (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_start?.slice(5, 7))} &apos;{" "}
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")} el{" "}
                {review.date_early_end?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_early_end?.slice(5, 7))} &apos;{" "}
                {review.date_end?.slice(2, 4)}
              </>
            )}
          </p>
        )}
        {!review.date_early_end && review.server_date > review.date_end && (
          <p className="text-sm text-slate-400">
            {i18n.language === "en" ? (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)}
                <span className="text-xs">{endingStart}</span>{" "}
                {HandleMonthName(review.date_start?.slice(5, 7))} &apos;
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")}{" "}
                {review.date_end?.slice(8, 10)}
                <span className="text-xs">{endingEnd}</span>{" "}
                {HandleMonthName(review.date_end?.slice(5, 7))} &apos;
                {review.date_end?.slice(2, 4)}
              </>
            ) : (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_start?.slice(5, 7))} &apos;
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")} el{" "}
                {review.date_end?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_end?.slice(5, 7))} &apos;
                {review.date_end?.slice(2, 4)}
              </>
            )}
          </p>
        )} */}
      </div>
      <p className="mt-1 italic">
        {showReviewsFirst === "worker"
          ? review.employer_review
          : review.worker_review}
      </p>
    </div>
  );
};

export default ReviewClientCard;
