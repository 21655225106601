import React, { useState, useEffect, useRef } from "react";
import {
  BASE_URL,
  CATEGORIES_WORKER,
  JOB_CATEGORIES,
} from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import Select from "react-select";
import Modal from "react-modal";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { getJobCateogries } from "src/Hooks/useApplicationProcess";

interface ModalEditCategoryProps {
  setShowModal: any;
  workerCategories: Array<{ name: string; category_id: string }>;
  setWorkerCategories: any;
  showEditCategoriesModal: boolean;
  refetchCategories: () => void;
}

function ModalEditCategory({
  workerCategories,
  setWorkerCategories,
  showEditCategoriesModal,
  setShowModal,
  refetchCategories,
}: ModalEditCategoryProps) {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [categorySearch, setCategorySearch] = useState<any>([]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [previousCategory, setPreviousCategory] = useState<any>([]);
  const [back, setBack] = useState(true);
  const [cancelData, setCancelData] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { isCategoriesLoading, jobCategoryData } = getJobCateogries();

  const jobCategories = () => {
    fetch(
      `${BASE_URL}${JOB_CATEGORIES}?category_id
          `,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!isCategoriesLoading) {
      setCategorySearch(jobCategoryData);
    }
  }, [jobCategoryData, isCategoriesLoading]);
  useEffect(() => {
    jobCategories();
    setPreviousCategory(workerCategories);
  }, []);
  //If user cancels instead of saving
  useEffect(() => {
    if (back === false) {
      setWorkerCategories(previousCategory);
    }
  }, [back]);

  //When user saves
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append(
    "category_list",
    workerCategories?.map((category) => category.category_id).toString()
  );

  const updateWorkerCategories = () => {
    fetch(`${BASE_URL}${CATEGORIES_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          refetchCategories();
          setShowModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  //React select settings
  const availableCategories = categorySearch?.map(function (row: any) {
    return {
      value: row.name,
      label: row.name,
      category_id: row.category_id,
      name: row.name,
    };
  });
  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  //To show and remove those buttons
  const addCategory = (category: { category_id: string; name: string }) => {
    let counter = 0;
    for (let i = 0; i <= workerCategories?.length; i++) {
      if (category.category_id === workerCategories[i]?.category_id) {
        counter++;
      }
    }
    if (counter === 0) {
      return setWorkerCategories((current: any) => [...current, category]);
    }
  };

  const removeHandler = (id: string) => {
    // let x = e.target.getAttribute("removecategory");
    // let y = e.target.parentElement.getAttribute("removecategory");
    setWorkerCategories(
      workerCategories.filter((items) => items.category_id !== id)
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditCategoriesModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditCategoriesModal]);

  const handleCancelation = () => {
    setWorkerCategories(previousCategory);
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    if (cancelData === true) {
      handleCancelation();
    }
  }, [cancelData]);

  const closeModal = () => {
    setCancelData(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showEditCategoriesModal} style={StylesModal}>
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[700px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] dark:border-border dark:bg-background bg-white border-[0.5px] dark:text-black`}
        ref={ref}
      >
        <div className="text-xl font-bold px-8 py-6 dark:border-border dark:border">
          {t("employer.job_post_edit.category")}
        </div>
        <div className="p-6">
          <p className="text-lg">{t("worker.edit_category.modal.desc")}</p>
          <p className="text-slate-400 font-normal text-base">
            {t("worker.edit_category.modal.desc2")}
          </p>
        </div>
        <div className="px-6 pb-6">
          <div className="flex flex-wrap max-h-24 text-sm overflow-auto mb-6">
            {workerCategories?.map((category) => {
              return (
                <div
                  key={category.category_id}
                  className="flex items-center gap-2 cursor-pointer px-2 py-0.5 mx-0.5 text-white bg-azulBonico my-0.5 border rounded-xl hover:bg-[#292e38]"
                  onClick={(e) => removeHandler(category.category_id)}
                  // removecategory={category.name}
                >
                  {/* <div removecategory={category.name} className="text-xs "> */}

                  <div className="text-xs">{category.name}</div>
                  {/* </div> */}
                  {/* <img
                      className="h-2.5 bg-white"
                      src={cross}
                      alt="plus"
                    /> */}

                  <Cross
                    removecategory={category.name}
                    className="h-[14px] cursor-pointer w-fit fill-white"
                  />
                </div>
              );
            })}
          </div>

          <Select
            options={availableCategories}
            styles={colourStyles}
            placeholder={t(
              "createprofile.submit.edityourCategories.placeholder"
            )}
            onChange={(e: any) => addCategory(e)}
            className="font-normal"
          />
        </div>
        <div className="flex w-full h-full items-end mt-12 p-4 gap-2 pr-4 justify-end ">
          <button
            className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 transition duration-300"
            onClick={() => {
              setBack(false);
              setTimeout(() => {
                closeModal();
              });
            }}
          >
            {t("worker.edit_category.modal.cancel")}
          </button>
          <button
            onClick={() => {
              setBack(true);
              updateWorkerCategories();
            }}
            className={` border-none  text-white w-[135px] rounded-[20px] text-[0.9rem] px-[0.6rem] py-[0] transition duration-300 h-10 ${
              workerCategories?.length >= 10 || workerCategories.length === 0
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
            }`}
            disabled={
              workerCategories?.length >= 10 || workerCategories.length === 0
            }
          >
            {t("worker.edit_category.modal.save")}
          </button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ModalEditCategory;
