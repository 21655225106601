import React, { useState, useEffect } from "react";
import { ReactComponent as HalfStar } from "../../assets/img/star-half-filled.svg";
import { ReactComponent as FilledStar } from "../../assets/img/star-filled.svg";
import { useTranslation } from "react-i18next";
import { cn } from "src/lib/utils";

interface RatingStarsProps {
  score?: string;
  activeStar: any;
  setActiveStar?: any;
  evaluation?: boolean;
  showHalf?: boolean;
  size?: string | number;
  area?: string;
  info?: { totalScores: string };
  reviews?: boolean;
  showModal?: any;
  align?: boolean;
}

const RatingStars = ({
  score,
  activeStar,
  setActiveStar,
  evaluation,
  showHalf,
  size,
  area,
  info,
  reviews,
  showModal,
  align,
}: RatingStarsProps) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const totalStars = 5;
  const handleClick = (index: number) => {
    switch (area) {
      case "cooperation":
        setActiveStar((prev: any) => ({ ...prev, cooperation: index }));
        break;
      case "skills":
        setActiveStar((prev: any) => ({ ...prev, skills: index }));
        break;
      case "availability":
        setActiveStar((prev: any) => ({ ...prev, availability: index }));
        break;
      case "requirements":
        setActiveStar((prev: any) => ({ ...prev, requirements: index }));
        break;
      case "rate":
        setActiveStar((prev: any) => ({ ...prev, rate: index }));
        break;
      case "communication":
        setActiveStar((prev: any) => ({ ...prev, communication: index }));
        break;
    }
  };

  useEffect(() => {
    if (activeStar !== "0.00" || activeStar !== null) {
      setIsClicked(true);
    }
  }, []);

  return (
    <div
      className={cn("flex flex-col items-center mt-3", !align && "items-start")}
    >
      <div className=" relative cursor-pointer flex items-center">
        {window.location.pathname !== "/my-jobs" && (
          <>
            {!showModal?.rating ||
              (!showModal && (
                <span className="text-black font-bold dark:text-whiteish text-[15px] mr-1">
                  {activeStar}
                </span>
              ))}
          </>
        )}
        <div className="flex items-center ">
          <span className="mr-2 font-medium text-sm">{score}</span>
          {[...new Array(totalStars)]?.map((arr, index) => {
            return (
              <div
                key={index}
                className="relative"
                onMouseEnter={() => {
                  if (!isClicked) {
                    handleClick(index + 1);
                  }
                }}
                onMouseLeave={() => {
                  if (!isClicked) {
                    setActiveStar(-1);
                  }
                }}
                onClick={() => {
                  if (score === "0.00" || score === null) {
                    handleClick(index + 1);
                    setIsClicked(true);
                  }
                }}
              >
                {Number.isInteger(activeStar) ||
                !showHalf ||
                activeStar?.slice(-1) === "0" ? (
                  <>
                    <div
                      className={`${
                        index + 1 <= activeStar ? "w-[100%]" : "w-[0%]"
                      } overflow-hidden absolute`}
                    >
                      <FilledStar
                        className={`${!size ? "h-[19px]" : `h-[${size}px]`} ${
                          isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                        }`}
                      />
                    </div>
                    <div>
                      <FilledStar
                        className={`${!size ? "h-[19px]" : `h-[${size}px]`} ${
                          isClicked ? "fill-[#d9d9d9]" : "fill-[#d9d9d9]"
                        }`}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`${
                        index + 1 <= activeStar ? "w-[100%]" : "w-[0%]"
                      } overflow-hidden absolute`}
                    >
                      <FilledStar
                        className={`${!size ? "h-[19px]" : `h-[${size}px]`} ${
                          isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                        }`}
                      />
                    </div>
                    <div
                      className={`${
                        index <= activeStar ? "w-[100%]" : "w-[0%]"
                      } overflow-hidden absolute`}
                    >
                      <HalfStar
                        className={`${!size ? "h-[19px]" : `h-[${size}px]`} ${
                          isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                        }`}
                      />
                    </div>
                    <div>
                      <FilledStar
                        className={`${!size ? "h-[19px]" : `h-[${size}px]`} ${
                          isClicked ? "fill-[#d9d9d9]" : "fill-[#d9d9d9]"
                        }`}
                        // <EmptyStar
                        //   className={`h-[19px] ${
                        //     isClicked ? "fill-[#ffd800]" : "fill-slate-500"
                        //   }`}
                      />
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>

        {/* <span className="ml-1">
          <ChevronDownIcon className="h-3 w-3  text-gray-500" />
        </span> */}
      </div>
      {window.location.pathname !== "/profile" && evaluation !== false && (
        <div className="relative mb-4 whitespace-nowrap">
          {1 <= activeStar && activeStar < 2 && (
            <p className="text-[#E22024] text-sm font-bold absolute">
              Poorly done!
            </p>
          )}
          {2 <= activeStar && activeStar < 3 && (
            <p className="text-[#F25B2A] text-sm font-bold absolute">
              Not good enough!
            </p>
          )}
          {3 <= activeStar && activeStar < 4 && (
            <p className="text-[#FDB041] text-sm font-bold absolute">
              Good enough!
            </p>
          )}
          {4 <= activeStar && activeStar < 5 && (
            <p className="text-[#91CB63] text-sm font-bold absolute">
              Very good!
            </p>
          )}
          {activeStar == 5 && (
            <p className="text-[#019545] text-sm font-bold absolute">
              Excellent done!
            </p>
          )}
        </div>
      )}
      {reviews && (
        <>
          {score !== "dont" && info && (
            <div className="relative mb-4 mt-2 whitespace-nowrap">
              <p
                className={`text-[${
                  size ?? "13"
                }px] text-center text-gray-500 font-medium`}
              >
                {info.totalScores} {t("worker.modal_job_card.rating_reviews")}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RatingStars;
