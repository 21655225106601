import React, { useEffect } from "react";
import Select from "react-select";

import useLocalStorage from "../../Hooks/UseLocalStorage";

import "../../index.css";
import { useAtom } from "jotai";
import { navBarSettingsWorker } from "../../Atoms/jotaiAtoms";
import { useTranslation } from "react-i18next";

export default function NotificationSettings() {
  const [userEmail, setUserEmail] = useLocalStorage("userEmail", "");
  const [decorationBar, setDecorationBar] = useAtom(navBarSettingsWorker);

  const { t } = useTranslation();

  useEffect(() => {
    setDecorationBar("notifications");
  }, []);
  // useEffect(() => {
  //   if (userInfo?.profile_type !== "worker") {
  //     navigate("/");
  //   }
  // }, []);
  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      // width: "330px",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const notifications = [
    {
      value: "All Activity",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.allactivity.muestra_notificaciones_para"
      )}`,
    },
    {
      value: "Important activity only",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.importantactivity.muestra_notificaciones_para"
      )}`,
    },
    {
      value: "Nothing",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.nothing.muestra_notificaciones_para"
      )}`,
    },
  ];
  const timespan = [
    {
      value: "Inmediate",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.muestra_notificaciones_para.immediate"
      )}`,
    },
    {
      value: "Every 15 minutes",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.muestra_notificaciones_para.15min"
      )}`,
    },
    {
      value: "Once an hour",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.muestra_notificaciones_para.1h"
      )}`,
    },
    {
      value: "Once a day",
      label: `${t(
        "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.correo_electronico.pantalla_principal.Select_placeholder.muestra_notificaciones_para.1day"
      )}`,
    },
  ];

  return (
    <div className="px-4  mt-4 lg:mt-0">
      {/* <h1 className="text-3xl font-medium mb-8">Notification Settings</h1> */}
      <div className="bg-background relative rounded-lg border w-full dark:text-whiteish">
        <div className=" flex flex-col ">
          <div className="flex justify-between items-center mb-6 px-8 py-6 border-b border-border">
            <p className="text-xl font-bold">
              {t("settings.notification_settings")}
            </p>
          </div>
          <div className="flex flex-col px-8 pb-8 border-b border-border">
            <p className="text-[18px] font-semibold tracking-wide">
              {t("settings.notification_settings.desktop")}
            </p>
            <p className="text-sm font-medium tracking-wide mt-5">
              {t("settings.notification_settings.show_notifications")}:
            </p>
            <div className="flex flex-col lg:flex-row items-start lg:items-center mt-3 gap-12">
              <Select
                placeholder={`${t(
                  "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.mensajes.pantalla_principal.Select_placeholder.muestra_notificaciones_para"
                )}`}
                options={notifications}
                styles={colourStyles}
                className="text-sm w-[200px]"
              />
              <label className="text-sm flex items-center gap-3">
                <input type="checkbox" />
                {t("settings.notification_settings.play_sound")}
              </label>
            </div>
            <p className="text-sm font-medium tracking-wide mt-5">
              {t("settings.notification_settings.increment_counter")}:{" "}
            </p>
            <Select
              placeholder={`${t(
                "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.mensajes.pantalla_principal.Select_placeholder.muestra_notificaciones_para"
              )}`}
              options={notifications}
              styles={colourStyles}
              className="mt-3 text-sm w-[200px]"
            />
          </div>

          <div className="flex flex-col px-8 py-8 border-b border-border">
            <p className="text-[18px] font-semibold tracking-wide">Mobile</p>
            <p className="text-sm font-medium tracking-wide mt-5">
              {t("settings.notification_settings.show_notifications")}:
            </p>
            <Select
              placeholder={`${t(
                "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.mensajes.pantalla_principal.Select_placeholder.muestra_notificaciones_para"
              )}`}
              options={notifications}
              styles={colourStyles}
              className="text-sm mt-3 w-[200px] "
            />
            <p className="text-sm font-medium tracking-wide mt-5">
              {t("settings.notification_settings.increment_counter")}:
            </p>
            <Select
              placeholder={`${t(
                "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.mensajes.pantalla_principal.Select_placeholder.muestra_notificaciones_para"
              )}`}
              options={notifications}
              styles={colourStyles}
              className="text-sm mt-3 w-[200px] "
            />
          </div>
          <div className="flex flex-col px-8 py-8">
            <p className="text-[18px] font-semibold tracking-wide">
              {t("settings.notification_settings.email")}
            </p>
            <p className="text-sm">
              ({t("settings.notification_settings.email_sending")}{" "}
              {userEmail?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")})
            </p>
            <p className="text-sm font-medium tracking-wide mt-5">
              {t("settings.notifcation_settings.send_mail")}:
            </p>
            <div className="flex flex-col lg:flex-row text-sm mt-3">
              <Select
                placeholder={`${t(
                  "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.mensajes.pantalla_principal.Select_placeholder.muestra_notificaciones_para"
                )}`}
                options={notifications}
                styles={colourStyles}
                className="w-[200px]  lg:pr-2 pr-0"
              />
              <Select
                placeholder={`${t(
                  "employer.leftbar.menu.buscartrabajo.my_profile.configuration.settings.ajustes_notificaciones.mensajes.pantalla_principal.Select_placeholder.muestra_notificaciones_para"
                )}`}
                options={timespan}
                styles={colourStyles}
                className="w-[200px]  mt-2 lg:mt-0"
              />
            </div>
            <label className="text-sm flex item-center gap-3 mt-6">
              <input type="checkbox" />
              {t("settings.notifcation_settings.only_sending")}
            </label>
          </div>
        </div>
      </div>

      <div className="bg-background relative rounded-lg border my-2 mb-32 dark:text-whiteish">
        <div className=" flex flex-col ">
          <div className="flex justify-between items-center mb-6 px-8 py-6 border-b border-border">
            <p className="text-xl font-semibold">
              {t("settings.notifcation_settings.email_updates")}
            </p>
          </div>
          <p className="px-8 pb-8 text-sm ">
            {t("settings.notifcation_settings.send_emails")}{" "}
            {userEmail?.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")}
          </p>
        </div>
      </div>
    </div>
  );
}
