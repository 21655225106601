import React, { useState } from "react";

import GoBackButton from "src/Components/ui/GoBack";
import { useNavigate } from "react-router-dom";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FaRegLightbulb } from "react-icons/fa";
const Reports = () => {
  const navigate = useNavigate();

  const [headerVisible, setHeaderVisible] = useState(true);
  const curr = new Date();
  const first = curr.getDate() - curr.getDay();
  const last = first + 6;

  const firstday = new Date(curr.setDate(first)).toDateString();
  const lastday = new Date(curr.setDate(last)).toDateString();

  return (
    <>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline mt-8 "
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      <div className="flex flex-col h-full mb-8">
        <p className="text-[30px] font-bold mt-[35px] h-full items-center">
          Manage your earnings
        </p>
      </div>

      <div className="bg-white rounded border dark:bg-background dark:text-whiteish ">
        <div className="flex border-b-2 border-gray-200 flex-col lg:flex-row w-full px-8 py-8 justify-between ">
          <div>
            <div className="flex items-center space-x-2">
              <h1 className=" font-semibold text-gray-600">Work in progress</h1>
              <BsFillQuestionCircleFill />
            </div>
            <h3 className="font-semibold text-lg">$0.00</h3>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h1 className="font-semibold text-gray-600">In review</h1>
              <BsFillQuestionCircleFill />
            </div>
            <h3 className="font-semibold space-x-2">$0.00</h3>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h1 className="font-semibold text-gray-600">Pending</h1>
              <BsFillQuestionCircleFill />
            </div>
            <h3 className="font-semibold space-x-2">$0.00</h3>
          </div>
          <div>
            <div className="flex items-center space-x-2">
              <h1 className="font-semibold text-gray-600">Available</h1>
              <BsFillQuestionCircleFill />
            </div>
            <h3 className="font-semibold space-x-2">$0.00</h3>
          </div>
        </div>
        <div className="flex w-full px-8 py-8 justify-between">
          <div>
            <p>
              Timesheet for {firstday.split(" ")[1]} {firstday.split(" ")[2]} -{" "}
              {lastday.split(" ")[2]} (this week) in progress
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <FaRegLightbulb className="text-green-500" />

            <p className="text-sm font-medium text-green-500">
              When will I get paid?
            </p>
          </div>
        </div>
        <table className="w-full px-8 py-8 justify-between">
          <thead className="border-b border-border">
            <tr>
              <th>Job</th>
              <th>MON</th>
              <th>TUE</th>
              <th>WED</th>
              <th>THU</th>
              <th>FRI</th>
              <th>SAT</th>
              <th>SUN</th>
              <th>HOURS</th>
              <th>RATE</th>
              <th>AMOUNT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center text-azulBonico font-medium py-2">
                Clening House for grandmas
              </td>
              <td className="text-center text-azulBonico font-medium">1:40</td>
              <td className="text-center text-azulBonico font-medium">0:30</td>
              <td className="text-center text-azulBonico font-medium">2:20</td>
              <td className="text-center font-medium">-</td>
              <td className="text-center font-medium">-</td>
              <td className="text-center font-medium">-</td>
              <td className="text-center font-medium">-</td>
              <td className="text-center font-medium ">4:30</td>
              <td className="text-center font-medium ">$195/hr</td>
              <td className="text-center font-medium ">$877.50</td>
            </tr>
          </tbody>
        </table>
        <div className=" w-full border-t border-border mt-10 py-4">
          <div className="ml-auto flex justify-end  items-center space-x-12 mr-7">
            <p className="text-center font-medium">4:30</p>
            <p className="text-center font-medium">$195/hr</p>
            <p className="text-center font-medium">6,025.84</p>
          </div>
        </div>
        <div className="px-8 py-8">
          <p className="text-sm font-medium text-gray-500">
            Note: This report is updated every hour.
          </p>
        </div>
      </div>
    </>
  );
};

export default Reports;
