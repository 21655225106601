import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  BASE_URL,
  CLOSE_JOB_EMPLOYER,
  CLOSE_JOB_REASONS,
} from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { userProfileStore } from "src/Routing/useProfileStore";

interface ModalCloseJobsProps {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
  job: { job_id: string };
}

const ModalCloseJob = ({
  showModal,
  setShowModal,
  job,
}: ModalCloseJobsProps) => {
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [reasons, setReasons] = useState<any>();
  const [closingReason, setClosingReason] = useState("");
  const [availableReasons, setAvailableReasons] = useState();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const getClosingReasons = () => {
    fetch(`${BASE_URL}${CLOSE_JOB_REASONS}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setReasons(data.result))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getClosingReasons();
  }, []);

  const closeData = new FormData();
  closeData.append("_method", "POST");
  closeData.append("client_id", userID);
  closeData.append("job_id", job?.job_id);
  const closeJob = () => {
    closeData.append("job_close_reason_id", closingReason);
    fetch(`${BASE_URL}${CLOSE_JOB_EMPLOYER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: closeData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (
            window.location.pathname === "/dashboard" ||
            window.location.pathname === "/dashboard-worker"
          ) {
            window.location.reload();
          } else {
            if (profileType === "employer") {
              navigate("/dashboard");
            } else {
              navigate("/dashboard-worker");
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (reasons) {
      setAvailableReasons(
        reasons?.map(function (row: any) {
          return { value: row.name, label: row.name, id: row.id };
        })
      );
    }
  }, [reasons]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      transition: "300ms",
      display: "flex",
      fontWeight: "300",
      color: "black",
      letterSpacing: "0.05em",
      backgroundColor: "#fff",
      padding: "0rem",
      paddingLeft: "0.5rem",
      width: "270px",
      borderRadius: "5px",
      height: "40px",
    }),
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        setShowModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 cursor-default transition duration-150 w-[90%] lg:w-[600px] left-[50%] border translate-x-[-50%] rounded top-[45%] translate-y-[-50%] bg-white border-[0.5px]`}
          onClick={(e) => e.stopPropagation()}
          ref={ref}
        >
          <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
            {t("modal.close_job.title")}
          </div>
          <div className="text-sm px-8 pt-6">{t("modal.close_job.desc")}</div>
          <div className="px-8 pb-16 mt-8  text-slate-500 text-sm flex items-center gap-2">
            <p>{t("modal.close_job.reason_for_closing")}</p>
            <Select
              styles={colourStyles}
              options={availableReasons}
              placeholder={`${t("employer.select.please_select")}...`}
              onChange={(e: any) => setClosingReason(e.id)}
            />
          </div>
          <div className="flex w-full h-full p-4 gap-2 justify-end">
            <Button
              className=" text-azulBonico w-32 hover:underline  text-[0.9rem] px-[1rem]  h-10 "
              onClick={() => setShowModal(false)}
            >
              {t("modal.close_job.cancel")}
            </Button>
            <Button
              type="button"
              disabled={!closingReason ? true : false}
              className={`border-none text-white w-fit rounded-[20px] text-[0.9rem] px-[1rem]  h-10  ${
                !closingReason
                  ? "cursor-not-allowed bg-blancoGris text-[#333] opacity-40"
                  : "bg-azulBonico hover:bg-azulOscuro"
              }  `}
              onClick={() => {
                closeJob();
              }}
            >
              {t("modal.close_job.close_job")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCloseJob;
