import React, {
  useEffect,
  useState,
  useRef,
  lazy,
  Dispatch,
  SetStateAction,
} from "react";

const Loader = lazy(() => import("src/Components/Loader/Loader"));

import { ReactComponent as Work } from "../../assets/img/work.svg";
import { ReactComponent as Offers } from "../../assets/img/offers.svg";
import { ReactComponent as Reject } from "../../assets/img/reject.svg";
import { Button } from "../ui/Button";
import { useTranslation } from "react-i18next";

interface ModalEditAvailabilityProps {
  setShowModalAvailability: Dispatch<SetStateAction<boolean>>;
  showModalAvailability: boolean;
  setAvailability: any;
  availability: string;
}

function ModalEditAvailability({
  setShowModalAvailability,
  showModalAvailability,
  setAvailability,
  availability,
}: ModalEditAvailabilityProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: { target: any }) => {
      if (
        showModalAvailability &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModalAvailability]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModalAvailability(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      {" "}
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[90%] lg:w-[28%] left-[50%] border translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
            Edit Availability
          </div>
          <div className="p-8">
            <p className="text-sm">
              Use this space to show employers your availability.
            </p>
            <div className="flex gap-5 text-center items-center align-bottom mt-12">
              <div
                className={`flex-[33%] h-32 border hover:border-[#14A800] border-slate-200 rounded cursor-pointer hover:bg-[#f7f4f4] ${
                  availability === "Available now" &&
                  "border-[#14A800] bg-[#f7f4f4]"
                }`}
                onClick={() => setAvailability("Available now")}
              >
                <p className="mt-3">Open to work</p>{" "}
                <Work
                  // stroke={"#14A800"}
                  className="h-16 flex w-full mt-3 items-center justify-center"
                />
              </div>
              <div
                className={`flex-[33%] h-32 border hover:border-[#FF7D01] border-slate-200 rounded cursor-pointer hover:bg-[#f7f4f4] ${
                  availability === "Listening offers..." &&
                  "border-[#FF7D01] bg-[#f7f4f4]"
                }`}
                onClick={() => setAvailability("Listening offers...")}
              >
                <p className="mt-5">I can listen offers</p>{" "}
                <Offers
                  // stroke={"#14A800"}
                  className="h-16 flex w-full mt-3 items-center justify-center"
                />
              </div>
              <div
                className={`flex-[33%] h-32 border hover:border-red-600 border-slate-200 rounded cursor-pointer hover:bg-[#f7f4f4] ${
                  availability === "Currently not available" &&
                  "border-red-600 bg-[#f7f4f4]"
                }`}
                onClick={() => setAvailability("Currently not available")}
              >
                <p className="mt-5">Currently not searching</p>{" "}
                <Reject className="h-16 flex w-full mt-3 items-center justify-center" />
              </div>
            </div>
          </div>

          <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end mt-6">
            <Button
              className={` border-none   w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]   h-10 ${
                loading
                  ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                  : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
              } `}
              onClick={(e) => {
                //   updateBio();
                setLoading(true);
                setTimeout(() => {
                  closeModal();
                  // window.location.reload(false);
                }, 200);
              }}
            >
              {t("worker.edit_job_type.modal.save")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEditAvailability;
