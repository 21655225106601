import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import ProgressBarOffer from "../../../Components/ViewJobPost/ProgressBarOffer";
import InviteWorkersNavBar from "../../../Components/ViewJobPost/InviteWorkersNavBar";
import SearchWorkers from "../../../Components/ViewJobPost/SearchWorkers";
import InvitedWorkers from "../../../Components/ViewJobPost/InvitedWorkers";
import { BASE_URL } from "../../../Config/api.config";
import { ApplicantTypes } from "../../../types/types";
import { useQuery } from "react-query";
import { getJobInfo } from "src/Hooks/useApplicationProcess";

export default function InviteWorkers() {
  const [selectedSection, setSelectedSection] = useState("search");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [jobData, setJobData] = useLocalStorage("jobData");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [applicantsData, setApplicantsData] = useState<ApplicantTypes[]>([]);
  const [refreshData, setRefreshData] = useState(false);

  const { isJobLoading, jobInfoData, refetchEditJob } = getJobInfo(jobID);

  useEffect(() => {
    if (!isJobLoading) {
      setJobData(jobInfoData);
    }
  }, [jobInfoData, isJobLoading]);

  const getJobMatches = async () => {
    const data = await fetch(
      `${BASE_URL}employer.matches?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return data;
  };

  const {
    data: jobMatchesData,
    isLoading,
    refetch: refetchJobMatches,
  } = useQuery(["job_matches", jobID], getJobMatches);

  useEffect(() => {
    setApplicantsData(jobMatchesData?.result);
  }, [jobMatchesData]);

  return (
    <>
      <ProgressBarOffer
        props={"invite"}
        jobData={jobData && jobData.data?.[0].job_status}
      />
      <div className="flex flex-col w-full bg-white mt-8 rounded border dark:bg-background dark:border dark:border-border dark:text-whiteish">
        <InviteWorkersNavBar
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />

        {selectedSection === "search" && (
          <SearchWorkers
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            setRefreshData={setRefreshData}
            isLoading={isLoading}
            refetch={refetchJobMatches}
          />
        )}
        {selectedSection === "invited" && (
          <InvitedWorkers
            refetch={refetchJobMatches}
            setSelectedSection={setSelectedSection}
            applicantsData={applicantsData.filter(
              (applicant) => applicant.invited === "true"
            )}
            setApplicantsData={setApplicantsData}
            setRefreshData={setRefreshData}
          />
        )}
      </div>
    </>
  );
}
