import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";
import { useState } from "react";

const useIncreaseCredits = (price: number, actionId: string) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [creditsDataInfo, setCreditsDataInfo] = useState("");

  // const purchaseActionId = 8;

  const creditsData = new FormData();
  creditsData.append("_method", "POST");
  creditsData.append("client_id", userID);
  creditsData.append("credits", `${price?.toString()}`);
  creditsData.append("action_id", `${actionId}`);

  const increaseCredits = async () => {
    const data = await fetch(`${BASE_URL}clients.wallet`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: creditsData,
    })
      .then((res) => res.json())
      .then((data) => setCreditsDataInfo(data.result))
      .catch((err) => console.log(err));
  };

  return { increaseCredits, creditsDataInfo };
};
export default useIncreaseCredits;
