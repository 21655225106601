import React, {
  useRef,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  lazy,
} from "react";
import { v4 as uniqueID } from "uuid";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import YearPicker from "../Time/YearPicker";
import { BASE_URL, EDUCATION_WORKER } from "../../Config/api.config";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

import exclamation from "../../assets/img/exclamation.png";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { options } from "src/Utils/content/Years";

interface ModalAddEducationProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showAddEducationModal: boolean;
  startingYear: string;
  setStartingYear: Dispatch<SetStateAction<string>>;
  endingYear: string;
  setEndingYear: Dispatch<SetStateAction<string>>;
  refetch: () => void;
}

export default function ModalAddEducation({
  setShowModal,
  showAddEducationModal,
  startingYear,
  setStartingYear,
  endingYear,
  setEndingYear,
  refetch,
}: ModalAddEducationProps) {
  const { t } = useTranslation();
  const [allEducations, setAllEducations] = useLocalStorage(
    "EducationData",
    []
  );
  const [education, setEducation] = useState({
    school: "",
    degree: "",
    area: "",
    date_start: "",
    date_end: "",
    description: "",
    id: "",
  });
  const unique_id = uniqueID();
  const [checking, setChecking] = useState(false);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append("institution_name", education.school);
  data.append("area_of_study", education.area);
  data.append("start_date", `${startingYear}-01-01`);
  data.append("description", education.description);
  data.append("degree", education.degree);

  const saveEducation = () => {
    fetch(`${BASE_URL}${EDUCATION_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          refetch();
          setShowModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAddition = () => {
    if (endingYear) {
      data.append("end_date", `${endingYear}-01-01`);
    }
    if (education.school && education.area && startingYear) {
      saveEducation();
      setLoading(true);
    } else {
      setChecking(true);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showAddEducationModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAddEducationModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  const arr = [];
  const secondArr: { value: string; label: string }[] = [];

  for (const item of options) {
    arr.push(item);
    if (item.value === startingYear) {
      break;
    }
  }

  for (const item of options) {
    secondArr.push(item);
    if (item.value === endingYear) {
      break;
    }
  }

  const intersection = options.filter((x) => !secondArr.includes(x));

  //pablo was here. hope u have a good day :}
  return (
    <Modal
      isOpen={showAddEducationModal}
      style={StylesModal}
      ariaHideApp={false}
    >
      {" "}
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      {/* {loading === false ? ( */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[700px] max-h-[90vh] overflow-y-auto left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityoureducationhistory.title")}
        </div>
        <form>
          <div className="flex flex-col">
            <label className="text-sm px-8 pt-4">
              <p className="font-bold">
                {t("worker.modal_education.school")} *
              </p>
              <input
                className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded transition duration-300 ${
                  checking === true && education.school.length === 0
                    ? "border-[#A4211B]"
                    : "hover:border-azulBonico focus:border-azulBonico"
                }`}
                placeholder={`${t(
                  "createprofile.submit.edityoureducationhistory.subtitle1.card.placeholder"
                )}`}
                onChange={(e) =>
                  setEducation((education) => ({
                    ...education,
                    school: e.target.value,
                  }))
                }
              ></input>
              {education.school.length === 0 && checking === true && (
                <div className="wrong-container text-sm">
                  <img className="exclamation" src={exclamation} alt="hey" />{" "}
                  {t("worker.modal_education.add_school")}
                </div>
              )}
            </label>
          </div>
          <div className="flex flex-col">
            <div className="text-sm px-8 pt-4">
              <p className="font-bold">{t("worker.modal_education.course")}</p>
              <input
                className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded transition duration-300 ${
                  education.area.length === 0 && checking === true
                    ? "border-[#A4211B]"
                    : "hover:border-azulBonico focus:border-azulBonico"
                } `}
                placeholder={`${t(
                  "createprofile.submit.edityoureducationhistory.subtitle2.card.placeholder"
                )}`}
                onChange={(e) =>
                  setEducation((education) => ({
                    ...education,
                    area: e.target.value,
                  }))
                }
              ></input>
              {education.area.length === 0 && checking === true && (
                <div className="wrong-container text-sm">
                  <img className="exclamation" src={exclamation} alt="hey" />{" "}
                  {t("worker.modal_education.add_fields")}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm px-8 pt-4">
              <p className="font-bold">
                {t("worker.modal_education.area_study")} *
              </p>
              <input
                className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded transition duration-300 
                      hover:border-azulBonico focus:border-azulBonico`}
                placeholder={`${t(
                  "createprofile.submit.edityoureducationhistory.subtitle3.card.placeholder"
                )}`}
                onChange={(e) => {
                  setEducation((education) => ({
                    ...education,
                    degree: e.target.value,
                  }));
                  setEducation((education) => ({
                    ...education,
                    id: unique_id,
                  }));
                }}
              />
            </div>
          </div>

          <div className="text-sm px-8 pt-4 ">
            <p className="font-bold">
              {t("worker.modal_education.dates_attended")}*
            </p>
            <div className="flex flex-col lg:flex-row gap-4 mt-3 ">
              <div className="flex flex-col flex-1">
                <YearPicker
                  setYear={setStartingYear}
                  year={startingYear}
                  checking={checking}
                  timespan={"From"}
                  startingYearOptions={intersection}
                  // startingYearOptions={intersection}
                />
                {!startingYear && checking === true && (
                  <div className="wrong-container text-sm">
                    <img className="exclamation" src={exclamation} alt="hey" />{" "}
                    {t("worker.modal_education.add_start_date")}
                  </div>
                )}
              </div>
              <div className="flex flex-col flex-1">
                <YearPicker
                  setYear={setEndingYear}
                  year={endingYear}
                  checking={false}
                  timespan={"Through"}
                  endingYearOptions={arr}
                />
              </div>
            </div>
          </div>
          <div className="px-8">
            <p className="text-sm mb-3 pt-4 font-bold">
              {t("worker.modal_education.description")}
            </p>
            <textarea
              className="w-full h-40 p-2 px-4 text-sm border outline-none border-blue-100 rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              onChange={(e) => {
                setEducation((education) => ({
                  ...education,
                  description: e.target.value,
                }));
              }}
            ></textarea>
          </div>
        </form>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro mt-1 "
            onClick={() => {
              closeModal();
            }}
          >
            {t("worker.modal_education.cancel")}
          </Button>
          <Button
            className="bg-azulBonico border-none  hover:bg-azulOscuro text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] h-10"
            onClick={(e: any) => handleAddition()}
          >
            {t("worker.modal_education.save")}
          </Button>
        </div>
      </div>
      {/* ) : (
        <Loader />
      )} */}
      {/* </div> */}
    </Modal>
  );
}
