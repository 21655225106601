import React, { useState, useEffect, useCallback } from "react";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { Categories, Cities, Postcode } from "src/types/types";
import Select from "react-select";
import { useAtom } from "jotai";
import {
  budgetEnd,
  budgetStart,
  categoriesArrayId,
  citiesArrayId,
  cityNameInput,
  jobTypeArrayId,
  mapHoursDayParams,
  postcodesArrayId,
  saveTypeId,
  workerInput,
} from "src/Atoms/jotaiAtoms";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { hoursInitial } from "src/Utils/content/Hours";
import { Button } from "src/Components/ui/Button";
import uuid from "react-uuid";
import { debounce } from "src/Utils/debounce";
import { useCategories } from "src/Hooks/useCategories";
import { useCities } from "src/Hooks/useCities";

export interface TimeSelection {
  day: string;
  hour_from: string;
  hour_to: string;
}
export const INITIAL_TIME_HOUR = {
  day: "",
  hour_from: "",
  hour_to: "",
};

export interface HoursSelectionType {
  day: string;
  hour_from: string;
  hour_to: string;
  id: string;
}

export interface SelectedPostcodeFilter {
  checked: boolean;
  class: string;
  id: string;
  label: string;
  postcode: string;
  state: string;
  value: string;
}

const FilterWorkers = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [cities, setCities] = useState<Cities[]>([]);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [allPostcodes, setAllPostcodes] = useState<Postcode[]>([]);
  const [postcodeIsSet, setPostcodeIsSet] = useState(false);
  const [citiesToShow, setCitiesToShow] = useState<Cities[]>([]);
  const [selectedHoursDay, setSelectedHoursDay] = useState<any>([]);
  const [selectedPostcodes, setSelectedPostCodes] = useState<
    SelectedPostcodeFilter[]
  >([]);
  const [hourTimeSelection, setHourTimeSelection] =
    useState<TimeSelection>(INITIAL_TIME_HOUR);

  const [categoriesIds, setCategoriesIds] = useAtom(categoriesArrayId);
  const [citiesId, setCitiesId] = useAtom(citiesArrayId);
  const [savedType, setSavedType] = useAtom(saveTypeId);
  const [jobType, setJobType] = useAtom(jobTypeArrayId);
  const [budgetValueFrom, setBudgetValueFrom] = useAtom(budgetStart);
  const [budgetValueTo, setBudgetValueTo] = useAtom(budgetEnd);
  const [categoriesToShow, setCategoriesToShow] = useState<Categories[]>([]);
  const [postcodeIds, setPostcodeIds] = useAtom(postcodesArrayId);
  const [mappedDayHours, setMappedDayHours] = useState<any>([]);
  const [mappedHoursOfDay, setMappedHoursDay] = useAtom(mapHoursDayParams);
  const [cityName, setCityName] = useAtom(cityNameInput);
  const [findWorkerInput, setFindWorkerInput] = useAtom(workerInput);
  const { t } = useTranslation();
  const { categories: categoriesData } = useCategories();

  const days = [
    {
      label: `${t(
        "worker.findwork.body.left.time availability.placeholder1.day.left.up.sortbyDropdown.day1"
      )}`,
      value: "Mon",
    },
    {
      label: `${t(
        "worker.findwork.body.left.time availability.placeholder1.day.left.up.sortbyDropdown.day2"
      )}`,
      value: "Tue",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day3"
      )}`,
      value: "Wed",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day4"
      )}`,
      value: "Thu",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day5"
      )}`,
      value: "Fri",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day6"
      )}`,
      value: "Sat",
    },
    {
      label: `${t(
        "worker.findwork.body.left.disponibilidadhoria.placeholder1.day.left.up.sortbyDropdown.day7"
      )}`,
      value: "Sun",
    },
  ];

  const { cities: citiesData, refetchCities } = useCities();

  useEffect(() => {
    setCities(citiesData);
  }, [citiesData]);

  const optionsCities = cities?.map((city) => {
    return { value: city.id, label: city.name };
  });

  useEffect(() => {
    setCategories(categoriesData);
  }, [categoriesData]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
      width: "180px",
    }),
  };

  const colourStylesCities = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
      width: "240px",
    }),
  };

  const optionsCategories = categories?.map((category) => {
    return { value: category.category_id, label: category.name };
  });

  const optionsPostcodes = allPostcodes?.map((postcode) => {
    return {
      label:
        postcode.locality + ", " + postcode.state + ", " + postcode.postcode,
      value:
        postcode.locality + ", " + postcode.state + ", " + postcode.postcode,
      state: postcode.state,
      class: "postcode",
      postcode: postcode.postcode,
      id: postcode.id,
      checked: false,
    };
  });

  const handleChangeCategory = (categoryId: string) => {
    if (!categoriesIds.includes(categoryId)) {
      setCategoriesIds((prev) => [...prev, categoryId]);
    } else {
      setCategoriesIds(categoriesIds.filter((id) => id !== categoryId));
    }
  };

  const handleFilterCategory = (categoryId: string) => {
    const selectedCategory: any = categories.find(
      (category) => category.category_id === categoryId
    );
    if (!categoriesToShow.includes(selectedCategory)) {
      setCategoriesToShow((prev) => [...prev, selectedCategory]);
    } else {
      setCategoriesToShow(
        categoriesToShow.filter(
          (category) => category.category_id !== categoryId
        )
      );
    }
  };

  const handleRemoveCategory = (categoryId: string) => {
    setCategoriesIds(
      categoriesIds.filter((category) => category !== categoryId)
    );

    setCategoriesToShow(
      categoriesToShow.filter((category) => category.category_id !== categoryId)
    );
  };

  const handleChangeJobCity = (cityId: string) => {
    if (!citiesId.includes(cityId)) {
      setCitiesId((prev) => [...prev, cityId]);
    } else {
      setCitiesId(citiesId.filter((id) => id !== cityId));
    }
  };

  const handleFilterJobCity = (cityId: string) => {
    const selectedCity: any = cities.find((city) => city.id === cityId);
    setCitiesToShow((prev) => [...prev, selectedCity]);
  };

  const handleRemoveJobCity = (cityId: string) => {
    setCitiesId(citiesId.filter((city) => city !== cityId));
    setCitiesToShow(citiesToShow.filter((city) => city.id !== cityId));
  };
  const getAllPostcodes = (props: string) => {
    fetch(`${BASE_URL}postcodes?country_code=es&name=${props}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    })
      .then((res) => res.json())
      .then((data) => setAllPostcodes(data.result))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllPostcodes("");
  }, []);

  const handlePostcodesFilter = (postcode: any, action: { action: string }) => {
    if (action.action === "select-option") {
      setSelectedPostCodes((prev) => [...prev, postcode]);
    } else if (action.action === "clear") {
      setSelectedPostCodes([]);
    }
  };

  const handleRemovePostcode = (postcodeId: string) => {
    setSelectedPostCodes(
      selectedPostcodes.filter((postcode) => postcode.id !== postcodeId)
    );
  };

  const handleChangeJobType = (e: string) => {
    if (!jobType.includes(e)) {
      setJobType((prev: string[]) => [...prev, e]);
    } else {
      setJobType(jobType.filter((type) => type !== e));
    }
  };

  const removeChangeJobType = (e: string) => {
    setJobType(jobType.filter((type: string) => type !== e));
  };

  const handleAddTime = () => {
    setSelectedHoursDay((prev: any) => [
      ...prev,
      {
        day: hourTimeSelection.day,
        hour_from: hourTimeSelection.hour_from,
        hour_to: hourTimeSelection.hour_to,
        id: uuid(),
      },
    ]);

    setHourTimeSelection(INITIAL_TIME_HOUR);
    emptySelectHourSelection();
  };

  const removeTimeSelection = (timeId: string) => {
    setSelectedHoursDay(
      selectedHoursDay.filter(
        (hourDay: { id: string }) => hourDay.id !== timeId
      )
    );
  };

  useEffect(() => {
    setPostcodeIds(selectedPostcodes?.map((postcode) => postcode?.id));
  }, [selectedPostcodes]);

  const groupBy = (key: string) => (array: any) =>
    array.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const groupByDay = groupBy("day");

  useEffect(() => {
    setMappedDayHours(Object.entries(groupByDay(selectedHoursDay)));
  }, [selectedHoursDay]);

  useEffect(() => {
    const mappedDays = mappedDayHours?.map(
      (day: ["", [{ hour_from: string; hour_to: string; day: string }]]) => ({
        dayname: day[0].toUpperCase(),
        hours: day[1]?.map(
          (dayhours) => dayhours.hour_from + "-" + dayhours.hour_to
        ),
      })
    );
    setMappedHoursDay(mappedDays);
  }, [mappedDayHours]);

  const handleCityName = (e: string) => {
    setCityName(e);
  };
  const optimisedSearch = useCallback(debounce(handleCityName), []);
  const emptySelectHourSelection = () => {
    setHourTimeSelection({ day: "", hour_from: "", hour_to: "" });
  };

  const onClearFilters = () => {
    setCitiesId([]);
    setCitiesToShow([]);
    setSelectedPostCodes([]);
    setSelectedHoursDay([]);
    setCategoriesIds([]);
    setCategoriesToShow([]);
    setJobType([]);
    setSavedType("");
    setBudgetValueFrom("");
    setBudgetValueTo("");
    setFindWorkerInput("");
  };

  return (
    <div className="flex bg-white rounded border mt-4 flex-col dark:border-border dark:bg-background dark:text-whiteish px-8 py-8 justify-between border-b border-border">
      <div className="flex flex-col items-start gap-10">
        <div className="flex items-center justify-between w-full">
          <p className="text-lg font-medium">
            {t("employer.find_workers.filters")}
          </p>
          <div
            onClick={() => onClearFilters()}
            className="flex items-center gap-2 bg-gray-100 dark:bg-input dark:text-whiteish  rounded px-2 py-1 cursor-pointer hover:bg-gray-200 transition"
          >
            <div className="text-sm">
              {t("employer.review_candidates.filters.clear")}
            </div>
            <XMarkIcon className="h-4 w-4" />
          </div>
        </div>
        <div className="flex flex-col items-start gap-4">
          <p className="text-sm mb-2 font-medium">
            {t("worker.search_jobs_filter.select_cities")}
          </p>

          <Select
            onInputChange={(e) => optimisedSearch(e)}
            styles={colourStylesCities}
            placeholder={`${t("worker.search_jobs_filter.select_cities")}`}
            className=" dark:text-black text-sm "
            options={optionsCities}
            controlShouldRenderValue={false}
            onChange={(e) => {
              handleChangeJobCity(e!.value);
              handleFilterJobCity(e!.value);
            }}
          />
          {citiesToShow.length > 0 && (
            <div className="">
              <div className="flex flex-wrap items-center gap-4">
                {citiesToShow?.map((city) => (
                  <div
                    className="flex flex-wrap bg-gray-100 items-center gap-4 cursor-pointer px-2 py-1 rounded"
                    key={city.id}
                    onClick={() => handleRemoveJobCity(city.id)}
                  >
                    <p className="text-sm">{city?.name}</p>
                    <XMarkIcon className="h-4 w-4" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-start gap-4">
          <p className="text-sm  font-medium">
            {t("worker.search_jobs_filter.search_postcodes")}
          </p>
          <Select
            options={optionsPostcodes}
            placeholder={`${t(
              "worker.search_jobs_filter.search_postcodes"
            )}...`}
            // isMulti
            onInputChange={(e) => getAllPostcodes(e)}
            // closeMenuOnSelect={false}
            // hideSelectedOptions={true}
            controlShouldRenderValue={false}
            isOptionSelected={(option: { checked: boolean }) =>
              option.checked === true
            }
            onChange={(e, action) => handlePostcodesFilter(e, action)}
            className="text-sm mb-3 dark:text-black dark:bg-input w-[200px]"
            noOptionsMessage={() =>
              t("worker.search_jobs_filter.search_postcodes_search_type")
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
          <div className="flex flex-wrap items-center gap-3">
            {selectedPostcodes.length > 0 &&
              selectedPostcodes?.map(
                (postcode: {
                  checked: boolean;
                  class: string;
                  id: string;
                  label: string;
                  postcode: string;
                  state: string;
                  value: string;
                }) => (
                  <div
                    onClick={() => handleRemovePostcode(postcode?.id)}
                    key={postcode?.id}
                    className="flex flex-wrap bg-gray-100 items-center gap-4 cursor-pointer px-2 py-1 rounded"
                  >
                    <p className="text-sm">{postcode?.label}</p>
                    <XMarkIcon className="h-4 w-4" />
                  </div>
                )
              )}
          </div>
        </div>
        <div className="flex flex-col items-start ">
          <p className="text-sm font-medium">
            {t("createprofile.submit.edityourAvailableHours.title")}
          </p>
          <div className="flex flex-col items-start">
            <div className="flex items-center gap-4 mt-4">
              <Select
                options={days}
                styles={colourStyles}
                value={
                  hourTimeSelection.day !== ""
                    ? {
                        label: hourTimeSelection.day,
                        value: hourTimeSelection.day,
                      }
                    : `${t("employer.find_workers.filters.select_day")}`
                }
                placeholder={`${t("employer.find_workers.filters.select_day")}`}
                className=" dark:text-black text-sm "
                onChange={(e: any) => {
                  setHourTimeSelection((prev) => ({
                    ...prev,
                    day: e.value,
                  }));
                }}
              />

              <Button
                onClick={() => handleAddTime()}
                className="border cursor-pointer bg-azulBonico dark:bg-input w-[180px] dark:text-whiteish dark:border-border text-white text-sm  py-2 rounded disabled:bg-gray-100 disabled:cursor-not-allowed disabled:text-[#333]"
                disabled={
                  hourTimeSelection.day === "" ||
                  hourTimeSelection.hour_from === "" ||
                  hourTimeSelection.hour_to === ""
                }
              >
                {t("welcome-client.rightcard.dayweek.monday.addtimeBtn")}
              </Button>
            </div>
            <div className="flex items-center gap-4 mt-4">
              <Select
                value={
                  hourTimeSelection.hour_from !== ""
                    ? {
                        label: hourTimeSelection.hour_from,
                        value: hourTimeSelection.hour_from,
                      }
                    : `${t("employer.find_workers.filters.hour_from")}`
                }
                options={hoursInitial}
                styles={colourStyles}
                placeholder="Select From"
                className=" dark:text-black text-sm "
                onChange={(e: any) => {
                  setHourTimeSelection((prev) => ({
                    ...prev,
                    hour_from: e.value,
                  }));
                }}
              />
              <Select
                value={
                  hourTimeSelection.hour_to !== ""
                    ? {
                        label: hourTimeSelection.hour_to,
                        value: hourTimeSelection.hour_to,
                      }
                    : `${t("employer.find_workers.filters.hour_to")}`
                }
                options={hoursInitial}
                styles={colourStyles}
                placeholder="Select To"
                className=" dark:text-black text-sm "
                onChange={(e: any) => {
                  setHourTimeSelection((prev) => ({
                    ...prev,
                    hour_to: e.value,
                  }));
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-start mt-4">
            {selectedHoursDay.length > 0 &&
              selectedHoursDay?.map((dayHour: HoursSelectionType) => (
                <div
                  className="flex items-center gap-6 w-full justify-between"
                  key={dayHour.id}
                >
                  <div className="flex items-center gap-4 ">
                    <p className="font-medium">{dayHour.day}</p>
                    <div className="flex bg-gray-200 rounded-full px-2 items-center gap-3">
                      <p className="text-sm text-[#333]">{dayHour.hour_from}</p>
                      <p className="text-sm text-[#333]">{dayHour.hour_to}</p>
                    </div>
                  </div>
                  <div
                    onClick={() => removeTimeSelection(dayHour.id)}
                    className="p-1 rounded-full hover:bg-gray-100 cursor-pointer"
                  >
                    <XMarkIcon className="h-4 w-4 " />
                  </div>
                </div>
              ))}
          </div>
        </div>

        {/* <div className="flex flex-col items-start gap-4">
          <p className="text-sm  font-medium">
            {t("worker.profile.categories")}
          </p>
          <Select
            options={optionsCategories}
            placeholder="Select Categories"
            className="text-sm w-[200px] dark:border-border dark:bg-input dark:text-background"
            onChange={(e) => {
              handleChangeCategory(e!.value);
              handleFilterCategory(e!.value);
            }}
          />
          <div className="flex flex-col items-start">
            {categories?.slice(0, 5)?.map((category) => (
              <div
                onClick={() => {
                  handleChangeCategory(category.category_id);
                  handleFilterCategory(category.category_id);
                }}
                className="flex items-center gap-2 mb-1"
                key={category.category_id}
              >
                <input
                  checked={categoriesIds.includes(category.category_id)}
                  type="checkbox"
                />
                <p className="text-sm">{category.name}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-wrap items-center gap-3">
            {categoriesToShow.length > 0 &&
              categoriesToShow?.map((category) => (
                <div
                  onClick={() => handleRemoveCategory(category.category_id)}
                  key={category.category_id}
                  className="flex flex-wrap dark:bg-input dark:border-border bg-gray-100 items-center gap-4 cursor-pointer px-2 py-1 rounded"
                >
                  <p className="text-sm">{category.name}</p>
                  <XMarkIcon className="h-4 w-4 cursor-pointer" />
                </div>
              ))}
          </div>
        </div> */}
        <div className="flex flex-col items-start gap-4">
          <p className="text-sm font-medium">
            {t("worker.search_jobs_filter.job_type")}
          </p>
          <div className="flex flex-col items-start">
            <div className="flex items-center gap-2 text-[14px]">
              <input
                onChange={() => {
                  handleChangeJobType("2");
                }}
                checked={jobType.includes("2")}
                type="checkbox"
              />
              <p>{t("worker.edit_job_type.modal.work_type.remote")}</p>
            </div>
            <div className="flex items-center gap-2 text-[14px]">
              <input
                onChange={() => {
                  handleChangeJobType("3");
                }}
                checked={jobType.includes("3")}
                type="checkbox"
              />
              <p>{t("worker.edit_job_type.modal.work_type.hybrid")}</p>
            </div>
            <div className="flex items-center gap-2 text-[14px]">
              <input
                onChange={() => {
                  handleChangeJobType("1");
                }}
                type="checkbox"
                checked={jobType.includes("1")}
              />
              <p>{t("worker.edit_job_type.modal.work_type.inperson")}</p>
            </div>
            <div className="flex items-center gap-2 text-[14px]">
              <input
                checked={jobType.includes("4")}
                onChange={() => {
                  handleChangeJobType("4");
                }}
                type="checkbox"
              />
              <p>{t("worker.edit_job_type.modal.work_type.nomatter")}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start gap-4">
          <h1 className="text-sm font-medium">{t("worker.profile.budget")}</h1>
          <div className="flex flex-col items-start gap-2 text-[14px]">
            <p className="text-sm">{t("employer.find_workers.filters.from")}</p>
            <input
              onChange={(e) => setBudgetValueFrom(e.target.value)}
              value={budgetValueFrom}
              type="number"
              className="w-full border dark:border-border dark:bg-input outline-none rounded px-2 py-1"
            />
          </div>
          <div className="flex flex-col items-start gap-2 text-[14px]">
            <p className="text-sm">{t("employer.find_workers.filters.to")}</p>
            <input
              onChange={(e) => setBudgetValueTo(e.target.value)}
              value={budgetValueTo}
              type="number"
              className="w-full border dark:border-border dark:bg-input outline-none rounded px-2 py-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWorkers;
