import {
  useState,
  useRef,
  useCallback,
} from "react";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";

// interface Props {
//     userID: string;
//     loggedInUserUid: string
// }
export interface Category {
  category_id: string;
  name: string;
}

export const searchCategory = async (
  userID: string,
  loggedInUserUid: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}worker.category?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    );
    const json = await response.json();

    const categories = json.result;

    return categories?.map((category: Category) => ({
      category_id: category.category_id,
      name: category.name,
    }));
  } catch (error: any) {
    throw new Error("Error Categories Not Found");
  }
};

export function useCategories() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [categories, setCategories] = useState<Category[] | any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCategories = async () => {
    try {
      setLoading(true);
      setError(null);
      const categories = await searchCategory(userID, loggedInUserUid);
      setCategories(categories);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { categories, setCategories, getCategories, loading };
}

export const searchOffers = async (userID: string, loggedInUserUid: string) => {
  try {
    const response = await fetch(
      `${BASE_URL}worker.matches?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    );
    const json = await response.json();

    const offers = json.result;

    return offers;
  } catch (error: any) {
    throw new Error("Error Offers Not Found");
  }
};

export function useOffers() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [offers, setOffers] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getOffers = async () => {
    try {
      setLoading(true);
      setError(null);
      const offers = await searchOffers(userID, loggedInUserUid);
      setOffers(offers);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { offers, setOffers, getOffers, loading, error };
}

export const searchNumberSavedJobs = async (
  userID: string,
  loggedInUserUid: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}worker.saved.jobs?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    );
    const json = await response.json();

    const savedJobs = json.result;
    return savedJobs;
  } catch (error: any) {
    throw new Error("Saved Jobs Error");
  }
};

export function useNumberOfSavedJobs() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [savedJobs, setSavedJobs] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getNumberOfSavedJobs = async () => {
    try {
      setLoading(true);
      setError(null);
      const savedJob = await searchNumberSavedJobs(userID, loggedInUserUid);
      setSavedJobs(savedJob);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { savedJobs, setSavedJobs, getNumberOfSavedJobs, loading, error };
}

export const searchppliedJobs = async (
  userID: string,
  loggedInUserUid: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}worker.applied.jobs?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    );
    const json = await response.json();

    const appliedJobs = json.result;
    return appliedJobs;
  } catch (error: any) {
    throw new Error("Applied Jobs Error");
  }
};

export function useNumberOfAppliedJobs() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [appliedJobs, setAppliedJobs] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getNumberOfAppliedJobs = async () => {
    try {
      setLoading(true);
      setError(null);
      const appliedJob = await searchppliedJobs(userID, loggedInUserUid);
      setAppliedJobs(appliedJob);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    appliedJobs,
    setAppliedJobs,
    getNumberOfAppliedJobs,
    loading,
    error,
  };
}

export const searchAppliedJobsNumbers = async (
  userID: string,
  loggedInUserUid: string
) => {
  try {
    const response = await fetch(
      `${BASE_URL}worker.current.job.status?client_id=${userID}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    );
    const json = await response.json();

    const appliedJobs = json.result;
    return appliedJobs;
  } catch (error: any) {
    throw new Error("Applied Job Error");
  }
};

export function useAppliedJobsNumber() {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const [appliedJobsNumber, setAppliedJobsNumber] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getAppliedJobsNumber = async () => {
    try {
      setLoading(true);
      setError(null);
      const appliedJob = await searchAppliedJobsNumbers(
        userID,
        loggedInUserUid
      );
      setAppliedJobsNumber(appliedJob);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    appliedJobsNumber,
    setAppliedJobsNumber,
    getAppliedJobsNumber,
    loading,
    error,
  };
}

export interface ParamsToSendProps {
  find: string;
  budget: string;
  post_code_id_list: string;
  country_code: string;
  postcode_id_list: string;
  city_id_list: string;
  job_type_id_list: string;
  category_id_list: string;
  skill_id_list: string;
}

export const searchPreviouslySearchedJob = async (
  userID: string,
  loggedInUserUid: string,
  paramsToSend: ParamsToSendProps
) => {
  try {
    const response = await fetch(
      `${BASE_URL}jobs?client_id=${userID}&find=${
        paramsToSend.find
      }&page=1&limit=10${
        paramsToSend?.budget ? `&budget=${paramsToSend?.budget}` : ""
      }${
        paramsToSend?.post_code_id_list?.length > 0
          ? `&country_code=ES&city_id_list=${paramsToSend.city_id_list}&post_code_id_list=${paramsToSend.postcode_id_list}`
          : paramsToSend?.city_id_list?.length > 0
          ? `&country_code=ES&city_id_list=${paramsToSend.city_id_list}`
          : ""
      }${
        paramsToSend.job_type_id_list?.length > 0
          ? `&job_type_list=${paramsToSend.job_type_id_list}`
          : ""
      }${
        paramsToSend.category_id_list?.length > 0
          ? `&category_id_list=${paramsToSend.category_id_list}`
          : ""
      }${
        paramsToSend.skill_id_list?.length > 0
          ? `&skill_id_list=${paramsToSend.skill_id_list}`
          : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    );
    const json = await response.json();

    const appliedJobs = json.result;
    return appliedJobs;
  } catch (error: any) {
    throw new Error("Applied Job Error");
  }
};

export function usePreviosulySearchedJob({
  paramsToSend,
}: {
  paramsToSend: ParamsToSendProps;
}) {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const previoursParamsSearch = useRef(paramsToSend);

  const [offers, setOffers] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPreviouslySearchedJobs = useCallback(
    async ({ paramsToSend }: { paramsToSend: ParamsToSendProps }) => {
      if (paramsToSend === previoursParamsSearch.current) return;
      try {
        setLoading(true);
        setError(null);
        previoursParamsSearch.current = paramsToSend;
        const offers = await searchPreviouslySearchedJob(
          userID,
          loggedInUserUid,
          paramsToSend
        );
        setOffers(offers);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return {
    offers,
    setOffers,
    getPreviouslySearchedJobs,
    loading,
    error,
  };
}
