import React, { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "src/Components/ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { cn } from "src/lib/utils";
import FlexiCoin from "src/assets/img/flexicoin.png";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { useActionTypesStore } from "src/Routing/useProfileStore";
import { decryptArray } from "src/Hooks/decryptString";
import Select from "react-select";
import { BASE_URL_IMG } from "src/Config/api.config";
import { GrLocation } from "react-icons/gr";
import { BsLightning } from "react-icons/bs";
import RatingStars from "src/Components/Rating/RatingStars";

interface AvailableWorkerProps {
  onClick: (laps: string) => void;
  availableStatus: boolean;
  availableValue: string;
  isAvailable: any;
  lapBadge: string;
  workerInfo: any;
  profileData: any;
}

const ModalAvailableProfile = ({
  onClick,
  availableStatus,
  availableValue,
  isAvailable,
  lapBadge,
  profileData,
  workerInfo,
}: AvailableWorkerProps) => {
  const [showModalAvailableProfile, setShowModalAvailableProfile] =
    useModalStore((state) => [
      state.showModalAvailableProfile,
      state.setShowModalAvailableProfile,
    ]);
  const [laps, setLaps] = useState<any>("1");
  const colourStyles = {
    control: () => ({
      border: "1px solid #2abeeb",
      "&:hover": {
        borderColor: "#1d90b3",
      },
      display: "flex",
      fontWeight: "600",
      constterSpacing: "0.05em",
      backgroundColor: "white",

      padding: "0rem",
      width: "160px",
      paddingLeft: "0.5rem",
      // paddingRight: "0.8rem",
      borderRadius: "10px",
      height: "40px",
    }),
  };
  const ref = useRef<HTMLDivElement>(null);
  const actions = useActionTypesStore((s: any) => s.actions);
  const { creditsDataInfo, updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "15"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "15"
    )[0]?.id
  );
  const { t } = useTranslation();

  const handleClose = () =>
    setShowModalAvailableProfile(showModalAvailableProfile);

  const availableWorker = () => {
    onClick(laps);
  };

  return (
    <Modal isOpen={showModalAvailableProfile} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-lg font-medium">
            {/* {t(
              "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.title"
            )} */}
            Available Badge
          </h1>
          <Button
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
            onClick={() => handleClose()}
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          {/* {t(
            "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.subtitle"
          )} */}
          La probabilidad de ser contratado es mayor si habilitas tu perfil .
        </h3>
        <div className="px-8 pt-2 pb-4">
          <p className="mb-4 text-gray-500">
            How many days would you like to stay available?
          </p>
          <Select
            options={[
              {
                value: (Number(lapBadge) / Number(lapBadge))?.toString(),
                label: `${lapBadge} days`,
              },
              {
                value: ((Number(lapBadge) / Number(lapBadge)) * 2)?.toString(),
                label: `${Number(lapBadge) * 2} days`,
              },
              {
                value: ((Number(lapBadge) / Number(lapBadge)) * 3)?.toString(),
                label: `${Number(lapBadge) * 3} days`,
              },
              {
                value: ((Number(lapBadge) / Number(lapBadge)) * 4)?.toString(),
                label: `${Number(lapBadge) * 4} days`,
              },
            ]}
            styles={colourStyles}
            onChange={(e) => setLaps(e!.value)}
            className="cursor-pointer text-sm dark:text-black"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="flex justify-end w-full py-6 px-8 ">
          {isAvailable?.length === 0 ? (
            <Button
              onClick={() => availableWorker()}
              className="bg-azulBonico px-4 py-1 flex items-center gap-1 text-white peer"
            >
              Turn on your availability
            </Button>
          ) : (
            <Button className="bg-gray-200 px-4 py-1 flex items-center gap-1 text-[#333] peer">
              Turned On
            </Button>
          )}

          <div className="absolute bottom-16  scale-0 bg-gray-100 rounded px-2 peer-hover:scale-100 transition">
            <div className="flex items-center gap-2">
              <div>1</div>
              <img src={FlexiCoin} className="h-4 w-4 " />
            </div>
          </div>
        </div>
        <div className="mx-8 mb-4 ">
          <p className="text-sm text-[#333]">Preview</p>
          <div className="border bg-white  p-4 relative mt-2 rounded">
            <div className="flex items-start space-x-4">
              <img
                className="h-10 w-10 object-cover rounded-full"
                src={`${BASE_URL_IMG}${workerInfo?.workerProfile.profile_image}`}
              />
              <div>
                <p className="text-xs">{workerInfo.workerProfile.title}</p>
                <p className="text-xs flex items-center space-x-2 mt-1 text-gray-500">
                  <GrLocation />
                  {workerInfo.workerProfile.city_name}
                </p>
                <div className="flex w-fit items-center gap-2 mt-2 px-4 py-1 bg-blue-50 border border-blue-400 rounded-full">
                  <BsLightning className="h-3 w-3 text-blue-400" />
                  <p className="text-xs font-medium text-blue-600">
                    Available Now
                  </p>
                </div>
                <p className="text-[10px] mt-4 mb-2">
                  {workerInfo.workerProfile.overview.slice(0, 200)}
                </p>
                <RatingStars
                  size={"10"}
                  activeStar={profileData?.score?.avgScore}
                  score={profileData?.score?.avgScore}
                  evaluation={false}
                  reviews={false}
                  showHalf={true}
                  info={profileData?.score}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAvailableProfile;
