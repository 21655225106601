import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";
import { useQuery } from "react-query";

export const useViewJobPost = () => {
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const getTimes = async () => {
    const data = await fetch(`${BASE_URL}job.time.profile?job_id=${jobID}`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const { data: timesData, isLoading: isLoadingTime } = useQuery(
    ["job_times"],
    getTimes
  );

  const getNumberOfAllApplicants = async () => {
    const data = await fetch(
      `${BASE_URL}employer.applicants?client_id=${userID}&job_id=${jobID}&filter_field=not archived`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data;
  };

  const { data: applicantsData } = useQuery(
    ["applicants"],
    getNumberOfAllApplicants
  );
  const getHired = async () => {
    const data = await fetch(
      `${BASE_URL}employer.hired.applicants?client_id=${userID}&job_id=${jobID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: hiresData } = useQuery(["hires"], getHired);

  return { timesData, isLoadingTime, applicantsData, hiresData };
};
