import React, {
  Dispatch,
  ElementRef,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { BASE_URL, JOB_SAVED_SEARCH } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import { ReactComponent as Cross } from "../../assets/img/crosszwei.svg";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

interface ModalSaveJobSearchProps {
  setShowSaveSearchModal: Dispatch<SetStateAction<boolean>>;
  showSaveSearchModal: boolean;
  selectedCategories: { category_id: string }[];
  selectedBudget: string;
  selectedJobType: { id: string }[];
  selectedSkills: { skill_id: string }[];
  selectedCities: { id: string }[];
  selectedPostCodes: { id: string }[];
  selectedCountry: any;
}

const ModalSaveJobSearch = ({
  setShowSaveSearchModal,
  showSaveSearchModal,
  selectedCategories,
  selectedBudget,
  selectedSkills,
  selectedJobType,
  selectedCities,
  selectedPostCodes,
  selectedCountry,
}: ModalSaveJobSearchProps) => {
  const [desiredJob, setDesiredJob] = useLocalStorage("desiredJob", []);

  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [savedSearchName, setSavedSearchName] = useState("");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [transition, setTransition] = useState(false);
  const ref = useRef<ElementRef<"div">>(null);

  const { t } = useTranslation();

  useEffect(() => {
    setSavedSearchName(desiredJob);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showSaveSearchModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSaveSearchModal]);

  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append("find", desiredJob);
  const handleSubmitSearch = () => {
    data.append("search_name", savedSearchName);
    if (selectedCategories?.length > 0) {
      data.append(
        "category_id_list",
        selectedCategories?.map((cat) => cat.category_id).toString()
      );
    }
    if (selectedBudget) {
      data.append("budget", selectedBudget);
    }
    if (selectedSkills?.length > 0) {
      data.append(
        "skill_id_list",
        selectedSkills?.map((skill) => skill.skill_id).toString()
      );
    }
    if (selectedCountry) {
      data.append("country_code", "ES");
    }
    if (selectedCities?.length > 0) {
      data.append(
        "city_id_list",
        selectedCities?.map((city) => city.id).toString()
      );
    }
    if (selectedPostCodes?.length > 0) {
      data.append(
        "postcode_id_list",
        selectedPostCodes?.map((code) => code.id).toString()
      );
    }
    if (selectedJobType?.length > 0) {
      data.append(
        "job_type_id_list",
        selectedJobType?.map((type) => type.id).toString()
      );
    }
    saveSearch();
  };

  const saveSearch = () => {
    fetch(`${BASE_URL}${JOB_SAVED_SEARCH}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowSaveSearchModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div>
      <div
        className={` ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 translate-y-[30%] -translate-x-[40%] w-[90%]  lg:w-[400px] p-6 border  rounded  bg-white `}
        ref={ref}
      >
        {" "}
        <div className="flex flex-col">
          <div className="flex justify-between items-center mb-3">
            <p className="font-bold text-sm">
              {t("modal.save_job_search.save_search_as")}
            </p>
            <div className="h-6 w-6 transition hover:bg-gray-200 rounded-full flex items-center justify-center">
              <Cross
                className="h-3 cursor-pointer"
                onClick={() => setShowSaveSearchModal(false)}
              />
            </div>
          </div>
          <form>
            <input
              type="text"
              className={`w-full  border h-10 pl-4 outline-none text-sm rounded ${
                savedSearchName?.length > 0
                  ? "hover:border-azulBonico focus:border-azulBonico transition duration-300"
                  : "border-red"
              } `}
              placeholder={`${t("modal.save_job_search.placeholder")}`}
              value={savedSearchName}
              onChange={(e) => setSavedSearchName(e.target.value)}
            />
            {savedSearchName?.length === 0 ? (
              <div className="wrong-container flex items-start mt-4">
                <ExclamationCircleIcon className="text-red-500 h-5 w-5" />

                <p className="text-sm ml-2">
                  {t("modal.save_job_search.provide_name")}
                </p>
              </div>
            ) : null}
            <button
              className={`border-none font-semibold w-full rounded-[20px] text-[0.9rem] mt-6 px-[1rem] transition duration-300 h-10 ${
                savedSearchName?.length > 0
                  ? "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
                  : "cursor-not-allowed bg-blancoGris text-slate-300"
              } `}
              disabled={savedSearchName?.length === 0}
              onClick={() => {
                handleSubmitSearch();
                setShowSaveSearchModal(false);
              }}
            >
              {t("modal.save_job_search.save")}
            </button>
          </form>
          <p className="text-sm mt-6">{t("modal.save_job_search.save_desc")}</p>
        </div>
      </div>
    </div>
  );
};

export default ModalSaveJobSearch;
