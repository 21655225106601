import React from "react";
import { BASE_URL_IMG } from "src/Config/api.config";
import { ReviewTypes } from "src/types/types";
import RatingStars from "../Rating/RatingStars";
import { useTranslation } from "react-i18next";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";

interface ReviewCardProps {
  review: ReviewTypes;
  endingStart: string;
  endingEnd: string;
  showReviewsFirst: string;
  date: string;
  endingEarlyEnd: string;
}

const ReviewCard = ({
  review,
  showReviewsFirst,
  endingEnd,
  endingStart,
  date,
  endingEarlyEnd,
}: ReviewCardProps) => {
  const { i18n, t } = useTranslation();

  return (
    <div
      key={review.id}
      className="flex flex-col gap-1 border-b border-blue-100 px-6 py-3"
    >
      <div className="flex items-start gap-2">
        <img
          src={`${BASE_URL_IMG}${review?.profile_image}`}
          alt="profile"
          className="h-6 w-6 object-cover rounded-full cursor-default"
        />
        <p className="font-bold">
          {showReviewsFirst !== "employer"
            ? // {/* {showReviewsFirst === "worker" */}
              review.employer_first_name + " " + review.employer_last_name
            : review.worker_first_name + " " + review.worker_last_name}
        </p>
      </div>
      <div className="flex items-center gap-3 font-bold">
        <div className="flex flex-[0_0_16%]">
          {review.score_from_worker !== "0.00" && review.score_from_worker ? (
            <div className="flex gap-3">
              <div className="mt-[3px]">
                <RatingStars
                  activeStar={
                    showReviewsFirst !== "employer"
                      ? // {/* showReviewsFirst === "worker"*/}
                        review.score_from_employer
                      : review.score_from_worker
                  }
                  evaluation={false}
                  score={review.score_from_worker}
                  showHalf={true}
                  size={14}
                  showModal={false}
                />
              </div>
              {/* <p className="text-xs mt-[3px] text-slate-500">
                    ({review.score_from_worker})
                  </p> */}
            </div>
          ) : (
            <div className="flex flex-col  w-full">
              <p className="text-xs mt-[3px] text-slate-500">
                ({t("worker.modal_jobcard.not_rated")})
              </p>
            </div>
          )}
        </div>
        {/* <div className="flex gap-3"> */}
        {/* <p>
                {showReviewsFirst === "worker"
                  ? review.employer_first_name +
                    " " +
                    review.employer_last_name
                  : review.worker_first_name +
                    " " +
                    review.worker_last_name}
              </p>
              <p>-</p> */}
        <p>{review.job_title}</p>
        {/* </div> */}
      </div>
      <div className="flex lg:flex-row flex-col items-start lg:items-center gap-1">
        <p className="text-sm ">
          {/* {t("worker.modal_jobcard.salary")}:{" "} */}
          <span className="font-medium">{review.budget ?? "0"}€</span> /h
        </p>
        <p className="text-xs text-slate-400 hidden lg:block">-</p>
        {!review.date_early_end &&
          review.date_start <= date &&
          date <= review.date_end && (
            <p className="text-sm text-slate-400">
              {i18n.language === "en" ? (
                <>
                  {t("worker.modal_jobcard.working_date")}{" "}
                  {review.date_start?.slice(8, 10)}
                  <span className="text-xs">{endingStart}</span>{" "}
                  {HandleMonthName(review.date_start?.slice(5, 7))} &apos;
                  {review.date_start?.slice(2, 4)}{" "}
                  {t("worker.modal_jobcard.until")}{" "}
                  {review.date_end?.slice(8, 10)}
                  <span className="text-xs">{endingEnd}</span>{" "}
                  {HandleMonthName(review.date_end?.slice(5, 7))} &apos;
                  {review.date_end?.slice(2, 4)}
                </>
              ) : (
                <>
                  {t("worker.modal_jobcard.working_date")}{" "}
                  {review.date_start?.slice(8, 10)} de{" "}
                  {HandleMonthNameES(review.date_start?.slice(5, 7))} &apos;
                  {review.date_start?.slice(2, 4)}{" "}
                  {t("worker.modal_jobcard.until")} el{" "}
                  {review.date_end?.slice(8, 10)} de{" "}
                  {HandleMonthNameES(review.date_end?.slice(5, 7))} &apos;
                  {review.date_end?.slice(2, 4)}
                </>
              )}
            </p>
          )}
        {review.date_early_end && (
          <p className="text-sm text-slate-400">
            {i18n.language === "en" ? (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)}
                <span className="text-xs">{endingStart}</span>{" "}
                {HandleMonthName(review.date_start?.slice(5, 7))} &apos;{" "}
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")}{" "}
                {review.date_early_end?.slice(8, 10)}
                <span className="text-xs">{endingEarlyEnd}</span>{" "}
                {HandleMonthName(review.date_early_end?.slice(5, 7))} &apos;{" "}
                {review.date_early_end?.slice(2, 4)}
              </>
            ) : (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_start?.slice(5, 7))} &apos;
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")} el{" "}
                {review.date_early_end?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_early_end?.slice(5, 7))} &apos;
                {review.date_early_end?.slice(2, 4)}
              </>
            )}
          </p>
        )}
        {!review.date_early_end && date > review.date_end && (
          <p className="text-sm text-slate-400">
            {i18n.language === "en" ? (
              <>
                {t("worker.modal_jobcard.worked_from")}{" "}
                {review.date_start?.slice(8, 10)}
                <span className="text-xs">{endingStart}</span>{" "}
                {HandleMonthName(review.date_start?.slice(5, 7))} &apos;
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")}{" "}
                {review.date_end?.slice(8, 10)}
                <span className="text-xs">{endingEnd}</span>{" "}
                {HandleMonthName(review.date_end?.slice(5, 7))} &apos;
                {review.date_end?.slice(2, 4)}
              </>
            ) : (
              <>
                {t("worker.modal_jobcard.worked_from")} el{" "}
                {review.date_start?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_start?.slice(5, 7))} &apos;
                {review.date_start?.slice(2, 4)}{" "}
                {t("worker.modal_jobcard.until")} el{" "}
                {review.date_end?.slice(8, 10)} de{" "}
                {HandleMonthNameES(review.date_end?.slice(5, 7))} &apos;
                {review.date_end?.slice(2, 4)}
              </>
            )}
          </p>
        )}
      </div>
      <p className="mt-1 italic">
        {showReviewsFirst !== "employer"
          ? review.employer_review
          : review.worker_review}
      </p>
    </div>
  );
};

export default ReviewCard;
