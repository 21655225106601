import React from "react";
import { ReviewTypes } from "../../types/types";
import RatingStars from "../Rating/RatingStars";
import {
  HandleMonthName,
  HandleMonthNameES,
} from "../../Utils/HandleMonthName";
import { useTranslation } from "react-i18next";
import { BASE_URL_IMG } from "src/Config/api.config";
import { userProfileStore } from "src/Routing/useProfileStore";

interface ReviewsCardProps {
  review: ReviewTypes;
  view: string;
}

function ReviewsCard({ review, view }: ReviewsCardProps) {
  const profileType = userProfileStore((s) => s.profileTypeToken);

  // const [profileType, setProfileType] = useLocalStorage("profileType", "");
  const { i18n } = useTranslation();

  return (
    <div className="flex flex-col w-full   border-t h-28 mb-4 ">
      <div className="flex items-start gap-4 mx-8 mt-4 mb-2">
        {review?.profile_image && (
          <img
            src={`${BASE_URL_IMG}${review.profile_image}`}
            alt="profile"
            className="h-10 w-10 object-cover rounded-full cursor-default"
          ></img>
        )}
        <div className="flex flex-col">
          <div className="flex gap-4 font-semibold text-sm">
            <div className="mt-[1px] flex gap-1">
              <RatingStars
                activeStar={
                  view === "worker"
                    ? review.score_from_worker
                    : review.score_from_employer
                }
                evaluation={false}
                score={"dont"}
                size={14}
                showHalf={true}
                showModal={false}
              />

              <p className="text-xs text-gray-500 dark:text-whiteish">
                {view === "worker"
                  ? review.score_from_worker
                  : review.score_from_employer}
              </p>
            </div>
            <p className="dark:text-whiteish">
              {profileType === "worker"
                ? review.employer_name
                : review.first_name}
            </p>
          </div>
          <p className="text-sm font-bold">
            {profileType === "worker" ? review.title : review.job_title}
          </p>
        </div>
      </div>
      <p className="mx-8 italic text-sm dark:text-whiteish">
        {view === "worker" ? (
          <div>
            {review.worker_review !== null ? review.worker_review : "No review"}
          </div>
        ) : (
          <div>
            {review.employer_review !== null
              ? review.employer_review
              : "No review"}
          </div>
        )}
      </p>
      <p className="text-sm text-slate-400 dark:text-whiteish mb-4 mx-8  mt-1">
        {view === "worker" ? (
          <>
            {i18n.language === "en" ? (
              <>
                {HandleMonthName(review.date_review_worker?.slice(5, 7))}{" "}
                {review.date_review_worker?.slice(0, 4)}
              </>
            ) : (
              <>
                {HandleMonthNameES(review.date_review_worker?.slice(5, 7))}{" "}
                {review.date_review_worker?.slice(0, 4)}
              </>
            )}
          </>
        ) : (
          <>
            {i18n.language === "en" ? (
              <>
                {HandleMonthName(review.date_review_employer?.slice(5, 7))}{" "}
                {review.date_review_employer?.slice(0, 4)}
              </>
            ) : (
              <>
                {HandleMonthNameES(review.date_review_employer?.slice(5, 7))}{" "}
                {review.date_review_employer?.slice(0, 4)}
              </>
            )}
          </>
        )}
      </p>
    </div>
  );
}

export default ReviewsCard;
