import React from "react";
import Modal from "react-modal";
import { StylesModal } from "../../Utils/stylesModal";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import deal from "../../assets/img/flexiExp4.png";
import jobPost from "../../assets/img/flexiExp1.png";
import chatting from "../../assets/img/flexiExp3.png";
import talent from "../../assets/img/flexiExp2.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import { Button } from "../ui/Button";

const ModalHowToUseWorker = ({ showModal, setShowModal }) => {
  const [transition, setTransition] = useState(false);
  const [step, setStep] = useState("first");
  const ref = useRef();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const handleContinue = () => {
    switch (step) {
      case "first":
        return setStep("second");
      case "second":
        return setStep("third");
      case "third":
        return setStep("four");
      case "four":
        return closeModal();
    }
  };
  const handleGoBack = () => {
    switch (step) {
      case "first":
        return closeModal();
      case "second":
        return setStep("first");
      case "third":
        return setStep("second");
      case "four":
        return setStep("third");
    }
  };

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 500);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <Modal isOpen={showModal} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[600px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        {step === "first" && (
          <div className="p-4">
            <p className="text-2xl font-semibold tracking-wide">
              {t("worker.home.rightbar.knowflexi.window.step1.title")}
            </p>
            <div className="flex items-center gap-4 mt-8">
              <img src={jobPost} alt="jobPost" className="h-32 w-fit" />
              <div className="flex flex-col">
                <p>
                  {parse(
                    t("worker.home.rightbar.knowflexi.window.step1.subtitle")
                  )}
                </p>
                <p className="text-azulBonico">
                  {typeof t(
                    "worker.home.rightbar.knowflexi.window.step1.subtitle_link"
                  ).split("<b>")[0] === "string" &&
                    t(
                      "worker.home.rightbar.knowflexi.window.step1.subtitle_link"
                    ).split("<b>")[0]}
                  <button
                    onClick={() => navigate("/how-to-worker-guide")}
                    className="font-semibold hover:underline cursor-pointer"
                  >
                    {parse(
                      t(
                        "worker.home.rightbar.knowflexi.window.step1.subtitle_link"
                      )
                        .split("<b>")[1]
                        .toString()
                    )}
                  </button>
                  {/* <a
                    href={`${HOST}/how-to-worker-guide`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="font-semibold hover:underline cursor-pointer"
                  >
                    {parse(
                      t(
                        "worker.home.rightbar.knowflexi.window.step1.subtitle_link"
                      )
                        .split("<b>")[1]
                        .toString()
                    )}
                  </a> */}
                </p>
              </div>
            </div>
          </div>
        )}
        {step === "second" && (
          <div className="p-4">
            <p className="text-2xl font-semibold tracking-wide">
              {t("worker.home.rightbar.knowflexi.window.step2.title")}
            </p>
            <div className="flex items-center gap-4 mt-8">
              <img src={talent} alt="talent" className="h-32 w-fit" />
              <div className="flex flex-col">
                <p>
                  {parse(
                    t("worker.home.rightbar.knowflexi.window.step2.subtitle")
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        {step === "third" && (
          <div className="p-4">
            <p className="text-2xl font-semibold tracking-wide">
              {parse(t("worker.home.rightbar.knowflexi.window.step3.title"))}
            </p>
            <div className="flex items-center gap-4 mt-8">
              <img src={chatting} alt="chatting" className="h-32 w-fit" />
              <p>{t("worker.home.rightbar.knowflexi.window.step3.subtitle")}</p>
            </div>
          </div>
        )}
        {step === "four" && (
          <div className="p-4">
            <p className="text-2xl font-semibold tracking-wide">
              {parse(t("worker.home.rightbar.knowflexi.window.step4.title"))}
            </p>
            <div className="flex items-center gap-4 mt-8">
              <img src={deal} alt="deal" className="h-32 w-fit" />
              <div className="flex flex-col">
                <p>
                  {parse(
                    t("worker.home.rightbar.knowflexi.window.step4.subtitle")
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 mt-2 justify-end border-t border-blue-100">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1"
            onClick={() => {
              handleGoBack();
            }}
          >
            {step === "first" &&
              t("worker.home.rightbar.knowflexi.window.title.leftBtn")}
            {(step === "second" || step === "third" || step === "four") &&
              t("worker.home.rightbar.knowflexi.window.title.leftBtn2")}
          </Button>
          <Button
            className={` border-none  w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] h-10 bg-azulBonico hover:bg-azulOscuro text-white `}
            onClick={() => {
              handleContinue();
            }}
          >
            {step === "four" &&
              t("worker.home.rightbar.knowflexi.window.title.leftBtn")}
            {(step === "first" || step === "second" || step === "third") &&
              t("worker.home.rightbar.knowflexi.window.title.rightBtn")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalHowToUseWorker;
