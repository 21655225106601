import React, { lazy, useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

import "./TitleJob.css";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import {
  ACTIONS,
  BASE_URL,
  DRAFTS,
  GIFT_ID,
  JOB_CATEGORIES,
} from "../../Config/api.config";

const SelectedSkills = lazy(() => import("./SelectedSkills"));

import Progress_bar from "../ProgressBar/ProgressBar";

import edit from "../../assets/img/edit.png";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../Pages/General/Home/LoadingSpinner";
import { Categories } from "src/types/types";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/Button";

import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useQuery } from "react-query";
import {
  useActionTypesStore,
  useEmployerStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import ModalCongratulationsGift from "../Modals/ModalCongratulationsGift";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { useAtom } from "jotai";
import { isCompany } from "src/Atoms/jotaiAtoms";
import { decryptArray } from "src/Hooks/decryptString";

export default function TitleJob() {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showHeadline, setShowHeadline] = useState(true);
  const [showSkills, setShowSkills] = useState(false);
  const [allCategories, setAllCategories] = useState<Categories[]>([]);
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [jobType, setJobType] = useLocalStorage("jobType", "");
  const [giftCredits, setGiftCredits] = useState("");
  const { updateCredits } = useUpdateCredits(giftCredits, GIFT_ID);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const navigate = useNavigate();
  useEffect(() => {
    setJobType("");
  }, []);
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  const [jobTitle, setJobTitle] = useLocalStorage("jobTitle", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [formValues, setFormValues] = useState({
    titleJob: "",
  });
  const [draftId, setDraftId] = useLocalStorage("draft_id", "");
  const [loading, setLoading] = useState(false);
  const [congratulationsGift, setCongratulationsGift] = useState(false);
  const getClientData = async () => {
    const data = await fetch(`${BASE_URL}employers?client_id=${userID}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    }).then((res) => res.json());

    return data.result[0];
  };

  const { data: employerData, refetch: refetchEmployer } = useQuery(
    ["employer", userID],
    getClientData
  );
  useEffect(() => {
    if (employerData?.gift_received === "0") {
      setCongratulationsGift(true);
    }
  }, [employerData]);

  const errorMessagesHeadline = Yup.object().shape({
    titleJob: Yup.string().required(
      `${t("welcome-client.rightcard.textTitle.error")}`
    ),
  });

  const [isUserCompany, setIsUserCompany] = useAtom(isCompany);
  const getAllCategories = async () => {
    const data = await fetch(`${BASE_URL}${JOB_CATEGORIES}?category_id`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    }).then((res) => res.json());
    return data.result;
  };
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const actions = useActionTypesStore((s: any) => s.actions);

  const { data: categoriesData } = useQuery(
    ["categories_all"],
    getAllCategories
  );

  const getActionTypes = async () => {
    const data = await fetch(`${BASE_URL}${ACTIONS}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const {
    data: actionsData,
    refetch,
    isLoading,
  } = useQuery(["actions"], getActionTypes);

  useEffect(() => {
    if (!isLoading) {
      setGiftCredits(
        decryptArray(actions)?.filter(
          (param: { id: string; name: string; credits: string }) =>
            param.name === "Welcome Gift"
        )[0]?.credits
      );
    }
  }, [actionsData]);

  useEffect(() => {
    if (isUserCompany && giftCredits !== "") {
      updateCredits();
    }
  }, [isUserCompany, giftCredits]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setAllCategories(categoriesData);
  }, [categoriesData]);

  //REACT SELECT SETTINGS
  const availableCategories = allCategories?.map(function (row) {
    return { value: row.name, label: row.name, id: row.category_id };
  });

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  //REQUESTS. EITHER A PUT OR A POST
  const data = new FormData();
  data.append("_method", "POST");
  data.append("client_id", userID);
  data.append("category_id", categoryID);

  const putdata = new FormData();
  putdata.append("_method", "PUT");
  putdata.append("client_id", userID);
  putdata.append("draft_id", draftId);
  putdata.append("category_id", categoryID);
  const titleAndCategoryPost = () => {
    fetch(`${BASE_URL}${DRAFTS}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.affected === "added") {
          setDraftId(data.result.data.draft_id);

          setShowHeadline(false);
          setShowSkills(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const goToSkills = () => {
    setLoading(true);
    if (!draftId) {
      titleAndCategoryPost();
    }

    if (draftId) {
      titleAndCategoryPut();
    }
  };

  const titleAndCategoryPut = () => {
    fetch(`${BASE_URL}${DRAFTS}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: putdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.affected === "Updated") {
          setShowHeadline(false);
          setShowSkills(true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleRemoveDraft = () => {
    fetch(`${BASE_URL}${DRAFTS}?draft_id=${draftId}`, {
      method: "DELETE",
      headers: {
        //   "Content-type": "application/json",
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          if (profileType === "employer") {
            navigate("/dashboard");
          } else {
            navigate("/dashboard-worker");
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      {showHeadline ? (
        <>
          <div className="flex flex-col lg:flex-row  mx-auto items-center h-[600px] justify-center mt-40 ">
            <div className="bg-[#343a47] h-full pb-4 lg:pb-0 w-full lg:w-[50%] max-w-[520px] px-[1.5rem] flex flex-col text-white rounded-[10px]">
              <Progress_bar
                height={8}
                bgcolor="#00dedf"
                progress="20"
                color1="#00dedf"
                color2=""
                color3=""
              />
              <h1 className="text-lg lg:text-3xl font-semibold mt-[4.5rem]  lg:mt-[8.5rem]">
                {t("welcome-client.leftcard.headline.title")}
              </h1>
              <br />
              <p className="text-sm lg:text-[15px]">
                {t("welcome-client.leftcard.headline.text")}
              </p>
            </div>
            <div className="rounded-[10px] h-full bg-white dark:bg-background dark:border-border dark:border dark:text-whiteish lg:w-[50%] mt-10 border lg:mt-0 py-4 lg:py-0 w-full max-w-[520px] relative">
              <div className="lg:absolute m-auto top-0 bottom-0 left-0 right-0 px-[3rem] h-fit">
                <Formik
                  initialValues={{ titleJob: jobTitle }}
                  validationSchema={errorMessagesHeadline}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    submitForm,
                  }) => (
                    <form className="flex flex-col w-full justify-center">
                      <div className="relative lg:h-[450px]  justify-center flex flex-col">
                        <h3 className="text-[1.1rem] font-[500]">
                          {t("welcome-client.rightcard.headline.title")}
                        </h3>
                        <div>
                          <input
                            type="text"
                            name="titleJob"
                            defaultValue={jobTitle}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${
                              errors.titleJob && touched.titleJob
                                ? "border-red"
                                : ""
                            } hover:border-[#a1dbfe] border dark:bg-input px-2  text-sm border-blue-100 w-full rounded h-10 outline-none focus:border-[#a1dbfe] transition duration-300`}
                          />
                          {values.titleJob.length < 10 &&
                          values.titleJob.length > 1 ? (
                            <div className="wrong-container">
                              <p className="too-short">
                                {t(
                                  "welcome-client.rightcard.textPlaceholder.TooShort"
                                )}
                              </p>
                            </div>
                          ) : null}
                          {errors.titleJob && touched.titleJob ? (
                            <div className="wrong-container flex items-center gap-2">
                              <AiOutlineExclamationCircle
                                size={17}
                                color="red"
                              />

                              {`${errors.titleJob}`}
                            </div>
                          ) : null}
                        </div>
                        {selectedCategory ? (
                          <>
                            <div className="flex justify-center items-center">
                              <p className="font-[400] mt-4 mb-2 text-sm">
                                {t(
                                  "welcome-client.rightcard.selectedcategoryEdit"
                                )}
                                :{" "}
                                <span className="font-bold text-base">
                                  {selectedCategory}
                                </span>
                              </p>
                              <img
                                src={edit}
                                alt="edit"
                                className="h-4 cursor-pointer ml-8"
                                onClick={() => setSelectedCategory()}
                              ></img>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="examples mt-4 mb-2 text-sm">
                              {t("welcome-client.rightcard.headline.subtitle")}
                            </p>
                            <Select
                              options={availableCategories}
                              styles={colourStyles}
                              className="text-sm dark:text-black"
                              placeholder={t(
                                "welcome-client.rightcard.headline.subtitle.dropdown.categoryPlaceholder"
                              )}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e: any) => {
                                setSelectedCategory(e.value);
                                setCategoryID(e.id);
                              }}
                            />
                          </>
                        )}
                      </div>

                      <div className="relative flex items-center justify-between  mt-14  ">
                        <Button
                          type="button"
                          className="text-sm text-slate-500 hover:underline hover:font-bold px-[0.6rem] py-[0]"
                          onClick={() => {
                            if (profileType === "employer") {
                              navigate("/dashboard");
                            } else {
                              navigate("/dashboard-worker");
                            }
                          }}
                        >
                          {t("welcome-client.rightcard.leftBtn.cancel")}
                        </Button>
                        <Button
                          disabled={
                            Number(creditsNumber) <
                            Number(
                              decryptArray(actions)?.filter(
                                (action: {
                                  id: string;
                                  name: string;
                                  credits: string;
                                }) => action.name === "Post job"
                              )[0].credits
                            )
                          }
                          type="button"
                          className={`group relative 
                            ${
                              loading ? " bg-blancoGris cursor-not-allowed" : ""
                            }
                              border-none hover:bg-azulOscuro bg-azulBonico  items-center text-center justify-center text-white w-[135px] rounded-[20px] text-[0.9rem] px-[0.6rem] py-[0] cursor-pointer transition duration-300 h-10 ${
                                !values.titleJob || !selectedCategory
                                  ? "btn-notallowed"
                                  : null
                              }`}
                          onClick={() => {
                            setFormValues({
                              titleJob: values.titleJob,
                            });
                            setJobTitle(values.titleJob);
                            if (draftId) {
                              putdata.append("title", values.titleJob);
                            } else {
                              data.append("title", values.titleJob);
                            }
                            setTimeout(() => {
                              goToSkills();
                            }, 400);
                          }}
                        >
                          {loading ? (
                            <div className="flex items-center justify-center">
                              <LoadingSpinner />
                            </div>
                          ) : (
                            <>{t("welcome-client.rightcard.rightBtn")}</>
                          )}
                          {/* <span className="group-hover:opacity-100 opacity-0 text-[#333] bg-gray-50 rounded-full border flex items-center text-xs absolute -top-8 right-0 px-2 py-1 ">
                            {postJob?.credits}
                            <img src={FlexiCoin} className="h-5 w-5 ml-2" />
                            </span> */}
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      ) : (
        // <Suspense fallback={<Loader />}>
        <SelectedSkills
          setShowHeadline={setShowHeadline}
          draftId={draftId}
          handleRemoveDraft={handleRemoveDraft}
        />
        // </Suspense>
      )}
      {congratulationsGift && (
        <ModalCongratulationsGift
          setShowCongratulationsGift={setCongratulationsGift}
          showCongratulationsGift={congratulationsGift}
        />
      )}
    </div>
  );
}
