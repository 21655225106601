import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import { ReactComponent as Banner } from "src/assets/img/allJobsEmployerBanner.svg";
import Switch from "react-switch";
import { cn } from "src/lib/utils";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { Categories, Cities } from "src/types/types";

import { useModalStore } from "src/Hooks/useModalStore";
import ModalInviteWorker from "src/Components/Modals/ModalInviteWorker";
import { useNavigate } from "react-router-dom";
import WorkerCard from "src/Components/Workers/WorkerCard";
import GoBackButton from "src/Components/ui/GoBack";

import useFiltersWorkers from "src/Hooks/useFiltersWorkers";
import { useAtom } from "jotai";
import {
  featuredWorkers,
  paginationFindWorkers,
  savedWorkers,
  selectedCategory,
  showFilterWorkers,
  showFilterWorkersTime,
  showWorkerSaved,
  sortType,
  workerInput,
} from "src/Atoms/jotaiAtoms";
import { FindWorkerCardSkeleton } from "src/Components/ui/FindWorkerCard";
import {
  ArrowUpIcon,
  ArrowsUpDownIcon,
  CalendarDaysIcon,
  FireIcon,
} from "@heroicons/react/24/solid";
import { useOnClickOutside } from "src/Hooks/useOnClickOutside";
import PaginationFindWorkers from "./PaginationFindWorkers";
import { debounce } from "src/Utils/debounce";
import { useGetCities } from "src/Hooks/useGetCities";
import { BASE_URL } from "src/Config/api.config";
import { useQuery } from "react-query";
import { IoClose } from "react-icons/io5";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import ModalFilterWorkersTime from "src/Components/Modals/ModalFilterWorkersTime";
import { BsSliders2 } from "react-icons/bs";
import ModalFilterWorkersSchedule from "src/Components/Modals/ModalFilterWorkersSchedule";
import { useActionTypesStore } from "src/Routing/useProfileStore";
import { decryptArray } from "src/Hooks/decryptString";
import { ModalDiscoverWorker } from "src/Components/Modals/ModalDiscoverWorker";

type Order = "asc" | "desc";
type SortOption = { enabled: boolean; order: Order };

type SortBy = {
  featured: SortOption;
  updated: SortOption;
  budget: SortOption;
};
export const GOOGLE_API_KEY = "AIzaSyCZrRxNGqvDU6bMRiot76YQKyDcERCoqDA";

const FindWorkers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checkedFeatured, setCheckedFeatured] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [cities, setCities] = useState<Cities[]>([]);
  const [categories, setCategories] = useState<Categories[]>([]);
  const [showModalInvite, setShowModalInvite] = useModalStore((state) => [
    state.showModalInvite,
    state.setShowModalInvite,
  ]);
  const [clickedWorker, setClickedWorker] = useState<any>();
  const [showModalDiscover, setShowModalDiscover] = useState({
    discover: false,
  });
  const [workerInfo, setWorkerInfo] = useState<any>();

  const [showAllWorkers, setShowAllWorkers] = useAtom(showWorkerSaved);
  const [workersFeatured, setWorkersFeatured] = useAtom(featuredWorkers);
  const [searchCategory, setSearchCategory] = useState("");
  const [filterWorkers, setFilterWorkers] = useAtom(showFilterWorkers);
  const [filterTime, setFilterTime] = useAtom(showFilterWorkersTime);
  const actions = useActionTypesStore((s: any) => s.actions);

  const [sortBy, setSortBy] = useState<SortBy>({
    featured: {
      enabled: true,
      order: "desc",
    },
    updated: {
      enabled: false,
      order: "desc",
    },
    budget: {
      enabled: false,
      order: "desc",
    },
  });
  const [openSort, setOpenSort] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [headerVisible, setHeaderVisible] = useState(true);
  const [selectCategory, setSelectCategory] = useAtom(selectedCategory);

  const [sortOptionSelected, setSortOptionSelected] = useAtom(sortType);
  const [pagination, setPagination] = useAtom(paginationFindWorkers);

  const [findWorkerInput, setFindWorkerInput] = useAtom(workerInput);
  const [saveWorkers, setSavedWorkers] = useAtom(savedWorkers);

  const filteredCategories = categories?.filter((category) =>
    category.name.toLowerCase().includes(searchCategory.toLowerCase())
  );

  const [showModalCategories, setShowModalCategories] = useState(true);
  const modalCategoriesRef = useRef<HTMLDivElement>(null);
  const [searchDirectionResult, setSearchDirectionResult] = useState<any>();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-autocomplete-script",
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
    region: "ES",
  });

  useOnClickOutside(modalRef, () => {
    setOpenSort(false);
  });
  // useOnClickOutside(modalCategoriesRef, () => {
  //   setShowModalCategories(false);
  // });

  const { workers, getDiscoverWorkers, loading } = useFiltersWorkers();

  const { citiesData } = useGetCities();

  const jobCategories = async () => {
    const data = await fetch(`${BASE_URL}job.categories?category_id`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: jobCategoryData, isLoading: isCategoriesLoading } = useQuery(
    ["job_categories"],
    jobCategories
  );

  useEffect(() => {
    setCities(citiesData);
  }, [citiesData]);

  useEffect(() => {
    if (sortBy.featured.enabled) {
      setSortOptionSelected(() => ({
        enabled: sortBy.featured.enabled,
        order: sortBy.featured.order,
        name: "date_featured",
      }));
    } else if (sortBy.updated.enabled) {
      setSortOptionSelected(() => ({
        enabled: sortBy.updated.enabled,
        order: sortBy.updated.order,
        name: "date_updated",
      }));
    } else if (sortBy.budget.enabled) {
      setSortOptionSelected(() => ({
        enabled: sortBy.budget.enabled,
        order: sortBy.budget.order,
        name: "budget",
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    setCategories(jobCategoryData);
  }, [jobCategoryData]);
  // const getAllCategories = async () => {
  //   const data = await fetch(`${BASE_URL}job.categories?category_id`, {
  //     headers: {
  //       Authorization: "Bearer " + loggedInUserUid,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => setCategories(data.result))
  //     .catch((err) => console.log(err));
  // };

  // useEffect(() => {
  //   getAllCategories();
  // }, []);
  const handleClick = (value: string) => {
    setShowModalCategories(false);
    setSearchCategory(value);
  };
  useEffect(() => {
    if (selectCategory) {
      setSearchCategory(selectCategory.name);
      setShowModalCategories(false);
    }
  }, [selectCategory]);

  useEffect(() => {
    if (searchCategory) {
      setShowModalCategories(true);
    }
  }, [searchCategory]);

  const handleToggleSortUpdated = () => {
    if (sortBy.budget.enabled) {
      setSortBy((prev) => ({
        ...prev,
        budget: { enabled: false, order: sortBy.budget.order },
      }));
    }
    if (sortBy.featured.enabled) {
      setSortBy((prev) => ({
        ...prev,
        featured: { enabled: false, order: sortBy.featured.order },
      }));
    }
    setSortBy((prev) => ({
      ...prev,
      updated: {
        enabled: true,
        order: sortBy.updated.order === "asc" ? "desc" : "asc",
      },
    }));
  };

  const handleToggleSortFeatured = () => {
    if (sortBy.budget.enabled) {
      setSortBy((prev) => ({
        ...prev,
        budget: { enabled: false, order: sortBy.budget.order },
      }));
    }
    if (sortBy.updated.enabled) {
      setSortBy((prev) => ({
        ...prev,
        updated: { enabled: false, order: sortBy.updated.order },
      }));
    }
    setSortBy((prev) => ({
      ...prev,
      featured: {
        enabled: true,
        order: sortBy.featured.order === "asc" ? "desc" : "asc",
      },
    }));
  };
  const handleToggleSortBudget = () => {
    if (sortBy.featured.enabled) {
      setSortBy((prev) => ({
        ...prev,
        featured: { enabled: false, order: sortBy.featured.order },
      }));
    }
    if (sortBy.updated.enabled) {
      setSortBy((prev) => ({
        ...prev,
        updated: { enabled: false, order: sortBy.updated.order },
      }));
    }
    setSortBy((prev) => ({
      ...prev,
      budget: {
        enabled: true,
        order: sortBy.budget.order === "asc" ? "desc" : "asc",
      },
    }));
  };

  const handleSearch = (e: string) => {
    setFindWorkerInput(e);
  };

  const optimisedSearch = useCallback(debounce(handleSearch), []);

  const highlightMatch = (name: string, term: string) => {
    const regex = new RegExp(`(${term})`, "gi");
    return name.replace(
      regex,
      "<mark class='font-semibold bg-transparent'>$1</mark>"
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showModalCategories &&
        modalCategoriesRef.current &&
        !modalCategoriesRef.current.contains(e.target)
      ) {
        setShowModalCategories(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModalCategories]);

  const onChange = () => {
    if (searchDirectionResult !== null && searchDirectionResult !== undefined) {
      console.log(searchDirectionResult?.getPlace().name);
    }
  };

  const onLoad = (autoComplete: any) => {
    setSearchDirectionResult(autoComplete);
  };

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("employer.find_workers.header_banner")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline my-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      {/* <div className="bg-white rounded border dark:bg-background dark:text-whiteish">
        <div className="flex w-full flex-col lg:flex-row px-8 py-8 justify-between border-b border-border   relative">
          <div className="flex items-center  rounded-full border  border-border pr-2 h-14 w-2/3 relative">
            <input
              className="px-4 py-2 h-full text-sm  rounded-full  transition  focus:border outline-none"
              placeholder="Service"
              value={searchCategory}
              onChange={(e) => setSearchCategory(e.target.value)}
            />
            {searchCategory.length > 0 && (
              <button
                onClick={() => {
                  setSearchCategory("");
                  setSelectCategory({ category_id: "", name: "" });
                }}
                className="h-6 w-6 flex items-center justify-center absolute left-[180px] top-4 z-10 bg-gray-100 rounded-full"
              >
                <IoClose className="h-4 w-4 text-gray-500" />
              </button>
            )}
            {searchCategory.length > 0 && showModalCategories && (
              <div
                ref={modalCategoriesRef}
                className="max-h-80 w-80 absolute top-20 bg-white border z-10 border border-border"
              >
                <ul className="h-full max-h-80 overflow-y-auto">
                  {filteredCategories?.map((category) => (
                    <li
                      onClick={() => {
                        setSelectCategory(category);
                        handleClick(category.name);
                      }}
                      key={category.category_id}
                      dangerouslySetInnerHTML={{
                        __html: highlightMatch(category.name, searchCategory),
                      }}
                      className="text-sm  py-4 px-6 hover:bg-[#f8f8f8] transition  cursor-pointer border-b border-border"
                    />
                  ))}
                </ul>
              </div>
            )}
            <div className="h-full w-2/3">
              {isLoaded && (
                <Autocomplete
                  onPlaceChanged={onChange}
                  onLoad={onLoad}
                  className="h-full border -none "
                >
                  <input
                    ref={inputRef}
                    className="px-4 -ml-4 rounded-full h-[95%] transition focus:border outline-none text-sm w-full      "
                    placeholder="Buscar trabajadores"
                  />
                </Autocomplete>
              )}
            </div>

            <button className="px-10 text-sm font-medium py-3 bg-azulBonico rounded-full text-white">
              Search
            </button>
          </div>
          <div className="flex items-center gap-4 mt-4 lg:mt-0">
            <Switch
              checked={checkedFeatured}
              onChange={(checked) => {
                setCheckedFeatured(!checkedFeatured);
                if (checked) {
                  setWorkersFeatured("1");
                } else {
                  setWorkersFeatured("0");
                }
              }}
            />

            <FireIcon
              className={cn(
                "h-6 w-6 ",
                checkedFeatured ? "text-orange-500" : "text-gray-500"
              )}
            />
          </div>
        </div>
      </div>*/}
      <div className="bg-white rounded-lg border -md dark:bg-background dark:text-whiteish">
        {/* Search Bar Section */}
        <div className="flex flex-col lg:flex-row px-8 py-8 justify-between border-b border-border relative">
          {/* Search Input */}
          <div className="flex items-center rounded-full border border-border pr-2 h-14 w-2/3 relative">
            <input
              type="text"
              className="px-4 py-2 h-full text-sm rounded-full transition focus:border outline-none w-full"
              placeholder="Service"
              value={searchCategory}
              onChange={(e) => setSearchCategory(e.target.value)}
            />
            {searchCategory.length > 0 && (
              <button
                onClick={() => {
                  setSearchCategory("");
                  setSelectCategory({ category_id: "", name: "" });
                }}
                className="absolute left-[180px] top-4 z-10 bg-gray-100 rounded-full h-6 w-6 flex items-center justify-center"
              >
                <IoClose className="h-4 w-4 text-gray-500" />
              </button>
            )}
            {/* Dropdown for Categories */}
            {searchCategory.length > 0 && showModalCategories && (
              <div
                ref={modalCategoriesRef}
                className="absolute top-20 bg-white border z-10  border-border w-80 max-h-80 overflow-y-auto"
              >
                <ul>
                  {filteredCategories?.map((category) => (
                    <li
                      key={category.category_id}
                      onClick={() => {
                        setSelectCategory(category);
                        handleClick(category.name);
                      }}
                      dangerouslySetInnerHTML={{
                        __html: highlightMatch(category.name, searchCategory),
                      }}
                      className="text-sm py-4 px-6 hover:bg-[#f8f8f8] transition cursor-pointer border-b border-border"
                    />
                  ))}
                </ul>
              </div>
            )}
            {/* Google Autocomplete */}
            <div className="h-full w-2/3">
              {isLoaded && (
                <Autocomplete
                  onPlaceChanged={onChange}
                  onLoad={onLoad}
                  className="h-full border -none"
                >
                  <input
                    ref={inputRef}
                    className="px-4 -ml-4 rounded-full h-[95%] transition focus:border outline-none text-sm w-full"
                    placeholder="Buscar trabajadores"
                  />
                </Autocomplete>
              )}
            </div>
            {/* Search Button */}
            <button className="px-10 text-sm font-medium py-3 bg-azulBonico rounded-full text-white">
              Search
            </button>
          </div>
          {/* Toggle and Featured Switch */}
          <div className="flex items-center gap-4 mt-4 lg:mt-0">
            <Switch
              checked={checkedFeatured}
              onChange={(checked) => {
                setCheckedFeatured(!checkedFeatured);
                setWorkersFeatured(checked ? "1" : "0");
              }}
            />
            <FireIcon
              className={cn(
                "h-6 w-6",
                checkedFeatured ? "text-orange-500" : "text-gray-500"
              )}
            />
          </div>
        </div>
      </div>

      {selectCategory?.name !== "" && (
        <div className="flex items-center space-x-4 mt-4">
          <div
            onClick={() => setFilterWorkers(true)}
            className="cursor-pointer  border-2 flex items-center justify-center w-32 h-10 bg-white rounded "
          >
            <BsSliders2 className="h-5 w-5 mr-2" />

            <p>Filters</p>
          </div>
          <div
            className="relative border-2 w-32 h-10 cursor-pointer border-border flex items-center justify-center  bg-white rounded "
            ref={modalRef}
          >
            <div
              onClick={() => setOpenSort(!openSort)}
              className="flex items-center gap-2 cursor-pointer "
            >
              <ArrowsUpDownIcon className="text-gray-500 h-4 w-4" />
              {t("employer.find_workers.sort")}
            </div>
            <div
              className={cn(
                "absolute top-10 right-0  transition w-60 p-2 bg-white rounded borderflex flex-col gap-1 z-20",
                openSort ? "scale-100 translate-y-0" : "scale-0 -translate-y-2"
              )}
            >
              <div
                className={`bg-white border-l border-t z-20 w-4 h-4 rotate-[45deg] absolute -top-2 right-6 `}
              />
              <div
                onClick={() => handleToggleSortFeatured()}
                className={cn(
                  "text-sm flex items-center justify-between cursor-pointer p-2 rounded",
                  sortBy.featured.enabled && "bg-gray-50"
                )}
              >
                {t("employer.find_workers.sort_featured")}
              </div>
              <div
                onClick={() => handleToggleSortUpdated()}
                className={cn(
                  "text-sm flex items-center justify-between cursor-pointer p-2 rounded",
                  sortBy.updated.enabled && "bg-gray-50"
                )}
              >
                {t("employer.find_workers.sort_updated")}
                <div className="bg-gray-100 rounded-full p-1">
                  <ArrowUpIcon
                    className={cn(
                      "h-3 w-3 transition",
                      sortBy.updated.order === "asc"
                        ? "rotate-180 transition"
                        : "rotate-0"
                    )}
                  />
                </div>
              </div>
              <div
                onClick={() => handleToggleSortBudget()}
                className={cn(
                  "text-sm flex items-center justify-between cursor-pointer p-2 rounded",
                  sortBy.budget.enabled && "bg-gray-50"
                )}
              >
                {t("employer.find_workers.sort_budget")}
                <div className="bg-gray-100 rounded-full p-1">
                  <ArrowUpIcon
                    className={cn(
                      "h-3 w-3 transition",
                      sortBy.budget.order === "asc"
                        ? "rotate-180 transition"
                        : "rotate-0"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setFilterTime(true)}
            className="relative border-2 w-32 gap-2 h-10 cursor-pointer border-border flex items-center justify-center  bg-white rounded "
          >
            <CalendarDaysIcon className="h-4 w-4" />
            <h1>¿Cuándo?</h1>
          </div>
        </div>
      )}

      <div className="flex flex-col lg:grid grid-cols-5 items-center mx-auto gap-4 w-full max-w-4xl ">
        <div className="my-4 w-full lg:col-span-5">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            {showAllWorkers ? (
              <h1 className="text-xl font-medium my-4">
                {t("employer.find_workers.professionals_flexi")}
              </h1>
            ) : (
              <h1 className="text-xl font-medium my-4">
                {t("employer.find_workers.sort_saved_workers")}
              </h1>
            )}
            <PaginationFindWorkers
              pagination={pagination}
              setPagination={setPagination}
              getDiscoverWorkers={getDiscoverWorkers}
            />
          </div>
          <div className="">
            <div className="flex flex-col gap-2 mt-1 ">
              <>
                {workersFeatured === "0" && workers?.length > 0 && (
                  <>
                    {workers?.map((worker) => (
                      <>
                        {loading ? (
                          <FindWorkerCardSkeleton />
                        ) : (
                          <>
                            <WorkerCard
                              key={worker.data.client_id_worker}
                              worker={worker}
                              setClickedWorker={setClickedWorker}
                              refetch={getDiscoverWorkers}
                              setShowModalDiscover={setShowModalDiscover}
                              showModalDiscover={showModalDiscover}
                              discoverValue={
                                decryptArray(actions)?.filter(
                                  (action: {
                                    name: string;
                                    credits: string;
                                    id: string;
                                  }) => action.name === "Discovery"
                                )[0].credits
                              }
                            />
                          </>
                        )}
                      </>
                    ))}
                  </>
                )}
                {workers.length === 0 && !loading && (
                  <h1 className="text-sm font-medium text-[#333]">
                    {t("employer.find_workers.no_results_found")}
                  </h1>
                )}
              </>
              <>
                {workersFeatured === "1" && workers?.length > 0 && (
                  <>
                    {workers
                      ?.filter((worker) => worker.data.date_featured !== null)
                      ?.map((worker) => (
                        <>
                          {loading ? (
                            <FindWorkerCardSkeleton />
                          ) : (
                            <>
                              <WorkerCard
                                setClickedWorker={setClickedWorker}
                                discoverValue={
                                  decryptArray(actions)?.filter(
                                    (action: {
                                      name: string;
                                      credits: string;
                                      id: string;
                                    }) => action.name === "Discovery"
                                  )[0].credits
                                }
                                key={worker.data.client_id_worker}
                                worker={worker}
                                refetch={getDiscoverWorkers}
                                setShowModalDiscover={setShowModalDiscover}
                                showModalDiscover={showModalDiscover}
                              />
                            </>
                          )}
                        </>
                      ))}
                  </>
                )}
                {/* {workers.length === 0 && !loading && (
                  <h1 className="text-sm font-medium text-[#333]">
                    {t("employer.find_workers.no_results_found")}
                  </h1>
                )} */}
              </>
            </div>
          </div>
        </div>
      </div>
      {showModalInvite && <ModalInviteWorker />}
      {filterWorkers && <ModalFilterWorkersTime />}
      {filterTime && <ModalFilterWorkersSchedule />}
      {showModalDiscover.discover && (
        <ModalDiscoverWorker
          discoverValue={
            decryptArray(actions)?.filter(
              (action: { name: string; credits: string; id: string }) =>
                action.name === "Discovery"
            )[0].credits
          }
          setWorkerInfo={setWorkerInfo}
          showModal={showModalDiscover}
          setShowModal={setShowModalDiscover}
          worker={clickedWorker}
          refetch={getDiscoverWorkers}
        />
      )}
    </>
  );
};

export default FindWorkers;
