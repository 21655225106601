import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "../ui/Button";
import { AiOutlineLoading } from "react-icons/ai";

import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import React from "react";
interface CardSetupFormProps {
  clientSecret: string;
  setShowAddPaymentMethod: React.Dispatch<React.SetStateAction<boolean>>;
  setClientSecret: React.Dispatch<React.SetStateAction<string>>;
  showAddPaymentMethod: boolean;
  loading: boolean;
}

const CardSetupForm: React.FC<CardSetupFormProps> = ({
  clientSecret,
  setShowAddPaymentMethod,
  showAddPaymentMethod,
  setClientSecret,
  loading,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error("Stripe is not initialized yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      toast.error("Card Element not found.");
      return;
    }

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: "Cardholder Name", // Replace with dynamic values
        },
      },
    });

    if (error) {
      console.error("Error:", error.message);
      toast.error("Failed to set up card: " + error.message);
    } else {
      console.log("SetupIntent:", setupIntent);
      toast.success("Card setup successfully!");
      setShowAddPaymentMethod(!showAddPaymentMethod);
      setClientSecret("");

      // Optionally, update your backend with setupIntent.id
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
      <CardElement className="border p-4 rounded" />
      {loading ? (
        <Button
          className=" flex justify-center items-center gap-2 border-2 px-4 py-2 border-gray-300 text-gray-700 hover:bg-gray-100"
          type="submit"
          disabled={!stripe}
        >
          {t(
            "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.confirm_card_method"
          )}{" "}
          <AiOutlineLoading size={20} className="animate-spin" />
        </Button>
      ) : (
        <Button
          className=" flex justify-center items-center gap-2 border-2 px-4 py-2 border-gray-300 text-gray-700 hover:bg-gray-100"
          type="submit"
          disabled={!stripe}
        >
          {t(
            "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.confirm_card_method"
          )}{" "}
        </Button>
      )}
    </form>
  );
};

export default CardSetupForm;
