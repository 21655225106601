import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { Button } from "../ui/Button";
import { useTranslation } from "react-i18next";
import { BASE_URL, CLOSE_JOB_EMPLOYER } from "src/Config/api.config";

import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useNavigate } from "react-router-dom";
import { JobTypes } from "src/types/types";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import LoadingSpinner from "src/Pages/General/Home/LoadingSpinner";
import parse from "html-react-parser";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { cn } from "src/lib/utils";
import { formatJobTitle } from "src/Utils/formatters";
import useUpdateCredits from "src/Hooks/useUpdateCredits";

import { useModalStore } from "src/Hooks/useModalStore";
import {
  useActionTypesStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import { decryptArray } from "src/Hooks/decryptString";

interface ModalCloneJobProps {
  showModal: boolean;
  job: JobTypes;
}

const ModalCloneJob = ({ showModal, job }: ModalCloneJobProps) => {
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [successCloned, setSuccessCloned] = useState(false);
  const [showCloneModal, setShowCloneModal] = useModalStore((state) => [
    state.showCloneModal,
    state.setShowCloneModal,
  ]);
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const actions = useActionTypesStore((s: any) => s.actions);

  const { creditsDataInfo, updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "2"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "2"
    )[0]?.id
  );

  const cloneData = new FormData();
  cloneData.append("_method", "PUT");
  cloneData.append("client_id", userID);
  cloneData.append("job_id", job.job_id);
  cloneData.append("process", "CLONE");

  const handleCloneJob = () => {
    setLoading(true);
    fetch(`${BASE_URL}${CLOSE_JOB_EMPLOYER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: cloneData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          updateCredits();
          setTimeout(() => {
            setLoading(false);
            setSuccessCloned(true);
          }, 2000);

          setTimeout(() => {
            setShowCloneModal(showCloneModal);
          }, 3000);
          setTimeout(() => {
            if (profileType === "employer") {
              navigate("/dashboard");
            } else {
              navigate("/dashboard-worker");
            }
          }, 4000);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowCloneModal(showCloneModal);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showModal} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-50 ${
          transition === false ? "opacity-40 scale-0" : "opacity-100 scale-100"
        } transition w-[90%]  lg:w-[700px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="px-8 py-6 flex items-center justify-between">
          <p className="text-xl font-bold ">
            {t("employer.applicants.view_jobpost.modal.clone.title")}
          </p>
          <div
            onClick={() => setShowCloneModal(showCloneModal)}
            className="p-2 hover:bg-gray-200 rounded-full transition cursor-pointer"
          >
            <Cross className="h-4 w-4" />
          </div>
        </div>

        <div className="px-8 mt-6">
          <div className="flex items-end justify-between bg-gray-50 rounded p-2">
            <div className="w-[80%]">
              <p className="text-sm text-neutral-600 font-medium">
                {" "}
                {formatJobTitle(job.title)}
              </p>
              <div className="mt-4">
                <p className="text-xs text-neutral-500 ">{job.details}</p>
                <p className="text-xs text-neutral-300 font-medium">
                  {job.location}
                </p>
              </div>
              <div>{/* {job.skill_list.split(",")} */}</div>
            </div>
            <div className="flex flex-col items-end w-[20%]">
              <p className="text-azulBonico text-xs">{job.category_name}</p>
              <p className="text-xs">
                <span className="font-semibold">€{job.budget}</span> /{" "}
                {t("employer.job_details.hour")}
              </p>
            </div>
          </div>
        </div>
        <div className="px-8 py-6 ">
          <p className="text-sm ">
            {parse(t("employer.applicants.view_jobpost.modal.clone.desc"))}
          </p>
          <div className="flex items-center mt-2">
            <p className="text-xs text-neutral-400 ">
              {Number(
                decryptArray(actions)?.filter(
                  (action: { id: string; name: string; credits: string }) =>
                    action.id === "2"
                )[0]?.credits
              )}{" "}
              {t("employer.applicants.view_jobpost.modal.clone.credits")}
            </p>
            <QuestionMarkCircleIcon className="h-4 w-4 ml-1 text-neutral-400" />
          </div>
        </div>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1"
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourworkexperience.leftBtn")}
          </Button>
          <Button
            className={cn(
              `border-none flex items-center transition  justify-center text-white w-[110px] bg-azulBonico hover:bg-azulOscuro rounded-[20px] text-[0.9rem] px-[1rem]   h-10 `,
              successCloned && !loading && "bg-green-500 scale-105"
            )}
            onClick={() => {
              handleCloneJob();
              // closeModal();
            }}
          >
            {loading && !successCloned && <LoadingSpinner />}
            {!loading && !successCloned && (
              <>{t("modal.end_early_date.accept")}</>
            )}
            {successCloned && !loading && (
              <>
                <CheckCircleIcon className={`h-6 w-6`} />
              </>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCloneJob;
