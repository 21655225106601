import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  Dispatch,
  SetStateAction,
} from "react";
import Select from "react-select";
import { useAtom } from "jotai";

import { BASE_URL, CITIES, POSTCODES } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import {
  ALL_FILTERS_INITIAL,
  allFiltersAppliedToJobSearch,
  categoryFilterOptions,
  cityFilterOptions,
  postcodeFilterOptions,
  skillFilterOptions,
} from "../../Atoms/jotaiAtoms";

import { ReactComponent as Search } from "../../assets/img/search.svg";

import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";
import autoAnimate from "@formkit/auto-animate";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "@heroicons/react/24/solid";

import { useThemeStore } from "src/Routing/useThemeStore";
import { BsCurrencyEuro } from "react-icons/bs";
import { Button } from "../ui/Button";
import { cn } from "src/lib/utils";
import { useGetCities } from "src/Hooks/useGetCities";
import { useCategories } from "src/Hooks/useCategories";
import { useSkills } from "src/Hooks/useSkills";

const countries = [{ value: "Spain", label: "Spain" }];

interface FiltersProps {
  selectedCategories: any;
  setSelectedCategories: Dispatch<SetStateAction<any>>;
  selectedSkills: [];
  setSelectedSkills: Dispatch<SetStateAction<any>>;
  selectedCountry: [];
  setSelectedCountry: Dispatch<SetStateAction<any>>;
  selectedCities: [];
  setSelectedCities: Dispatch<SetStateAction<any>>;
  selectedPostcodes: [];
  setSelectedPostCodes: Dispatch<SetStateAction<any>>;
  setSelectedBudget: Dispatch<SetStateAction<any>>;
  setSelectedJobType: Dispatch<SetStateAction<any>>;
  selectedJobType: any;
  // jobTypeFilter: any;
  // setJobTypeFilter: Dispatch<SetStateAction<any>>;
  clearSalaryInput: boolean;
  setClearSalaryInput: Dispatch<SetStateAction<boolean>>;
  setAreThereFiltersChosen: Dispatch<SetStateAction<boolean>>;
  hideAllFilters: boolean;
  setHideAllFilters: Dispatch<SetStateAction<boolean>>;
}

function Filters({
  selectedCategories,
  setSelectedCategories,
  selectedSkills,
  setSelectedSkills,
  selectedCountry,
  setSelectedCountry,
  selectedCities,
  setSelectedCities,
  selectedPostcodes,
  setSelectedPostCodes,
  setSelectedBudget,
  setSelectedJobType,
  selectedJobType,
  // jobTypeFilter,
  // setJobTypeFilter,
  clearSalaryInput,
  setClearSalaryInput,
  setAreThereFiltersChosen,
  hideAllFilters,
  setHideAllFilters,
}: FiltersProps) {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [desiredJob, setDesiredJob] = useLocalStorage("desiredJob", []);

  const [allCategories, setAllCategories] = useState([]);
  const [allSkills, setAllSkills] = useState<
    {
      name: string;
      skill_id: string;
    }[]
  >([{ name: "", skill_id: "" }]);
  const [allCities, setAllCities] = useState<any>([]);
  const [allPostcodes, setAllPostcodes] = useState<any>([]);
  const [categoryFilter, setCategoryFilter] = useAtom(categoryFilterOptions);
  const [skillsFilter, setSkillsFilter] = useAtom(skillFilterOptions);
  const [cityFilter, setCityFilter] = useAtom(cityFilterOptions);
  const [postcodeFilter, setPostcodeFilter] = useAtom(postcodeFilterOptions);
  const [budget, setBudget] = useState("");
  //Show Filters

  const [showLocationFilter, setShowLocationFilter] = useState(false);
  const [showBudgetFilter, setShowBudgetFilter] = useState(false);
  // const [showLengthFilter, setShowLengthFilter] = useState(false);
  const [showJobTypeFilter, setShowJobTypeFilter] = useState(true);
  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  const [showSkillsFilter, setShowSkillsFilter] = useState(false);
  const [savedSearchesFilters, setSavedSearchesFilters] = useAtom(
    allFiltersAppliedToJobSearch
  );
  const [auxiliarCounter, setAuxiliarCounter] = useState(0);
  const [categoryIsSet, setCategoryIsSet] = useState(false);
  const [skillIsSet, setSkillIsSet] = useState(false);
  const [cityIsSet, setCityIsSet] = useState(false);
  const [postcodeIsSet, setPostcodeIsSet] = useState(false);

  const jobTypeRef = useRef(null);
  const budgetTypeRef = useRef(null);
  const categoryRef = useRef(null);
  const skillsRef = useRef(null);
  const locationRef = useRef(null);
  const theme = useThemeStore((state) => state.themeMode);
  const { categories, refetchCategories } = useCategories();
  const { skillsData: skills, refetchSkills } = useSkills();

  const { t } = useTranslation();

  const { citiesData } = useGetCities();

  useEffect(() => {
    setAllCities(citiesData);
  }, [citiesData]);

  useEffect(() => {
    jobTypeRef.current && autoAnimate(jobTypeRef.current);
  }, [jobTypeRef]);
  useEffect(() => {
    budgetTypeRef.current && autoAnimate(budgetTypeRef.current);
  }, [budgetTypeRef]);
  useEffect(() => {
    categoryRef.current && autoAnimate(categoryRef.current);
  }, [categoryRef]);
  useEffect(() => {
    skillsRef.current && autoAnimate(skillsRef.current);
  }, [skillsRef]);
  useEffect(() => {
    locationRef.current && autoAnimate(locationRef.current);
  }, [locationRef]);

  const budgetRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hideAllFilters === true) {
      setShowLocationFilter(false);
      setShowBudgetFilter(false);
      setShowCategoryFilter(false);
      setShowSkillsFilter(false);
      setHideAllFilters(false);
    }
  }, [hideAllFilters]);

  useEffect(() => {
    setAllCategories(categories);
  }, [categories]);

  useEffect(() => {
    setAllSkills(skills);
  }, [skills]);

  useEffect(() => {
    refetchCategories();
    refetchSkills();
  }, []);

  const getAllCities = () => {
    if (savedSearchesFilters && auxiliarCounter === 0) {
      fetch(`${BASE_URL}${CITIES}?country_code=es`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setAllCities(data.result))
        .catch((err) => console.log(err));
    } else if (!savedSearchesFilters) {
      fetch(`${BASE_URL}${CITIES}?country_code=ES&limit=4000`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setAllCities(data.result))
        .catch((err) => console.log(err));
    }
  };

  const getAllPostcodes = (props: string) => {
    if (savedSearchesFilters && auxiliarCounter === 0) {
      fetch(`${BASE_URL}${POSTCODES}?country_code=es`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => setAllPostcodes(data.result))
        .catch((err) => console.log(err));
    } else if (!savedSearchesFilters) {
      if (props?.length > 1) {
        fetch(`${BASE_URL}${POSTCODES}?country_code=ES&name=${props}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${loggedInUserUid}`,
          },
        })
          .then((res) => res.json())
          .then((data) => setAllPostcodes(data.result))
          .catch((err) => console.log(err));
      }
    }
  };

  useEffect(() => {
    if (selectedCountry && !savedSearchesFilters) {
      setSelectedCities([]);
      setSelectedPostCodes([]);
      getAllCities();
      getAllPostcodes("");
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (savedSearchesFilters) {
      getAllCities();
      getAllPostcodes("");
    }
  }, [savedSearchesFilters]);

  useEffect(() => {
    const append = allCities?.map(function (row: { name: string; id: string }) {
      return {
        value: row.name,
        label: row.name,
        id: row.id,
        class: "city",
        name: row.name,
        checked: false,
      };
    });
    setCityFilter(append);
    setTimeout(() => {
      setCityIsSet(true);
    }, 1000);
  }, [allCities]);
  useEffect(() => {
    const append = allPostcodes?.map(function (row: {
      postcode: string;
      locality: string;
      state: string;
      id: string;
    }) {
      return {
        label: row.locality + ", " + row.state + ", " + row.postcode,
        value: row.locality + ", " + row.state + ", " + row.postcode,
        state: row.state,
        class: "postcode",
        postcode: row.postcode,
        id: row.id,
        checked: false,
      };
    });
    setPostcodeFilter(append);
    setTimeout(() => {
      setPostcodeIsSet(true);
    }, 1000);
  }, [allPostcodes]);
  useEffect(() => {
    if (allCategories?.length > 0) {
      const append = allCategories?.map(function (row: {
        name: string;
        category_id: string;
      }) {
        return {
          value: row.name,
          label: row.name,
          category_id: row.category_id,
          name: row.name,
          class: "category",
          checked: false,
        };
      });
      setCategoryFilter(append);
      setTimeout(() => {
        setCategoryIsSet(true);
      }, 500);
    }
  }, [allCategories]);

  useEffect(() => {
    const append = allSkills?.map(function (row) {
      return {
        value: row.name,
        label: row.name,
        class: "skill",
        skill_id: row.skill_id,
        name: row.name,
        checked: false,
      };
    });
    setSkillsFilter(append);
    setTimeout(() => {
      setSkillIsSet(true);
    }, 500);
  }, [allSkills]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      backgroundColor: theme === "dark" ? "input" : "white",

      padding: "0rem",
      paddingLeft: "2rem",
      borderRadius: "4px",
      height: "40px",
    }),
  };
  const colourStyles2 = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      backgroundColor: "white",
      padding: "0rem",
      borderRadius: "4px",
      height: "40px",
    }),
  };

  useEffect(() => {
    if (clearSalaryInput === true && budgetRef.current) {
      if (auxiliarCounter === 0) {
        budgetRef.current.value = "";
      }
      setClearSalaryInput(false);
      setSelectedBudget("");
      setBudget("");
    }
  }, [clearSalaryInput]);

  useEffect(() => {
    if (
      savedSearchesFilters &&
      auxiliarCounter === 0 &&
      categoryIsSet === true &&
      skillIsSet === true &&
      cityIsSet === true &&
      postcodeIsSet === true
    ) {
      if (savedSearchesFilters?.find) setDesiredJob(savedSearchesFilters.find);

      if (
        savedSearchesFilters?.category_id_list &&
        categoryFilter?.length > 0
      ) {
        const arr_idx = savedSearchesFilters.category_id_list.split(",");
        setSelectedCategories(
          arr_idx
            ?.map((idx: string) =>
              categoryFilter.filter(
                (item: { category_id: string }) => item.category_id === idx
              )
            )
            .flat()
        );
        for (let i = 0; i < arr_idx.length; i++) {
          setCategoryFilter((current: any) =>
            current?.map((obj: { category_id: string }) => {
              if (obj.category_id === arr_idx[i]) {
                return { ...obj, checked: true };
              }
              return obj;
            })
          );
        }
      }

      if (savedSearchesFilters?.skill_id_list && skillsFilter?.length > 0) {
        const arr_idx = savedSearchesFilters.skill_id_list.split(",");
        setSelectedSkills(
          arr_idx
            ?.map((idx: string) =>
              skillsFilter.filter(
                (item: { skill_id: string }) => item.skill_id === idx
              )
            )
            .flat()
        );
        for (let i = 0; i < arr_idx.length; i++) {
          setSkillsFilter((current: any) =>
            current?.map((obj: { skill_id: string }) => {
              if (obj.skill_id === arr_idx[i]) {
                return { ...obj, checked: true };
              }
              return obj;
            })
          );
        }
      }

      if (savedSearchesFilters?.country_code) {
        setSelectedCountry(countries[0]);
      }

      if (savedSearchesFilters?.city_id_list && cityFilter?.length > 0) {
        const arr_idx = savedSearchesFilters.city_id_list.split(",");
        setSelectedCities(
          arr_idx
            ?.map((idx) =>
              cityFilter.filter((item: { id: string }) => item.id === idx)
            )
            .flat()
        );

        // SLOWIND DOWN CITIES DROPDOWN
        for (let i = 0; i < arr_idx.length; i++) {
          setCityFilter((current: any) =>
            current?.map((obj: { id: string }) => {
              if (obj.id === arr_idx[i]) {
                return { ...obj, checked: true };
              }
              return obj;
            })
          );
        }
      }
      if (
        savedSearchesFilters?.postcode_id_list &&
        postcodeFilter?.length > 0
      ) {
        const arr_idx = savedSearchesFilters.postcode_id_list.split(",");
        setSelectedPostCodes(
          arr_idx
            ?.map((idx) =>
              postcodeFilter.filter((item: { id: string }) => item.id === idx)
            )
            .flat()
        );
        for (let i = 0; i < arr_idx.length; i++) {
          setPostcodeFilter((current: any) =>
            current?.map((obj: { id: string }) => {
              if (obj.id === arr_idx[i]) {
                return { ...obj, checked: true };
              }
              return obj;
            })
          );
        }
      }

      setTimeout(() => {
        setAreThereFiltersChosen(true);
        setAuxiliarCounter(1);
      }, 500);
    }
  }, [categoryIsSet, skillIsSet, cityIsSet, postcodeIsSet]);

  useEffect(() => {
    setSavedSearchesFilters((prev) => ({
      ...prev,
      budget: budget,
    }));
  }, [budget]);

  useEffect(() => {
    if (auxiliarCounter === 1) setSavedSearchesFilters(ALL_FILTERS_INITIAL);
  }, [auxiliarCounter]);

  // const handleJobTypeChange = (type: {
  //   checked: boolean;
  //   id: string;
  //   label: string;
  //   class: string;
  // }) => {
  //   setSelectedJobType([...selectedJobType, type]);
  //   setJobTypeFilter((current: any) =>
  //     current?.map((obj: { id: string }) => {
  //       if (obj.id === type.id) {
  //         return { ...obj, checked: !type.checked };
  //       }
  //       return obj;
  //     })
  //   );
  // };

  const handleCategoryFilter = (category: any, action: { action: string }) => {
    if (action.action === "select-option") {
      setSelectedCategories([
        ...selectedCategories,
        category[category?.length - 1],
      ]);
    } else if (action.action === "clear") {
      setSelectedCategories([]);
    }
  };

  const handleSkillsFilter = (skill: any, action: { action: string }) => {
    if (action.action === "select-option") {
      setSelectedSkills([...selectedSkills, skill[skill.length - 1]]);
    } else if (action.action === "clear") {
      setSelectedSkills([]);
    }
  };

  const handleCitiesFilter = (city: any, action: { action: string }) => {
    if (action.action === "select-option") {
      setSelectedCities([...selectedCities, city[city.length - 1]]);
    } else if (action.action === "clear") {
      setSelectedCities([]);
      setSelectedPostCodes([]);
    }
  };

  const handlePostcodesFilter = (postcode: any, action: { action: string }) => {
    if (action.action === "select-option") {
      setSelectedPostCodes([
        ...selectedPostcodes,
        postcode[postcode.length - 1],
      ]);
    } else if (action.action === "clear") {
      setSelectedPostCodes([]);
    }
  };

  return (
    <Suspense fallback={null}>
      <div className="dark:bg-background dark:border-border dark:border dark:text-whiteish hidden desktop:block desktop:flex-[0_0_25%] desktop:max-w-[25%] desktop:mx-0 flex-[0_0_100%] max-w-[100%] p-4  pr-8 bg-white h-[13%] py-6 border rounded-lg">
        <p className="font-bold text-xl">{t("worker.search_jobs_filter")}</p>

        {/* <div
          className="border-b border-slate-400 mt-4 dark:border-border dark:text-whiteish"
          ref={jobTypeRef}
        >
          <div
            className="flex cursor-pointer mb-4 items-center justify-between"
            onClick={() => setShowJobTypeFilter(!showJobTypeFilter)}
          >
            <p className="text-sm font-bold tracking-wide">
              {t("worker.search_jobs_filter.job_type")}
            </p>
            <ArrowDown
              aria-label="arrow-down"
              className={`${
                showJobTypeFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform`}
            />
          </div>
          {showJobTypeFilter && (
            <>
              {jobTypeFilter?.map(
                (type: {
                  id: string;
                  checked: boolean;
                  label: string;
                  class: string;
                }) => (
                  <div className=" flex flex-col gap-3 mb-4" key={type.id}>
                    <label className="flex items-center text-sm tracking-wide">
                      <input
                        type="checkbox"
                        checked={type.checked}
                        onChange={() => handleJobTypeChange(type)}
                        className="mr-3"
                      ></input>
                      {type.label}
                    </label>
                  </div>
                )
              )}
            </>
          )}
        </div> */}

        {/* <div className="border-b border-slate-400 mt-4">
        <div
          className="flex cursor-pointer mb-4 items-center justify-between"
          onClick={() => setShowProposalsFilter(!showProposalsFilter)}
        >
          <p className="text-sm font-bold tracking-wide">Number of proposals</p>
          <img
            src={`${showProposalsFilter === false ? arrowDown : arrowUp}`}
            alt="arrow"
            className="h-3 cursor-pointer"
          />
        </div>
        {showProposalsFilter && (
          <>
            {proposalsFilter?.map((proposals) => (
              <div className=" flex flex-col gap-3 mb-4" key={proposals.id}>
                <label className="flex items-center text-sm tracking-wide">
                  <input
                    type="checkbox"
                    checked={proposals.checked}
                    onChange={() => handleProposalsChange(proposals)}
                    className="mr-3"
                  ></input>
                  {proposals.label}
                </label>
              </div>
            ))}
          </>
        )}
      </div> */}
        <div
          className="border-b border-slate-400 mt-4 dark:border-border"
          ref={budgetTypeRef}
        >
          <div
            className="flex cursor-pointer mb-4 items-center justify-between"
            onClick={() => setShowBudgetFilter(!showBudgetFilter)}
          >
            <p className="text-sm font-bold tracking-wide">
              {t("worker.search_jobs_filter.salary")}
            </p>
            <ArrowDown
              className={`${
                showBudgetFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform dark:fill-whiteish`}
            />
            {/* <img
              src={arrowDown}
              alt="arrow"
              className={`${
                showBudgetFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform`}
            /> */}
          </div>
          {showBudgetFilter && (
            <div className="flex items-center gap-1 mb-3">
              <div className="relative w-full">
                <input
                  type="number"
                  className="h-[40px] border text-black dark:bg-input dark:border-border text-sm dark:text-whiteish border-blue-200  rounded w-full outline-none placeholder pl-[35px] "
                  placeholder={`${t(
                    "worker.search_jobs_filter.select_minimum_salary"
                  )}`}
                  id="inputID"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                  ref={budgetRef}
                  // onKeyDown={() => {
                  //   setSelectedBudget(savedSearchesFilters.budget);
                  // }}
                  // onKeyDown={(e) => {
                  //   console.log(e.target.value);
                  //   setSelectedBudget(budget);
                  //   setSavedSearchesFilters((prev: any) => ({
                  //     ...prev,
                  //     budget: budget,
                  //   }));
                  // }}
                />
                <BsCurrencyEuro className="h-4 absolute top-3 left-3" />
                {/* <Dollar
                  className="h-4 absolute top-3 left-4"
                  fill={"#6e7081"}
                /> */}
              </div>
              <Button
                disabled={!budget || budget === ""}
                className={cn(
                  `bg-white h-[40px] text-sm  flex items-center justify-center border transition rounded w-[45px]   hover:bg-blancoGrisClaro`,
                  budget === "" &&
                    "bg-gray-100 text-[#333] cursor-not-allowed opacity-30"
                )}
                onClick={() => {
                  setSelectedBudget(budget);
                  // setSavedSearchesFilters((prev: any) => ({
                  //   ...prev,
                  //   budget: budget,
                  // }));
                }}
              >
                <CheckIcon className="h-5 w-5 text-center" />
                {/* {t("worker.search_jobs_filter.select_minimum_salary_save")} */}
              </Button>
            </div>
          )}
        </div>

        <div
          className="border-b border-slate-400 mt-4 dark:border-border"
          ref={categoryRef}
        >
          <div
            className="flex cursor-pointer mb-4 items-center justify-between"
            onClick={() => setShowCategoryFilter(!showCategoryFilter)}
          >
            <p className="text-sm font-bold tracking-wide">
              {t("worker.search_jobs_filter.category")}
            </p>
            <ArrowDown
              className={`${
                showCategoryFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform dark:fill-whiteish`}
            />
            {/* <img
              src={arrowDown}
              alt="arrow"
              className={`${
                showCategoryFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform`}
            /> */}
          </div>

          {showCategoryFilter && (
            <>
              <div className="flex flex-wrap items-center w-full ">
                {selectedCategories?.map(
                  (cate: { value: string; name: string }) => (
                    <div
                      key={cate.value}
                      className="bg-blancoGris rounded-full px-2 mr-2 mb-1"
                    >
                      <p className="text-xs">{cate.name}</p>
                    </div>
                  )
                )}
              </div>
              <div className="relative z-50">
                <div>{/* <>{selectedCategories}</> */}</div>
                <Search
                  className="h-4 absolute top-[11px] left-3.5 z-20"
                  fill={"#6e7081"}
                />
                <Select
                  options={categoryFilter}
                  // options={newArrayForSelect}
                  styles={colourStyles}
                  placeholder={t("worker.search_jobs_filter.select_category")}
                  className="text-sm mb-3 z-10 dark:text-black"
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  controlShouldRenderValue={false}
                  isOptionSelected={(option: any) => option.checked === true}
                  onChange={(e, action) => handleCategoryFilter(e, action)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </>
          )}
        </div>

        <div className="border-b border-slate-400 mt-4" ref={skillsRef}>
          <div
            className="flex cursor-pointer mb-4 items-center justify-between"
            onClick={() => setShowSkillsFilter(!showSkillsFilter)}
          >
            <p className="text-sm font-bold tracking-wide">
              {t("worker.search_jobs_filter.skills")}
            </p>
            <ArrowDown
              className={`${
                showSkillsFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform dark:fill-whiteish`}
            />
            {/* <img
              src={arrowDown}
              alt="arrow"
              className={`${
                showSkillsFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer duration-150 ease-in-out transition-all transform`}
            /> */}
          </div>
          {showSkillsFilter && (
            <>
              <div className="flex flex-wrap items-center w-full ">
                {selectedSkills.length > 0 &&
                  selectedSkills?.map(
                    (skill: { value: string; name: string }) => (
                      <div
                        key={skill.value}
                        className="bg-blancoGris rounded-full px-2 mr-2 mb-1"
                      >
                        <p className="text-xs">{skill.name}</p>
                      </div>
                    )
                  )}
              </div>
              <div className="relative z-40">
                <Search
                  className="h-4 absolute top-[11px] left-3.5 z-10"
                  fill={"#6e7081"}
                />
                <Select
                  options={skillsFilter}
                  styles={colourStyles}
                  placeholder={t("worker.search_jobs_filter.select_skills")}
                  className="text-sm mb-3 dark:text-black"
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={true}
                  controlShouldRenderValue={false}
                  isOptionSelected={(option: any) => option.checked === true}
                  onChange={(e, action) => handleSkillsFilter(e, action)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="border-b border-slate-400 mt-4" ref={locationRef}>
          <div
            className="flex cursor-pointer mb-4 items-center justify-between"
            onClick={() => setShowLocationFilter(!showLocationFilter)}
          >
            <p className="text-sm font-bold tracking-wide">
              {t("worker.search_jobs_filter.location")}
            </p>
            <ArrowDown
              className={`${
                showLocationFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform dark:fill-whiteish`}
            />
            {/* <img
              src={arrowDown}
              alt="arrow"
              className={`${
                showLocationFilter === false ? "rotate-0" : "rotate-180"
              } h-3 cursor-pointer  duration-150 ease-in-out transition-all transform`}
            /> */}
          </div>
          {showLocationFilter && (
            <>
              <Select
                styles={colourStyles2}
                options={[
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  {
                    label: "Spain",
                    value: `${t("available_countries.spain")}`,
                  },
                ]}
                placeholder={t("worker.search_jobs_filter.select_country")}
                value={selectedCountry}
                onChange={(e) => setSelectedCountry(e)}
                className="text-sm mb-3 z-30 dark:text-black "
                isSearchable={false}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                getOptionLabel={(e: { icon: string; value: string }) => (
                  <div className="flex items-center text-sm gap-2">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.icon}
                    </div>
                    <span style={{ marginLeft: 0 }}>{e.value}</span>
                  </div>
                )}
              />
              {selectedCountry && (
                <div className="relative z-20">
                  <Search
                    className="h-4 absolute top-[11px] left-3.5 z-10"
                    fill={"#6e7081"}
                  />
                  <Select
                    options={cityFilter}
                    styles={colourStyles}
                    placeholder={t("worker.search_jobs_filter.select_cities")}
                    className="text-sm mb-3 dark:text-black"
                    isMulti
                    // closeMenuOnSelect={false}
                    // hideSelectedOptions={true}
                    // controlShouldRenderValue={false}
                    isOptionSelected={(option: { checked: boolean }) =>
                      option.checked === true
                    }
                    onChange={(e, action) => handleCitiesFilter(e, action)}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              )}
              {selectedCities?.length > 0 && (
                <div className="relative">
                  <Search
                    className="h-4 absolute top-[11px] left-3.5 z-10"
                    fill={"#6e7081"}
                  />
                  <Select
                    onInputChange={(e) => getAllPostcodes(e)}
                    options={postcodeFilter}
                    styles={colourStyles}
                    placeholder={`${t(
                      "worker.search_jobs_filter.search_postcodes"
                    )}...`}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={true}
                    controlShouldRenderValue={false}
                    isOptionSelected={(option: { checked: boolean }) =>
                      option.checked === true
                    }
                    onChange={(e, action) => handlePostcodesFilter(e, action)}
                    className="text-sm mb-3 dark:text-black dark:bg-input "
                    noOptionsMessage={() =>
                      t(
                        "worker.search_jobs_filter.search_postcodes_search_type"
                      )
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* <div className="border-b border-slate-400 mt-4">
        <div
          className="flex cursor-pointer mb-4 items-center justify-between"
          onClick={() => setShowSalaryFilter(!showSalaryFilter)}
        >
          <p className="text-sm font-bold tracking-wide">Salary</p>
          <img
            src={`${showSalaryFilter === false ? arrowDown : arrowUp}`}
            alt="arrow"
            className="h-3 cursor-pointer"
          />
        </div>
        {showSalaryFilter && (
          <>
            <Slider
              value={selectedSalary}
              onChange={handleChangePrice}
              // valueLabelDisplay="auto"
            />
          </>
        )}
      </div> */}

        {/* <div className="border-b border-slate-400 mt-4">
        <div
          className="flex cursor-pointer mb-4 items-center justify-between"
          onClick={() => setShowLengthFilter(!showLengthFilter)}
        >
          <p className="text-sm font-bold tracking-wide">Job length</p>
          <img
            src={`${showLengthFilter === false ? arrowDown : arrowUp}`}
            alt="arrow"
            className="h-3 cursor-pointer"
          />
        </div>
        {showLengthFilter && (
          <>
            {durationFilter?.map((duration) => (
              <div className=" flex flex-col gap-3 mb-4" key={duration.id}>
                <label className="flex items-center text-sm tracking-wide">
                  <input
                    type="checkbox"
                    checked={duration.checked}
                    onChange={() => handleDurationChange(duration)}
                    className="mr-3"
                  ></input>
                  {duration.label}
                </label>
              </div>
            ))}
          </>
        )}
      </div> */}

        {/* <div className="border-b border-slate-400 mt-4">
        <div
          className="flex cursor-pointer mb-4 items-center justify-between"
          onClick={() => setShowHoursFilter(!showHoursFilter)}
        >
          <p className="text-sm font-bold tracking-wide">Hours per week</p>
          <img
            src={`${showHoursFilter === false ? arrowDown : arrowUp}`}
            alt="arrow"
            className="h-3 cursor-pointer"
          />
        </div>
        {showHoursFilter && (
          <>
            {hoursPerWeekFilter?.map((hours) => (
              <div className=" flex flex-col gap-3 mb-4" key={hours.id}>
                <label className="flex items-center text-sm tracking-wide">
                  <input
                    type="checkbox"
                    checked={hours.checked}
                    onChange={() => handleHoursPerWeekChange(hours)}
                    className="mr-3"
                  ></input>
                  {hours.label}
                </label>
              </div>
            ))}
          </>
        )}
      </div> */}
      </div>
    </Suspense>
  );
}

export default Filters;
