import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "./UseLocalStorage";
import { useQuery } from "react-query";

export const useCategories = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");

  const getAllCategories = async () => {
    const data = await fetch(`${BASE_URL}job.categories?category_id`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: categories, refetch: refetchCategories } = useQuery(
    ["categories"],
    getAllCategories
  );

  return { categories, refetchCategories };
};
