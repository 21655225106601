import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import PhoneInput from "react-phone-input-2";
import Modal from "react-modal";
import "react-phone-input-2/lib/style.css";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, PROFILE_WORKER } from "../../Config/api.config";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

interface ModalEditPhoneProps {
  setShowEditPhoneModal: Dispatch<SetStateAction<boolean>>;
  showEditPhoneModal: boolean;
}

function ModalEditPhone({
  setShowEditPhoneModal,
  showEditPhoneModal,
}: ModalEditPhoneProps) {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [phone, setPhone] = useState("");
  const [prefix, setPrefix] = useState("");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [allowed, setAllowed] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);

  const updatePhone = () => {
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const handleUpdate = () => {
    data.append("prefix_phone", prefix);
    data.append("phone", phone?.slice(prefix?.length));
    updatePhone();
    setTimeout(() => {
      setShowEditPhoneModal(false);
      window.location.reload();
    }, 100);
  };

  useEffect(() => {
    setPhone(`${userInfo?.prefix_phone}${userInfo?.phone}`);
  }, []);

  const handleOnChange = (value: any, data: any) => {
    setAllowed(true);
    setPhone(`${data.dialCode}${value?.slice(data.dialCode.length)}`);
    setPrefix(data.dialCode);
  };
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditPhoneModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditPhoneModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditPhoneModal(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  // useEffect(() => {
  //   if (cancelData === true) {
  //     handleCancelation();
  //   }
  // }, [cancelData]);
  return (
    <Modal isOpen={showEditPhoneModal} style={StylesModal} ariaHideApp={false}>
      {" "}
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-[700px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityourTelephone.title.card")}
        </div>
        <div className="p-8">
          <p className="text-sm">
            {t("createprofile.submit.edityourTelephone.title")}
          </p>
          <div className="mt-4 text-sm">
            <PhoneInput
              country="au"
              value={phone}
              // className="-ml-4 pl-4 outline-none focus:outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              // className="w-max border h-10 pl-4 mt-2 outline-none focus:outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              onChange={handleOnChange}
            />
          </div>
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1 "
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourTelephone.leftBtn")}
          </Button>
          <Button
            className={`border-none w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10 ${
              allowed === false
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white "
            } `}
            disabled={allowed === false && true}
            onClick={() => handleUpdate()}
          >
            {t("createprofile.submit.edityourTelephone.rightBtn")}
          </Button>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
}

export default ModalEditPhone;
