import React, { useState } from "react";
import Header from "src/Components/Header/Header";
import FlexihoursLogoPng from "../../../assets/img/logopng.png";
import { useNavigate } from "react-router-dom";
import {
  FaDiscord,
  FaLinkedin,
  FaTiktok,
  FaUser,
  FaYoutube,
} from "react-icons/fa";
import { MdMail } from "react-icons/md";
import { FaXTwitter } from "react-icons/fa6";
import useShowComingSoon from "src/Hooks/useShowComingSoon";
import { BASE_URL } from "src/Config/api.config";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "src/Components/ui/Form";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
  const { showPreview, setShowPreview } = useShowComingSoon();
  const [submittedForm, setSubmittedForm] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginSchema = z.object({
    username: z.string().min(1, "Please tell us your name"),
    email: z.string().email(),
  });

  type LoginSchema = z.infer<typeof loginSchema>;
  const preregisterForm = useForm<LoginSchema>({
    resolver: zodResolver(loginSchema),

    defaultValues: {
      username: "",
      email: "",
    },
  });
  const preregister = async (username: string, email: string) => {
    const response = await fetch(
      `${BASE_URL}preclient.register?client_name=${username}&email=${email}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setSubmittedForm(true);
        } else if (data.status === "error") {
          toast.error(data.result.error_msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const onSubmit = async (values: LoginSchema) => {
    preregister(values.username, values.email);
  };
  return (
    <div className="h-screen w-screen overflow-x-hidden">
      <div className="fixed top-0 left-0 right-0">
        <Header />
      </div>

      <div className="h-full w-full flex flex-col items-center justify-center">
        {/* <div
          onClick={() => setShowPreview(true)}
          className="absolute top-10 cursor-pointer"
        >
          <h1>Home</h1>
        </div> */}
        <div className="max-w-4xl mx-auto flex flex-col items-center space-y-6">
          <img
            onClick={() => setShowPreview(true)}
            src={FlexihoursLogoPng}
            className="h-14  object-cover cursor-pointer"
          />
          {!submittedForm && (
            <h1 className=" m-4 text-4xl lg:text-7xl lg:h-20 font-black bg-clip-text text-transparent bg-gradient-to-r from-[#ED4685] to-[#4D9AFD] ">
              Coming Soon
            </h1>
          )}
          {submittedForm ? (
            <div className="flex flex-col space-y-5">
              <h3 className="text-center font-semibold text-xl">
                {t("coming-soon.congrats")}
              </h3>
              <div className="lg:max-w-96 mx-auto bg-gradient-to-r from-gray-100 to-gray-50 p-6 rounded-lg border -md">
                <p className="text-center font-semibold text-gray-800 mb-4">
                  {t("coming-soon.congrats-subtitle")}
                </p>
                <div className="flex items-center space-x-6 justify-center mt-4">
                  <a
                    href="https://x.com/flexihours_"
                    className="flex items-center space-x-2 text-gray-600 hover:text-blue-500 transition-transform duration-200 transform hover:scale-110"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FaXTwitter className="h-6 w-6" />
                  </a>
                  <a
                    href="https://www.tiktok.com/@flexihours"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-gray-600 hover:text-black transition-transform duration-200 transform hover:scale-110"
                  >
                    <FaTiktok className="h-6 w-6" />
                  </a>
                  <a
                    href="https://www.youtube.com/@Flexihours"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-gray-600 hover:text-red-500 transition-transform duration-200 transform hover:scale-110"
                  >
                    <FaYoutube className="h-6 w-6" />
                  </a>
                  <a
                    href="https://discord.com/invite/w6RmjpNP"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center space-x-2 text-gray-600 hover:text-purple-500 transition-transform duration-200 transform hover:scale-110"
                  >
                    <FaDiscord className="h-6 w-6" />
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <Form {...preregisterForm}>
              <form
                className="w-full flex flex-col gap-6"
                onSubmit={preregisterForm.handleSubmit(onSubmit)}
              >
                <FormField
                  name="username"
                  control={preregisterForm.control}
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center space-x-2 bg-[#f8f8f8]  p-2 rounded-lg w-full px-5 mt-4">
                        <FaUser className="h-4 w-4 text-gray-400 mr-2" />
                        <FormControl>
                          <input
                            {...field}
                            type="text"
                            placeholder={`${t("coming-soon.input-username")}`}
                            className="text-[#777]  placeholder:text-gray-500 bg-transparent h-10 w-72 outline-none flex-grow"
                          />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="email"
                  control={preregisterForm.control}
                  render={({ field }) => (
                    <FormItem>
                      <div className="flex items-center space-x-2 bg-[#f8f8f8]  p-2 rounded-lg w-full px-5">
                        <MdMail className="h-4 w-4 text-gray-400 mr-2" />
                        <FormControl>
                          <input
                            {...field}
                            type="email"
                            placeholder={`${t("coming-soon.input-email")}`}
                            className="text-[#777] placeholder:text-gray-500 bg-transparent h-10 w-72 outline-none flex-grow"
                          />
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <button
                  type="submit"
                  className="bg-[#333] text-white rounded text-sm border h-10 px-2 font-semibold active:scale-95 w-full "
                >
                  {t("coming-soon.submit-button")}
                </button>
              </form>
            </Form>
          )}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-center justify-between max-w-7xl w-full fixed lg:absolute bottom-10 lg:left-10 lg:right-10 mx-auto">
        <div onClick={() => navigate("/legal")} className="cursor-pointer">
          <h3 className="text-[#555]">Privacy Policy</h3>
        </div>
        <div className="flex items-center space-x-2 mt-2 lg:mt-0">
          <span className="text-[#555]">
            <a
              href="https://x.com/flexihours_"
              className="flex items-center space-x-2"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaXTwitter className="h-5 w-5" />
              <span className="hidden lg:block">Twitter /</span>
            </a>{" "}
          </span>
          <span className="text-[#555]">
            <a
              href="https://www.tiktok.com/@flexihours"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2"
            >
              {" "}
              <FaTiktok className="h-5 w-5 " />
              <span className="hidden lg:block">Titkok /</span>
            </a>
          </span>
          <span className="text-[#555]">
            <a
              href="https://www.youtube.com/@Flexihours"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2"
            >
              {" "}
              <FaYoutube className="h-5 w-5" />
              <span className="hidden lg:block">Youtube /</span>
            </a>
          </span>
          <span className="text-[#555]">
            <a
              href="https://www.linkedin.com/company/flexihours/?viewAsMember=true"
              className="flex items-center space-x-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <FaLinkedin className="h-5 w-5" />
              <span className="hidden lg:block">Linkedin</span>
            </a>{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
