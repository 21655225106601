import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ImLocation } from "react-icons/im";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useModalStore } from "src/Hooks/useModalStore";

import { BsFire, BsLightning } from "react-icons/bs";
import { FindWorker } from "src/types/types";
import { BASE_URL, BASE_URL_IMG } from "src/Config/api.config";
import { selectedWorkerId, workerProfile } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { userProfileStore } from "src/Routing/useProfileStore";

import { cn } from "src/lib/utils";
import { useTranslation } from "react-i18next";
import RatingStars from "../Rating/RatingStars";
import { useNavigate } from "react-router-dom";

interface WorkerCardProps {
  worker: FindWorker;
  refetch: () => void;
  showModalDiscover: { discover: boolean };
  setShowModalDiscover: Dispatch<SetStateAction<{ discover: boolean }>>;
  discoverValue: string;
  setClickedWorker: any;
}

const WorkerCard = ({
  worker,
  refetch,
  setShowModalDiscover,
  showModalDiscover,
  discoverValue,
  setClickedWorker,
}: WorkerCardProps) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const { profileTypeToken } = userProfileStore((s) => s);
  const [animationData, setAnimationData] = useState<any>();
  useEffect(() => {
    import("src/Utils/lotties/Fire.json").then(setAnimationData);
  }, []);
  const [activeStar, setActiveStar] = useState();
  console.log(profileTypeToken);

  const [showModalInvite, setShowModalInvite] = useModalStore((state) => [
    state.showModalInvite,
    state.setShowModalInvite,
  ]);
  const [workerId, setWorkerId] = useAtom(selectedWorkerId);
  const { t } = useTranslation();
  const [workerProf, setWorkerProf] = useAtom(workerProfile);
  const navigate = useNavigate();
  const workerData = new FormData();
  workerData.append("_method", "POST");
  workerData.append("client_id_employer", userID);
  workerData.append("client_id_worker", worker.data.client_id_worker);

  const saveWorker = async () => {
    const data = await fetch(`${BASE_URL}discover.workers`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: workerData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  const unsaveWorker = async (workerId: string) => {
    const data = await fetch(
      `${BASE_URL}discover.workers?client_id_employer=${userID}&client_id_worker=${workerId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 1) {
          refetch();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      onClick={() => {
        // setShowModalInvite(showModalInvite);
        if (worker.data.blurred === "false") {
          navigate(`/find-worker/${worker.data.client_id_worker}`);
        }
        setWorkerProf(worker);
        setWorkerId(worker.data.client_id_worker);
      }}
      key={worker.data.client_id_worker}
      className="w-full relative dark:bg-background dark:text-whiteish dark:border-border  border overflow-hidden border-b border-border bg-white flex  space-x-4 px-4 hover:border transition cursor-pointer justify-between items-start py-6 rounded-lg mb-4"
    >
      <div className="flex items-start space-x-4">
        {worker?.data.profile_image !== null ? (
          <img
            loading="lazy"
            src={`${BASE_URL_IMG}${worker?.data.profile_image}`}
            alt="profile image"
            className={cn(
              "h-24 w-24 object-cover rounded-full  transition-all duration-300 ease-in-out ml-4 mt-4",
              worker?.data.blurred === "true"
                ? "blur-lg"
                : "hover:scale-105 hover:border",
              "opacity-0 animate-fadeIn"
            )}
          />
        ) : (
          <img
            src={require("../../assets/img/user-worker.png")}
            className="bg-[#e2e2e2] h-24 w-24 object-cover rounded-full  transition-all duration-300 ease-in-out ml-4 mt-4 blur-sm"
          />
        )}

        <div className="px-8 py-2 flex flex-col items-start ">
          <div className="space-y-4 flex-grow">
            <h1 className="text-xl font-semibold capitalize text-gray-800 dark:text-whiteish">
              {worker.data.title}
            </h1>

            <div className="flex items-center gap-2 text-gray-600 dark:text-gray-400">
              <ImLocation className="h-5 w-5 text-gray-500" />
              <p>{worker.data.city_name ?? "Worldwide"}</p>
            </div>

            <div className="flex items-center gap-3">
              {worker.data.profile_badge === "true" && (
                <div className="flex items-center gap-2 px-4 py-1 bg-blue-50 border border-blue-400 rounded-full">
                  <BsLightning className="text-blue-400" />
                  <p className="text-sm font-medium text-blue-600">
                    Available Now
                  </p>
                </div>
              )}
              {worker.data.profile_boost === "true" && (
                <div className="flex items-center gap-2 px-4 py-1 bg-orange-50 border border-orange-400 rounded-full">
                  <BsFire className="text-orange-400" />
                  <p className="text-sm font-medium text-orange-600">Boosted</p>
                </div>
              )}
            </div>
          </div>
          <div className="text-sm text-[#777777] mb-4 mt-6">
            {worker?.data.overview ? (
              <div className="pr-12 text-ellipsis">
                <p className="font-semibold text-black dark:text-whiteish">
                  {t("employer.invite_workers.description")} -
                </p>

                <p>
                  {worker?.data.overview.slice(0, 170)}
                  {worker.data.overview.length > 170 && "..."}
                </p>
              </div>
            ) : (
              <p className="text-ellipsis">No description</p>
            )}
          </div>
          <div className="mb-4">
            <RatingStars
              score={worker?.data.score_avg}
              setActiveStar={setActiveStar}
              activeStar={worker?.data.score_avg}
              showHalf={true}
              showModal={false}
              evaluation={false}
            />
          </div>
          <div className=" pb-4 flex text-xs mt-2 gap-2 cursor-pointer w-fit">
            {worker.ProfileTime.length > 0 &&
              worker.ProfileTime?.map((day, i) => (
                <div
                  key={day.worker_time_profile_id}
                  className="flex flex-col text-center  bg-blue-200 rounded-lg w-[45px] "
                >
                  <p className="border-b border-blue-200 px-2 font-bold border-b-white text-white drop-border py-2">
                    {day.dayname}
                  </p>
                  <p className="px-2 text-slate-500 font-bold py-2">
                    {day.hours}h
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-5">
        <h1 className="text-lg  ">
          <span className="font-semibold text-xl">{worker.data.budget}</span>€/h
        </h1>
        {worker.data.blurred === "true" && (
          <div className="pr-8">
            <button
              onClick={(e) => {
                e.stopPropagation();
                setClickedWorker(worker.data);
                setShowModalDiscover((prev: any) => ({
                  ...prev,
                  discover: true,
                }));
              }}
              className="px-6 h-fit text-sm font-semibold whitespace-nowrap py-1 bg-azulBonico text-white border border-azulBonico hover:bg-azulOscuro rounded-full transition duration-300 flex items-center gap-2"
            >
              <span>Discover</span>
            </button>
          </div>
        )}
      </div>
    </div>
    // </div>
  );
};

export default WorkerCard;
