import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GoBackButton from "src/Components/ui/GoBack";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import {
  useEmployerStore,
  userEmployerTypeStore,
} from "src/Routing/useProfileStore";
import { Wallet } from "src/types/types";
import FilterCredits from "./FilterCredits";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import { ReactComponent as Banner } from "src/assets/img/allJobsEmployerBanner.svg";
import ModalRechargeCredits from "src/Components/Modals/ModalRechargeCredits";
import { useQuery } from "react-query";
import CreditsHistoryCard from "src/Components/Credits/CreditsHistoryCard";
import CreditSkeleton from "src/Components/ui/skeleton/CreditSkeleton";
import PaginationCredits from "./PaginationCredits";
import { filterActionType } from "src/Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import { TiCancel } from "react-icons/ti";
import FlexiCoin from "src/assets/img/flexicoin.png";
import { useClientCredits } from "src/Hooks/useClientCredits";

const Credits = () => {
  const { t } = useTranslation();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [walletInfo, setWalletInfo] = useState<Wallet[]>([]);
  const navigate = useNavigate();
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);
  const [checkedPack, setCheckedPack] = useState({ price: "", quantity: "" });
  const [showCreditsPack, setShowCreditsPack] = useState(false);
  const [finalData, setFinalData] = useState<Wallet[]>([]);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    limit: 10,
    currentPage: 1,
    pageNumbers: [],
  });
  const [clientSecret, setClientSecret] = useState("");
  const [showModalRechargeCredits, setShowModalRechargeCredits] =
    useState(false);

  const [headerVisible, setHeaderVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedActionType, setSelectedActionType] = useAtom(filterActionType);
  const { clientCreditsData, refetchClientCredits } = useClientCredits();
  const employerType = userEmployerTypeStore((s) => s.employerType);

  useEffect(() => {
    if (pagination.totalPages) {
      setPagination((prev: any) => ({
        ...prev,

        pageNumbers: [...Array(pagination.totalPages + 1).keys()]?.slice(1),
      }));
    }
  }, [pagination.totalPages]);

  const getWalletInfo = async () => {
    const data = await fetch(
      `${BASE_URL}clients.wallet?client_id=${userID}&mode=detail&limit=${
        pagination.limit
      }&page=${pagination.currentPage}${
        selectedActionType.value !== ""
          ? `&action_id=${selectedActionType?.value}`
          : ""
      }`,

      {
        headers: {
          Authorization: "Bearer" + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data;
  };

  const { data: walletInfoData, isLoading: isLoadingWalletInfo } = useQuery(
    ["wallet_info", pagination.currentPage, selectedActionType?.value],
    getWalletInfo
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      totalPages: walletInfoData?.paginate.pages,
    }));
    setWalletInfo(walletInfoData?.result);
    setFinalData(walletInfoData?.result);
  }, [walletInfoData]);

  useEffect(() => {
    setCreditsNumber(clientCreditsData);
  }, [clientCreditsData]);

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("employer.credits.banner.manage_movements")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline my-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      <div className="bg-white dark:bg-background dark:text-whiteish ">
        <div className="flex flex-col lg:flex-row items-center justify-between pt-4 lg:p-8">
          <div className="flex items-end space-x-5">
            <p className="lg:text-xl font-semibold   ">
              {t("employer.wallet_info.title")}
            </p>
            <p className="text-sm">
              <span className="font-medium">{creditsNumber}</span>{" "}
              {t("employer.applicants.view_jobpost.modal.clone.credits")}
            </p>
          </div>
        </div>
      </div>
      {!showCreditsPack && (
        <div className="flex items-center justify-between bg-white">
          <FilterCredits walletInfo={walletInfo} setFinalData={setFinalData} />
          {!showCreditsPack && (
            <PaginationCredits
              refetch={getWalletInfo}
              pagination={pagination}
              setPagination={setPagination}
            />
          )}
        </div>
      )}

      {!showCreditsPack && (
        <>
          <div className="flex items-center justify-between   bg-white dark:bg-background dark:border-border py-2   border-t border-b px-8">
            <div className="w-1/3">
              <p className="text-left font-semibold">
                {t("credits.header_title.date")}
              </p>
            </div>

            <div className="w-1/3 ">
              <p className="text-left font-semibold">
                {t("credits.header_title.type")}
              </p>
            </div>
            <div className="w-1/3 flex items-end justify-end">
              <img src={FlexiCoin} className="h-5 w-5 object-contain" />
              <p className="text-right ml-2 font-semibold">Flexicoins</p>
            </div>
          </div>
          {finalData?.length > 0 &&
            finalData.map((wallet) => (
              <CreditsHistoryCard key={wallet.id} wallet={wallet} />
            ))}
          {finalData?.length === 0 && (
            <div className="flex flex-col items-center justify-center p-8 text-center bg-white">
              <TiCancel className="h-20 w-20 text-gray-400" />

              <p className="text-sm text-gray-400">Nothing found...</p>
            </div>
          )}
        </>
      )}

      {isLoadingWalletInfo && (
        <>
          <CreditSkeleton />
          <CreditSkeleton />
        </>
      )}

      {clientSecret && checkedPack?.price !== "" && (
        <ModalRechargeCredits
          price={checkedPack?.price}
          numberOfCredits={checkedPack?.quantity}
          clientSecret={clientSecret}
          setShowRechargeCredits={setShowModalRechargeCredits}
          showRechargeCredits={showModalRechargeCredits}
        />
      )}
    </>
  );
};

export default Credits;
