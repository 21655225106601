import React, { useState } from "react";
import { Button } from "../ui/Button";
import { CgClose } from "react-icons/cg";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown } from "react-icons/md";
import cookie from "../../assets/img/cookie-3180329_1280.jpg";

const Cookies = () => {
  const [cookiesStatus, setCookiesStatus] = useLocalStorage("cookies", false);
  const [closeCookies, setCloseCookies] = useState(false);
  const [showModal, setShowModal] = useState(false); // Estado para manejar el modal
  const [necessaryCookies, setNecessaryCookies] = useState(true);
  const [performanceCookies, setPerformanceCookies] = useState(false);

  const { t, i18n } = useTranslation();

  const handleAccept = () => {
    setCookiesStatus(true);
    setCloseCookies(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleCustomize = () => {
    setCloseCookies(true); // Ocultar el contenedor principal
    setShowModal(true); // Mostrar el modal
  };

  if (!cookiesStatus && (!closeCookies || showModal))
    return (
      <>
        {/* Contenedor principal */}
        {!closeCookies && (
          <div className="fixed left-1/2 -translate-x-[50%] sm:bottom-[230px] bottom-3 gap-2 lg:bottom-14 z-[300] w-[90%] lg:w-[60%] 2xl:w-[36%] shadow-xl bg-white border rounded-2xl p-6">
            <div className="flex 2xl:flex-row flex-col items-center 2xl:justify-evenly 2xl:gap-56 gap-10">
              <h1 className="font-normal text-lg lg:text-xl">
                {t("cookies.title")}.{" "}
                <span>
                  {i18n.language === "en" ? (
                    <>
                      Consult our{" "}
                      <span className="font-medium underline">
                        Privacy policy
                      </span>{" "}
                      to obtain detailed information
                    </>
                  ) : (
                    <>
                      Consulte nuestra{" "}
                      <span className="font-medium">
                        Política de privacidad
                      </span>{" "}
                      para obtener información detallada.
                    </>
                  )}
                </span>
              </h1>
              <div className=" flex flex-col justify-center sm:block">
                <Button
                  onClick={handleAccept}
                  className="px-4 py-1 bg-rositaOscuro text-md rounded-md text-white mb-2 w-44 mr-2"
                >
                  {i18n.language === "en" ? <>Accept</> : <>Aceptar</>}
                </Button>
                <Button
                  onClick={handleAccept}
                  className="px-4 py-1 bg-black text-md rounded-md text-white mb-2 w-44 mr-2"
                >
                  {i18n.language === "en" ? <>Decline</> : <>Rechazar</>}
                </Button>
                <Button
                  onClick={handleCustomize}
                  className="px-4 py-1 bg-black text-md rounded-md text-white w-44"
                >
                  {i18n.language === "en" ? <>Customize</> : <>Personalizar</>}
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Modal */}
        {showModal && (
          <div className="absolute inset-0 z-[400] flex items-center justify-center">
            <div className="bg-white rounded-xl p-6 w-[90%] sm:w-[30%] relative">
              <button
                onClick={closeModal}
                className="absolute top-3 right-3 text-gray-500 hover:text-black"
              >
                <CgClose size={24} />
              </button>
              <div className=" flex items-center gap-2">
                <img src={cookie} className=" w-16 -mt-3" alt="" />
                <h2 className="text-xl font-semibold mb-4">
                  Preferencias de cookies
                </h2>
              </div>
              <p className="text-gray-700 mb-6">
                Esta web utiliza cookies para garantizar las funcionalidades
                básicas y para mejorar su experiencia online. Puedes elegir
                activarlas o desactivarlas cuando quieras para cualquier
                categoría. Para obtener más información en relación con las
                cookies y otros datos confidenciales, lee la política de
                privacidad. Para cualquier consulta en relación con nuestra
                política sobre cookies y sus elecciones, lea nuestra política de
                cookies.
              </p>
              <div className="mb-4">
                <details className="border rounded-lg mb-2">
                  <summary className="px-4 py-2 text-lg font-medium flex items-center justify-between cursor-pointer">
                    <div className=" justify-center flex items-center gap-2">
                      <MdKeyboardArrowDown size={20} />
                      Cookies necesarias
                    </div>
                    <input
                      type="checkbox"
                      checked={necessaryCookies}
                      onChange={(e) => setNecessaryCookies(e.target.checked)}
                      className="ml-2"
                    />
                  </summary>
                  <p className="px-4 py-2 text-gray-600">
                    Estas cookies son esenciales para el funcionamiento adecuado
                    del sitio web. Sin estas cookies, el sitio web no
                    funcionaría correctamente.
                  </p>
                </details>
                <details className="border rounded-lg">
                  <summary className="px-4 py-2 text-lg font-medium flex items-center justify-between cursor-pointer">
                    <div className=" justify-center flex items-center gap-2">
                      <MdKeyboardArrowDown size={20} />
                      Cookies de rendimiento y análisis
                    </div>

                    <input
                      type="checkbox"
                      checked={performanceCookies}
                      onChange={(e) => setPerformanceCookies(e.target.checked)}
                      className="ml-2"
                    />
                  </summary>
                  <p className="px-4 py-2 text-gray-600">
                    Estas cookies permiten recordar las opciones que has
                    aceptado anteriormente.
                  </p>
                </details>
              </div>
              <div className="flex justify-end">
                <Button
                  onClick={closeModal}
                  className="px-4 py-2 bg-rositaOscuro text-white rounded-md"
                >
                  Aceptar
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  else {
    return null;
  }
};

export default Cookies;
