import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import ApplicantDetails from "../ViewJobPost/ApplicantDetails";

import { ReactComponent as LeftArrow } from "../../assets/img/arrow-left.svg";
import { BASE_URL } from "../../Config/api.config";
import { ApplicantTypes, ReviewTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import HandleDayEnding from "src/Utils/HandleDayEnding";
// import * as jsPDF from "jspdf";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import ReviewClientCard from "../HiredWorkers/ReviewClientCard";

export type ModalProps = {
  decline: boolean;
  invite: boolean;
  chat: boolean;
  end: boolean;
  rating: boolean;
  report: boolean;
  video: boolean;
  worker: boolean;
  discover: boolean;
};

interface ModalWorkerCardProps {
  showModal: ModalProps;
  setShowModal: Dispatch<SetStateAction<ModalProps>>;
  worker: ApplicantTypes;
  setWorker: any;
  handleShortlist: (worker: any) => void;
  handleArchived: (worker: any) => void;
  setDeclinedApplicant: any;
  checkIfThereIsConversation: (worker: any) => void;
  workerInfo?: any;
}

function ModalWorkerCard({
  showModal,
  setShowModal,
  worker,
  setWorker,
  handleShortlist,
  handleArchived,
  setDeclinedApplicant,
  checkIfThereIsConversation,
  workerInfo,
}: ModalWorkerCardProps) {
  const { t } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [transition, setTransition] = useState(false);
  const [reviews, setReviews] = useState<ReviewTypes[]>([]);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const ref = useRef<HTMLDivElement>(null);
  const showReviewsFirst = "worker";
  const { i18n } = useTranslation();
  const imageRef = useRef(null);
  const getReviews = () => {
    fetch(
      `${BASE_URL}connections.reviews?client_id=${
        worker.client_id ? worker.client_id : worker.client_id_worker
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => setReviews(data.result.reviews))
      .catch((err) => console.log(err));
  };

  const getIsFlagged = () => {
    fetch(
      `${BASE_URL}inapropiate.worker.profile?client_id_worker=${worker.client_id_worker}&client_id_employer=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) =>
        setWorker((prev: any) => ({ ...prev, flagged: data.result }))
      )
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIsFlagged();
    getReviews();
  }, []);

  const categorySkills: any[] = [];
  for (
    let i = 0;
    i <
    (workerInfo?.categories.length > worker.skills?.split(",").length
      ? workerInfo?.categories.length
      : worker?.skills?.split(",").length);
    i++
  ) {
    // for (let j = 0; j < worker.skill_list?.split(",").length; j++) {
    categorySkills.push({
      category: workerInfo?.categories[i]?.name ?? "",
      skill: worker?.skills?.split(",")[i],
    });
    // }
  }

  // const handleGeneratePdf = () => {
  //   const doc = new jsPDF({
  //     format: "a4",
  //     unit: "px",
  //   });
  //   // const doc = new jsPDF("p", "pt");
  //   doc.setFont("helvetica");
  //   // const col = ["Title", "Employer"];
  //   const col = ["Title", "Employer"];
  //   const col1 = ["Employer", "Title", "Worker Review", "Employer Review"];
  //   doc.text(`${worker.first_name} ${worker.last_name}`, 28, 10);
  //   doc.text("Details", 28, 30);
  //   doc.text(`${worker.title}`, 28, 50);
  //   doc.text(`${worker.cover_letter}`, 28, 70);

  //   // const col = ["Employer", "Worker", "Employer Review", "Worker Review"];
  //   const rows: any = [];
  //   const rows1: any = [];
  //   const rows2: any = [];
  //   const rows3: any = [];
  //   const rows4: any = [];
  //   const col3 = ["Category", "Skills"];
  //   const col4 = ["Languages", "Level"];
  //   // const col3 = ["Category",];
  //   // const col4 = ["Skills"];

  //   // workerInfo.categories.forEach((el) => {
  //   //   const temp = [el.name];
  //   //   rows2.push(temp);
  //   // });
  //   // worker.skills?.split(",").forEach((el) => {
  //   //   const temp = [el];
  //   //   rows3.push(temp);
  //   // });
  //   categorySkills.forEach((element) => {
  //     const temp = [element.category, element.skill];
  //     rows3.push(temp);
  //   });
  //   // doc.autoTable(col3, rows3, { startY: 90 });
  //   // workerInfo.languages?.map((ele: any) => {
  //   //   const temp = [ele.language_name, ele.languale_level_name];
  //   //   rows4.push(temp);
  //   // });
  //   // doc.autoTable(col4, rows4, { startY: 400 });
  //   doc.text(`Rating ${workerInfo.score.avgScore} de 5`, 28, 500);
  //   doc.text(
  //     `Éxito en el trabajo ${workerInfo.score.totalConnections}`,
  //     28,
  //     520
  //   );
  //   doc.text(
  //     `Trabajos terminados ${workerInfo.score?.totalConnections}`,
  //     28,
  //     540
  //   );
  //   doc.text(
  //     `Trabajos dejados ${workerInfo.score?.totalConnectionsTerminated}`,
  //     28,
  //     560
  //   );

  //   // console.log(workerInfo.categories);
  //   // const col3 = ["Category", "Skills", "Hours"];
  //   // rows2.push(worker.category_name.split(","));
  //   // doc.autoTable(col3, rows2, { startY: 90 });
  //   // doc.autoTable(col4, rows3, { startY: 200 });
  //   // doc.html(imageRef.current, {
  //   //   async callback(doc) {
  //   //     await doc.save("image-test.pdf");
  //   //   },
  //   // });
  //   doc.addPage("a4", "p");
  //   doc.text("Reviews", 28, 40);
  //   reviews.forEach((element) => {
  //     const temp = [
  //       element.job_title,
  //       `${element.employer_first_name}  ${element.employer_last_name}`,
  //     ];
  //     const temp1 = [
  //       element.employer_first_name,
  //       element.job_title,
  //       element.worker_review ? element.worker_review : "No Review",
  //       element.employer_review ? element.employer_review : "No Review",
  //     ];

  //     rows.push(temp);
  //     rows1.push(temp1);
  //   });
  //   // doc.autoTable(col, rows, { startY: 60 });
  //   // doc.autoTable(col1, rows1, { startY: 200 });
  //   doc.save("sample-file.pdf");
  // };

  // const handleGeneratePdf = () => {
  //   const doc = new jsPDF({
  //     format: "a4",
  //     unit: "px",
  //   });

  //   // Adding the fonts
  //   doc.setFont("Inter-Regular", "normal");

  //   doc.html(imageRef.current, {
  //     async callback(doc) {
  //       await doc.save("document");
  //     },
  //   });
  // };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal.decline) {
        if (showModal.worker && !showModal.decline) closeModal();
      } else if (showModal.invite) {
        if (showModal.worker && !showModal.invite) closeModal();
      } else if (showModal.chat) {
        if (showModal.worker && !showModal.chat) closeModal();
      } else if (showModal.end) {
        if (showModal.worker && !showModal.end) closeModal();
      } else if (showModal.rating) {
        if (showModal.worker && !showModal.rating) closeModal();
      } else if (showModal.report) {
        if (showModal.worker && !showModal.report) closeModal();
      } else if (showModal.discover) {
        if (showModal.worker && !showModal.discover) closeModal();
      } else if (showModal.video) {
        if (showModal.worker && !showModal.video) closeModal();
      } else {
        if (
          showModal.worker &&
          ref.current &&
          !ref.current.contains(e.target)
        ) {
          closeModal();
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [
    showModal.worker,
    showModal.decline,
    showModal.invite,
    showModal.chat,
    showModal.end,
    showModal.rating,
    showModal.video,
    showModal.report,
    showModal.discover,
  ]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev) => ({ ...prev, worker: false }));
      document.body.classList.add("ReactModal__Body--before-close");
    }, 500);
  };

  useEffect(() => {
    // setTimeout(() => {
    setTransition(true);
    document.body.classList.remove("ReactModal__Body--before-close");

    // }, 100);
  }, []);

  const stylesModal: any = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.35)",
      zIndex: 200,
    },
    content: {
      position: "absolute",
      top: "-20px",
      left: "40px",
      right: "40px",
      bottom: "0px",

      overflow: "auto",
      border: "none",
      background: "#fff",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal
      closeTimeoutMS={200}
      onRequestClose={() => {
        // closeModal();
        document.body.classList.add("ReactModal__Body--before-close");
      }}
      // id="applicants"
      isOpen={showModal.worker}
      style={stylesModal}
      ariaHideApp={false}
    >
      <div
        className={`fixed ${
          transition === false ? "scale-x-0 translate-x-full" : "scale-x-100"
        }  transition duration-500 h-full right-0 w-[90%] lg:w-[1000px] rounded overflow-y-auto overflow-x-hidden jobpost bg-blancoGris border-blue-100 border`}
        ref={ref}
      >
        <div className="text-sm font-medium border-b border-blue-100 bg-white px-8 py-6 flex justify-between">
          <LeftArrow
            className="h-5 cursor-pointer"
            fill={"#2abeeb"}
            onClick={() => {
              closeModal();
            }}
          />
        </div>
        <ApplicantDetails
          worker={worker}
          setWorker={setWorker}
          setShowModal={setShowModal}
          workerInfo={workerInfo}
          handleShortlist={handleShortlist}
          handleArchived={handleArchived}
          closeModal={closeModal}
          setDeclinedApplicant={setDeclinedApplicant}
          checkIfThereIsConversation={checkIfThereIsConversation}
          // downloadPdf={handleGeneratePdf}
        />

        {reviews?.length > 0 && (
          <div className="px-6 pt-3">
            <div className="text-sm drop-border bg-white flex flex-col rounded">
              <div className="border-b border-blue-100">
                <p className="font-bold text-xl tracking-wide my-6 mx-6">
                  {t("worker.profile.employment_history")}
                </p>
              </div>
              <div>
                {reviews
                  ?.slice(0)
                  .reverse()
                  ?.slice(0, 10)
                  .sort((a: any, b: any) => {
                    if (
                      a.date_early_end !== null &&
                      b.date_early_end !== null
                    ) {
                      return b.date_early_end.localeCompare(a.date_early_end);
                    } else if (a.date_end !== null && b.date_end !== null) {
                      return b.date_end.localeCompare(a.date_end);
                    }
                  })
                  ?.map((review) => {
                    if (review.date_start < review.server_date) {
                      const endingEnd = HandleDayEnding(
                        review.date_end?.slice(9)
                      );
                      const endingStart = HandleDayEnding(
                        review.date_start?.slice(9)
                      );
                      let endingEarlyEnd;
                      if (review.date_early_end) {
                        endingEarlyEnd = HandleDayEnding(
                          review.date_early_end?.slice(9)
                        );
                      }
                      return (
                        <ReviewClientCard
                          key={review.title}
                          review={review}
                          showModal={showModal}
                          showReviewsFirst={showReviewsFirst}
                        />
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        )}
        <div className="mb-8"></div>
      </div>
    </Modal>
  );
}

export default ModalWorkerCard;
