import React from "react";
import { useNavigate } from "react-router-dom";
import GoBackButton from "src/Components/ui/GoBack";

import "./Calendar.css";
import useCalendar from "src/Hooks/useCalendar";

const Calendar = () => {
  const {
    availabilityData,
    converRangesIntoHourly,
    convertObjectIntoArrayOfObjects,
    itHasHourlyRanges,
    removeDuplicates,
    updateFormatting,
  } = useCalendar();
  const navigate = useNavigate();

  return (
    <>
      <div
        className="flex items-center gap-2 mb-4 fill-azulBonico cursor-pointer mt-8 text-azulBonico hover:underline"
        onClick={() => navigate("/dashboard")}
      >
        <GoBackButton />
      </div>
      <div className="block bg-transparent mt-10 ">
        <div className="flex items-center space-x-2">
          {Object.entries(availabilityData).map((date: any) => {
            const rangeAvailableHours = Object.entries(date[1]).filter(
              (obj: any) => obj[1].availability === true
            );

            const convertedObject = convertObjectIntoArrayOfObjects(date[1]);
            const expandedRange = converRangesIntoHourly(convertedObject);
            const updatedObjects = updateFormatting(expandedRange);
            const [objects, convertedObjects] = updatedObjects;
            const formattedObjects = [...objects, ...convertedObjects];
            const nonDuplicated = itHasHourlyRanges(formattedObjects);
            const availableDataSortedByAvailability =
              removeDuplicates(nonDuplicated);

            return (
              <div key={date[0]}>
                <div className="bg-gray-200 rounded text-sm font-medium">
                  <p className="text-center">{date[0]}</p>
                </div>

                <div className="h-40">
                  {rangeAvailableHours.map((range: any) => (
                    <div
                      key={range[1].jobId}
                      className="mb-2 border rounded p-2"
                    >
                      <p>
                        Job{" "}
                        <span className="font-medium">{range[1].jobId}</span>
                      </p>
                      <p className="text-sm">{range[0]}</p>
                    </div>
                  ))}
                </div>

                {availableDataSortedByAvailability.map(
                  (hourRange: {
                    availability: boolean;
                    hourFrom: string;
                    hourTo: string;
                  }) => {
                    if (hourRange.availability === false)
                      return (
                        <div
                          key={hourRange.hourFrom}
                          className="h-14 w-40 bg-gray-200 border border-white p-2"
                        >
                          <p className="text-sm text-gray-400">
                            {hourRange.hourFrom} - {hourRange.hourTo}
                          </p>
                        </div>
                      );
                    if (hourRange.availability === true) {
                      return (
                        <div
                          key={hourRange.hourFrom}
                          className="h-14 w-40 bg-green-300 border border-white text-white p-2"
                        >
                          <p className="text-green-700 text-sm">
                            {hourRange.hourFrom} - {hourRange.hourTo}
                          </p>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Calendar;
