import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import { BASE_URL } from "../../../Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import Conversation from "../../../Components/Messages/Conversation";
import DotsLoader from "../../../Components/Loader/DotsLoader";
import { compareDesc } from "date-fns";
import {
  isWorkerOnline,
  messages4JobId,
  onlineStatusMode,
  process1WithParamNew,
} from "../../../Atoms/jotaiAtoms";

import { ReactComponent as Search } from "../../../assets/img/search.svg";
import { ReactComponent as Circle } from "../../../assets/img/circle.svg";
import { ReactComponent as NoChats } from "../../../assets/img/no-chats.svg";
import { useTranslation } from "react-i18next";
import { ActiveChats } from "../../../types/types";
import GoBackButton from "src/Components/ui/GoBack";
import { BiConversation } from "react-icons/bi";
import { useQuery } from "react-query";
import ActiveChatCard from "src/Pages/Worker/Chat/ActiveChatCard";
import { AdjustmentsVerticalIcon } from "@heroicons/react/24/solid";

const INITIAL_CHAT = {
  msg_date: "",
  client_id: "",
  job_id: "",
  img_profile: "",
  client_name: "",
  msg_time: "",
  title: "",
  new_messages: "",
  seen: "",
  job_title: "",
  sender: "",
  msg: "",
  status: "",
  reported: "",
  deleted: "",
  client_id_send: "",
  chat_message_id: "",
};

const MessagesEmployer = () => {
  const [selectedWorkerChat, setSelectedWorkerChat] =
    useState<ActiveChats>(INITIAL_CHAT);
  const date = new Date();
  const today = `${date.getDate()}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  const [selectedChat, setSelectedChat] = useState();
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [activeChats, setActiveChats] = useState<ActiveChats[]>([]);
  const [refreshConversation, setRefreshConversation] = useState(false);

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [filteredList, setFilteredList] = useState<any>([]);
  const [showDotsLoader, setShowDotsLoader] = useState(false);
  const [showLoaderActiveChats, setShowLoaderActiveChats] = useState(true);
  const [visibilityStatus, setVisibilityStatus] = useAtom(onlineStatusMode);
  const [loggedStatus, setLoggedStatus] = useAtom(isWorkerOnline);

  const [messagesJobId, setMessagesJobId] = useAtom(messages4JobId);
  const [useParamsForStartConversation, setUseParamsForStartConversation] =
    useAtom(process1WithParamNew);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [headerVisible, setHeaderVisible] = useState(true);

  //CAMBIAR STATUS A ONLINE CUANDO ABRE LA PESTAÑA MESSAGES
  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("status", "LOGIN");
  const setStatus = () => {
    fetch(`${BASE_URL}chat`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.response === "LOGIN SUCCESS") {
          setLoggedStatus(false);
        }
      })
      .then((err) => console.log(err));
  };
  useEffect(() => {
    // setSelectedTab("messages");
    if (loggedStatus === false) {
      setStatus();
    }
  }, [loggedStatus]);

  const INTERVAL_CONVERSATIONS = 10000;

  const checkNewMessages = async () => {
    const data = await fetch(
      `${BASE_URL}chat?client_id=${userID}${
        messagesJobId !== "" ? `&job_id=${messagesJobId}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());
    return data.result;
  };

  const { data: newMessagesData, isLoading } = useQuery(
    ["newMessages", messagesJobId, userID],
    checkNewMessages
  );

  function compareDates(
    a: { msg_date: string; msg_time: string },
    b: { msg_date: string; msg_time: string }
  ) {
    const dateA = new Date(`${a.msg_date}T${a.msg_time}`);
    const dateB = new Date(`${b.msg_date}T${b.msg_time}`);

    return compareDesc(dateA, dateB);
  }

  useEffect(() => {
    setActiveChats(newMessagesData?.active_chat.flat().sort(compareDates));
    setFilteredList(newMessagesData?.active_chat.flat().sort(compareDates));
    setShowLoaderActiveChats(false);
    if (newMessagesData?.status === "ONLINE") {
      setVisibilityStatus({
        value: "Online",
        label: (
          <div className="flex items-center gap-4">
            <Circle className="h-3  fill-green-500" />
            Online
          </div>
        ),
      });
    } else if (newMessagesData?.status === "INVISIBLE") {
      setVisibilityStatus({
        value: "Invisible",
        label: (
          <div className="flex items-center gap-4">
            <Circle className="h-3 fill-slate-400" />
            Invisible
          </div>
        ),
      });
    }
  }, [newMessagesData]);

  const checkNewMessagesNewConversation = async () => {
    const data = await fetch(
      `${BASE_URL}chat?client_id=${userID}&job_id=${messagesJobId}${
        useParamsForStartConversation !== ""
          ? `&client_id_chat_new=${useParamsForStartConversation}`
          : ""
      } 
      `,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));
    return data.result;
  };

  const { data: newConversationChat } = useQuery({
    queryKey: ["new_conversation", messagesJobId],
    queryFn: checkNewMessagesNewConversation,
    enabled: messagesJobId !== "",
  });

  useEffect(() => {
    if (newConversationChat?.active_chat?.length > 0) {
      setActiveChats(
        newConversationChat?.active_chat.flat().sort(compareDates)
      );
      setFilteredList(
        newConversationChat?.active_chat.flat().sort(compareDates)
      );
      setShowLoaderActiveChats(false);
      if (newConversationChat?.status === "ONLINE") {
        setVisibilityStatus({
          value: "Online",
          label: (
            <div className="flex items-center gap-4">
              <Circle className="h-3  fill-green-500" />
              {t("employer.chat.online")}
            </div>
          ),
        });
      } else if (newConversationChat?.status === "INVISIBLE") {
        setVisibilityStatus({
          value: "Invisible",
          label: (
            <div className="flex items-center gap-4">
              <Circle className="h-3 fill-slate-400" />
              {t("employer.chat.invisible")}
            </div>
          ),
        });
      }
    }
  }, [newConversationChat]);

  useEffect(() => {
    if (selectedWorkerChat?.client_id === "" && activeChats?.length > 0) {
      setSelectedWorkerChat(activeChats[0]);
    }
  }, [selectedWorkerChat, activeChats]);

  //PROCESO QUE CHECKEA SI HAY CONVERSACIONES NUEVAS
  useEffect(() => {
    const interval = setInterval(() => {
      if (useParamsForStartConversation) {
        checkNewMessagesNewConversation();
      } else {
        checkNewMessages();
      }
    }, INTERVAL_CONVERSATIONS);
    return () => clearInterval(interval);
  }, [useParamsForStartConversation]);

  // useEffect(() => {
  //   checkNewMessages();
  //   checkNewMessagesNewConversation();
  // }, []);

  //PROCESO QUE CHECKEA SI HAY NUEVOS MENSAJES EN LA CONVERSACION ABIERTA
  useEffect(() => {
    if (selectedWorkerChat) {
      // setShowLoader(true);
      const interval = setInterval(() => {
        refetch();
      }, INTERVAL_CONVERSATIONS);
      return () => clearInterval(interval);
    }
  }, [selectedWorkerChat]);

  const getMessagesFromJob = async () => {
    const data = await fetch(
      `${BASE_URL}chat?client_id=${userID}&client_id_chat=${selectedWorkerChat?.client_id}&job_id=${selectedWorkerChat?.job_id}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());

    return data.result.chat;
  };

  const {
    data: messagesData,
    refetch,
    isLoading: isMessagesLoading,
  } = useQuery({
    queryKey: ["new_messages", selectedWorkerChat?.client_id],
    queryFn: getMessagesFromJob,
    enabled: selectedWorkerChat?.client_id !== "",
  });

  useEffect(() => {
    if (refreshConversation) {
      if (selectedWorkerChat?.client_id !== "") {
        refetch();
      }
      setRefreshConversation(false);
      setShowDotsLoader(false);
    }
  }, [refreshConversation, selectedWorkerChat]);

  //Make a search in the existing chats
  const filterBySearch = (e: any) => {
    const query = e.target.value;
    let updatedList: any = [...filteredList];
    updatedList = updatedList.filter((item: { client_name: string }) => {
      return item.client_name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setActiveChats(updatedList.sort(compareDates));
    // setActiveChats(updatedList);
  };

  // useEffect(() => {
  //   // setSelected Chat to the first one
  //   if (activeChats?.length > 0) {
  //     setSelectedWorkerChat(activeChats[0]);
  //   }
  // }, []);

  return (
    <>
      <div className="py-4 px-6 fill-azulBonico text-azulBonico mt-24 ">
        <div
          className="w-fit flex items-center cursor-pointer gap-2"
          onClick={() => navigate(-1)}
        >
          <GoBackButton />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row bg-white   border lg:h-[calc(100vh-290px)] dark:bg-background dark:border-border dark:border rounded">
        <div className="lg:flex-[0_0_27%] lg:max-w-[27%] flex-col  border-r border-azulBonico ">
          <div className="flex justify-between items-center h-[60px] border-b border-border bg-white dark:bg-background  border ">
            <div className="flex items-center bg-gray-100 ml-6 py-2 px-2 rounded dark:bg-input flex-grow dark:border dark:border-border">
              <Search className="h-4 dark:fill-whiteish" fill={"#6e7081"} />
              <input
                type="text"
                placeholder={`${t(
                  "worker.messages.leftbar.search.placeholder"
                )}`}
                className="outline-none w-max h-max text-sm pl-3 bg-transparent dark:text-whiteish "
                onChange={(e) => filterBySearch(e)}
              />
            </div>
            <AdjustmentsVerticalIcon className="h-4 w-4 mx-4" />
          </div>
          <div className="flex flex-col dark:hover overflow-y-scroll overflow-x-hidden jobpost bg-white dark:bg-background dark:border-border dark:border dark:text-whiteish border ">
            {showLoaderActiveChats === true && (
              <div className="flex justify-center mt-24">
                <DotsLoader height="50" width="80" radius="8" color="#2abeeb" />
              </div>
            )}
            {activeChats?.length > 0 &&
              activeChats?.map((chat) => {
                const msgDate = `${chat?.msg_date?.slice(
                  8
                )}-${chat?.msg_date?.slice(5, 7)}-${chat?.msg_date?.slice(
                  0,
                  4
                )}`;
                return (
                  <ActiveChatCard
                    chat={chat}
                    msgDate={msgDate}
                    refetch={refetch}
                    selectedWorkerChat={selectedWorkerChat}
                    setSelectedWorkerChat={setSelectedWorkerChat}
                    setShowSearchBar={setShowSearchBar}
                    today={today}
                    key={chat.chat_message_id}
                  />
                );
              })}
          </div>
          {activeChats?.length === 0 && (
            <div className="mt-4 flex flex-col items-center">
              <h1 className="text-xl text-center text-gray-300">
                {t("worker.messages.chats_notfound")}
              </h1>
              <BiConversation className="h-14 w-14 mt-2 text-gray-300" />
            </div>
          )}
        </div>
        {/* )} */}

        <div
          className={`
            ${
              activeChats?.length === 0 && !selectedChat
                ? "flex-[0_0_100%] max-w-[100%]"
                : "lg:flex-[0_0_73%] lg:max-w-[73%] flex-[0_0_100%] max-w-[100%]"
            }
            flex flex-col justify-between 
            `}
        >
          {activeChats?.length > 0 && (
            <Conversation
              data={selectedChat}
              setData={setSelectedChat}
              messages={messagesData}
              selectedWorkerChat={selectedWorkerChat}
              refreshConversation={refreshConversation}
              setRefreshConversation={setRefreshConversation}
              showSearchBar={showSearchBar}
              setShowSearchBar={setShowSearchBar}
              showDotsLoader={showDotsLoader}
              setShowDotsLoader={setShowDotsLoader}
              setUseParamsForStartConversation={
                setUseParamsForStartConversation
              }
            />
          )}
          {!isMessagesLoading && activeChats?.length === 0 && (
            <div className="h-full flex flex-col items-center justify-center my-10 mr-52 b-white space-y-10 dark:bg-background dark:text-whiteish dark:border-border dark:border">
              <NoChats className="h-60" />
              <div className="text-xl ">{t("worker.message.empty")}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MessagesEmployer;
