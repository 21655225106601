import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { cn } from "src/lib/utils";
import CreditsPackCard from "../Credits/CreditsPackCard";
import ModalRechargeCredits from "../Modals/ModalRechargeCredits";
import LoadingSpinner from "src/Pages/General/Register/LoadingSpinner";

const infoRecharge = [
  {
    quantity: "102",
    price: "89",
    popular: true,
  },
  {
    quantity: "90",
    price: "69",
  },
  {
    quantity: "12",
    price: "29",
  },
  {
    quantity: "6",
    price: "19",
  },
];

interface RechargePlansProps {
  refetchCredits: () => void;
}

const RechargePlans = ({ refetchCredits }: RechargePlansProps) => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [showRechargeActions, setShowRechargeActions] = useState(false);
  const [checkedPack, setCheckedPack] = useState({ price: "", quantity: "" });
  const [loading, setLoading] = useState(false);

  const [clientSecret, setClientSecret] = useState("");
  const [showModalRechargeCredits, setShowModalRechargeCredits] =
    useState(false);

  const createPaymentData = new FormData();

  createPaymentData.append("amount", `${checkedPack.price}00`);
  const createPayment = async () => {
    setLoading(true);
    const response = await fetch(`${BASE_URL}create.php`, {
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      method: "POST",
    })
      .then((res) => res.json())
      .then((data) => {
        setShowModalRechargeCredits(true);
        setClientSecret(data.clientSecret);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });

    return response;
  };

  const { t } = useTranslation();
  const creditsData = new FormData();
  creditsData.append("_method", "POST");
  creditsData.append("client_id", userID);

  const rechargeCredits = async (price: string) => {
    creditsData.append("credits", `${price}`);

    const data = await fetch(`${BASE_URL}clients.wallet`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: creditsData,
    })
      .then((res) => res.json())
      .then((data) => {
        toast.success(`Purchase ${price} Credits Successful`);
        if (data.result === 1) {
          refetchCredits();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mb-10 rounded px-8">
      <div className="rounded   bg-white dark:bg-background py-2 px-2 dark:border-border dark:border flex justify-between items-center cursor-pointer">
        <div className=" text-lg font-medium">
          <h1>{t("subscription.settings.recharge")}</h1>
        </div>
      </div>
      <div
        className={cn(
          `grid grid-cols-1 laptop:grid-cols-4 gap-10 transition mt-4 `
        )}
      >
        {infoRecharge?.map((recharge) => (
          <CreditsPackCard
            setCheckedPack={setCheckedPack}
            checkedPack={checkedPack}
            key={recharge.price}
            recharge={recharge}
          />
        ))}
      </div>

      {clientSecret && checkedPack.price !== "" && (
        <ModalRechargeCredits
          price={checkedPack?.price}
          numberOfCredits={checkedPack?.quantity}
          clientSecret={clientSecret}
          setShowRechargeCredits={setShowModalRechargeCredits}
          showRechargeCredits={showModalRechargeCredits}
        />
      )}
      <div className="ml-auto w-[25%] mt-10">
        <button
          onClick={() => createPayment()}
          className={cn(
            "transition text-sm font-semibold py-1  text-white w-40 flex items-center justify-center rounded-full  ",
            checkedPack.price !== ""
              ? "bg-green-500 hover:bg-green-600"
              : "bg-gray-400 cursor-not-allowed"
          )}
        >
          {loading ? (
            <div className="py-[2px]">
              <LoadingSpinner />
            </div>
          ) : (
            <p>{t("subscription.settings.buy")}</p>
          )}
        </button>
      </div>
    </div>
  );
};

export default RechargePlans;
