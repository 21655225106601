import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { BASE_URL_IMG } from "src/Config/api.config";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";

import { HireTypes } from "src/types/types";

interface DiplayHiresJobSharedProps {
  hire: HireTypes;
  showHires: any;
  setShowHires: Dispatch<SetStateAction<any>>;
  getWorkerInfo: (args: string) => void;
  setClickedWorker: Dispatch<SetStateAction<HireTypes>>;
  endingStart: string;
  endingEnd: string;
  endingEarlyEnd: string;
  setShowModal: any;
}

const DiplayHiresJobShared = ({
  hire,
  showHires,
  getWorkerInfo,
  setClickedWorker,
  endingEarlyEnd,
  endingEnd,
  endingStart,

  setShowModal,
}: DiplayHiresJobSharedProps) => {
  const { i18n, t } = useTranslation();
  return (
    <div
      key={hire.connection_id}
      className={`py-3 border-b border-border ${
        showHires
          ?.map((hire: { state: boolean; job_id: string }) => {
            if (hire.state === true) return hire.job_id;
          })
          .includes(hire.job_id)
          ? "visible"
          : "hidden"
      }`}
    >
      <div className="flex flex-col lg:flex-row items-center justify-between  w-full">
        <div className="flex gap-4 ml-8">
          <img
            src={`${BASE_URL_IMG}${hire?.profile_image}`}
            alt="img"
            className="h-10 w-10 lg:h-14 lg:w-14 object-cover rounded-full"
          />
          <div className="flex flex-col">
            <p className="font-semibold ">
              {hire.first_name} {hire.last_name}. &nbsp;
              {/* <span
                className="text-xs font-semibold text-azulBonico cursor-pointer hover:text-azulOscuro hover:underline"
                onClick={() => {
                  setClickedWorker(hire);
                  setClickedWorker((prev: any) => ({
                    ...prev,
                    client_id: hire.client_id_worker,
                  }));
                  getWorkerInfo(hire.client_id_worker);
                }}
              >
                {t("employer.hired_workers.display_hires.view_profile")}
              </span> */}
            </p>

            {!hire.date_early_end && hire.date_start > hire.server_date && (
              // <p className="text-sm text-slate-400">
              <p className="text-sm text-[#333] mt-2">
                {/* {t("worker.diplay_jobs.employer.started_working")}{" "} */}
                {t(
                  "employer.hired_workers.display_hires.start_working_on"
                )}{" "}
                {i18n.language === "en" ? (
                  <>
                    {HandleMonthName(hire.date_start?.slice(5, 7))}{" "}
                    {hire.date_start?.slice(8)}
                    <span className="text-xs">{endingStart}</span>{" "}
                  </>
                ) : (
                  <>
                    {hire.date_start?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_start?.slice(5, 7))}
                  </>
                )}
                {t("worker.diplay_jobs.employer.until")}{" "}
                {i18n.language === "en" ? (
                  <>
                    {HandleMonthName(hire.date_end?.slice(5, 7))}{" "}
                    {hire.date_end?.slice(8)}
                    <span className="text-xs">{endingEnd}</span>
                  </>
                ) : (
                  <>
                    el {hire.date_end?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_end?.slice(5, 7))}
                  </>
                )}
              </p>
            )}
            {!hire.date_early_end &&
              hire.date_start <= hire.server_date &&
              hire.server_date <= hire.date_end && (
                <p className="text-sm text-[#333] mt-2">
                  {/* // <p className="text-sm text-slate-400"> */}
                  {i18n.language === "en" ? (
                    <>
                      {t(
                        "employer.hired_workers.display_hires.start_working_on"
                      )}{" "}
                      {/* {t("worker.diplay_jobs.employer.started_working")}{" "} */}
                      {HandleMonthName(hire.date_start?.slice(5, 7))}{" "}
                      {hire.date_start?.slice(8)}
                      <span className="text-xs">{endingStart}</span>{" "}
                      {t("worker.diplay_jobs.employer.until")}{" "}
                      {HandleMonthName(hire.date_end?.slice(5, 7))}{" "}
                      {hire.date_end?.slice(8)}
                      <span className="text-xs">{endingEnd}</span>
                    </>
                  ) : (
                    <>
                      {t(
                        "employer.hired_workers.display_hires.start_working_on"
                      )}{" "}
                      {/* {t("worker.diplay_jobs.employer.started_working")}{" "} */}
                      {hire.date_start?.slice(8)} de{" "}
                      {HandleMonthNameES(hire.date_start?.slice(5, 7))}{" "}
                      {t("worker.diplay_jobs.employer.until")} el{" "}
                      {hire.date_end?.slice(8)} de{" "}
                      {HandleMonthNameES(hire.date_end?.slice(5, 7))}
                    </>
                  )}
                </p>
              )}
            {!hire.date_early_end && hire.server_date > hire.date_end && (
              // <p className="text-sm text-slate-400">
              <p className="text-sm text-[#333] mt-2">
                {i18n.language === "en" ? (
                  <>
                    {/* {t("worker.diplay_jobs.employer.worked_from")}{" "} */}
                    {t(
                      "employer.hired_workers.display_hires.start_working_on"
                    )}{" "}
                    {HandleMonthName(hire.date_start?.slice(5, 7))}{" "}
                    {hire.date_start?.slice(8)}
                    <span className="text-xs">{endingStart}</span>{" "}
                    {t("worker.diplay_jobs.employer.until")}{" "}
                    {HandleMonthName(hire.date_end?.slice(5, 7))}{" "}
                    {hire.date_end?.slice(8)}
                    <span className="text-xs">{endingEnd}</span>
                  </>
                ) : (
                  <>
                    {/* {t("worker.diplay_jobs.employer.worked_from")} el{" "} */}
                    {t("employer.hired_workers.display_hires.start_working_on")}{" "}
                    el {hire.date_start?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_start?.slice(5, 7))}{" "}
                    {t("worker.diplay_jobs.employer.until")} el{" "}
                    {hire.date_end?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_end?.slice(5, 7))}
                  </>
                )}
              </p>
            )}
            {hire.date_early_end < hire.server_date && (
              <p className="text-sm text-[#333] mt-2">
                {/* // <p className="text-sm text-slate-400"> */}
                {i18n.language === "en" ? (
                  <>
                    {/* {t("worker.diplay_jobs.employer.worked_from")}{" "} */}
                    {t(
                      "employer.hired_workers.display_hires.start_working_on"
                    )}{" "}
                    {HandleMonthName(hire.date_start?.slice(5, 7))}{" "}
                    {hire.date_start?.slice(8)}
                    <span className="text-xs">{endingStart}</span>{" "}
                    {t("worker.diplay_jobs.employer.until")}{" "}
                    {HandleMonthName(hire.date_early_end?.slice(5, 7))}{" "}
                    {hire.date_early_end?.slice(8)}
                    <span className="text-xs">{endingEarlyEnd}</span>
                  </>
                ) : (
                  <>
                    {/* {t("worker.diplay_jobs.employer.worked_from")} el{" "} */}
                    {t("employer.hired_workers.display_hires.start_working_on")}{" "}
                    el {hire.date_start?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_start?.slice(5, 7))}{" "}
                    {t("worker.diplay_jobs.employer.until")} el{" "}
                    {hire.date_early_end?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_early_end?.slice(5, 7))}
                  </>
                )}
              </p>
            )}
            {hire.date_early_end > hire.server_date && (
              <p className="text-sm text-[#333] mt-2">
                {i18n.language === "en" ? (
                  <>
                    {/* {t("worker.diplay_jobs.employer.worked_from")}{" "} */}
                    {t(
                      "employer.hired_workers.display_hires.start_working_on"
                    )}{" "}
                    {HandleMonthName(hire.date_start?.slice(5, 7))}{" "}
                    {hire.date_start?.slice(8)}
                    <span className="text-xs">{endingStart}</span>{" "}
                    {t("worker.diplay_jobs.employer.until")}{" "}
                    {HandleMonthName(hire.date_early_end?.slice(5, 7))}{" "}
                    {hire.date_early_end?.slice(8)}
                    <span className="text-xs">{endingEnd}</span>
                  </>
                ) : (
                  <>
                    {/* {t("worker.diplay_jobs.employer.worked_from")} */}
                    {t("employer.hired_workers.display_hires.start_working_on")}
                    el {hire.date_start?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_start?.slice(5, 7))}{" "}
                    {t("worker.diplay_jobs.employer.until")} el{" "}
                    {hire.date_early_end?.slice(8)} de{" "}
                    {HandleMonthNameES(hire.date_early_end?.slice(5, 7))}
                  </>
                )}
              </p>
            )}
            {hire.date_early_end && (
              <p className="text-sm  text-slate-400">
                {/* // <p className="text-sm text-slate-400"> */}
                {t("employer.hired_workers.display_hires.job_finished")}{" "}
                <span className="font-semibold">{hire.early_end_for}</span>{" "}
                {hire?.end_work_reason_name && (
                  <>
                    <span>
                      {t("employer.hired_workers.display_hires.because_of")}
                    </span>{" "}
                    <span className="font-semibold">
                      {hire.end_work_reason_name}
                    </span>
                  </>
                )}
              </p>
            )}
          </div>
        </div>
        {!hire.date_early_end && hire.date_start > hire.server_date && (
          <div className="text-sm mr-8 text-right">
            <p className="font-semibold text-slate-500">
              {t("worker.diplay_jobs.employer.no_working_yet")}
            </p>
          </div>
        )}
        {!hire.date_early_end &&
          hire.date_start <= hire.server_date &&
          hire.server_date <= hire.date_end && (
            <div className="text-sm flex flex-col gap-2 w-fit h-fit items-end mr-8">
              {/* <p className="text-green-500 font-semibold">
                {t("worker.diplay_jobs.employer.currently_working")}.
              </p> */}

              <button
                // className="button-rate !w-full"
                className="bg-azulBonico text-white hover:bg-azulOscuro px-4 py-1 rounded-full transition"
                onClick={() => {
                  setClickedWorker(hire);
                  setShowModal((prev: any) => ({
                    ...prev,
                    end: true,
                  }));
                }}
              >
                {t("worker.diplay_jobs.employer.end_contract")}
              </button>
            </div>
          )}
        {hire.server_date < hire.date_early_end && (
          <div className="text-sm flex gap-1 w-fit h-fit items-end mr-8">
            <p className="text-green-500 font-semibold">
              {t("worker.diplay_jobs.employer.currently_working")}{" "}
            </p>
          </div>
        )}
        {(hire.server_date > hire.date_end ||
          hire.server_date >= hire.date_early_end) && (
          <div className="text-sm flex flex-col gap-2 mr-8 items-end text-right">
            {/* <p className="font-semibold text-slate-500">
              {t("worker.diplay_jobs.employer.finished_working")}
            </p> */}
            {(hire.score_from_employer === "0.00" ||
              hire.score_from_employer === null) && (
              <button
                className="bg-azulBonico text-white hover:bg-azulOscuro px-4 py-1 rounded-full transition"
                // className="button-rate"
                onClick={() => {
                  setClickedWorker(hire);
                  setShowModal((prev: any) => ({
                    ...prev,
                    rating: true,
                  }));
                }}
              >
                {t("worker.diplay_jobs.employer.give_feedback")}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DiplayHiresJobShared;
