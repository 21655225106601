import React, { Dispatch, SetStateAction, useEffect } from "react";
import RechargePlans from "./RechargePlans";
import { useEmployerStore } from "src/Routing/useProfileStore";

import CreditsPack from "./CreditsPack";
import { useTranslation } from "react-i18next";
import { useClientCredits } from "src/Hooks/useClientCredits";

interface SubscriptionsProps {
  setDecorationBar: Dispatch<SetStateAction<string>>;
  decorationBar: string;
}

type CreditOption = {
  title: string;
  credits: string;
};

const creditOptions: CreditOption[] = [
  {
    title: "Publish or clone for job post",
    credits: "90",
  },
  {
    title: "Invite worker",
    credits: "2",
  },
  { title: "Chat candidate", credits: "2" },
];

export type CreditsPack = {
  desc: string;
  price: string;
  gift: string;
};

const creditsPack: CreditsPack[] = [
  {
    desc: "408",
    price: "270",
    gift: "102",
  },
  {
    desc: "612",
    price: "450",
    gift: "204",
  },
  {
    desc: "1020",
    price: "630",
    gift: "306",
  },
];

const Subscriptions = (props: SubscriptionsProps) => {
  const { t } = useTranslation();
  const [creditsNumber, setCreditsNumber] = useEmployerStore((state) => [
    state.creditsNumber,
    state.setCreditsNumber,
  ]);

  const { clientCreditsData, refetchClientCredits } = useClientCredits();
  useEffect(() => {
    setCreditsNumber(clientCreditsData);
  }, [clientCreditsData]);

  return (
    <div className="px-4 py-2">
      <div className="bg-white rounded-lg border pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-8">
            <p className="text-xl font-semibold mb-4">
              {t("employer.leftbar.menu.item6.title")}
            </p>
          </div>

          <div className="">
            <RechargePlans refetchCredits={refetchClientCredits} />
          </div>
          {/* <div className="mb-10 rounded px-8">
            <div className="grid grid-cols-1 laptop:grid-cols-4 gap-14 transition mt-4">
              {creditsPack?.map((credit) => (
                <CreditsPackInfo
                  key={credit.price}
                  credit={credit}
                  refetchCredits={getClientCredits}
                />
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
