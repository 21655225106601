import React from "react";

import WorkerCards from "./WorkerCards";

import { ReactComponent as Preference } from "../../assets/img/preferences.svg";
import { useTranslation } from "react-i18next";
import { ApplicantTypes } from "../../types/types";

interface ShorlistesApplicantsProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: any;
  setRefreshData: any;
  maxPages: any;
  currentPage: any;
  setCurrentPage: any;
  refetch: () => void;
}

const ShortlistedApplicants = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  maxPages,
  currentPage,
  setCurrentPage,
  refetch,
}: ShorlistesApplicantsProps) => {
  const { t } = useTranslation();

  return (
    <div>
      {applicantsData?.length > 0 ? (
        <>
          <WorkerCards
            refetch={refetch}
            applicantsData={applicantsData}
            setApplicantsData={setApplicantsData}
            setRefreshData={setRefreshData}
          />
          <div
            className={`flex justify-center border-t border-blue-100 py-4 ${
              maxPages === currentPage && "hidden"
            } `}
          >
            <button
              className="px-8 py-2 text-azulBonico hover:bg-[#f7f4f4] border border-blue-100  bg-white rounded-full transition duration-150"
              onClick={() => setCurrentPage(currentPage + 1)}
            >
              {t("shortlisted_applicants.load_more")}
            </button>
          </div>
        </>
      ) : (
        <div className="flex flex-col w-full items-center justify-center my-12 dark:text-whiteish">
          <Preference className="h-32 mb-6" />
          <p className="text-lg font-bold tracking-wide">
            {t("shortlisted_applicants.no_applicants.title")}
          </p>
          <p className="text-sm mt-4">
            {t("shortlisted_applicants.no_applicants.desc")}
          </p>
        </div>
      )}
    </div>
  );
};

export default ShortlistedApplicants;
