import React, { useEffect, useState } from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { VerticalLayout } from "../Layout/VerticalLayout";
import { userEmployerTypeStore } from "src/Routing/useProfileStore";
import { SubscriptionPackage } from "../../../types/types";
import { useClientData } from "src/Hooks/useClientData";
import { AiOutlineLoading } from "react-icons/ai";

import {
  BASE_URL,
  SUBSCRIPTION_CHECKOUT,
  SUBSCRIPTIONS,
} from "src/Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";

const Subscriptions = () => {
  const { employerData, refetchEmployer } = useClientData();

  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [subscriptions, setSubscriptions] =
    useState<SubscriptionPackage | null>(null);
  const { t } = useTranslation();
  const [employerType, setEmployerType] = userEmployerTypeStore((s) => [
    s.employerType,
    s.setEmployer,
  ]);
  const [userInfo, setUserInfo] = useState<any>();
  const getUserInfo = () => {
    fetch(`${BASE_URL}workers?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setUserInfo(data.result.data[0]))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (employerType?.type === "worker") {
      getUserInfo();
    } else {
      setUserInfo(employerData);
    }
  }, [employerType]);

  // useEffect(() => {
  //   setUserInfo(employerData);
  // }, [employerData]);

  const getSubscriptions = () => {
    fetch(`${BASE_URL}${SUBSCRIPTIONS}?id=1`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setSubscriptions(data.result);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getSubscriptions();
    setIsLoading(false);
  }, []);

  const handleGetStarted = async () => {
    setIsLoading(true);
    const packageObject = subscriptions && subscriptions;

    if (!packageObject) {
      console.error("No subscription package found.");
      return;
    }

    const packageId = packageObject.id; // Get the package ID

    try {
      const response = await fetch(`${BASE_URL}${SUBSCRIPTION_CHECKOUT}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
        body: JSON.stringify({ package_id: packageId, token: loggedInUserUid }), // Send only the package ID
      });

      if (!response.ok) {
        throw new Error("Failed to start subscription checkout.");
      }

      const data = await response.json();

      // Check if checkout_url is returned in the response
      if (data.checkout_url) {
        // Redirect the user to the Stripe checkout page
        window.location.href = data.checkout_url;
      } else {
        console.error("No checkout URL found.");
      }
    } catch (err) {
      console.error("Error during subscription checkout:", err);
    }
  };

  console.log("employerType", employerType);
  const bulletPointsWorker = {
    pricePremium: "5",
    free: [
      { label: "Precio por destacarte en ofertas de empleo", value: "2€" },
      { label: "Skills máximas en tu perfil (sin pagar)", value: "5" },
      { label: "Permite conocer empleadores ocultos", value: "No" },
      { label: "Permite poner más categorías", value: "No" },
      { label: "Permite tener más de un perfil", value: "No" },
      {
        label: "Permite conocer si has pasado o no de fase en una oferta",
        value: "No",
      },
    ],
    premium: [
      {
        label: "Precio por destacarte en ofertas de empleo",
        value: "Siempre destacado",
      },
      { label: "Skills máximas en tu perfil (sin pagar)", value: "10" },
      { label: "Permite conocer empleadores ocultos", value: "Sí" },
      { label: "Permite poner más categorías", value: "Sí" },
      { label: "Permite tener más de un perfil", value: "Sí" },
      {
        label: "Permite conocer si has pasado o no de fase en una oferta",
        value: "Sí",
      },
    ],
  };

  const bulletPoints = {
    pricePremiumParticular: "10",
    pricePremiumCompany: "50",
    freeParticular: [
      { label: "Precio de las ofertas publicadas", value: "10€" },
      { label: "Otras features de pago", value: "Si" },
      { label: "Número de ofertas simultáneas", value: "Ilimitadas" },
      { label: "Tiempo de publicación de la oferta", value: "1 mes" },
      { label: "Sistema de rangos", value: "No se ve" },
      {
        label: "Número de personas a las que puedes contratar con una oferta",
        value: "1",
      },
      {
        label: "Acceso a videos de los candidatos",
        value: "No",
      },
      {
        label:
          "Cúando puedes cancelar una contratación ya realizada sin ser sancionado",
        value: "48 horas antes del servicio",
      },
      {
        label: "Número de dispositivos simultáneos",
        value: "1",
      },
      {
        label: "Acceso número de atención especial",
        value: "No",
      },
      {
        label: "Permite destacar las valoraciones que crea conveniente",
        value: "No",
      },
      {
        label: "Precio por destacar anuncions",
        value: "10€",
      },
    ],
    premiumParticular: [
      { label: "Precio de las ofertas publicadas", value: "0€" },
      { label: "Otras features de pago", value: "No, todo gratis" },
      {
        label: "Número de ofertas simultáneas",
        value: "Ilimitadas, hasta la 3ª",
      },
      { label: "Tiempo de publicación de la oferta", value: "2 meses" },
      { label: "Sistema de rangos", value: "Visible" },
      {
        label: "Número de personas a las que puedes contratar con una oferta",
        value: "3",
      },
      {
        label: "Acceso a videos de los candidatos",
        value: "Sí",
      },
      {
        label:
          "Cúando puedes cancelar una contratación ya realizada sin ser sancionado",
        value: "24 horas antes del servicio",
      },
      {
        label: "Número de dispositivos simultáneos",
        value: "2",
      },
      {
        label: "Acceso número de atención especial",
        value: "Sí",
      },
      {
        label: "Permite destacar las valoraciones que crea conveniente",
        value: "Sí",
      },
      {
        label: "Precio por destacar anuncions",
        value: "5€",
      },
    ],
    freeCompany: [
      { label: "Precio de las ofertas publicadas", value: "35€" },
      { label: "Otras features de pago", value: "Si" },
      { label: "Número de ofertas simultáneas", value: "Ilimitadas" },
      { label: "Tiempo de publicación de la oferta", value: "1 mes" },
      { label: "Sistema de rangos", value: "No se ve" },
      {
        label: "Número de personas a las que puedes contratar con una oferta",
        value: "3",
      },
      {
        label: "Acceso a videos de los candidatos",
        value: "No",
      },
      {
        label:
          "Cúando puedes cancelar una contratación ya realizada sin ser sancionado",
        value: "48 horas antes del servicio",
      },
      {
        label: "Número de dispositivos simultáneos",
        value: "1",
      },
      {
        label: "Acceso número de atención especial",
        value: "No",
      },
      {
        label: "Permite destacar las valoraciones que crea conveniente",
        value: "No",
      },
      {
        label: "Precio por destacar anuncions",
        value: "10€",
      },
    ],
    premiumCompany: [
      { label: "Precio de las ofertas publicadas", value: "0€" },
      { label: "Otras features de pago", value: "No, todo gratis" },
      {
        label: "Número de ofertas simultáneas",
        value: "Ilimitadas, hasta la 3ª",
      },
      { label: "Tiempo de publicación de la oferta", value: "2 meses" },
      { label: "Sistema de rangos", value: "Visible" },
      {
        label: "Número de personas a las que puedes contratar con una oferta",
        value: "10",
      },
      {
        label: "Acceso a videos de los candidatos",
        value: "Sí",
      },
      {
        label:
          "Cúando puedes cancelar una contratación ya realizada sin ser sancionado",
        value: "24 horas antes del servicio",
      },
      {
        label: "Número de dispositivos simultáneos",
        value: "5",
      },
      {
        label: "Acceso número de atención especial",
        value: "Sí",
      },
      {
        label: "Permite destacar las valoraciones que crea conveniente",
        value: "Sí",
      },
      {
        label: "Precio por destacar anuncions",
        value: "5€",
      },
    ],
  };
  return (
    <VerticalLayout>
      <h1 className="text-center text-xl lg:text-3xl font-bold mt-10 mb-5 text-[#333] dark:text-whiteish ">
        {t("worker.subscriptions.pricing.title")}
      </h1>
      <p className="text-center text-base lg:text-lg text-[#333] dark:text-whiteish">
        {t("worker.subscriptions.pricing.subtitle")}
      </p>
      <div className="flex lg:flex-row flex-col items-center justify-center gap-20 mt-10">
        <div className="border-2  rounded-3xl p-5 w-[500px] h-full my-4">
          <div className="flex items-center gap-4">
            <div>
              <p className="text-xl dark:text-whiteish">
                {t(
                  "worker.subscriptions.pricing.body.leftcard.freeplan.maintitle"
                )}
              </p>
              <p className="text-4xl font-bold dark:text-whiteish">
                $0
                <span className="font-normal text-lg">
                  {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.mainsubtitle"
                  )}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-2">
            <h3 className="font-semibold text-xl text-[#333] dark:text-whiteish">
              {t("worker.subscriptions.pricing.body.leftcard.freeplan.title1")}
            </h3>
            <div className="bg-gray-400 bg-gradient-to-r from-gray-400 to-gray-200 relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
              <h1 className="font-semibold text-center text-white uppercase text-[16px]">
                {t(
                  "worker.subscriptions.pricing.body.leftcard.freeplan.title2.subtitle2.currentplanBtn"
                )}
              </h1>
            </div>
            <ul className=" mt-3 space-y-2">
              {employerType?.type === "worker" &&
                bulletPointsWorker.free.map((point) => (
                  <li
                    key={point.label}
                    className="flex items-center justify-between gap-4 border-b pb-2"
                  >
                    <div className="flex items-center space-x-2 w-[70%]">
                      <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                        {point.label}
                      </p>
                    </div>
                    <p className="text-right">{point.value}</p>
                  </li>
                ))}
              {employerType?.type !== "worker" &&
                employerType?.isCompany &&
                bulletPoints.freeCompany.map((point) => (
                  <li
                    key={point.label}
                    className="flex items-center justify-between gap-4 border-b pb-2"
                  >
                    <div className="flex items-center space-x-2 w-[70%]">
                      <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                        {point.label}
                      </p>
                    </div>
                    <p className="text-right">{point.value}</p>
                  </li>
                ))}
              {employerType?.type !== "worker" &&
                !employerType?.isCompany &&
                bulletPoints.freeParticular.map((point) => (
                  <li
                    key={point.label}
                    className="flex items-center justify-between gap-4 border-b pb-2"
                  >
                    <div className="flex items-center space-x-2 w-[70%]">
                      <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                        {point.label}
                      </p>
                    </div>
                    <p className="text-right">{point.value}</p>
                  </li>
                ))}
            </ul>
          </div>

          <div className="mt-2"></div>
        </div>
        <div className="relative border-2 border-[#2563eb] rounded-3xl pt-5  w-[500px] h-full my-4 pb-5">
          <div className="absolute -top-1 flex items-center justify-center w-[500px] rounded-full ">
            <div className="border-4 w-40 rounded-full border-[#2563eb] " />
          </div>
          <div className="flex items-center gap-4  px-4">
            <div>
              <p className="text-xl dark:text-whiteish">
                {t(
                  "worker.subscriptions.pricing.body.rightcard.proplan.maintitle"
                )}
              </p>
              <p className="text-4xl font-bold dark:text-whiteish">
                {employerType?.type === "worker" &&
                  `${bulletPointsWorker.pricePremium}`}
                {employerType?.type !== "worker" &&
                  employerType?.isCompany &&
                  `${bulletPoints.pricePremiumCompany}`}
                {employerType?.type !== "worker" &&
                  !employerType?.isCompany &&
                  `${bulletPoints.pricePremiumCompany}`}
                €
                <span className="font-normal text-lg dark:text-whiteish">
                  {" "}
                  {t(
                    "worker.subscriptions.pricing.body.rightcard.proplan.mainsubtitle"
                  )}{" "}
                </span>
              </p>
            </div>
          </div>
          <div className="mt-2 px-4">
            <h3 className="font-semibold text-xl text-[#333] dark:text-whiteish">
              {t("worker.subscriptions.pricing.body.rightcard.proplan.title1")}
            </h3>
            {userInfo?.is_subscribed == 0 ? (
              <div className="bg-[#2563eb] bg-gradient-to-r from-[#2563eb]  to-azulBonico relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
                <button onClick={() => handleGetStarted()}>
                  <h1 className=" font-semibold text-center text-white  uppercase text-[16px] ">
                    {t(
                      "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2.getstartedBtn"
                    )}
                  </h1>
                </button>
                {isLoading ? (
                  <div className="px-3 py-1 bg-white  rounded-full absolute right-4">
                    <AiOutlineLoading className="h-5 w-5 text-azulOscuro animate-spin" />
                  </div>
                ) : (
                  <div className="px-3 py-1 bg-white  rounded-full absolute right-4">
                    <ArrowUpRightIcon className="h-5 w-5 text-azulOscuro" />
                  </div>
                )}
              </div>
            ) : (
              <>
                {" "}
                <div className="bg-[#2563eb] bg-gradient-to-r from-[#2563eb]  to-azulBonico relative flex items-center justify-center py-3 px-3 rounded-full mt-4">
                  <button>
                    <h1 className=" font-semibold text-center text-white  uppercase text-[16px] ">
                      {t(
                        "worker.subscriptions.pricing.body.rightcard.proplan.title2.subtitle2.alreadySubscribedBtn"
                      )}
                    </h1>
                  </button>
                </div>
              </>
            )}
            <ul className=" mt-3 space-y-2">
              {employerType?.isCompany &&
                employerType.type !== "worker" &&
                bulletPoints.premiumCompany.map((point) => (
                  <li
                    key={point.label}
                    className="flex items-center justify-between gap-4 border-b pb-2"
                  >
                    <div className="flex items-center space-x-2 w-[70%]">
                      <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                        {point.label}
                      </p>
                    </div>
                    <p className="text-right">{point.value}</p>
                  </li>
                ))}
              {!employerType?.isCompany &&
                employerType?.type !== "worker" &&
                bulletPoints.premiumParticular.map((point) => (
                  <li
                    key={point.label}
                    className="flex items-center justify-between gap-4 border-b pb-2"
                  >
                    <div className="flex items-center space-x-2 w-[70%]">
                      <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                        {point.label}
                      </p>
                    </div>
                    <p className="text-right">{point.value}</p>
                  </li>
                ))}
              {employerType?.type === "worker" &&
                bulletPointsWorker.premium.map((point) => (
                  <li
                    key={point.label}
                    className="flex items-center justify-between gap-4 border-b pb-2"
                  >
                    <div className="flex items-center space-x-2 w-[70%]">
                      {/* <CheckIcon className="h-5 w-5 text-[#333] dark:text-whiteish" /> */}
                      <p className="font-normal text-[16px] text-[#333] dark:text-whiteish">
                        {/* {t(
                    "worker.subscriptions.pricing.body.leftcard.freeplan.title1.subtitle1"
                    )} */}
                        {point.label}
                      </p>
                    </div>
                    <p className="text-right">{point.value}</p>
                  </li>
                ))}
            </ul>
          </div>
          <div className="mt-4 px-4"></div>
          <div className="flex flex-col px-4"></div>
          <div className="mt-2"></div>
        </div>
      </div>
    </VerticalLayout>
  );
};

export default Subscriptions;
