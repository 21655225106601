import React, { useState } from "react";
import { Form, FormField, FormLabel } from "../../../Components/ui/Form";

import Modal from "react-modal";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { Button } from "src/Components/ui/Button";
import { useIsPublicStore } from "src/Hooks/usePublicStore";

interface PublicPasswordProps {
  showModal: boolean;
}

const passwordToEnter = "elauro";

const PublicPassword = ({ showModal }: PublicPasswordProps) => {
  const setPublic = useIsPublicStore((s) => s.setIsPublic);

  // const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const StylesModal: any = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      zIndex: 100,
    },
    content: {
      position: "absolute",
      border: "none",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      outline: "none",
      padding: "20px",
      backgroundColor: "transparent",
    },
  };

  const checkPassword = (password: string) => {
    if (JSON.stringify(password) === JSON.stringify(passwordToEnter)) {
      setPublic("true");
    }
    setLoading(false);
    toast.error("Wrong password");
  };

  const formSchema = z.object({
    password: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setLoading(true);
      // checkPassword(values.password);
      if (values.password === passwordToEnter) {
        setPublic("true");
      } else {
        toast.error("Wrong password");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-screen w-screen overflow-hidden flex flex-col items-center justify-center bg-[#4C35DE]">
      <Modal isOpen={showModal} style={StylesModal} ariaHideApp={false}>
        <div
          className={`fixed z-50  transition duration-150 w-[300px] left-[50%] border translate-x-[-50%] rounded top-[10%] translate-y-[-50%] bg-white `}
          // onKeyDown={handleKeyPress}
        >
          <div className="px-4 py-5">
            <div className="flex flex-col justify-center">
              <Form {...form}>
                <form
                  className="w-full flex flex-col gap-6"
                  onSubmit={form.handleSubmit(onSubmit)}
                >
                  <FormLabel> Enter password to continue:</FormLabel>
                  <FormField
                    name="password"
                    control={form.control}
                    render={({ field }) => (
                      <input
                        autoFocus
                        disabled={loading}
                        {...field}
                        type="password"
                        className="outline-none border border-gray-300 w-full rounded mt-2 px-2 text-sm h-[28px]"
                        // onChange={(e) => setPassword(e.target.value)}
                      />
                    )}
                  />
                  {/* <div className="mt-3 flex justify-center"> */}
                  <Button
                    disabled={loading}
                    type="submit"
                    className={`${"bg-azulBonico"} mt-2 text-white font-medium px-4 rounded py-1 `}
                    // onClick={() => checkPassword()}
                  >
                    OK
                  </Button>
                  {/* </div> */}
                </form>
              </Form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PublicPassword;
