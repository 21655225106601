import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import { BASE_URL, POSTINGS } from "../../Config/api.config";
import { ReactComponent as Search } from "../../assets/img/search.svg";

import Pagination from "../EmployerJobsPagination/Pagination";
import "../../Components/ClientDashboard/ScrollBar.css";
import JobPostingsSearchCard from "./JobPostingsSearchCard";
import { ReactComponent as NoPosts } from "../../assets/img/pagination-noposts.svg";
import { ReactComponent as Banner } from "../../assets/img/allJobsEmployerBanner.svg";
import { useTranslation } from "react-i18next";

import { useCallback } from "react";
import { debounce } from "../../Utils/debounce";
import { JobTypes } from "../../types/types";
import HeaderBanner from "../ui/HeaderBanner";
import GoBackButton from "../ui/GoBack";
import { useQuery } from "react-query";
import { Skeleton } from "../ui/skeleton";

export default function JobPostingsSearch() {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [jobsData, setJobsData] = useState<JobTypes[]>([]);
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const { t } = useTranslation();
  const [pagination, setPagination] = useState({
    limit: 20,
    totalPages: "",
    totalPosts: "",
    currentPage: 1,
    pageNumbers: [],
    order: "date_updated",
    title: "",
    category: "",
  });
  const ref = useRef<HTMLInputElement>(null);
  const [orderSense, setOrderSense] = useState({ value: "asc", label: "ASC" });
  const [orderParam, setOrderParam] = useState({
    value: "updated",
    label: t("employer.alljobs.sortbyDropdown.updated"),
  });
  const [headerVisible, setHeaderVisible] = useState(true);

  const navigate = useNavigate();

  const sortOptions = [
    {
      value: "Updated",
      label: t("employer.alljobs.sortbyDropdown.updated"),
      param: "date_updated",
    },
    {
      value: "Created",
      label: t("employer.alljobs.sortbyDropdown.created"),
      param: "date_created",
    },
    {
      value: "New applicants",
      label: t("employer.alljobs.sortbyDropdown.newapplicants"),
      param: "applicants_new",
    },
    {
      value: "Budget",
      label: t("worker.job_search.sort.budget"),
      param: "budget",
    },
    {
      value: "Expiration date",
      label: t("employer.alljobs.sortbyDropdown.expirationdate"),
      param: "date_expire",
    },
  ];

  const getJobPostsFiltered = async () => {
    const data = await fetch(
      `${BASE_URL}${POSTINGS}?client_id=${userID}&limit=${
        pagination.limit
      }&page=${pagination.currentPage}&order=${orderParam.value.toLowerCase()}${
        pagination.title.length > 0 ? `&title=${pagination.title}` : ``
      }&order_sense=${orderSense.value.toUpperCase()}`,
      {
        headers: {
          Authorization: "Bearer " + loggedInUserUid,
        },
      }
    ).then((res) => res.json());
    return data;
  };

  const { data, refetch, isLoading } = useQuery(
    ["jobs_posts", orderParam.value, pagination.currentPage],
    getJobPostsFiltered
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      totalPosts: data?.paginate?.rows,
      totalPages: data?.paginate?.pages,
    }));
    setJobsData(data?.result);
  }, [data]);

  const colourStyles = {
    control: () => ({
      "&:hover": {
        backgroundColor: "#fff",
      },
      display: "flex",
      width: "120px",
      marginRight: "14px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
      cursor: "pointer",
    }),
  };

  const handleChangeSearch = (e: string) => {
    setPagination((prev) => ({
      ...prev,
      currentPage: 1,
      title: e,
      category: e,
    }));
    refetch();
    // getJobPostsFiltered("");
  };

  const optimisedSearch = useCallback(debounce(handleChangeSearch), []);

  return (
    <>
      <HeaderBanner
        headerVisible={headerVisible}
        setHeaderVisible={setHeaderVisible}
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("employer.all_kind_of_jobs.header_banner")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div
        className="flex items-center gap-1 fill-azulBonico cursor-pointer text-azulBonico hover:underline my-8 mx-4"
        onClick={() => navigate(-1)}
      >
        <GoBackButton />
      </div>
      <div className=" rounded h-fit mb-20 lg:max-w-4xl mx-4  lg:mx-auto">
        <div className="flex flex-col lg:flex-row w-full  lg:pr-8 py-8 justify-between ">
          <div className="relative w-full lg:w-1/2">
            <input
              ref={ref}
              type="text"
              className="px-2 border-slate-200 dark:bg-input dark:border-border dark:text-whiteish hover:border-blue-300 border text-sm w-full rounded h-10 outline-none focus:border-blue-100  transition duration-300"
              placeholder={`${t(
                "employer.alljobs.searchjobpostingsPlaceholder"
              )}`}
              onChange={(e) => {
                optimisedSearch(e.target.value);
              }}
            />
            <Search className="h-4 absolute fill-slate-500 right-[0.5rem] top-[12px] cursor-pointer " />
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center my-2 lg:my-0 lg:mx-4 gap-3 z-20 lg:z-[500]  relative  dark:text-whiteish">
            <p className="text-sm font-medium text-gray-500 dark:text-whiteish ">
              {t("employer.alljobs.sortbyDropdown.sortby")}
            </p>
            <Select
              styles={colourStyles}
              options={sortOptions}
              className="text-sm dark:text-black w-[160px] bg-white dark:bg-input hover:bg-white rounded border-border border"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              placeholder={`${t(
                "employer.alljobs.sortbyDropdown.placeholder"
              )}`}
              onChange={(e: any) => {
                setPagination((prev) => ({ ...prev, currentPage: 1 }));
                // getJobPostsFiltered(e.param);
                setOrderParam(e);
              }}
            />
          </div>
          <div className="flex items-center">
            <Select
              value={orderSense}
              styles={colourStyles}
              options={[
                { value: "asc", label: "ASC" },
                { value: "desc", label: "DESC" },
              ]}
              className="text-sm dark:text-black dark:bg-input  bg-white rounded border-border border"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onChange={(e: any) => setOrderSense(e)}
            />
          </div>
        </div>

        <div className="flex flex-col  h-[80%] overflow-y-auto jobpost ">
          {isLoading && (
            <div className="flex items-center justify-between bg-white px-4 border border-borderrounded-lg py-2">
              <div className="flex flex-col gap-2">
                <Skeleton className="h-5 w-40" />
                <Skeleton className="h-5 w-24" />
                <Skeleton className="h-5 w-20" />
                <Skeleton className="h-5 w-60" />
              </div>
              <div>
                <Skeleton className="w-40 h-6 " />
              </div>
              <Skeleton className="w-20 h-6 " />
              <Skeleton className="w-20 h-6 " />
              <Skeleton className="w-20 h-6 " />
            </div>
          )}
          {!isLoading &&
            jobsData?.map((job) => (
              <JobPostingsSearchCard
                key={job.job_id}
                job={job}
                setJobInfo={setJobInfo}
                setJobID={setJobID}
              />
            ))}
        </div>
        {!isLoading && jobsData?.length > 0 && (
          <Pagination
            pagination={pagination}
            setPagination={setPagination}
            getJobPostsFiltered={getJobPostsFiltered}
          />
        )}
        {!isLoading && jobsData?.length === 0 && (
          <div className="flex flex-col justify-center items-center my-10 space-y-10 bg-white rounded border py-4">
            <NoPosts className="h-60" />
            <h1 className="text-xl font-semibold">
              {t("employer.alljobs.empty")}
            </h1>
          </div>
        )}
      </div>
    </>
  );
}
