import React from "react";
import { useNavigate } from "react-router-dom";

//ICONS
import { ReactComponent as Tag } from "../../assets/img/price-tag.svg";
import { ReactComponent as Applicants } from "../../assets/img/applicants.svg";
import { ReactComponent as Messages } from "../../assets/img/comments.svg";
import { ReactComponent as Hires } from "../../assets/img/handshake.svg";
import { ReactComponent as Pin } from "../../assets/img/in-person.svg";
import { ReactComponent as Location } from "../../assets/img/location.svg";
import { ReactComponent as Offers } from "../../assets/img/briefcase.svg";
import { useTranslation } from "react-i18next";
import { JobTypes } from "../../types/types";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";
import HandleDayEnding from "src/Utils/HandleDayEnding";
import { cn } from "src/lib/utils";

interface JobPostingsSearchCardProps {
  job: JobTypes;
  setJobInfo: any;
  setJobID: any;
}

export default function JobPostingsSearchCard({
  job,
  setJobInfo,
  setJobID,
}: JobPostingsSearchCardProps) {
  const navigate = useNavigate();
  let completed = false;
  if (job.connections === job.workers_needed) completed = true;
  const { t, i18n } = useTranslation();

  const colorsIfAvailable =
    // "h-5 fill-azulBonico  hover:scale-105 cursor-pointer transition transform duration-300 ease-in-out hover:fill-blue-500 ";
    "h-5  hover:scale-105 cursor-pointer transition transform duration-300 ease-in-out  hover:fill-blue-500";
  const colorsIfUnavailable =
    "h-5 fill-[#333]  hover:scale-105 cursor-pointer transition transform duration-300 ease-in-out hover:fill-[#64748B]";
  return (
    <div
      className={`flex bg-white border px-4 dark:bg-background dark:text-whiteish rounded-lg border-l-[4px] dark:border-border mb-1  ${
        completed
          ? "border-green-500"
          : Number(job.offers_pending) > 0
          ? "border-yellow-400"
          : "border-red-600"
      }  border border-y-slate-200 border-r-slate-200 py-3 `}
    >
      <div className="flex flex-col w-full text-[#333] dark:text-whiteish">
        <div className="flex justify-between">
          <div
            className="flex flex-col cursor-pointer"
            onClick={() => {
              if (job.applicants === "0" && job.offers_pending === "0") {
                setJobInfo(job);
                setJobID(job.job_id);
                navigate("/applicants/view-job-post");
              }
              if (Number(job.offers_pending) > 0) {
                setJobInfo(job);
                setJobID(job.job_id);
                navigate("/applicants/offers");
              }
              if (Number(job.connections) > 0) {
                setJobInfo(job);
                setJobID(job.job_id);
                navigate("/applicants/hired");
              } else if (
                Number(job.applicants) > 0 &&
                Number(job.connections) === 0 &&
                Number(job.offers_pending) === 0
              ) {
                setJobInfo(job);
                setJobID(job.job_id);
                navigate("/applicants/review");
              }
            }}
          >
            <p className="font-semibold hover:underline hover:underline-offset-4 hover:text-blue-400 transition transform duration-150 ease-in-out">
              {job.title.charAt(0).toUpperCase() + job.title?.slice(1)}
            </p>
          </div>
        </div>
        <div className="flex mt-3">
          <div className="flex  gap-9 w-full">
            <div className="w-[20%]">
              <div className="flex flex-col mr-9">
                <p className="text-sm text-slate-500 whitespace-nowrap mb-3">
                  {job.category_name}
                </p>
                <div className="flex items-center text-sm gap-2 mr-4">
                  <Tag className="h-4 fill-black dark:fill-whiteish" />
                  <p className="whitespace-nowrap">
                    <span className="font-bold">{job.budget}€</span>/ h
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden lg:inline-flex items-end w-[80%] gap-4">
              <div className="flex flex-col items-center w-[25%]">
                {Number(job.applicants_new) > 0 && (
                  <div className="relative">
                    <div className="absolute -top-1.5 bg-azulOscuro rounded-full w-4 h-4 z-10">
                      <p className=" text-center text-white font-semibold text-[10px]">
                        {job.applicants_new}+
                      </p>
                    </div>
                  </div>
                )}

                <Applicants
                  className={`${
                    Number(job.applicants) > 0
                      ? colorsIfAvailable
                      : colorsIfUnavailable
                  }`}
                />
                <p
                  className={cn(
                    "text-xs "
                    // Number(job.applicants) > 0
                    //   ? "text-blue-500"
                    //   : colorsIfUnavailable
                  )}
                >
                  {t("employer.alljobs.ApplicantsIcon")}
                </p>
              </div>
              <div className="flex flex-col items-center w-[25%]">
                {Number(job.messages_new) > 0 && (
                  <div className="relative">
                    <div className="absolute -top-1.5 bg-azulOscuro rounded-full w-4 h-4 z-10">
                      <p className=" text-center text-white font-semibold text-[10px]">
                        {job.messages_new}+
                      </p>
                    </div>
                  </div>
                )}
                <Messages
                  className={`${
                    Number(job.messages) > 0
                      ? colorsIfAvailable
                      : colorsIfUnavailable
                  }`}
                />
                <p
                  className={cn(
                    "text-xs "
                    // Number(job.messages) > 0
                    //   ? "text-blue-500"
                    //   : colorsIfUnavailable
                  )}
                >
                  {t("employer.alljobs.MessagesIcon")}
                </p>
              </div>
              <div className="flex flex-col items-center w-[25%]">
                {Number(job.offers_pending) > 0 && (
                  <div className="relative">
                    <div className="absolute -top-1.5 bg-azulOscuro rounded-full w-4 h-4 z-10">
                      <p className=" text-center text-white font-semibold text-[10px]">
                        {job.offers_pending}+
                      </p>
                    </div>
                  </div>
                )}
                <Offers
                  className={`${
                    Number(job.offers_pending) > 0
                      ? colorsIfAvailable
                      : colorsIfUnavailable
                  }`}
                />
                <p
                  className={cn(
                    "text-xs "
                    // Number(job.offers_pending) > 0
                    //   ? "text-blue-500"
                    //   : colorsIfUnavailable
                  )}
                >
                  {t("employer.alljobs.OffersIcon")}
                </p>
              </div>
              <div className="flex flex-col items-center w-[25%]">
                {Number(job.connections) > 0 && (
                  <div className="relative">
                    <div className="absolute -top-1.5 bg-azulOscuro rounded-full w-4 h-4 z-10">
                      <p className="text-center text-white font-semibold text-[10px]">
                        {job.connections}+
                      </p>
                    </div>
                  </div>
                )}
                <Hires
                  className={`${
                    Number(job.connections) > 0
                      ? colorsIfAvailable
                      : colorsIfUnavailable
                  }`}
                />
                <p
                  className={cn(
                    "text-xs "
                    // Number(job.connections) > 0
                    //   ? "text-blue-500"
                    //   : colorsIfUnavailable
                  )}
                >
                  {t("employer.alljobs.HiresMessagesIcon")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <div className="flex items-center  gap-2">
            <Pin className="h-3 w-3 fill-azulBonico" />
            {job.city_name && (
              <>
                <Location className="h-3 w-3 fill-gray-400" />
                <p className="text-sm">{job.city_name}</p>
              </>
            )}
          </div>
          <div className="flex items-center   gap-3">
            <p className="text-sm  ">
              <span className="hidden lg:block">
                {/* {t("employer.allJobs.posted_on")}{" "} */}
                {i18n.language === "en" ? (
                  <>
                    {HandleMonthName(job.date_created?.slice(5, 7))}{" "}
                    {job.date_created?.slice(8)}
                    {HandleDayEnding(job.date_created?.slice(8))}
                  </>
                ) : (
                  <>
                    {job.date_created?.slice(8)} de{" "}
                    {HandleMonthNameES(job.date_created?.slice(5, 7))}
                  </>
                )}
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
