import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import WorkerActiveJobs from "../../../Components/WorkerAppliedJobs/WorkerActiveJobs";
import { BASE_URL } from "../../../Config/api.config";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { ReactComponent as Search } from "../../../assets/img/search.svg";
import { ReactComponent as Banner } from "../../../assets/img/appliedJobsWorkerBanner.svg";

import { useTranslation } from "react-i18next";
import autoAnimate from "@formkit/auto-animate";
import {
  OfferCounters,
  OfferApplied,
  ArchivedCounters,
  OfferArchived,
} from "src/types/types";
import HeaderBanner from "src/Components/ui/HeaderBanner";
import GoBackButton from "src/Components/ui/GoBack";
import { useQuery } from "react-query";
import { userProfileStore } from "src/Routing/useProfileStore";

const INITIAL_APPLIED = {
  active: [],
  applied: [],
  offered: [],
};

const WorkerProposals = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selected, setSelected] = useState("active");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [counters, setCounters] = useState<OfferCounters>({
    active: 0,
    applied: 0,
    offered: 0,
  });
  const [appliedJobs, setAppliedJobs] = useState<OfferApplied>(INITIAL_APPLIED);
  const [activeFilteredList, setActiveFilteredList] =
    useState<OfferApplied>(INITIAL_APPLIED);
  const [archivedCounter, setArchivedCounter] = useState<ArchivedCounters>({
    closed: 0,
    declined: 0,
    expired: 0,
  });
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [archivedJobs, setArchivedJobs] = useState<OfferArchived>({
    closed: [],
    declined: [],
    expired: [],
  });
  const [archivedFilteredList, setArchivedFilteredList] =
    useState<OfferArchived>({ closed: [], declined: [], expired: [] });

  const searchRef = useRef<HTMLInputElement>(null);
  const parent = useRef(null);
  const [headerVisible, setHeaderVisible] = useState(true);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const getAppliedJobsInfo = async () => {
    const data = await fetch(
      `${BASE_URL}worker.current.job.status?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: appliedJobsInfoData, isLoading } = useQuery(
    ["applied_jobs"],
    getAppliedJobsInfo
  );

  useEffect(() => {
    setCounters({
      active: appliedJobsInfoData?.numberOfActiveJobs,
      applied: appliedJobsInfoData?.numberOfAppliedJobs,
      offered: appliedJobsInfoData?.numberOfOfferJobs,
    });
    setAppliedJobs({
      active: appliedJobsInfoData?.activeJobs,
      applied: appliedJobsInfoData?.appliedJobs,
      offered: appliedJobsInfoData?.offerJobs,
    });
    setActiveFilteredList({
      active: appliedJobsInfoData?.activeJobs,
      applied: appliedJobsInfoData?.appliedJobs,
      offered: appliedJobsInfoData?.offerJobs,
    });
  }, [appliedJobsInfoData]);

  const getDeclinedJobsInfo = async () => {
    const data = await fetch(
      `${BASE_URL}worker.archived.job?client_id=${userID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: declinedJobsInfoData } = useQuery(
    ["declined_jobs"],
    getDeclinedJobsInfo
  );

  useEffect(() => {
    setArchivedCounter({
      closed: declinedJobsInfoData?.numberOfClosedJobs,
      declined: declinedJobsInfoData?.numberOfDeclinedOffers,
      expired: declinedJobsInfoData?.numberOfExpiredJobs,
    });
    setArchivedJobs({
      closed: declinedJobsInfoData?.closedJobs,
      declined: declinedJobsInfoData?.declinedOffers,
      expired: declinedJobsInfoData?.expiredJobs,
    });
    setArchivedFilteredList({
      closed: declinedJobsInfoData?.closedJobs,
      declined: declinedJobsInfoData?.declinedOffers,
      expired: declinedJobsInfoData?.expiredJobs,
    });
  }, [declinedJobsInfoData]);

  useEffect(() => {
    searchRef.current!.value = "";
    if (selected === "active") {
      getAppliedJobsInfo();
    }
  }, [selected]);

  const filterBySearch = (e: any) => {
    const query = e.target.value;
    if (selected === "active") {
      let offersUpdatedList = [...activeFilteredList.offered];
      let activeUpdatedList = [...activeFilteredList.active];
      let appliedUpdatedList = [...activeFilteredList.applied];
      offersUpdatedList = offersUpdatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      activeUpdatedList = activeUpdatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      appliedUpdatedList = appliedUpdatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      setAppliedJobs({
        offered: offersUpdatedList,
        active: activeUpdatedList,
        applied: appliedUpdatedList,
      });
    } else if (selected === "archived") {
      let declinedUpdatedList = [...archivedFilteredList.declined];
      let expiredUpdatedList = [...archivedFilteredList.expired];
      declinedUpdatedList = declinedUpdatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      expiredUpdatedList = expiredUpdatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      setArchivedJobs((prev: any) => ({
        ...prev,
        declined: declinedUpdatedList,
        expired: expiredUpdatedList,
      }));
    } else if (selected === "closed") {
      let closedUpdatedList = [...archivedFilteredList.closed];
      closedUpdatedList = closedUpdatedList.filter((item) => {
        return item.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });

      setArchivedJobs((prev: any) => ({ ...prev, closed: closedUpdatedList }));
    }
  };

  return (
    <>
      <HeaderBanner
        setHeaderVisible={setHeaderVisible}
        headerVisible={headerVisible}
        className="bg-[#2abeeb]"
      >
        <div className="flex flex-col h-full">
          <p className="text-[30px] font-[800] mt-[35px] h-full items-center">
            {t("worker.worker_proposals.header_banner")}
          </p>
        </div>
        <Banner className="h-28 w-fit" />
      </HeaderBanner>
      <div className="flex justify-between mt-6 mb-2 items-center">
        <div
          className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer"
          onClick={() => {
            if (profileType === "employer") {
              navigate("/dashboard");
            } else {
              navigate("/dashboard-worker");
            }
          }}
        >
          <GoBackButton />
        </div>
      </div>

      <div
        ref={parent}
        className="bg-white dark:bg-background dark:border-border dark:border dark:text-whiteish transition mt-2 pb-3 px-4 lg:px-8 rounded border "
      >
        {/* <div className="flex gap-5 mt-12 font-semibold text-sm ml-4 pt-8"> */}
        {/* <p
            className={` cursor-pointer transition ${
              selected === "active"
                ? "text-azulBonico underline  underline-offset-[13.5px]"
                : "hover:text-azulOscuro "
            } `}
            onClick={() => setSelected("active")}
          >
            &nbsp;&nbsp;{t("worker.myjobs.body.appliedjobs.layer1.title")}
            &nbsp;&nbsp;
          </p> */}
        {/* <p
            className={`cursor-pointer transition ${
              selected === "archived"
                ? "text-azulBonico underline underline-offset-[13.5px]"
                : "hover:text-azulOscuro "
            } `}
            onClick={() => {
              // getDeclinedJobsInfo("archived");
              setSelected("archived");
            }}
          >
            &nbsp;&nbsp;{t("worker.myjobs.body.appliedjobs.layer2.title")}
            &nbsp;&nbsp;
          </p> */}
        {/* <p
            className={`cursor-pointer  transition ${
              selected === "closed"
                ? "text-azulBonico underline underline-offset-[13.5px]"
                : "hover:text-azulOscuro "
            } `}
            onClick={() => {
              // getDeclinedJobsInfo("closed");

              setSelected("closed");
            }}
          >
            &nbsp;&nbsp;{t("worker.myjobs.body.appliedjobs.layer3.title")}
            &nbsp;&nbsp;
          </p> */}
        {/* </div> */}

        {/* <div className="border-b border-border mt-2"></div> */}
        <div className="relative flex items-center mt-4  transition dark:bg-input   dark:text-whiteish w-52 h-10 dark:border-border rounded border justify-end ml-auto">
          <Search className=" h-4 absolute left-2 top-3 fill-slate-500" />
          <input
            className="h-full w-full dark:bg-input outline-none px-2 pl-10 text-sm focus:ring transition ring-1 ring-border rounded"
            // className="px-2 pl-12 text-[#333]  hover:border-azulBonico dark:bg-transparent dark:text-whiteish text-sm h-10 outline-none focus:border-azulBonico transition duration-300"
            placeholder={`${t(
              "worker.myjobs.body.appliedjobs.search.placeholder"
            )}...`}
            onChange={(e) => filterBySearch(e)}
            ref={searchRef}
          />
        </div>
        {/* {selected === "active" && ( */}
        <WorkerActiveJobs
          counters={counters}
          jobs={appliedJobs}
          isLoading={isLoading}
        />
        {/* )} */}
        {/* {selected === "archived" && (
          <WorkerArchivedJobs
            counter={archivedCounter}
            jobs={archivedJobs}
            isLoading={isLoading}
          />
        )} */}
        {/* {selected === "closed" && (
          <WorkerClosedJobs
            counter={archivedCounter}
            jobs={archivedJobs}
            isLoading={isLoading}
          />
        )} */}
        <div className="flex gap-4 mt-8 justify-end text-sm pr-12 ">
          <p
            className="cursor-pointer text-azulBonico hover:text-azulOscuro hover:underline font-semibold"
            onClick={() => navigate("/jobs-search")}
          >
            {t("worker.applications.search_jobs")}
          </p>
          <p>|</p>
          <p
            className="cursor-pointer text-azulBonico hover:text-azulOscuro hover:underline font-semibold"
            onClick={() => navigate("/profile")}
          >
            {t("worker.applications.manage_profile")}
          </p>
        </div>
      </div>
    </>
  );
};

export default WorkerProposals;
