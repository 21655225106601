import React, {
  Dispatch,
  SetStateAction,
  Suspense,
} from "react";
import { BASE_URL_IMG } from "src/Config/api.config";
import { ReactComponent as Check } from "src/assets/img/check.svg";
import { useTranslation } from "react-i18next";


import StepsRightContainer from "../HowItWorks/StepsRightContainer";
import RatingStars from "../Rating/RatingStars";
import { HandleMonthName, HandleMonthNameES } from "src/Utils/HandleMonthName";

interface DashboardRightContainerProps {
  clientData: any;
  loadingCredits: boolean;
  setShowInstructionsCollapsed: Dispatch<SetStateAction<boolean>>;
  showInstructionsCollapsed: boolean;
  clientScore: {
    client_type_name: string;
    date_joined: string;
    score_avg: string;
    totalConnections: string;
    totalConnectionsTerminated: string;
    totalScores: string;
  };
}
const DashboardRightContainer = ({
  clientData,
  loadingCredits,
  showInstructionsCollapsed,
  setShowInstructionsCollapsed,
  clientScore,
}: DashboardRightContainerProps) => {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  return (
    <div className="hidden laptop:!block flex-[0_0_35%] ml-8 max-w-[35%] p-2 mt-4 ">
      <div className="flex flex-col items-center m-auto py-4 bg-white rounded-lg border  mb-4">
        <div className="flex items-center justify-center px-6">
          <div className="flex items-center space-x-6">
            {!clientData?.company_logo ? (
              <div className="flex flex-col items-center space-y-4 bg-white p-6 ">
                <img
                  src={require("../../assets/img/user-worker.png")}
                  alt="Default Profile"
                  className="h-20 w-20 rounded-full border-2 border-gray-200"
                />
                <p className="text-lg font-semibold text-gray-800">
                  {clientData?.first_name} {clientData?.last_name}
                </p>
                <div className="flex items-center space-x-2">
                  <Check className="h-4 fill-azulBonico" />
                  <p className="text-sm font-medium text-azulBonico">
                    {t("employer.dashboard.validated")}
                  </p>
                </div>
                <RatingStars
                  size="14"
                  activeStar={clientScore?.score_avg}
                  score={clientScore?.score_avg}
                  evaluation={false}
                  reviews={true}
                  showHalf={true}
                  info={{ totalScores: clientScore?.totalScores }}
                />
                <ul className="flex flex-col gap-3 mt-4 text-gray-700 w-full">
                  <li className="flex justify-between items-center">
                    <span className="font-medium">
                      {clientScore?.totalConnections}
                    </span>
                    <span className="text-sm text-gray-500">
                      {t("admin.analytics.jobs")}
                    </span>
                  </li>
                  <li className="flex justify-between items-center">
                    <span className="font-medium">
                      {clientScore?.totalConnectionsTerminated}
                    </span>
                    <span className="text-sm text-gray-500">
                      {t("dashboard_right_container_jobs_cancelled")}
                    </span>
                  </li>
                  <li className="text-sm text-gray-400">
                    {t("worker.dashboard.member_since")}{" "}
                    {i18n.language === "en"
                      ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
                      : HandleMonthNameES(
                          clientScore?.date_joined?.slice(5, 7)
                        )}{" "}
                    {clientScore?.date_joined?.slice(0, 4)}
                  </li>
                </ul>
              </div>
            ) : (
              // <div className="flex flex-col items-center">
              //   <img
              //     src={require("../../assets/img/user-worker.png")}
              //     className="h-16 w-16 rounded-full"
              //     alt="Default Profile"
              //   />
              //   <p className="mt-2 text-base font-semibold text-gray-800">
              //     {clientData?.first_name} {clientData?.last_name}
              //   </p>
              //   <div className="flex items-center space-x-2 mt-1">
              //     <Check className="h-4 fill-azulBonico" />
              //     <p className="text-xs text-azulBonico">
              //       {t("employer.dashboard.validated")}
              //     </p>
              //   </div>
              //   <RatingStars
              //     size="13"
              //     activeStar={clientScore?.score_avg}
              //     score={clientScore?.score_avg}
              //     evaluation={false}
              //     reviews={true}
              //     showHalf={true}
              //     info={{ totalScores: clientScore?.totalScores }}
              //   />
              //   <ul className="flex flex-col gap-2 mt-2 font-medium text-sm">
              //     <li className="flex items-center">
              //       <span>{clientScore?.totalConnections}</span>
              //       <span className="text-xs text-gray-500 ml-2">
              //         {t("admin.analytics.jobs")}
              //       </span>
              //     </li>
              //     <li className="flex items-center">
              //       <span>{clientScore?.totalConnectionsTerminated}</span>
              //       <span className="text-xs text-gray-500 ml-2">
              //         {t("dashboard_right_container_jobs_cancelled")}
              //       </span>
              //     </li>
              //     <li>
              //       <p className="text-xs text-gray-400">
              //         {t("worker.dashboard.member_since")}{" "}
              //         {i18n.language === "en"
              //           ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
              //           : HandleMonthNameES(
              //               clientScore?.date_joined?.slice(5, 7)
              //             )}{" "}
              //         {clientScore?.date_joined?.slice(0, 4)}
              //       </p>
              //     </li>
              //   </ul>
              // </div>
              <div className="flex flex-col items-center space-y-4 bg-white p-6 r">
                <img
                  loading="lazy"
                  src={`${BASE_URL_IMG}${clientData?.company_logo}`}
                  alt="Company Logo"
                  className="h-24 w-24 object-cover rounded-full border-2 border-gray-200 "
                />
                <p className="text-lg font-semibold text-gray-800">
                  {clientData?.company_name}
                </p>
                <div className="flex items-center space-x-2">
                  <Check
                    className={`h-5 ${
                      clientData?.company_validated === "1"
                        ? "fill-gray-400"
                        : "fill-azulBonico"
                    }`}
                  />
                  <p
                    className={`text-sm font-medium ${
                      clientData?.company_validated === "1"
                        ? "text-gray-400"
                        : "text-azulBonico"
                    }`}
                  >
                    {clientData?.company_validated === "1"
                      ? t("employer.dashboard.not_validated")
                      : t("employer.dashboard.validated")}
                  </p>
                </div>
                <div className="flex flex-col items-center mt-4">
                  <RatingStars
                    size="14"
                    activeStar={clientScore?.score_avg}
                    score={clientScore?.score_avg}
                    evaluation={false}
                    reviews={true}
                    showHalf={true}
                    info={{ totalScores: clientScore?.totalScores }}
                  />
                  <ul className="flex flex-col gap-3 mt-4 text-gray-700 w-full">
                    <li className="flex justify-between items-center">
                      <span className="font-medium">
                        {clientScore?.totalConnections}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t("admin.analytics.jobs")}
                      </span>
                    </li>
                    <li className="flex justify-between items-center">
                      <span className="font-medium">
                        {clientScore?.totalConnectionsTerminated}
                      </span>
                      <span className="text-sm text-gray-500">
                        {t("dashboard_right_container_jobs_cancelled")}
                      </span>
                    </li>
                    <li className="text-sm text-gray-400">
                      {t("worker.dashboard.member_since")}{" "}
                      {i18n.language === "en"
                        ? HandleMonthName(clientScore?.date_joined?.slice(5, 7))
                        : HandleMonthNameES(
                            clientScore?.date_joined?.slice(5, 7)
                          )}{" "}
                      {clientScore?.date_joined?.slice(0, 4)}
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Suspense fallback={null}>
        <StepsRightContainer
          showInstructionsCollapsed={showInstructionsCollapsed}
          setShowInstructionsCollapsed={setShowInstructionsCollapsed}
        />
      </Suspense>
    </div>
  );
};

export default DashboardRightContainer;
