import { useState } from "react";
import useLocalStorage from "./UseLocalStorage";
import { BASE_URL } from "src/Config/api.config";
import { useQuery } from "react-query";

export const usePriceStripe = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [cachedPrice, setCachedPrice] = useState(null);

  const getPriceStripe = async () => {
    if (cachedPrice) {
      // If price data is cached, return the cached data
      return cachedPrice;
    }

    try {
      const response = await fetch(`${BASE_URL}retrieveProductPrice.php`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + loggedInUserUid,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to fetch product price");
      }

      const data = await response.json();

      // Cache the price data
      setCachedPrice(data);

      return data;
    } catch (error) {
      console.error("Error fetching Stripe price:", error);
      throw error;
    }
  };

  const {
    data: priceStripeData,
    refetch: refetchPriceStripe,
    isLoading,
  } = useQuery(["stripe_price"], getPriceStripe, {
    // Disable automatic fetching if cachedPrice exists
    enabled: !cachedPrice,
  });

  return {
    priceStripeData: cachedPrice || priceStripeData,
    refetchPriceStripe,
    isLoading,
  };
};
