import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, DRAFTS, JOB_TIME_PROFILE } from "../../Config/api.config";
import ModalEdit from "./ModalEdit";
import edit from "../../assets/img/edit.png";
import ModalEditSchedule from "../Modals/ModalEditSchedule";
import AvailableDays from "../Time/AvailableDays";
import exclamation from "../../assets/img/exclamation.png";
import Confetti from "react-confetti";

import { AiOutlineArrowDown as ArrowDown } from "react-icons/ai";

import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import GoBackButton from "../ui/GoBack";
import { useQuery } from "react-query";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import {
  useActionTypesStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import { decryptArray } from "src/Hooks/decryptString";

export default function JobPostEdit() {
  const { t, i18n } = useTranslation();
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [jobDescription, setJobDescription] = useLocalStorage(
    "jobDescription",
    ""
  );
  const [allLocations, setAllLocations] = useLocalStorage("allLocations", []);
  const [originalData, setOriginalData] = useLocalStorage("originalData", "");
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [jobInfo, setJobInfo] = useLocalStorage("jobInfo", "");
  const [jobTitle, setJobTitle] = useLocalStorage("jobTitle", "");
  const [budget, setBudget] = useLocalStorage("budget", "");
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", []);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [jobType, setJobType] = useLocalStorage("jobType", "");
  const [clientSkillsID, setClientSkillsID] = useLocalStorage(
    "clientSkillsID",
    ""
  );
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState();
  const [showAdvancedPreferences, setShowAdvancedPreferences] = useState(false);
  const [hireDate, setHireDate] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [workersNeeded, setWorkersNeeded] = useState("01");
  const [showModalEditSchedule, setShowModalEditSchedule] = useState(false);
  const [allChosenTimes, setAllChosenTimes] = useState<any>();
  const [buttonActive, setButtonActive] = useLocalStorage("buttonActive", "");
  const [skillsID, setSkillsID] = useLocalStorage("skillsID", []);
  const [jobLocation, setJobLocation] = useLocalStorage("jobLocation", "");
  const [jobLocationID, setJobLocationID] = useLocalStorage(
    "jobLocationID",
    ""
  );
  const [selected, setSelected] = useLocalStorage("selected", "");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const [postcodeID, setPostcodeID] = useState("");
  const [skills, setSkills] = useLocalStorage("skills", []);
  const [showModal, setShowModal] = useState(false);
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModalSkills, setShowModalSkills] = useState(false);
  const [showModalJobType, setShowModalJobType] = useState(false);
  const [showModalBudget, setShowModalBudget] = useState(false);
  const [checkErrors, setCheckErrors] = useState<{
    title: boolean;
    details: boolean;
  }>({ title: false, details: false });
  const [netPay, setNetPay] = useState();
  const params = useParams();
  const inputRefTitle = useRef<HTMLInputElement>(null);
  const inputRefDetails = useRef<HTMLTextAreaElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const scrollToSection = (e: any) => {
    e.current.scrollIntoView({ behavior: "smooth" });
  };
  const [activateConfetti, setActivateConfetti] = useState(false);
  const navigate = useNavigate();
  const data = new FormData();
  const actions = useActionTypesStore((s: any) => s.actions);
  const { updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "1"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "1"
    )[0]?.id
  );
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const draftId = params.id;

  const getData = async () => {
    const data = await fetch(`${BASE_URL}${DRAFTS}?draft_id=${draftId}`, {
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
    }).then((res) => res.json());

    return data.result;
  };

  const { data: draftData, isLoading: isDraftLoading } = useQuery(
    ["draft", draftId],
    getData
  );

  useEffect(() => {
    if (!isDraftLoading) {
      setJobInfo(draftData?.data[0]);
      setClientSkills(draftData?.skills);
      setJobType(draftData?.data[0].job_type);
      setJobLocation({ name: draftData?.data[0].city_name });
      setJobLocationID(draftData?.data[0]?.city_id);
      setCategoryID(draftData?.data[0].category_id);
      setSelectedCategory(draftData?.data[0].category_name);
      setPostcodeID(draftData?.data[0]?.post_code_id);
      setPostcode(draftData?.data[0].location);
      setCountry(draftData?.data[0].country_code);
      setNetPay(draftData?.data[0].netpay);
    }
  }, [draftData]);

  const getTimes = async () => {
    const data = await fetch(
      `${BASE_URL}${JOB_TIME_PROFILE}?job_id=${draftId}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data.result;
  };

  const { data: timeData, isLoading } = useQuery(["time"], getTimes);

  useEffect(() => {
    setAllChosenTimes(timeData);
  }, [timeData]);
  const deleteTimes = (id: string) => {
    fetch(`${BASE_URL}${JOB_TIME_PROFILE}?job_time_profile_id=${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  const sendData = new FormData();
  sendData.append("_method", "POST");
  sendData.append("job_id", jobInfo?.job_id);
  const sendTime = (from: string, to: string, day: string) => {
    sendData.append("hour_from", from);
    sendData.append("hour_to", to);
    sendData.append("dayname", day);
    fetch(`${BASE_URL}${JOB_TIME_PROFILE}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: sendData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // getTimes();
    if (!originalData?.budget && jobInfo) {
      setOriginalData((prev: any) => ({
        ...prev,
        budget: jobInfo.budget,
        category_name: jobInfo.category_name,
        category_id: jobInfo.category_id,
        city_name: jobInfo.city_name,
        city_id: jobInfo.city_id,
        country_code: jobInfo.country_code,
        details: jobInfo.details,
        job_type: jobInfo.job_type,
        location: jobInfo.location,
        postcode_id: jobInfo.post_code_id,
        title: jobInfo.title,
        type_name: jobInfo.type_name,
        skills: clientSkills,
        netpay: jobInfo?.netpay,
      }));
      setJobType(jobInfo.job_type);
      setJobLocation((prev: any) => ({ ...prev, name: jobInfo.city_name }));
      setPostcode(jobInfo.location);
      setCountry(jobInfo.country_code);
      setNetPay(jobInfo?.netpay);
      setHireDate(jobInfo.due_days);
      setExpireDate(jobInfo.expire_offer_days);
      setWorkersNeeded(jobInfo.workers_needed);
    }
  }, [jobInfo]);

  useEffect(() => {
    if (allChosenTimes?.length > 0 && !originalData?.times) {
      setOriginalData((prev: any) => ({
        ...prev,
        times: allChosenTimes,
      }));
    }
  }, [allChosenTimes]);
  //cuando se dar a cancel, para no actualizar los horarios
  const isIncluded = () => {
    //para eliminar los horarios añadidos
    const arrayIdsCurrent = allChosenTimes?.map(
      (time: { job_time_profile_id: string }) => time.job_time_profile_id
    );
    const arrayIdsOriginal = originalData.times?.map(
      (time: { job_time_profile_id: string }) => time.job_time_profile_id
    );
    const deleteIds = arrayIdsCurrent.filter(
      (x: any) => !arrayIdsOriginal.includes(x)
    );
    for (let i = 0; i < deleteIds?.length; i++) {
      deleteTimes(deleteIds[i]);
    }
    //para añadir los horarios eliminados
    for (let i = 0; i < originalData?.times?.length; i++) {
      sendTime(
        originalData.times[i].hour_from,
        originalData.times[i].hour_to,
        originalData.times[i].dayname
      );
    }
  };
  useEffect(() => {
    if (jobInfo?.job_type == 2) {
      setJobInfo((prev: any) => ({
        ...prev,
        city_name: "",
        country_code: "",
        location: "",
      }));
      setPostcode("");
      setJobLocation();
      setCountry("");
    }
  }, []);
  const checkAppendings = (props: string) => {
    if (originalData.title !== jobInfo.title) {
      data.append("title", jobInfo.title);
    }
    if (originalData.details !== jobInfo.details) {
      data.append("details", jobInfo.details);
    }
    if (originalData.category_name !== jobInfo.category_name) {
      data.append("category_id", jobInfo.category_id);
    }
    if (
      JSON.stringify(
        originalData?.skills?.map((skills: { skill_id: string }) => {
          return skills.skill_id;
        })
      ) !=
      JSON.stringify(
        clientSkills?.map((skill: { skill_id: string }) => {
          return skill.skill_id;
        })
      )
    ) {
      data.append(
        "skills_list",
        clientSkills?.map((skill: { skill_id: string }) => skill.skill_id)
      );
    }
    if (originalData.job_type != jobType) {
      if (jobType == 2) {
        data.append("job_type", jobType);
      } else {
        data.append("job_type", jobType);
        if (
          originalData?.city_id !== jobLocationID &&
          originalData?.postcode_id !== postcodeID
        ) {
          data.append("city_id", jobLocationID);
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.postcode_id !== postcodeID) {
          data.append("city_id", originalData?.city_id);
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.city_id !== jobLocationID) {
          data.append("post_code_id", originalData?.postcode_id);
          data.append("city_id", jobLocationID);
          data.append("country_code", "ES");
        } else {
          data.append("post_code_id", originalData?.postcode_id);
          data.append("city_id", originalData?.city_id);
          data.append("country_code", "ES");
        }
      }
    } else {
      if (jobType != 2) {
        if (
          originalData?.city_id !== jobLocationID &&
          originalData?.postcode_id !== postcodeID
        ) {
          data.append("city_id", jobLocationID);
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.postcode_id !== postcodeID) {
          data.append("post_code_id", postcodeID);
          data.append("country_code", "ES");
        } else if (originalData?.city_id !== jobLocation?.id) {
          data.append("city_id", jobLocationID);
          data.append("country_code", "ES");
        }
      }
    }
    if (originalData.budget !== jobInfo?.budget) {
      data.append("budget", jobInfo.budget);
    }
    data.append("netpay", netPay!);
    data.append("currency_code", "EUR");
    data.append("_method", "PUT");
    data.append("client_id", userID);
    data.append("draft_id", jobInfo?.job_id);
    data.append("workers_needed", workersNeeded === "01" ? "1" : workersNeeded);

    if (props === "post") {
      data.append("job_status_id", "1");
    }
    setOriginalData();
    setJobInfo();
    setJobID();
    setSelected();
    setSelected();
    setBudget();
    setSelectedCategory();
    setJobLocation();
    setJobDescription();
    setCategoryID();
    setSkillsID();
    setSkills();
    setJobTitle();
    setJobLocationID();
    setClientSkillsID();
    setClientSkills();
    setJobType();
    jobPosting();
    if (props === "post") {
      setTimeout(() => {
        if (profileType === "employer") {
          navigate("/dashboard");
        } else {
          navigate("/dashboard-worker");
        }
      }, 3000);
    } else {
      setTimeout(() => {
        if (profileType === "employer") {
          navigate("/dashboard");
        } else {
          navigate("/dashboard-worker");
        }
      }, 500);
    }
  };
  const jobPosting = () => {
    fetch(`${BASE_URL}${DRAFTS}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setActivateConfetti(true);
          updateCredits();
        }
      })
      .catch((err) => console.log(err));
  };

  const handleJobPost = () => {
    whatLocationID();
    jobPosting();
    setJobID();
    setBudget();
    setSelectedCategory();
    setJobLocation();
    setJobDescription();
    setCategoryID();
    setSkillsID();
    setSkills();
    setJobTitle();
    // setJobLocationID();
    setClientSkillsID();
    setClientSkills();
    setJobType();
    navigate("/dashboard");
    // }, 3000);
  };
  const handleJobCancel = () => {
    isIncluded();
    setOriginalData();
    setJobInfo();
    setJobID();
    setSelected();
    setSelected();
    setBudget();
    setSelectedCategory();
    setJobLocation();
    setJobDescription();
    setCategoryID();
    setSkillsID();
    setSkills();
    setJobTitle();
    setJobLocationID();
    setClientSkillsID();
    setClientSkills();
    setJobType();
    jobPosting();
    // navigate("/dashboard");
  };
  const whatLocationID = () => {
    if (jobType === "1" || jobType === 1) {
      for (let i = 0; i < allLocations?.length; i++) {
        if (jobLocation.includes(allLocations[i]?.location)) {
          data.append("location_id", allLocations[i].id);
          setJobLocationID(allLocations[i].id);
        }
      }
    }
  };

  useEffect(() => {
    if (jobInfo?.title?.length > 8)
      setCheckErrors((prev: any) => ({ ...prev, title: false }));
    if (jobInfo?.details?.length >= 50)
      setCheckErrors((prev: any) => ({ ...prev, details: false }));
  }, [jobInfo?.title, jobInfo?.details]);

  return (
    <>
      {activateConfetti && (
        <>
          <Confetti
            numberOfPieces={300}
            gravity={0.3}
            initialVelocityX={10}
            initialVelocityY={150}
          />
        </>
      )}
      <div
        ref={topRef}
        className="flex items-center mt-10 gap-2 fill-azulBonico cursor-pointer"
        onClick={() => {
          handleJobCancel();
          navigate(-1);
        }}
      >
        <GoBackButton />
      </div>
      <div className="flex flex-col bg-white pb-20 rounded my-10 border dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex items-center border-b border-blue-100 dark:border-border pl-8 lg:pl-0 w-full justify-between py-4">
          <h1 className="lg:p-8 py-4 lg:py-0 text-3xl font-bold text-[#343a47] dark:text-whiteish">
            {t("employer.job_post_edit.review")}
          </h1>
          <button
            className="px-6 font-bold h-10 text-white bg-azulBonico hover:bg-azulOscuro rounded-full mr-8"
            onClick={() => {
              if (jobInfo?.title?.length > 8 && jobInfo?.details?.length > 50) {
                checkAppendings("post");
              } else {
                if (jobInfo?.title?.length < 8 || !jobInfo?.title)
                  setCheckErrors((prev) => ({ ...prev, title: true }));
                if (jobInfo?.details?.length <= 50 || !jobInfo?.details)
                  setCheckErrors((prev) => ({ ...prev, details: true }));
              }
            }}
          >
            {t("employer.draft_edit.post_job")}
          </button>
        </div>
        <div className="flex flex-col py-4 px-8 border-b-[0.5px] border-blue-100 dark:border-border dark:border">
          <p className="mb-4 font-bold">{t("employer.draft_edit.headline")}</p>
          <input
            ref={inputRefTitle}
            className="border h-10 pl-4 dark:border-border outline-none rounded dark:bg-input hover:border-azulBonico focus:border-azulBonico transition duration-300 w-full lg:w-2/3"
            defaultValue={jobInfo?.title}
            // onChange={(e) => setJobTitle(e.target.value)}
            onChange={(e) =>
              setJobInfo((prev: any) => ({
                ...prev,
                title: e.target.value,
              }))
            }
          ></input>
          {checkErrors.title && (
            <div className=" flex align-center text-[#9b211b] text-xs mt-4">
              <img className="exclamation" src={exclamation} alt="hey" />{" "}
              {t("employer.post_review.larger_title")}
            </div>
          )}
        </div>
        <div className="flex flex-col py-4 px-8 border-b border-blue-100 dark:border-border dark:border">
          <p className="font-bold mb-4">
            {t("employer.draft_edit.describe_job")}
          </p>
          <p className="text-sm mb-2">
            {t("employer.draft_edit.introduction")}
          </p>
          <p className="text-sm mb-4">
            {t("employer.draft_edit.expectations_examples")}
            {/* Include your expectations about the task or deliverable, what
                you’re looking for in a work relationship, and anything unique
                about your project, team, or company.{" "}
                <span className="text-azulBonico">
                  Here are several examples
                </span>
                &nbsp;that illustrate best practices for effective job posts. */}
          </p>
          <form>
            <div className="flex flex-col w-full">
              <textarea
                ref={inputRefDetails}
                defaultValue={jobInfo?.details}
                placeholder={`${t("employer.job_edit.descriptionPlaceholder")}`}
                onChange={(e) =>
                  setJobInfo((prev: any) => ({
                    ...prev,
                    details: e.target.value,
                  }))
                }
                className="w-full lg:w-2/3 h-40 p-2 px-4 text-sm border outline-none dark:bg-input dark:border-border dark:border border-blue-100 rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              />
              {jobInfo?.details?.length < 50 && (
                <p className="text-xs flex w-full mt-2 lg:mt-0 lg:w-2/3 justify-end">
                  {i18n.language === "en" ? (
                    <>
                      At least {50 - jobInfo?.details?.length} characters more
                    </>
                  ) : (
                    <>Faltan {50 - jobInfo?.details?.length} caracteres más</>
                  )}
                </p>
              )}
            </div>
          </form>
          {checkErrors.details && (
            <div className=" flex align-center text-[#9b211b] text-xs mt-4">
              <img className="exclamation" src={exclamation} alt="hey" />{" "}
              {t("employer.post_review.larger_description")}
            </div>
          )}
        </div>

        <div className="flex flex-col border-b border-blue-100 dark:border-border dark:border">
          <div className="flex flex-col p-4 pb-3 px-8">
            <p className="font-bold mb-4">
              {t("employer.draft_edit.category")}
            </p>
            <div className="flex items-center">
              <p className="text-sm mr-4">{jobInfo?.category_name}</p>
              <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowModalCategory(true)}
              ></img>
            </div>
            {showModalCategory === true && (
              <ModalEdit
                setShowModal={setShowModalCategory}
                title={`${t("employer.job_post_edit.category")}`}
                jobInfo={jobInfo}
                setJobInfo={setJobInfo}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoryID={categoryID}
                setCategoryID={setCategoryID}
              />
            )}
          </div>
          <div className="flex flex-col p-3 px-8">
            <p className="font-bold mb-4">{t("employer.draft_edit.skills")}</p>
            <div className="flex items-center flex-wrap">
              {clientSkills?.map(
                (skill: { skill_name: string; skill_id: string }) => {
                  return (
                    <p
                      className="mr-2 bg-[#4d5669] hover:bg-[#292e38] text-white rounded-full px-2 py-1 text-xs cursor-default mb-1"
                      key={skill.skill_id}
                    >
                      {skill.skill_name}
                    </p>
                  );
                }
              )}

              <img
                src={edit}
                alt="edit"
                className="h-3 ml-4 cursor-pointer"
                onClick={() => setShowModalSkills(true)}
              ></img>
            </div>
            {showModalSkills === true && (
              // <div className="absolute top-0 left-0 w-full h-full bg-black/50">
              //   <div className="fixed z-10 w-[30%] left-[50%] translate-x-[-50%] rounded top-[50%] translate-y-[-50%] h-1/3 bg-black">
              //     <div className="bg-white absolute top-[20%] left-[30%] w-[40%] p-[20px] rounded border"></div>
              //   </div>
              // </div>
              <ModalEdit
                setShowModal={setShowModalSkills}
                title={`${t("employer.job_post_edit.skills")}`}
                clientSkills={clientSkills}
                setClientSkills={setClientSkills}
                jobInfo={jobInfo}
              />
            )}
          </div>

          <div className="flex flex-col p-3 px-8">
            <p className="font-bold mb-4">
              {t("employer.draft_edit.type_job")}
            </p>
            <div className="flex items-center">
              <p className="text-sm mr-4">
                {(jobType === 1 || jobType === "1") &&
                  (jobLocation?.label || jobLocation?.name) && (
                    <>
                      <p> {t("employer.draft_edit.in_person")}</p>
                      <p className="font-semibold">
                        {jobLocation?.name
                          ? jobLocation?.name
                          : jobLocation?.label}
                      </p>
                      <p className="font-semibold"> {postcode}, ES</p>
                    </>
                  )}
                {(jobType === 2 || jobType === "2") && "Remote"}
                {(jobType === 3 || jobType === "3") &&
                  (jobLocation?.label || jobLocation?.name) && (
                    <>
                      <p> {t("employer.draft_edit.hybrid")}</p>
                      <p className="font-semibold">
                        {jobLocation?.name
                          ? jobLocation?.name
                          : jobLocation?.label}
                      </p>
                      <p className="font-semibold"> ES</p>
                    </>
                  )}
              </p>
              <img
                src={edit}
                alt="edit"
                className="h-3 ml-4 cursor-pointer"
                onClick={() => setShowModalJobType(true)}
              />
            </div>
            {showModalJobType === true && (
              <ModalEdit
                setShowModal={setShowModalJobType}
                title={`${t("employer.job_post_edit.type_job")}`}
                setJobType={setJobType}
                jobType={jobType}
                setJobLocation={setJobLocation}
                setJobLocationID={setJobLocationID}
                setPostcodeID={setPostcodeID}
                jobLocation={jobLocation}
                postcode={postcode}
                setPostcode={setPostcode}
                country={country}
                setCountry={setCountry}
                setJobInfo={setJobInfo}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </div>
          <div className="flex flex-col p-3 px-8 mb-3">
            <p className="font-bold mb-4">{t("employer.draft_edit.budget")}</p>
            <div className="flex items-center">
              <p className="text-sm mr-4">
                {jobInfo?.budget} € {netPay === "0" ? "brutos" : "netos"} / h
              </p>
              <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowModalBudget(true)}
              />
            </div>
            {showModalBudget === true && (
              <ModalEdit
                setShowModal={setShowModalBudget}
                title={`${t("employer.job_post_edit.budget")}`}
                jobInfo={jobInfo}
                setJobInfo={setJobInfo}
                setSelectedCurrencyCode={setSelectedCurrencyCode}
                netPay={netPay}
                setNetPay={setNetPay}
              />
            )}
          </div>
          <div className="flex flex-col p-3 px-8 mb-3">
            <div className="flex items-center gap-4 mb-4">
              <p className="font-bold">{t("employer.draft_edit.schedule")}</p>
              <img
                src={edit}
                alt="edit"
                className="h-3 cursor-pointer"
                onClick={() => setShowModalEditSchedule(true)}
              ></img>
            </div>
            {showModalEditSchedule && (
              <ModalEditSchedule
                setShowModal={setShowModalEditSchedule}
                showModal={showModalEditSchedule}
                allChosenTimes={allChosenTimes}
                draftId={draftId!}
                setAllChosenTimes={setAllChosenTimes}
              />
            )}
            <AvailableDays allChosenTimes={allChosenTimes} />
          </div>
          <div>
            <div
              className="border-t border-blue-100 cursor-pointer flex justify-between items-center px-8 py-6 "
              onClick={() =>
                setShowAdvancedPreferences(!showAdvancedPreferences)
              }
            >
              <p className="text-base font-bold">
                {t("employer.draft_edit.advanced_preferences")}
              </p>
              <ArrowDown
                className={`h-3 ${
                  showAdvancedPreferences
                    ? "animate-toArrowUp rotate-180"
                    : "rotate-0"
                }`}
              />
              {/* {showAdvancedPreferences ? (
                    <ArrowUp className="h-3 fill-black" />
                  ) : (
                    <ArrowDown className="h-3 fill-black" />
                  )} */}
            </div>
            {showAdvancedPreferences && (
              <div
                className={`text-sm px-8 flex flex-col lg:flex-row pb-6 gap-4 lg:gap-48 transition `}
              >
                {/* <div className="flex flex-col gap-2">
                  <p className="font-bold mb-1">
                    {t("employer.draft_edit.due_days")}
                  </p>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="7"
                      checked={hireDate === "7"}
                      onChange={() => setHireDate("7")}
                    />
                    1 {t("employer.draft_edit.week")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="14"
                      checked={hireDate === "14"}
                      onChange={() => setHireDate("14")}
                    />
                    2 {t("employer.draft_edit.week")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="30"
                      checked={hireDate === "30"}
                      onChange={() => setHireDate("30")}
                    />
                    1 {t("employer.draft_edit.month")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="90"
                      checked={hireDate === "90"}
                      // defaultChecked={jobInfo?.due_days === "90" && true}
                      onChange={() => setHireDate("90")}
                    />
                    3 {t("employer.draft_edit.months")}
                  </label>
                </div> */}
                <div className="flex flex-col gap-2">
                  <p className="font-bold mb-1">
                    {t("employer.draft_edit.expire_offer")}
                  </p>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="1"
                      checked={expireDate === "1"}
                      onChange={() => setExpireDate("1")}
                    />
                    1 {t("employer.draft_edit.day")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="3"
                      checked={expireDate === "3"}
                      onChange={() => setExpireDate("3")}
                    />
                    3 {t("employer.draft_edit.days")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="7"
                      checked={expireDate === "7"}
                      onChange={() => setExpireDate("7")}
                    />
                    1 {t("employer.draft_edit.week")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="14"
                      checked={expireDate === "14"}
                      onChange={() => setExpireDate("14")}
                    />
                    2 {t("employer.draft_edit.weeks")}
                  </label>
                </div>
                <div className="flex flex-col gap-2">
                  <p className="font-bold mb-1">
                    {t("employer.draft_edit.professionals_needed")}
                  </p>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="01"
                      checked={workersNeeded === "01"}
                      onChange={() => setWorkersNeeded("01")}
                    />
                    {t("review.jobpostreview.body.card4.subtitle10.check1")}
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      value="More"
                      checked={workersNeeded !== "01"}
                      onChange={() => setWorkersNeeded("02")}
                    />
                    {t("review.jobpostreview.body.card4.subtitle10.check2")}
                  </label>
                  {workersNeeded !== "01" && (
                    <input
                      type="number"
                      className="w-full outline-none border-2 border-blue-100 hover:border-blue-200 rounded-lg h-7 px-2 focus:border-blue-200"
                      defaultValue={workersNeeded}
                      onChange={(e) => setWorkersNeeded(e.target.value)}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end px-8 pt-6">
          {/* <button
            className="px-6 font-bold h-10 text-azulBonico border border-azulBonico hover:bg-[#eceaea] rounded-full"
            onClick={() => handleJobCancel()}
          >
            {t("employer.draft_edit.go_back")}
          </button> */}
          <div>
            <Button
              className="mr-4 text-azulBonico hover:underline  hover:text-azulOscuro"
              onClick={() => {
                checkAppendings("");
              }}
            >
              {t("employer.draft_edit.save")}
            </Button>
            <Button
              className={`px-6 font-bold h-10 mt-4 lg:mt-0 rounded-full ${
                !jobInfo?.title ||
                !jobInfo?.category_name ||
                !jobInfo?.budget ||
                !jobType ||
                clientSkills?.length == 0
                  ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                  : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
              } `}
              disabled={
                !jobInfo?.title ||
                !jobInfo?.category_name ||
                !jobInfo?.budget ||
                !jobType ||
                clientSkills?.length == 0
              }
              onClick={() => {
                if (
                  jobInfo?.title?.length > 8 &&
                  jobInfo?.details?.length >= 50
                ) {
                  checkAppendings("post");
                } else {
                  if (jobInfo?.details?.length < 50 || !jobInfo?.details) {
                    inputRefDetails.current?.focus();

                    setCheckErrors((prev: any) => ({ ...prev, details: true }));
                  }
                  if (jobInfo?.title?.length < 8 || !jobInfo?.title) {
                    inputRefTitle.current?.focus();

                    setCheckErrors((prev: any) => ({ ...prev, title: true }));
                  }
                  scrollToSection(topRef);
                }
              }}
            >
              {t("employer.draft_edit.post_job")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
