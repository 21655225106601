import React from "react";
import { useTranslation } from "react-i18next";
import { cn } from "src/lib/utils";

interface CreditsPackCardProps {
  recharge: {
    price: string;
    quantity: string;
    popular?: boolean;
  };
  checkedPack: any;
  setCheckedPack: any;
}

const CreditsPackCard = ({
  recharge,
  checkedPack,
  setCheckedPack,
}: CreditsPackCardProps) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={() => setCheckedPack(recharge)}
      className={cn(
        "hover:border cursor-pointer transition duration-200 ease-in-out flex items-center rounded px-2 py-3 border bg-white dark:bg-background dark:border-border",
        recharge.popular && "border-2 border-azulBonico border -xl",
        checkedPack.price === recharge.price && "border bg-[#f8f8f8]"
      )}
      key={recharge.price}
    >
      <input
        checked={checkedPack?.quantity === recharge.quantity}
        type="checkbox"
        onChange={() => setCheckedPack(recharge)}
        className="rounded-full"
      />
      <div className="flex flex-col items-start ml-4">
        <h1 className=" font-semibold text-sm ">
          {recharge.quantity}{" "}
          {t("employer.applicants.view_jobpost.modal.clone.credits")}
        </h1>
        <h3 className="  font-semibold text-xs text-gray-500">
          {recharge.price}€
        </h3>
      </div>
    </div>
  );
};

export default CreditsPackCard;
