import React, { SetStateAction } from "react";

import { counterSaved, savedSearchesData } from "../../../Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import JobCards from "../../../Components/WorkerDashboard/JobCards/JobCards";
import { OffersTypes } from "../../../types/types";
import CustomSkillCarousel from "../../../Components/WorkerDashboard/CustomSkillCarousel";

interface SearchesDashboardWorkerProps {
  showLoader: boolean;
  setSearchSelected: React.Dispatch<SetStateAction<string>>;
  searchSelected: string;
  setParamsToSend: any;
  updateSaved: boolean;
  setUpdateSaved: React.Dispatch<SetStateAction<boolean>>;
  updateApplied: boolean;
  setUpdateApplied?: React.Dispatch<SetStateAction<boolean>>;
  setOffers: React.Dispatch<React.SetStateAction<any>>;
  offers: OffersTypes[];
}

function SearchesDashboardWorker({
  showLoader,
  offers,
  searchSelected,
  setOffers,
  setParamsToSend,
  setSearchSelected,
  setUpdateApplied,
  setUpdateSaved,
  updateApplied,
  updateSaved,
}: SearchesDashboardWorkerProps) {
  const [savedSearchedJobsData, setSavedSearchedJobsData] =
    useAtom(savedSearchesData);
  const [savedJobsCounter, setSavedJobsCounter] = useAtom(counterSaved);

  return (
    <>
      {/* {showLoader === true ? (
        <div className="relative">
          <Suspense fallback={null}>
            <Loader />
          </Suspense>
        </div>
      ) : ( */}
      <>
        {savedSearchedJobsData?.length > 0 && (
          <>
            <div className="border-b border-blue-100  relative">
              <div className="py-2 text-black overflow-visible z-10 justify-center w-full px-5">
                <CustomSkillCarousel>
                  {savedSearchedJobsData?.map(
                    (item: { search_name: string; params_json: string }) => (
                      <div
                        key={item.search_name}
                        className={` ${
                          searchSelected === item.search_name
                            ? "bg-[#556075] hover:bg-[#292e38]"
                            : "bg-[#8a9cbda9] hover:bg-[#556075]"
                        }  px-12 text-xs py-0.5 flex whitespace-nowrap mx-1 max-w-[700px]  my-1 border text-center items-center align-center justify-center rounded-xl cursor-pointer text-white `}
                        onClick={() => {
                          setSearchSelected(item.search_name);
                          setParamsToSend(JSON.parse(item.params_json));
                        }}
                      >
                        {item.search_name}
                      </div>
                    )
                  )}
                </CustomSkillCarousel>
              </div>
            </div>
            <JobCards
              loading={false}
              refetchSavedJobs={() => console.log("refetch")}
              // filteredList={undefined}
              // searchedJobs={""}
              updateSaved={updateSaved}
              setUpdateSaved={setUpdateSaved}
              updateApplied={updateApplied}
              setUpdateApplied={setUpdateApplied}
              offers={offers}
              setOffers={setOffers}
              savedJobsCounter={savedJobsCounter?.toString()}
              setSavedJobsCounter={setSavedJobsCounter}
            />
          </>
        )}
      </>
      {/* )} */}
    </>
  );
}

export default SearchesDashboardWorker;
