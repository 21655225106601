import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { BASE_URL } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";

import { ReactComponent as ArrowUp } from "../../assets/img/arrow-up-solid.svg";
import Modal from "react-modal";
import ScheduleDays from "../Time/ScheduleDays";
import { StylesModal } from "../../Utils/stylesModal";
import { userProfileStore } from "../../Routing/useProfileStore";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";

import { useTranslation } from "react-i18next";
import { HourDaysTypes, HoursJobTypes } from "../../types/types";
import { toast } from "react-hot-toast";
import { Button } from "../ui/Button";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

interface ModalEditScheduleProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  allChosenTimes: HoursJobTypes[];
  setAllChosenTimes: React.Dispatch<React.SetStateAction<HoursJobTypes[]>>;
  draftId?: string;
}
const ModalEditSchedule = ({
  showModal,
  setShowModal,
  allChosenTimes,
  setAllChosenTimes,
  draftId,
}: ModalEditScheduleProps) => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [showOpen, setShowOpen] = useState<number | null>(null);
  const [update, setUpdate] = useState(false);
  const [updateTimes, setUpdateTimes] = useState(false);
  const [dayTimes, setDayTimes] = useState<any>();
  const [selectedDays, setSelectedDays] = useState<Array<number>>([]);
  const [shortenedDay, setShortenedDay] = useState("");
  // const [profileType, setProfileType] = useLocalStorage("profileType", "");
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [showSchedule, setShowSchedule] = useState(true);
  const [iClicked, setIClicked] = useState<number | null>();
  const [transition, setTransition] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    console.log("profileType", profileType);
  }, [profileType]);

  const data = new FormData();
  data.append("_method", "POST");
  data.append("hour_from", "00:00");
  data.append("hour_to", "23:59");

  const getTimes = async () => {
    const response = await fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile?${
        profileType === "worker" ? `client_id=${userID}` : `job_id=${draftId}`
      }`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setAllChosenTimes(data.result);
      })
      .catch((err) => console.log(err));
  };

  const sendTime = (day: string) => {
    if (profileType === "worker") {
      data.append("client_id", userID);
    } else {
      data.append("job_id", draftId!);
    }

    data.append("dayname", day);
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: data,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setUpdate(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteAllTimes = (day: string) => {
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile?${
        profileType === "worker" ? `client_id=${userID}` : `job_id=${draftId}`
      }&dayname=${day?.slice(0, 3)}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setUpdate(true);
        }
      })
      .catch((err) => console.log(err));
  };
  const getTimesPerDay = async (day: string, i: number) => {
    const response = await fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile?${
        profileType === "worker" ? `client_id=${userID}` : `job_id=${draftId}`
      }&dayname=${day}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setDayTimes(data.result);
          setShowOpen(i);

          if (data.result.length === 0) {
            handleRemoveCheck(day);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleRemoveCheck = (day: string) => {
    if (day.toUpperCase().includes("MON")) {
      setSelectedDays(selectedDays.filter((item) => item !== 0));
    } else if (day.toUpperCase().includes("TUE")) {
      setSelectedDays(selectedDays.filter((item) => item !== 1));
    } else if (day.toUpperCase().includes("WED")) {
      setSelectedDays(selectedDays.filter((item) => item !== 2));
    } else if (day.toUpperCase().includes("THU")) {
      setSelectedDays(selectedDays.filter((item) => item !== 3));
    } else if (day.toUpperCase().includes("FRI")) {
      setSelectedDays(selectedDays.filter((item) => item !== 4));
    } else if (day.toUpperCase().includes("SAT")) {
      setSelectedDays(selectedDays.filter((item) => item !== 5));
    } else if (day.toUpperCase().includes("SUN")) {
      setSelectedDays(selectedDays.filter((item) => item !== 6));
    }
  };

  useEffect(() => {
    if (allChosenTimes) {
      const days = allChosenTimes?.map((day) => day.dayname);
      let append: any = [];
      if (days.includes("MON") && !selectedDays.includes(0)) {
        append = [...append, 0];
      }
      if (days.includes("TUE") && !selectedDays.includes(1)) {
        append = [...append, 1];
      }
      if (days.includes("WED") && !selectedDays.includes(2)) {
        append = [...append, 2];
      }
      if (days.includes("THU") && !selectedDays.includes(3)) {
        append = [...append, 3];
      }
      if (days.includes("FRI") && !selectedDays.includes(4)) {
        append = [...append, 4];
      }
      if (days.includes("SAT") && !selectedDays.includes(5)) {
        append = [...append, 5];
      }
      if (days.includes("SUN") && !selectedDays.includes(6)) {
        append = [...append, 6];
      }
      setSelectedDays([...selectedDays, append.flat()].flat());
    }
  }, [allChosenTimes]);

  useEffect(() => {
    if (update) {
      getTimes();
      getTimesPerDay(shortenedDay, iClicked!);
      setUpdate(false);
    }
  }, [update]);

  const handleToggle = (i: number, day: string, dayTimes: HourDaysTypes[]) => {
    if (
      dayTimes[0]?.hour_from === "00:00:00" &&
      dayTimes[0]?.hour_to === "23:59:00"
    ) {
      setSelectedDays(selectedDays.filter((item) => item !== i));
      handleDeleteAllTimes(day);
    } else {
      handleDeleteAllTimes(day);
      setTimeout(() => {
        sendTime(day?.slice(0, 3));
      }, 100);
      setShowOpen(null);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal]);
  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const handleDelete = () => {
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile?${
        profileType === "worker" ? "client_id" : "job_id"
      }=${profileType === "worker" ? userID : draftId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast.success(`${t("modal.time_selection.resetted_all_hours")}`);
          getTimes();
          setShowOpen(null);
        }
      });
  };

  return (
    <Modal isOpen={showModal} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[650px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="flex items-center justify-between px-10">
          <div className="text-[16px] lg:text-xl font-bold border-b border-blue-100 px-8 py-6">
            {t("createprofile.submit.edityourAvailableHours.title.card")}
          </div>
          <Button
            onClick={() => handleDelete()}
            className={`${
              allChosenTimes.length > 0
                ? "cursor-pointer border-gray-200 text-gray-600 hover:text-white hover:bg-red-700"
                : "cursor-not-allowed text-gray-600/40"
            } border px-3 py-[2px] rounded hover:bg-gray-200  text-xs flex items-center  group`}
          >
            {/* <h1
            className=" text-xs  font-semibold  transition"
          > */}
            <Trash
              className={`h-[12px] hidden lg:block  mr-2 fill-gray-600 ${
                allChosenTimes.length > 0 ? "group-hover:fill-white" : ""
              }  transition`}
            />
            <p className="text-sm text-[#333]">
              {t("welcome-client.rightcard.resetallBtn")}
            </p>
            {/* </h1> */}
          </Button>
        </div>

        <div className="rounded-[10px] h-full bg-white w-full px-8 mx-auto my-4  relative">
          <div className=" mx-auto top-8 bottom-0 left-0 right-0 px-1 h-fit overflow-y-auto max-h-[480px] jobpost">
            {days?.map((day, i, arr) => {
              return (
                <div
                  key={day}
                  onClick={() => setShowOpen(i)}
                  className={`flex flex-col ${
                    arr[i] === day &&
                    showOpen !== i &&
                    allChosenTimes?.length > 0 &&
                    !allChosenTimes
                      ?.map((time) =>
                        time.dayname.includes(day?.slice(0, 3).toUpperCase())
                      )
                      .includes(true) &&
                    !selectedDays.includes(i) &&
                    "hover:bg-[#fdfbfb] hover:text-black text-slate-300 hover:border-slate-300 transition duration-15  "
                  } ${
                    (allChosenTimes
                      ?.map((time) =>
                        time.dayname.includes(day?.slice(0, 3).toUpperCase())
                      )
                      .includes(true) ||
                      selectedDays.includes(i)) &&
                    " text-black"
                  } ${
                    (showOpen === i || selectedDays.includes(i)) &&
                    "text-black  border "
                  } border rounded-lg pr-5 pl-3 py-2 mb-3 `}
                >
                  <div
                    className={`flex justify-between items-center cursor-pointer`}
                    onClick={() => {
                      setIClicked(i);

                      if (showOpen === i) {
                        setShowOpen(null);
                        setShortenedDay("");
                      } else {
                        getTimesPerDay(day?.slice(0, 3), i);
                        setShortenedDay(day?.slice(0, 3));
                      }
                    }}
                  >
                    <div className="flex items-center">
                      <div
                        // onClick={(e) => e.stopPropagation()}
                        className="flex items-center"
                      >
                        <div
                          className="flex items-center"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleToggle(i, day, dayTimes);
                          }}
                        >
                          <ToggleSwitch
                            handleToggle={handleToggle}
                            isOn={selectedDays?.includes(i)}
                            onColor="#2abeeb"
                          />
                        </div>
                        <p
                          className={`font-medium tracking-wider mr-4 pl-3 w-24`}
                        >
                          {/* {day} */}

                          {day === "Monday" &&
                            t("welcome-client.rightcard.dayweek.monday")}
                          {day === "Tuesday" &&
                            t("welcome-client.rightcard.dayweek.tuesday")}
                          {day === "Wednesday" &&
                            t("welcome-client.rightcard.dayweek.wednesday")}
                          {day === "Thursday" &&
                            t("welcome-client.rightcard.dayweek.thursday")}
                          {day === "Friday" &&
                            t("welcome-client.rightcard.dayweek.friday")}
                          {day === "Saturday" &&
                            t("welcome-client.rightcard.dayweek.saturday")}
                          {day === "Sunday" &&
                            t("welcome-client.rightcard.dayweek.sunday")}
                        </p>
                      </div>
                      {allChosenTimes?.length > 0 && (
                        <div className="flex flex-wrap whitespace-nowrap gap-3">
                          {allChosenTimes
                            .sort((a, b) =>
                              a.hour_from.localeCompare(b.hour_from)
                            )
                            ?.map((times) => {
                              if (
                                times.dayname === day?.slice(0, 3).toUpperCase()
                              ) {
                                return (
                                  <div
                                    key={times.job_time_profile_id}
                                    className="flex text-sm text-black "
                                    // key={times}
                                  >
                                    <div className="bg-[#f8f8f8] border flex items-center  px-2 rounded">
                                      <p className="mr-1">
                                        {times.hour_from?.slice(0, 5)}{" "}
                                      </p>
                                      <p> - </p>
                                      <p className="ml-1">
                                        {" "}
                                        {times.hour_to?.slice(0, 5)}
                                      </p>
                                    </div>
                                    <p
                                      className="ml-[5px] -mr-1
                                    
                                    "
                                    >
                                      {" "}
                                      •
                                    </p>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      )}
                    </div>
                    {!allChosenTimes
                      ?.map((time) =>
                        time.dayname.includes(day?.slice(0, 3).toUpperCase())
                      )
                      .includes(true) ? (
                      <>
                        {/* {showOpen === i ? (
                          <ArrowUp className="h-3 fill-black"></ArrowUp>
                        ) : (
                          <ArrowDown className={`h-3 fill-slate-300`} />
                        )} */}
                        <ArrowUp
                          className={`h-3 fill-black transform duration-150 ease-in-out transition-all ${
                            showOpen === i
                              ? "rotate-180"
                              : "rotate-0 fill-stone-300"
                          } `}
                        ></ArrowUp>
                      </>
                    ) : (
                      <>
                        {/* {showOpen === i ? (
                            <ArrowUp className="h-3 fill-black"></ArrowUp>
                          ) : (
                            <ArrowDown className={`h-3 fill-black`} />
                          )} */}
                        <ArrowUp
                          className={`h-3 fill-black transform duration-150 ease-in-out transition-all ${
                            showOpen === i ? "rotate-180" : "rotate-0"
                          } `}
                        ></ArrowUp>
                      </>
                    )}
                  </div>
                  {showOpen === i && (
                    <div>
                      <ScheduleDays
                        handleToggle={handleToggle}
                        day={day}
                        setUpdate={setUpdate}
                        getTimesPerDay={getTimesPerDay}
                        dayTimes={dayTimes!}
                        i={i}
                        refetchTimes={getTimes}
                        setSelectedDays={setSelectedDays}
                        selectedDays={selectedDays}
                        shortenedDay={shortenedDay}
                        draftId={draftId}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro cursor-pointer mt-1 transition duration-300"
            onClick={() => {
              // handleNoMoreLocal();
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourskills.leftBtn")}
          </button>
          <Button
            className="bg-azulBonico border-none  hover:bg-azulOscuro text-white w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10"
            onClick={() => closeModal()}
          >
            {t("createprofile.submit.edityourskills.rightBtn")}
            {/* {t("createprofile.submit.edityourAvailableHoursBtn")} */}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEditSchedule;
