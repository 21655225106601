import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";

import useLocalStorage from "../../Hooks/UseLocalStorage";

import { BASE_URL, BASE_URL_IMG } from "../../Config/api.config";
import ModalCloseAccount from "../Modals/ModalCloseAccount";
import { ReactComponent as Company } from "../../assets/img/company.svg";
import { ReactComponent as Plus } from "../../assets/img/plus.svg";
import edit from "../../assets/img/edit.png";

import "react-phone-input-2/lib/style.css";
import "../../index.css";
import {
  showEditableCompany,
} from "../../Atoms/jotaiAtoms";
import { useAtom } from "jotai";
import ModalAddProfilePhoto from "../Modals/ModalAddProfilePhoto";
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import LoadingSpinner from "src/Pages/General/Home/LoadingSpinner";
import { cn } from "src/lib/utils";
import { useQuery } from "react-query";
import toast from "react-hot-toast";
import { userProfileStore } from "src/Routing/useProfileStore";

interface ContactInfoProps {
  decorationBar?: string;
  setDecorationBar: Dispatch<SetStateAction<string>>;
}

export default function ContactInfo({
  decorationBar,
  setDecorationBar,
}: ContactInfoProps) {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [userEmail, setUserEmail] = useLocalStorage("userEmail", "");
  const [showEditInfo, setShowEditInfo] = useState(false);
  const [showInfoFilled, setShowInfoFilled] = useState(true);
  const [showLocationInfo, setShowLocationInfo] = useState(true);
  const [showLocationEdition, setShowLocationEdition] = useState(false);
  const [showEditCompany, setShowEditCompany] = useAtom(showEditableCompany);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [isHovering, setIsHovering] = useState(false);
  const [countries, setCountries] = useLocalStorage("AllCountries", []);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [profileData, setProfileData] = useState<any>();
  const [phone, setPhone] = useState();
  const [prefix, setPrefix] = useState();
  const [rerender, setRerender] = useState(false);
  const [citiesList, setCitiesList] = useState<any>();
  const [selectedPostcodesList, setSelectedPostcodesList] = useState([]);
  const [notCodesFound, setNotCodesFound] = useState(false);
  const [selectedCitiesList, setSelectedCitiesList] = useState<any>([]);
  const [postcodesList, setPostcodesList] = useState<any>();
  const [originalData, setOriginalData] = useState<any>();
  const [notFound, setNotFound] = useState(false);
  const profileType: any = userProfileStore((s) => s.profileTypeToken);

  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const [editData, setEditData] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [preview, setPreview] = useState(null);
  const [companyLogoPreview, setCompanyLogoPreview] = useState(
    userInfo?.company_logo
  );
  const [refresh, setRefresh] = useState(false);
  const [companyLogoFile, setCompanyLogoFile] = useState(null);
  const [showAddCompanyLogo, setShowAddCompanyLogo] = useState(false);
  const [errorCif, setErrorCif] = useState("valid");
  const [errorPhone, setErrorPhone] = useState("");
  const [originalCompanyData, setOriginalCompanyData] = useState({
    fullPhoneNumber: userInfo?.company_phone,
    company_id: userInfo?.company_id,
    name: userInfo?.company_name,
    cif: userInfo?.company_cif,
    city: userInfo?.company_city,
    description: userInfo?.company_description,
    prefix_phone: userInfo?.company_prefix,
    phone: userInfo?.company_phone,
    country_code: userInfo?.company_country_code,
    city_id: userInfo?.company_city_id,
    // logo: userInfo?.company_logo,
  });
  const [isBeingValidated, setIsBeingValidated] = useState(false);
  const [loadingEmailValidation, setLoadingEmailValidation] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");

  const { t, i18n } = useTranslation();

  const companyCifRef = useRef(null);

  useEffect(() => {
    setDecorationBar("info");
  }, []);

  useEffect(() => {
    if (!showEditCompany) {
      setIsBeingValidated(false);
    }
  }, [showEditCompany]);

  useEffect(() => {
    setOriginalData({
      city_id: userInfo?.city_id,
      city_name: userInfo?.city_name,
      country_code: userInfo?.country_code,
      post_code_id: userInfo?.post_code_id,
      post_code_name: userInfo?.post_code_name,
    });
  }, [userInfo]);

  const getProfileData = async () => {
    const data = await fetch(`${BASE_URL}worker.profile?client_id=${userID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err));

    return data.result;
  };

  const { data: workerInfo, refetch: refetchWorkerInfo } = useQuery(
    ["workerInfo"],
    getProfileData
  );

  useEffect(() => {
    setProfileData(workerInfo?.workerProfile);
  }, [workerInfo]);

  const infoData = new FormData();
  const updateUserInfo = () => {
    fetch(`${BASE_URL}workers`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: infoData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast.success(`${t("profile.user_update.response_success")}`);
        }
      })

      .catch((err) => console.log(err));
  };

  const data = new FormData();
  const saveDetails = () => {
    fetch(`${BASE_URL}worker.profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          getProfileData();
        }
      })
      .catch((err) => console.log(err));
  };

  // const { employerData, refetchEmployer } = useClientData();
  // useEffect(() => {
  //   if(profileType !=="worker"){

  //     setUserInfo(employerData);
  //   }else{
  //     setUserInfo
  //   }
  // }, [employerData]);
  // const catchClientData = () => {
  //   fetch(`${BASE_URL}employers?client_id=${userID}`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${loggedInUserUid}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setUserInfo(data.result[0]);
  //     })
  //     .catch((err) => console.log(err));
  // };

  const companyData = new FormData();

  const handleUpdateCompanyInfo = () => {
    companyData.append("_method", "PUT");
    companyData.append("company_id", userInfo.company_id);

    if (userInfo.company_name !== originalCompanyData.name) {
      companyData.append("company_name", userInfo.company_name);
    }
    if (userInfo.company_description !== originalCompanyData.description) {
      companyData.append("description", userInfo.company_description);
    }

    if (userInfo.company_phone !== originalCompanyData.phone) {
      companyData.append("prefix_phone", "+34");
      companyData.append("phone", userInfo.company_phone);
    }
    if (userInfo.company_cif !== originalCompanyData.cif) {
      companyData.append("cif", userInfo.company_cif);
    }
    if (userInfo.company_city_id !== originalCompanyData.city_id) {
      companyData.append("city_id", userInfo.company_city_id);
    }
    // companyData.append("company_logo", null);
    companyData.append("company_logo", companyLogoFile!);
    // companyData.append("country_code", updateCompanyData.country_code);
    fetch(`${BASE_URL}company`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: companyData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          // catchClientData();
          // setShowEditCompany(false);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const handleAccountDetails = () => {
    setShowEditInfo(true);
    setShowInfoFilled(false);
  };

  const displayAccountInfo = () => {
    setShowEditInfo(false);
    setShowInfoFilled(true);
  };

  const displayLocationInfo = () => {
    setShowLocationInfo(true);
    setShowLocationEdition(false);
  };

  const handleUserInfoUpdate = () => {
    infoData.append("_method", "PUT");
    infoData.append("client_id", userID);
    infoData.append("first_name", userInfo?.first_name);
    infoData.append("last_name", userInfo?.last_name);
    displayAccountInfo();
    updateUserInfo();
  };

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      transition: "300ms",
      display: "flex",
      // width: "max-width",
      padding: "0rem",
      borderRadius: "5px",
    }),
  };

  const getAllCities = (props: any) => {
    if (props?.length >= 1) {
      fetch(`${BASE_URL}cities?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCitiesList(data.result);
          if (data.result.length === 0) {
            setNotFound(true);
          } else {
            setNotFound(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const removeImage = () => {
    setImageUrl(null);
    setPreview(null);
  };

  useEffect(() => {
    const append = citiesList?.map(function (row: {
      name: string;
      id: string;
    }) {
      return {
        label: row.name,
        value: row.name,
        id: row.id,
      };
    });
    setSelectedCitiesList(append);
  }, [citiesList]);

  const getPostcodesFromCountry = (props: any) => {
    if (props?.length > 1) {
      fetch(`${BASE_URL}postcodes?country_code=ES&name=${props}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setPostcodesList(data.result);
          if (data.result.length === 0) {
            setNotCodesFound(true);
          } else {
            setNotCodesFound(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const append = postcodesList?.map(function (row: any) {
      return {
        label: row.locality + "," + row.state + "," + row.postcode,
        value: row.locality + "," + row.state + "," + row.postcode,
        id: row.id,
      };
    });
    setSelectedPostcodesList(append);
  }, [postcodesList]);

  const handleOnChange = (value: any) => {
    setUserInfo((prev: any) => ({ ...prev, company_phone: value }));
  };

  //validate CIF

  const validateApiDataCif = new FormData();
  validateApiDataCif.append("_method", "POST");

  validateApiDataCif.append("cif", userInfo?.company_cif);
  const validateCIFapi = (cif: string) => {
    setIsBeingValidated(true);
    if (companyCifRef.current === cif) return;
    validateApiDataCif.set("cif", cif);
    fetch(`${BASE_URL}company`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: validateApiDataCif,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.error_id === "401") {
          setErrorCif(data.result.error_msg);
        }
        if (data.status === "ok") {
          setErrorCif(data.result);
          // setValidCif(cif);
        }
      })
      .catch((err) => console.log(err));
    // .finally(() => setLoadingCifValidation(false));
  };

  //validate Phone
  const validateApiDataPhone = new FormData();
  validateApiDataPhone.append("_method", "POST");
  validateApiDataPhone.append("phone", phone!);

  const validatePhoneApi = (phone: any) => {
    validateApiDataPhone.set("phone", phone.split(" "));
    fetch(`${BASE_URL}company`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: validateApiDataPhone,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.error_id === "401") {
          setErrorPhone(data.result.error_msg);
        }
        if (data.status === "ok") {
          setErrorPhone(data.result);
        }
      })
      .catch((err) => console.log(err));
  };

  //validate Email
  const validateApiDataEmail = new FormData();
  validateApiDataEmail.set("_method", "POST");
  // validateApiDataEmail.set("email", form)
  const validateApiEmail = (email: string) => {
    setLoadingEmailValidation(true);
    validateApiDataEmail.set("email", email);
    fetch(`${BASE_URL}company`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: validateApiDataEmail,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.error_id === "401") {
          setErrorEmail(data.result.error_msg);
        }
        // if (data.status === "ok") {
        //   setErrorEmail(data.result);
        // }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoadingEmailValidation(false));
  };

  return (
    <div className=" mt-4 lg:mt-0 w-full px-4">
      <div className="bg-white relative rounded-lg border dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className=" flex flex-col ">
          <div className="flex justify-between items-center  mb-6 px-8 py-6 border-b border-blue-100">
            <p className="text-xl font-semibold">
              {t("settings.account.title")}
            </p>
            <img
              src={edit}
              alt="edit"
              className={`${
                showEditInfo === false ? "h-4 cursor-pointer" : "hidden"
              }`}
              onClick={() => handleAccountDetails()}
            ></img>
          </div>
          <div className={`${showInfoFilled === true ? "" : "hidden"}`}>
            <div className="flex flex-col px-8 pb-8">
              {/* <div className="mb-4">
                <p className="text-sm font-semibold mb-2">
                  {t("settings.contact_info.user_id")}
                </p>
                <p className="text-sm">{userID}</p>
              </div> */}
              <div className="mb-4">
                <p className="text-sm font-semibold mb-2">
                  {t("settings.contact_info.name")}
                </p>
                <p className="text-sm">
                  {userInfo?.first_name} {userInfo?.last_name}
                </p>
              </div>
              <div className="mb-12">
                <p className="text-sm font-semibold mb-2">
                  {t("settings.contact_info.email")}
                </p>
                <p className="text-sm">
                  {userInfo?.email?.replace(
                    /(\w{3})[\w.-]+@([\w.]+\w)/,
                    "$1***@$2"
                  )}
                </p>
              </div>
            </div>
            {/* <p
              className="text-azulBonico text-sm font-semibold tracking-wide absolute right-11 bottom-4 m-4 cursor-pointer hover:text-azulOscuro hover:underline"
              onClick={() => navigate("/settings/close-account")}
            >
              {t("settings.contact_info.close_account")}
            </p> */}
          </div>
          <div
            className={`${
              showEditInfo === true ? "flex flex-col px-8  mb-6" : "hidden"
            }`}
          >
            <div className="flex flex-col gap-3">
              <p className="text-sm font-semibold">
                {t("settings.contact_info.first_name")}
              </p>
              <input
                type="text"
                value={userInfo?.first_name}
                onChange={(e) =>
                  setUserInfo((info: any) => ({
                    ...info,
                    first_name: e.target.value,
                  }))
                }
                className="w-full lg:w-2/3 border dark:bg-input dark:border-border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition "
              ></input>
              <p className="text-sm font-semibold">
                {t("settings.contact_info.last_name")}
              </p>
              <input
                type="text"
                value={userInfo?.last_name}
                onChange={(e) =>
                  setUserInfo((info: any) => ({
                    ...info,
                    last_name: e.target.value,
                  }))
                }
                className="w-full lg:w-2/3 dark:bg-input dark:border-borderh-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition "
              ></input>
              <p className="text-sm font-semibold">
                {t("settings.contact_info.email")}
              </p>
              <input
                type="text"
                value={userInfo?.email}
                onChange={(e) =>
                  setUserInfo((info: any) => ({
                    ...info,
                    email: e.target.value,
                  }))
                }
                onBlur={(e) => {
                  if (e.target.value !== "" && e.target.value !== " ") {
                    validateApiEmail(e.target.value);
                  }
                }}
                className="w-full lg:w-2/3 border dark:bg-input dark:border-border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition "
              />
              {errorEmail !== "" && (
                <div className=" flex items-center ">
                  {loadingEmailValidation && <LoadingSpinner size={14} />}
                  {!loadingEmailValidation && errorEmail !== "" && (
                    <span
                      className={`${
                        errorEmail === "valid"
                          ? "text-green-500"
                          : "text-red-500"
                      } text-xs font-semibold flex items-center gap-1`}
                    >
                      {errorEmail === "valid" ? (
                        <AiOutlineCheck />
                      ) : (
                        <BiErrorCircle />
                      )}
                      {errorEmail.charAt(0).toUpperCase() +
                        errorEmail?.slice(1)}
                    </span>
                  )}
                </div>
              )}
              <div className="flex w-full items-end justify-end mt-4 gap-3 text-sm font-[600]">
                <button
                  className="px-7 py-2 text-azulBonico tracking-wide  border border-blue-100 bg-white rounded-full transition "
                  onClick={() => displayAccountInfo()}
                >
                  {t("settings.contact_info.cancel")}
                </button>
                <button
                  disabled={errorEmail !== "valid"}
                  className={cn(
                    `px-7 py-2 text-white tracking-wide bg-azulBonico cursor-pointer transition hover:text-white hover:bg-azulOscuro rounded-full transition"`,
                    errorEmail !== "valid" && "bg-gray-200 text-[#333]"
                  )}
                  onClick={() => handleUserInfoUpdate()}
                >
                  {t("settings.contact_info.update")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white relative rounded-lg border my-6 ">
        {/* {profileType === "worker" && (
          <div className=" flex flex-col ">
            <div className="flex justify-between items-center  mb-6 px-8 py-6 border-b border-blue-100">
              <p className="text-xl font-semiboldold">
                {t("settings.contact_info.location")}
              </p>
              <img
                src={edit}
                alt="edit"
                className={`h-4 cursor-pointer ${
                  showLocationEdition === true && "hidden"
                } `}
                onClick={() => handleLocationInfo()}
              ></img>
            </div>
            {showLocationInfo === true ? (
              <div className="flex flex-col px-8 pb-8">
                <div className="mb-4">
                  <p className="text-sm font-semibold mb-2">
                    {t("settings.contact_info.address")}
                  </p>
                  <p className="text-sm">{userInfo?.address_street}</p>
                  <p className="text-sm">
                    {userInfo?.city_name}, {userInfo?.post_code_name}
                  </p>
                  <p className="text-sm">{userInfo?.country_code_name}</p>
                </div>
              </div>
            ) : (
              <>
                <div className="flex flex-col px-8 pb-8">
                  <div className="flex gap-6 mt-6">
                    <div className="flex flex-col flex-1">
                      <label className="relative">
                        <p className="text-sm font-semibold tracking-wide mb-3">
                          {t("settings.contact_info.city")}
                        </p>
                        <Select
                          onInputChange={(e) => getAllCities(e)}
                          options={selectedCitiesList}
                          className="mt-2 text-sm"
                          styles={colourStyles}
                          placeholder="Search Cities..."
                          value={{
                            label: userInfo?.city_name,
                            value: userInfo?.city_name,
                          }}
                          onChange={(e) =>
                            setUserInfo((info) => ({
                              ...info,
                              city_name: e.value,
                              city_id: e.id,
                            }))
                          }
                          noOptionsMessage={() =>
                            notFound === true
                              ? "No results found"
                              : "Please, type for search"
                          }
                        />
                      </label>
                    </div>
                    <div className="flex flex-col flex-1">
                      <label className="relative">
                        <p className="text-sm font-semibold tracking-wide mb-3">
                          {t("settings.contact_info_post_code")}
                        </p>
                        <Select
                          onInputChange={(e) => getPostcodesFromCountry(e)}
                          options={selectedPostcodesList}
                          className="mt-2 text-sm"
                          styles={colourStyles}
                          placeholder="Search Postcodes..."
                          value={{
                            label: userInfo?.post_code_name,
                            value: userInfo?.post_code_name,
                          }}
                          onChange={(e) =>
                            setUserInfo((info) => ({
                              ...info,
                              post_code_name: e.value,
                              post_code_id: e.id,
                            }))
                          }
                          noOptionsMessage={() =>
                            notCodesFound === true
                              ? "No results found"
                              : "Please, type for search"
                          }
                        />
                      </label>
                    </div>
                  </div>
                  <p className="text-sm font-semibold tracking-wide mb-3 mt-6">
                    {t("settings.contact_info.street_address")}
                  </p>
                  <input
                    value={userInfo?.address_street}
                    onChange={(e) =>
                      setUserInfo((info) => ({
                        ...info,
                        address_street: e.target.value,
                      }))
                    }
                    type="text"
                    className="w-full border h-10 pl-4 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
                  ></input>
                </div>
                <div className="flex gap-3 text-sm font-[600] px-8 pb-6">
                  <button
                    className="px-7 py-2 text-white tracking-wide bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                    onClick={() => handleDetailsUpdate()}
                  >
                    {t("settings.contact_info.update")}
                  </button>
                  <button
                    className="px-7 py-2 text-azulBonico tracking-wide hover:bg-[#f7f4f4] border border-blue-100 bg-white rounded-full transition duration-300"
                    onClick={() => {
                      recoverValues("worker");
                      displayLocationInfo();
                    }}
                  >
                    {t("settings.contact_info.cancel")}
                  </button>
                </div>
              </>
            )}
          </div>
        )} */}
        {profileType === "employer" && userInfo?.company_id && (
          <div className="flex flex-col">
            <div className="flex justify-between items-center  mb-6 px-8 py-6 border-b border-blue-100 ">
              <p className="text-xl font-semibold">
                {t("settings.contact_info.company_info.title")}
              </p>
              <img
                src={edit}
                alt="edit"
                className={`${
                  !showEditCompany ? "h-4 cursor-pointer" : "hidden"
                }`}
                onClick={() => setShowEditCompany(true)}
              ></img>
            </div>
            <div className={`px-8 pb-8 ${showEditCompany && "hidden"}`}>
              <div className="flex flex-col">
                <div className="flex">
                  <div className="mb-4 basis-1/2">
                    <p className="text-sm font-semibold mb-2">
                      {t("settings.contact_info.company_info.name")}
                    </p>
                    <p className="text-sm">{userInfo.company_name}</p>
                  </div>
                  <div className="mb-4 basis-1/2">
                    <p className="text-sm font-semibold mb-2">
                      {t("settings.contact_info.company_info.company_id")}
                    </p>
                    <p className="text-sm">{userInfo.company_id}</p>
                  </div>
                </div>
                <div className="flex">
                  <div className="mb-4 basis-1/2">
                    <p className="text-sm font-semibold mb-2">
                      {t("settings.contact_info.company_info.phone")}
                    </p>
                    <p className="text-sm">{userInfo.company_phone}</p>
                  </div>
                  <div className="mb-4 basis-1/2">
                    <p className="text-sm font-semibold mb-2">
                      {t("settings.contact_info.company_info.city")}
                    </p>
                    <p className="text-sm">{userInfo.company_city_name}</p>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-sm font-semibold mb-2">
                    {t("settings.contact_info.company_info.company_logo")}
                  </p>
                  {userInfo.company_logo ? (
                    <div
                      onClick={removeImage}
                      className="flex flex-col items-end filter hover:brightness-110 transition duration-150 transform hover:scale-105 cursor-pointer  p-4 animate__animated animate__slideInDown "
                    >
                      <img
                        src={`${BASE_URL_IMG}${userInfo.company_logo}`}
                        className="h-32 w-32 object-cover rounded-full "
                        alt="profile"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                        }}
                      />
                    </div>
                  ) : (
                    <p className="text-sm mb-4">
                      {t("settings.contact_info.company_info.not_specified")}
                    </p>
                  )}
                </div>
                <div className="w-full">
                  <p className="text-sm font-semibold mb-2">
                    {t("settings.contact_info.company_info.description")}
                  </p>
                  <p className="text-sm">{userInfo.company_description}</p>
                </div>
              </div>
            </div>
            <div
              className={`${
                showEditCompany ? "flex flex-col px-8  mb-6" : "hidden"
              }`}
            >
              <div className="flex flex-col gap-3">
                <div className="flex gap-6">
                  <div className="flex flex-col basis-1/2 gap-2">
                    <p className="text-sm font-semibold">
                      {t("settings.contact_info.company_info.company_name")}
                    </p>
                    <input
                      autoFocus
                      type="text"
                      value={userInfo.company_name}
                      onChange={(e) =>
                        setUserInfo((prev: any) => ({
                          ...prev,
                          company_name: e.target.value,
                        }))
                      }
                      // onChange={(e) =>
                      //   setUpdateCompanyData((prev) => ({
                      //     ...prev,
                      //     name: e.target.value,
                      //   }))
                      // }
                      className="w-full border h-10 pl-4 outline-none border-[#E2E2E2] text-sm rounded hover:border-azulBonico focus:border-azulBonico transition "
                    ></input>
                  </div>
                  <div className="flex flex-col basis-1/2 gap-2">
                    <p className="text-sm font-semibold">
                      {t("settings.contact_info.company_info.company_cif")}
                    </p>
                    <input
                      ref={companyCifRef}
                      type="text"
                      defaultValue={userInfo?.company_cif}
                      onBlur={(e) => validateCIFapi(e.target.value)}
                      onChange={(e) =>
                        setUserInfo((prev: any) => ({
                          ...prev,
                          company_cif: e.target.value,
                        }))
                      }
                      className={`w-full border h-10 pl-4 ${
                        errorCif !== ""
                          ? errorCif === "valid"
                            ? ""
                            : "!border-red-500"
                          : ""
                      } outline-none text-sm rounded border-[#E2E2E2] hover:border-azulBonico focus:border-azulBonico transition duration-300`}
                    ></input>
                    {isBeingValidated && (
                      <div
                        className={`text-sm flex items-center gap-1 justify-end text-right ${
                          errorCif === "valid"
                            ? "text-green-500"
                            : "text-red-500"
                        }`}
                      >
                        {errorCif === "valid" ? (
                          <AiOutlineCheck />
                        ) : (
                          <BiErrorCircle />
                        )}
                        {errorCif === "valid" ? "Valid" : errorCif}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex gap-6 my-4">
                  <div className="flex flex-col basis-1/2 gap-2">
                    <p className="text-sm font-semibold">
                      {t("settings.contact_info.company_info.phone")}
                    </p>
                    <PhoneInput
                      country="es"
                      // disableDropdown
                      // alwaysDefaultMask={true}
                      // defaultMask="34 ... ... ..."
                      placeholder="658 855 412"
                      disableCountryCode
                      onlyCountries={["es"]}
                      value={userInfo.company_phone}
                      onBlur={(e) => validatePhoneApi(e.target.value)}
                      onChange={handleOnChange}
                      inputStyle={{
                        border: `${
                          errorPhone !== ""
                            ? errorPhone === "valid"
                              ? "1px solid rgb(22 163 74)"
                              : "1px solid rgb(239 68 68)"
                            : ""
                        }`,
                        width: "inherit",
                        height: "37px",
                      }}
                    />
                    <p
                      className={`text-sm ${
                        errorPhone === "valid"
                          ? "text-green-600"
                          : "text-red-500"
                      }`}
                    >
                      {errorPhone === "valid" ? "Valid" : errorPhone}
                    </p>
                  </div>
                  <div className="flex flex-col basis-1/2 gap-2">
                    <p className="text-sm font-semibold">
                      {t("settings.contact_info.company_info.city")}
                    </p>
                    <Select
                      onInputChange={(e) => getAllCities(e)}
                      options={selectedCitiesList}
                      className="text-sm"
                      styles={colourStyles}
                      placeholder="Search Cities..."
                      value={{
                        label: userInfo?.company_city_name,
                        value: userInfo?.company_city_name,
                      }}
                      onChange={(e: any) =>
                        setUserInfo((prev: any) => ({
                          ...prev,
                          company_city_id: e.id,
                          company_city_name: e!.label,
                        }))
                      }
                      noOptionsMessage={() => "Please, type for search"}
                    />
                  </div>
                </div>
                <div>
                  <p className="text-sm font-semibold mb-2">
                    {t("settings.contact_info.company_info.company_logo")}
                  </p>
                  <div className="w-full flex items-center gap-12">
                    {userInfo?.company_logo || companyLogoFile ? (
                      <div
                        onClick={removeImage}
                        className="flex flex-col items-end filter hover:brightness-110 transition duration-150 transform hover:scale-105 cursor-pointer  p-4 animate__animated animate__slideInDown "
                      >
                        {companyLogoFile ? (
                          <img
                            src={URL.createObjectURL(companyLogoFile)}
                            className="h-32 w-32  object-cover -mb-4 rounded-full "
                            alt="profile"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                            }}
                          />
                        ) : (
                          <img
                            src={`${BASE_URL_IMG}${userInfo?.company_logo}`}
                            className="h-32 w-32  object-cover -mb-4 rounded-full "
                            alt="profile"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <Company className="h-9 w-9" />
                    )}
                    <div
                      className=" relative  cursor-pointer top-0 right-0"
                      onClick={() => setShowAddCompanyLogo(true)}
                    >
                      <div className="relative">
                        <Plus
                          // fill={"#2abeeb"}
                          className="h-3 absolute top-[22px] left-[14px] w-auto"
                        />
                        <button className="px-4 text-sm font-semibold w-fit py-1 pl-8  mt-3 hover:bg-[#f7f4f4] border border-blue-100 bg-white  rounded-full transition duration-300">
                          {t("settings.contact_info.company_info.upload_photo")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <p className="text-sm font-semibold">
                    {t(
                      "settings.contact_info.company_info.company_description"
                    )}
                  </p>
                  <textarea
                    className="w-full outline-none h-16 mt-1 border border-[#E2E2E2] rounded px-4 py-2 text-sm hover:border-azulBonico focus:border-azulBonico"
                    value={userInfo.company_description}
                    onChange={(e) =>
                      setUserInfo((prev: any) => ({
                        ...prev,
                        company_description: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex mt-4 gap-3 text-sm font-[600]">
                  <button
                    className="px-7 py-2 text-white tracking-wide bg-azulBonico hover:bg-azulOscuro rounded-full transition duration-300"
                    onClick={() => {
                      if (errorCif === "valid") {
                        handleUpdateCompanyInfo();
                      }
                    }}
                  >
                    {t("settings.contact_info.update")}
                  </button>
                  <button
                    className="px-7 py-2 text-azulBonico tracking-wide  border border-blue-100 bg-white rounded-full transition "
                    onClick={() => {
                      // recoverValues("employer");
                      setCompanyLogoFile(null);
                      // catchClientData();
                      setShowEditCompany(false);
                    }}
                  >
                    {t("settings.contact_info.cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white dark:text-whiteish flex flex-col items-start rounded-lg border gap-3 my-6 px-6 py-6 text-sm dark:bg-background dark:border-border dark:border">
        <p>
          {i18n.language === "en" ? (
            <>
              {profileType?.slice(0, 1).toUpperCase() +
                profileType?.slice(1).toLowerCase()}
              's Account
            </>
          ) : (
            <>
              {/* {t("settings.contact_info.company_info.this_is")} una{" "} */}
              {t("settings.account.title".toLowerCase())}{" "}
              {profileType === "employer"
                ? "de un Empleador"
                : "de un Trabajador"}
              {/* {t("settings.contact_info.company_info.this_is")} {profileType}{" "}
              {t("settings.account.title".toLowerCase())} */}
            </>
          )}
        </p>
        <button
          className="px-9 py-1 border border-slate-300 rounded-full dark:bg-neutral-400/30 hover:bg-[#cecdcd] bg-blancoGris transition duration-150"
          onClick={() => setShowCloseAccountModal(true)}
        >
          {t("settings.contact_info.close_account")}
        </button>
      </div>

      {showCloseAccountModal === true && (
        <ModalCloseAccount
          showCloseAccountModal={showCloseAccountModal}
          setShowCloseAccountModal={setShowCloseAccountModal}
        />
      )}
      {showAddCompanyLogo && (
        <ModalAddProfilePhoto
          refetchWorkerInfo={refetchWorkerInfo}
          setShowAddProfilePhotoModal={setShowAddCompanyLogo}
          showAddProfilePhotoModal={showAddCompanyLogo}
          // setPicturePreview={setCompanyLogoPreview}
          // picturePreview={companyLogoPreview}
          company={"company"}
          // setOriginalCompanyData={setOriginalCompanyData}
          // originalCompanyData={originalCompanyData}
          setCompanyLogoFile={setCompanyLogoFile}
        />
      )}
    </div>
  );
}
