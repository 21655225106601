import autoAnimate from "@formkit/auto-animate";
import React, {
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";

import { BsChevronRight } from "react-icons/bs";
import { days } from "../../Utils/content/Days";
import { useAtom } from "jotai";
import { daysReplicatedTime, wantsToReplicate } from "../../Atoms/jotaiAtoms";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { HourDaysTypes } from "../../types/types";
import { useTranslation } from "react-i18next";

type Props = {
  modalReplicate: boolean;
  setModalReplicate: Dispatch<SetStateAction<boolean>>;
  daySelected: string;
  startingHour: {
    label: string;
    value: string;
  };
  endingHour: {
    label: string;
    value: string;
  };
  replicateTimeAnotherDay: (day: string) => void;
};

const ModalDuplicateHour = ({
  modalReplicate,
  setModalReplicate,
  daySelected,
  endingHour,
  startingHour,
  replicateTimeAnotherDay,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [transition, setTransition] = useState(false);
  const [animatiopnModal, setAnimationModal] = useState(false);
  const [replicateHours, setReplicateHours] = useAtom(daysReplicatedTime);
  const [allChosenTimes, setAllChosenTimes] = useLocalStorage("workTimes", []);
  const [isBeingReplicated, setIsBeingReplicated] = useAtom(wantsToReplicate);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (modalReplicate && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [modalReplicate]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setModalReplicate(false);
    }, 150);
  };

  useEffect(() => {
    if (modalReplicate) {
      setAnimationModal(true);
    } else {
      setAnimationModal(false);
    }
  }, [modalReplicate]);

  useEffect(() => {
    ref.current && autoAnimate(ref.current);
  }, [ref]);

  function isReplicated() {
    const repeatedTimes = allChosenTimes.filter(
      (day: HourDaysTypes) => day.hour_from?.slice(0, 5) === startingHour.value
    );
    const daysAreRepeated = repeatedTimes?.map(
      (day: { dayname: string }) => day.dayname
    );
    const daysFull = allChosenTimes
      .filter(
        (day: { hour_from: string; hour_to: string }) =>
          day.hour_from?.slice(0, 5) === "00:00" &&
          day.hour_to?.slice(0, 5) === "23:59"
      )
      ?.map((day: { dayname: string }) => day.dayname);

    return [...daysAreRepeated, ...daysFull];
  }

  useEffect(() => {
    const daysRepeated = isReplicated();
    setReplicateHours(daysRepeated);
  }, []);

  return (
    <div className={`absolute top-0 right-0 z-40`}>
      <div
        ref={ref}
        className={`${
          animatiopnModal
            ? "scale-20 opacity-100"
            : "scale-50 -translate-y-2 opacity-0"
        }  border -2xl transition-all transform duration-150 ease-in-out `}
      >
        <div className="bg-white rounded w-56  hover:bg-gray-100 cursor-pointer group">
          <div className="border-b px-4 py-2">
            <div className="flex items-center justify-between ">
              <h1 className="text-[13px] ">
                {t("createprofile.schedule.card.replicateBtn")}{" "}
                <span className="font-medium text-gray-400">
                  {startingHour.value} - {endingHour.value}
                </span>{" "}
                {i18n.language === "en" ? <>in</> : <>en</>}
              </h1>
              <BsChevronRight size={14} className="text-gray-400" />
            </div>
          </div>
          {days?.map((day: any) => (
            <button
              key={day.value}
              disabled={replicateHours?.includes(day.value.toUpperCase())}
              onClick={() => replicateTimeAnotherDay(day.value)}
              className={`${
                replicateHours?.includes(day.value.toUpperCase())
                  ? "bg-gray-100 cursor-not-allowed text-gray-300 "
                  : "bg-white"
              }  border-b px-4 py-1 hover:bg-gray-100 hidden cursor-pointer group-hover:block w-full`}
            >
              <h1 className="text-[13px]">
                {day.label === "Monday" &&
                  t("welcome-client.rightcard.dayweek.monday")}
                {day.label === "Tuesday" &&
                  t("welcome-client.rightcard.dayweek.tuesday")}
                {day.label === "Wednesday" &&
                  t("welcome-client.rightcard.dayweek.wednesday")}
                {day.label === "Thursday" &&
                  t("welcome-client.rightcard.dayweek.thursday")}
                {day.label === "Friday" &&
                  t("welcome-client.rightcard.dayweek.friday")}
                {day.label === "Saturday" &&
                  t("welcome-client.rightcard.dayweek.saturday")}
                {day.label === "Sunday" &&
                  t("welcome-client.rightcard.dayweek.sunday")}
              </h1>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModalDuplicateHour;
