import React, { useState, useEffect } from "react";
import ProgressBarOffer from "../../../Components/ViewJobPost/ProgressBarOffer";
import useLocalStorage from "../../../Hooks/UseLocalStorage";
import { ReactComponent as Resume } from "../../../assets/img/resume.svg";
import WorkerCards from "../../../Components/ViewJobPost/WorkerCards";
import { useTranslation } from "react-i18next";
import {
  useApplicationProcessHires,
  useApplicationProcessJob,
} from "src/Hooks/useApplicationProcess";
import { Skeleton } from "src/Components/ui/skeleton";

const HiredApplicants = () => {
  const [jobData, setJobData] = useLocalStorage("jobData", "");

  const [refreshData, setRefreshData] = useState(true);
  const [hiredApplicants, setHiredApplicants] = useState<any>();
  const { t } = useTranslation();
  const { hiresData, isHiresLoading, refetchHires } =
    useApplicationProcessHires();
  const { jobsData } = useApplicationProcessJob();

  useEffect(() => {
    setHiredApplicants(hiresData);
  }, [hiresData]);

  useEffect(() => {
    setJobData(jobsData);
  }, [jobsData]);

  const refetch = () => {
    refetchHires();
  };

  return (
    <>
      <ProgressBarOffer
        props={"hire"}
        jobData={jobData && jobData.data?.[0].job_status}
      />
      <div className="flex flex-col w-full bg-white mt-8 rounded border relative dark:bg-background dark:text-whiteish dark:border dark:border-border ">
        <div>
          {!isHiresLoading && hiredApplicants?.length === 0 && (
            <div className="flex flex-col w-full items-center my-12">
              <Resume className="h-32 mb-6" />
              <p className="text-lg font-bold tracking-wide">
                {t("employer.hired_applicants.empty")}
              </p>
              <p className="text-sm mt-4">
                {t("employer.hired_applicants.empty_desc")}
              </p>
            </div>
          )}
          {!isHiresLoading && hiredApplicants?.length > 0 && (
            <div>
              <div>
                <p className="text-sm px-8 py-6">
                  {t("employer.hired_applicants")}
                </p>
                <WorkerCards
                  refetch={refetch}
                  applicantsData={hiredApplicants}
                  setApplicantsData={setHiredApplicants}
                  setRefreshData={setRefreshData}
                />
              </div>
            </div>
          )}
          {isHiresLoading && (
            <div className="flex items-start justify-between w-full p-8">
              <Skeleton className="h-20 w-20 rounded-full flex-shrink-0" />
              <div className="flex flex-col space-y-4 ml-10 w-full">
                <Skeleton className="h-6 w-40 rounded" />
                <Skeleton className="h-4 w-[70%] rounded" />
                <Skeleton className="h-4 w-[70%] rounded" />
              </div>
              <div className="flex flex-col items-center space-y-4">
                <Skeleton className="w-20 h-4 rounded-full" />
                <Skeleton className="w-40 h-4 rounded-full" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default HiredApplicants;
