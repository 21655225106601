import React, { useRef } from "react";
import { useModalStore } from "src/Hooks/useModalStore";
import { Button } from "../ui/Button";
import { AiOutlineClose } from "react-icons/ai";
import { useAtom } from "jotai";
import { jobFeatured } from "src/Atoms/jotaiAtoms";
import { useTranslation } from "react-i18next";
import { BASE_URL, JOB_FEATURED } from "src/Config/api.config";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { IoDiamond } from "react-icons/io5";
import { formatDistance } from "date-fns";
import { es } from "date-fns/locale";
import { HoursJobTypes } from "src/types/types";
import { FiHeart, FiThumbsDown } from "react-icons/fi";

interface Props {
  refetchJob: () => void;
  jobData?: any;
  skills: any;
  allChosenTimes: HoursJobTypes[];
}

const ModalEditFeaturedJob = ({
  refetchJob,
  jobData,
  skills,
  allChosenTimes,
}: Props) => {
  const { t } = useTranslation();
  const [showModalEditFeatured, setShowModalEditFeatured] = useModalStore(
    (state) => [state.showModalFeatured, state.setShowModalFeatured]
  );
  const [editFeatureJob, setEditFeatureJob] = useAtom(jobFeatured);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClose = () => setShowModalEditFeatured(showModalEditFeatured);
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const formData = new FormData();
  formData.append("_method", "POST");
  formData.append("client_id", userID);
  formData.append("job_id", jobData?.job_id);
  const featureJob = async () => {
    const response = await fetch(`${BASE_URL}${JOB_FEATURED}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + loggedInUserUid,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          refetchJob();
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };

  const handleSave = () => {
    if (editFeatureJob) {
      featureJob();
    } else {
      handleClose();
    }
  };

  return (
    <div
      className="fixed top-0 right-0  w-full h-full bg-black/50 text-black z-50"
      ref={modalRef}
    >
      <div className="fixed  z-[50] w-[90%] lg:w-[38%] left-[50%] transition border translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white">
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-lg font-medium">
            {t("employer.applicants.job_post.featured_modal.title")}
          </h1>
          <Button
            onClick={() => handleClose()}
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          {t("employer.applicants.job_post.featured_modal.subtitle")}
        </h3>

        <div className="flex flex-col items-start px-8 py-4 space-y-3  rounded-lg border -md">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Feature Job Post
          </h3>

          <div
            onClick={() => setEditFeatureJob(true)}
            className={`flex items-center cursor-pointer p-2 rounded-md transition-colors duration-200 ${
              editFeatureJob
                ? "bg-blue-100"
                : "bg-transparent hover:bg-gray-100"
            }`}
          >
            <div
              className={`w-4 h-4 rounded-full border-2 mr-3 ${
                editFeatureJob
                  ? "border-blue-600 bg-blue-600"
                  : "border-gray-400"
              } transition-all duration-200`}
            />
            <p
              className={`text-sm ${
                editFeatureJob ? "text-blue-600 font-semibold" : "text-gray-600"
              }`}
            >
              {t("employer.applicants.job_post.featured_modal.featured")}
            </p>
          </div>

          <div
            onClick={() => setEditFeatureJob(false)}
            className={`flex items-center cursor-pointer p-2 rounded-md transition-colors duration-200 ${
              !editFeatureJob
                ? "bg-blue-100"
                : "bg-transparent hover:bg-gray-100"
            }`}
          >
            <div
              className={`w-4 h-4 rounded-full border-2 mr-3 ${
                !editFeatureJob
                  ? "border-blue-600 bg-blue-600"
                  : "border-gray-400"
              } transition-all duration-200`}
            />
            <p
              className={`text-sm ${
                !editFeatureJob
                  ? "text-blue-600 font-semibold"
                  : "text-gray-600"
              }`}
            >
              {t("employer.applicants.job_post.featured_modal.not_featured")}
            </p>
          </div>
        </div>
        {editFeatureJob && (
          <>
            <p className="px-8  mt-4">Preview</p>
            <div className="border bg-white mx-8 my-4 p-4 relative">
              <div className="flex items-center space-x-4 absolute top-6 right-6">
                <FiThumbsDown
                  className={`rounded-full  dark:bg-whiteish hover:bg-white w-fit h-3 overflow-visible  hover:border-red-500 cursor-pointer transition  `}
                />
                <FiHeart
                  className={`rounded-full  dark:bg-whiteish hover:bg-white w-fit h-3 overflow-visible  hover:border-red-500 cursor-pointer transition  `}
                />
              </div>
              <div className="flex items-center space-x-5 mb-4 ">
                <div className="flex items-center space-x-2 bg-azulOscuro w-fit px-2 py-1 rounded ">
                  <IoDiamond className="h-3 w-3 text-white" />
                  <p className="text-[10px] font-medium text-white">Featured</p>
                </div>
              </div>
              <div className="flex items-center space-x-2 text-[#5e6d55] ">
                <p className="text-[10px]">
                  Publicado hace{" "}
                  {formatDistance(new Date(jobData.date_created), new Date(), {
                    locale: es,
                  })}
                </p>
              </div>
              <div>
                <p className="text-xs">{jobData.title}</p>
                <p className="text-[10px] text-gray-500">
                  Hourly - {jobData.budget}€
                </p>
                <div className="my-4">
                  <p className="text-[11px]">{jobData.details}</p>
                </div>
                <div className="flex flex-wrap space-x-2 gap-2">
                  {skills.map(
                    (skill: { skill_name: string; skill_id: string }) => (
                      <p
                        className="bg-[#e2e2e2] text-[9px] px-4 py-1 rounded-full border"
                        key={skill.skill_id}
                      >
                        {skill.skill_name}
                      </p>
                    )
                  )}
                  {/* {skills.split(",").map((skill: string) => (
                <>{skill}</>
              ))} */}
                </div>
                <div className="flex text-[9px] mt-4 mb-4 gap-2 cursor-pointer w-fit">
                  {allChosenTimes.map(
                    (day: { job_time_profile_id: string; dayname: string }) => (
                      <div
                        key={day.job_time_profile_id}
                        className="flex flex-col text-center  bg-blue-200 rounded-lg w-[40px] "
                      >
                        <p className="border-b border-blue-200 px-2 font-bold border-b-white text-white drop-border py-2">
                          {day.dayname}
                        </p>
                        <p className="px-2 text-slate-500 font-bold py-2">
                          24h
                        </p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="flex items-center w-full justify-end px-8 py-4">
          <Button
            onClick={() => handleClose()}
            className="bg-none text-[0.9rem] w-24 h-10 border  text-azulBonico    mt-1 mr-4 rounded-full "
          >
            {t("employer.applicants.job_post.featured_modal.cancel")}
          </Button>
          <Button
            onClick={() => handleSave()}
            className="bg-none text-[0.9rem] w-24 h-10 border hover:bg-azulOscuro bg-azulBonico text-white rounded-full   mt-1 "
          >
            {t("employer.applicants.job_post.featured_modal.save")}
          </Button>
        </div>
        {/* {sendWithNote && (
          <div className="px-8 py-6 ">
            <textarea
              className="border border-border w-full h-36 p-3"
              placeholder=""
            />
          </div>
        )}
        {!sendWithNote ? (
          <div className="border-t border-border flex items-center justify-end w-full gap-4 px-8 py-2">
            <Button
              onClick={() => setSendWithNote(!sendWithNote)}
              className="bg-none text-[0.9rem] w-24 h-10 border  text-azulBonico rounded   mt-1 "
            >
              Add a note
            </Button>
            <Button className="bg-none text-[0.9rem] w-24 h-10 border bg-azulBonico text-white rounded   mt-1 ">
              Send now
            </Button>
          </div>
        ) : (
          <div className="border-t border-border flex items-center justify-end w-full gap-4 px-8 py-2">
            <Button
              onClick={() => setSendWithNote(false)}
              className="bg-none text-[0.9rem] w-24 h-10 border text-azulBonico rounded mt-1"
            >
              Cancel
            </Button>
            <Button className="bg-none text-[0.9rem] w-24 h-10 border bg-azulBonico text-white rounded   mt-1 ">
              Send
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ModalEditFeaturedJob;
