import React, { useState, useEffect } from "react";
import { Button } from "../ui/Button";
import { ReactComponent as PaypalLogo } from "src/assets/img/paypal-logo.svg";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { RiDeleteBin6Fill } from "react-icons/ri";
import CardSetupForm from "./CardSetupForm";
import { useTranslation } from "react-i18next";
import {
  BASE_URL,
  CREATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  UPDATE_DEFAULT_PAYMENT_METHOD,
} from "src/Config/api.config";
import { loadStripe } from "@stripe/stripe-js";
import { BsFillCreditCardFill } from "react-icons/bs";
import { Elements } from "@stripe/react-stripe-js";
import { useClientData } from "src/Hooks/useClientData";
import VisaLogo from "src/assets/img/visa.png";
import MasterCardLogo from "src/assets/img/mastercard.png";
import { AiOutlineLoading } from "react-icons/ai";

const stripe = loadStripe(
  "pk_test_51QKjbIKfPqiMx5RCWGXOWNbSaKJbYzPjhlrIH7bjSn8xEa5ndo5CxqIrQZ3SuRqfeBf23jT6QXyBPLFjUunO0Yb300iS1pZJQx"
);

const formSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

const BillingPayments = () => {
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]); // Store the payment methods here
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(""); // Store the payment methods here
  const { employerData, refetchEmployer } = useClientData();

  const handleShowPayment = () => {
    setShowAddPaymentMethod(!showAddPaymentMethod), setClientSecret("");
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
    },
  });

  // Separate fetchPaymentMethods function
  const fetchPaymentMethods = async () => {
    try {
      setLoadingPaymentMethods(true); // Start loading
      const response = await fetch(`${BASE_URL}${GET_PAYMENT_METHODS}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_stripe_id: userInfo?.customer_stripe_id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const { payment_methods, default_payment_method } = data.result.data;

        setPaymentMethods(payment_methods || []);
        setDefaultPaymentMethod(default_payment_method?.id || null); // Use optional chaining and fallback
      } else {
        throw new Error(data.message || "Failed to fetch payment methods");
      }
    } catch (error) {
      console.error("Error fetching payment methods:", error);
    } finally {
      setLoadingPaymentMethods(false); // Finished loading
    }
  };

  // UseEffect to fetch payment methods on component load
  useEffect(() => {
    if (userInfo?.customer_stripe_id) {
      fetchPaymentMethods();
    }
  }, [userInfo?.customer_stripe_id, clientSecret]);

  const makeDefaultPaymentMethod = async (paymentMethodId: string) => {
    try {
      setLoading(true); // Show loading state

      const response = await fetch(
        `${BASE_URL}${UPDATE_DEFAULT_PAYMENT_METHOD}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${loggedInUserUid}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer_stripe_id: userInfo?.customer_stripe_id,
            payment_method_id: paymentMethodId, // Pass the new default payment method ID
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Update the default payment method in the state
        setDefaultPaymentMethod(paymentMethodId);
        toast.success("Default payment method updated successfully.");
      } else {
        throw new Error(
          data.message || "Failed to update default payment method"
        );
      }
    } catch (error) {
      console.error("Error updating default payment method:", error);
    } finally {
      setLoading(false); // Hide loading state
    }
  };

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      setLoading(true);

      const response = await fetch(`${BASE_URL}${CREATE_PAYMENT_METHOD}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userInfo?.id,
          customer_stripe_id: userInfo?.customer_stripe_id,
          first_name: values.firstName,
          last_name: values.lastName,
          email: userInfo?.email,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setClientSecret(data.result.data.client_secret);
      } else {
        throw new Error(data.message || "Failed to save payment method");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again.");
    } finally {
      try {
        const updatedEmployerData = await refetchEmployer();
        setUserInfo(updatedEmployerData.data);
      } catch (refetchError) {
        console.error("Error during employer data refetch:", refetchError);
      } finally {
        setLoading(false);
      }
    }
  };
  const deletePaymentMethod = async (paymentMethodId: string) => {
    try {
      setLoading(true);

      const response = await fetch(`${BASE_URL}${DELETE_PAYMENT_METHOD}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer_stripe_id: userInfo?.customer_stripe_id,
          payment_method_id: paymentMethodId, // Pass the payment method ID to delete
        }),
      });

      const data = await response.json();

      if (response.ok && data.status === "success") {
        // Update the paymentMethods state after successful deletion
        setPaymentMethods((prevMethods) =>
          prevMethods.filter((method) => method.id !== paymentMethodId)
        );
        toast.success("Payment method removed successfully.");
      } else {
        throw new Error(data.message || "Failed to remove payment method");
      }
    } catch (error) {
      console.error("Error deleting payment method:", error);
      toast.error("Failed to remove payment method.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=" px-4 py-2">
      <div className="bg-white rounded-lg border pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-8">
            <h1 className="text-2xl font-semibold mb-4">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments"
              )}
            </h1>
          </div>

          {!showAddPaymentMethod && (
            <div className="border p-4 m-8">
              <div className="flex flex-col lg:flex-row items-center justify-between">
                <h1 className="text-lg font-semibold">
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.left.title"
                  )}
                </h1>
                <Button
                  onClick={handleShowPayment}
                  className="border-2 border-azulBonico text-sm lg:text-[15px px-1 mt-2 lg:mt-0 lg:px-6 py-2 text-azulOscuro hover:bg-blue-50"
                >
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.rightBtn"
                  )}
                </Button>
              </div>

              {/* Display Payment Methods */}
              {loadingPaymentMethods ? (
                <div className="px-3 py-10 bg-white flex justify-center rounded-full">
                  <AiOutlineLoading size={50} className="animate-spin" />
                </div>
              ) : paymentMethods.length > 0 ? (
                <div className="overflow-x-auto mt-4">
                  <table className="min-w-full bg-white dark:bg-background border dark:border-border">
                    <thead>
                      <tr className="border-b">
                        <th className="py-2 px-4 text-left font-semibold">
                          {t("Card Brand")}
                        </th>
                        <th className="py-2 px-4 text-left font-semibold">
                          {t("Card Ending")}
                        </th>
                        <th className="py-2 px-4 text-left font-semibold">
                          {t("Expires")}
                        </th>
                        <th className="py-2 px-4 text-left font-semibold">
                          {t("Action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentMethods.map((method, index) => (
                        <tr key={index} className="border-b">
                          <td className="py-2 px-4">
                            {method.card.brand === "visa" && (
                              <img src={VisaLogo} alt="Visa" className="h-6" />
                            )}
                            {method.card.brand === "mastercard" && (
                              <img
                                src={MasterCardLogo}
                                alt="MasterCard"
                                className="h-6"
                              />
                            )}
                          </td>
                          <td className="py-2 px-4">
                            **** **** **** {method.card.last4}
                          </td>
                          <td className="py-2 px-4">
                            {method.card.exp_month}/{method.card.exp_year}
                          </td>
                          <td className="py-2 px-4 flex items-center gap-2">
                            {defaultPaymentMethod === method.id ? (
                              <span className="text-sm font-bold text-green-500 bg-green-100 px-2 py-1 rounded">
                                Default
                              </span>
                            ) : (
                              <Button
                                className="text-blue-500 border-blue-500 hover:bg-blue-50 px-2 py-1 rounded"
                                onClick={() =>
                                  makeDefaultPaymentMethod(method.id)
                                } // Call the function
                              >
                                Make Default
                              </Button>
                            )}
                            <Button
                              className="text-red-500"
                              onClick={() => deletePaymentMethod(method.id)} // Pass the payment method ID
                            >
                              <RiDeleteBin6Fill size={25} />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="flex flex-col mt-14">
                  <p className="text-sm font-medium">
                    {t(
                      "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.subTitle1"
                    )}
                  </p>
                  <p className="text-sm mt-4">
                    {t(
                      "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.subTitle2"
                    )}
                  </p>
                </div>
              )}
            </div>
          )}

          {showAddPaymentMethod && (
            <div className="border p-4 m-8">
              <div className="flex items-center justify-between">
                <h1
                  onClick={() => console.log(userInfo)}
                  className="text-sm lg:text-lg font-semibold"
                >
                  {t(
                    "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.rightBtn"
                  )}
                </h1>
                <Button
                  onClick={handleShowPayment}
                  className="border-2 border-azulBonico px-6 py-2 text-azulOscuro hover:bg-blue-50"
                >
                  Cancel
                </Button>
              </div>
              <div className="flex flex-col items-start mt-14">
                <div className="flex items-center gap-4 border-b w-full py-4 border-border">
                  <Button
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={loading}
                    className="flex items-center gap-2 border-2 px-4 py-2 border-gray-300 text-gray-700 hover:bg-gray-100"
                  >
                    <BsFillCreditCardFill />
                    {t(
                      "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.payment_card"
                    )}
                    {loading && <AiOutlineLoading className="animate-spin" />}
                  </Button>
                </div>
                {clientSecret && (
                  <Elements stripe={stripe} options={{ clientSecret }}>
                    <div className="mt-8 w-full">
                      <h2 className="text-lg font-medium">
                        {t(
                          "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.billing&payments.body.card.add_card_method"
                        )}
                      </h2>
                      <CardSetupForm
                        clientSecret={clientSecret}
                        showAddPaymentMethod={showAddPaymentMethod}
                        setShowAddPaymentMethod={setShowAddPaymentMethod}
                        setClientSecret={setClientSecret}
                        loading={loading}
                      />
                    </div>
                  </Elements>
                )}

                <div className="flex items-center gap-4  w-full py-4 border-t mt-4">
                  <PaypalLogo className="h-10 w-28" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BillingPayments;
