import React, { useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Button } from "src/Components/ui/Button";
import { useModalStore } from "src/Hooks/useModalStore";
import { cn } from "src/lib/utils";
import { FireIcon } from "@heroicons/react/24/solid";
import FlexiCoin from "src/assets/img/flexicoin.png";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { StylesModal } from "src/Utils/stylesModal";
import { useActionTypesStore } from "src/Routing/useProfileStore";

import Select from "react-select";
import { BASE_URL_IMG } from "src/Config/api.config";
import { GrLocation } from "react-icons/gr";
import { BsFire } from "react-icons/bs";
import RatingStars from "src/Components/Rating/RatingStars";

interface FeatureWorkerProps {
  onClick: (laps: string) => void;
  featureStatus: boolean;
  featureValue: string;
  featureBadge: string;
  workerInfo?: any;
  profileData?: any;
}

const ModalFeaturedProfile = ({
  onClick,
  featureStatus,
  featureValue,
  featureBadge,
  workerInfo,
  profileData,
}: FeatureWorkerProps) => {
  const [showModalFeaturedProfile, setShowModalFeaturedProfile] = useModalStore(
    (state) => [
      state.showModalFeaturedProfile,
      state.setShowModalFeaturedProfile,
    ]
  );
  const [laps, setLaps] = useState<any>();
  const ref = useRef<HTMLDivElement>(null);
  const actions = useActionTypesStore((s: any) => s.actions);
  const { t } = useTranslation();

  const handleClose = () =>
    setShowModalFeaturedProfile(showModalFeaturedProfile);
  const handleCancel = () =>
    setShowModalFeaturedProfile(showModalFeaturedProfile);

  const featureWorker = () => {
    onClick(laps);
  };
  const colourStyles = {
    control: () => ({
      border: "1px solid #2abeeb",
      "&:hover": {
        borderColor: "#1d90b3",
      },
      display: "flex",
      fontWeight: "600",
      constterSpacing: "0.05em",
      backgroundColor: "white",

      padding: "0rem",
      width: "160px",
      paddingLeft: "0.5rem",
      // paddingRight: "0.8rem",
      borderRadius: "10px",
      height: "40px",
    }),
  };
  return (
    <Modal isOpen={showModalFeaturedProfile} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
        ref={ref}
      >
        <div className="flex items-center justify-between border-b px-8 py-6">
          <h1 className="text-lg font-medium">
            {t(
              "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.title"
            )}
          </h1>
          <Button
            className="h-6 w-6 rounded-md p-0 bg-gray-200 flex justify-center items-center "
            onClick={() => handleClose()}
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <h3 className="px-8 py-6">
          {t(
            "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.subtitle"
          )}
          .
        </h3>
        <div className="px-8 pt-2 pb-4">
          <p className="mb-4 text-gray-500">
            How many days would you like to boost your profile?
          </p>
          <Select
            options={[
              {
                value: (
                  Number(featureBadge) / Number(featureBadge)
                )?.toString(),
                label: `${featureBadge} days`,
              },
              {
                value: (
                  (Number(featureBadge) / Number(featureBadge)) *
                  2
                )?.toString(),
                label: `${Number(featureBadge) * 2} days`,
              },
              {
                value: (
                  (Number(featureBadge) / Number(featureBadge)) *
                  3
                )?.toString(),
                label: `${Number(featureBadge) * 3} days`,
              },
              {
                value: (
                  (Number(featureBadge) / Number(featureBadge)) *
                  4
                )?.toString(),
                label: `${Number(featureBadge) * 4} days`,
              },
            ]}
            styles={colourStyles}
            defaultValue={{
              label: "7 days",
              value: "7",
            }}
            onChange={(e) => setLaps(e!.value)}
            className="cursor-pointer text-sm dark:text-black"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="flex justify-end w-full py-6 px-8 relative ">
          {!featureStatus ? (
            <Button
              onClick={() => featureWorker()}
              className="bg-azulBonico px-4 py-1 flex items-center gap-1 text-white peer"
            >
              {t(
                "worker.leftbar.menu.item_my.profile.pencilbtn.feature_yourself.turn_on.btn"
              )}
              <FireIcon className="h-4 w-4" />
            </Button>
          ) : (
            <Button
              disabled
              className="bg-gray-100 px-4 py-1 flex items-center peer gap-1 text-[#333] disabled:cursor-not-allowed"
              // onClick={() => unfeatureWorker()}
            >
              {t("worker.leftbar.menu.item_my.profile.Featured_fireBtn")}
              <FireIcon className="h-4 w-4" />
            </Button>
          )}
          <div className="absolute bottom-16  scale-0 bg-gray-100 rounded px-2 peer-hover:scale-100 transition">
            <div className="flex items-center gap-2">
              <div>{featureValue}</div>
              <img src={FlexiCoin} className="h-4 w-4 " />
            </div>
          </div>
        </div>
        <div className="mx-8 mb-4 ">
          <p className="text-sm text-[#333]">Preview</p>
          <div className="border bg-white  p-4 relative mt-2 rounded">
            <div className="flex items-start space-x-4">
              <img
                className="h-10 w-10 object-cover rounded-full"
                src={`${BASE_URL_IMG}${workerInfo?.workerProfile.profile_image}`}
              />
              <div>
                <p className="text-xs">{workerInfo.workerProfile.title}</p>
                <p className="text-xs flex items-center space-x-2 mt-1 text-gray-500">
                  <GrLocation />
                  {workerInfo.workerProfile.city_name}
                </p>
                <div className="flex w-fit items-center gap-2 mt-2 px-4 py-1 bg-orange-50 border border-orange-400 rounded-full">
                  <BsFire className="text-orange-400 h-3 w-3" />
                  <p className="text-xs font-medium text-orange-600">Boosted</p>
                </div>
                <p className="text-[10px] mt-4 mb-2">
                  {workerInfo.workerProfile.overview.slice(0, 200)}
                </p>
                <RatingStars
                  size={"10"}
                  activeStar={profileData?.score?.avgScore}
                  score={profileData?.score?.avgScore}
                  evaluation={false}
                  reviews={false}
                  showHalf={true}
                  info={profileData?.score}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalFeaturedProfile;
