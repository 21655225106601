import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { BASE_URL, CLOSE_JOB_EMPLOYER } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { ReactComponent as Search } from "../../assets/img/search.svg";
import { useNavigate } from "react-router-dom";
import postings from "../../assets/img/postings.svg";

import Pagination from "../EmployerJobsPagination/Pagination";

import { useTranslation } from "react-i18next";
import { JobTypes } from "../../types/types";

import GoBackButton from "../ui/GoBack";
import { useQuery } from "react-query";
import { Skeleton } from "../ui/skeleton";
import ClosedJobsSearchCard from "./ClosedJobsSearchCard";
import { debounce } from "src/Utils/debounce";

const ClosedJobsSearch = () => {
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loading, setLoading] = useState(false);
  const [jobsData, setJobsData] = useState<JobTypes[]>([]);
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [jobID, setJobID] = useLocalStorage("jobID", "");
  const [pagination, setPagination] = useState({
    limit: 10,
    totalPages: "",
    totalPosts: "",
    currentPage: 1,
    pageNumbers: [],
    order: "updated",
    title: "",
  });
  const { t, i18n } = useTranslation();
  const [params, setParams] = useState();
  const sortOptions = [
    {
      value: "Closed date",
      label: `${t("employer.all_closed_jobs.closed_date")}`,
      param: "date_closed",
    },
  ];

  const [searchInput, setSearchInput] = useState("");

  const navigate = useNavigate();

  const getClosedJobs = async () => {
    const data = await fetch(
      `${BASE_URL}${CLOSE_JOB_EMPLOYER}?client_id=${userID}&limit=${
        pagination.limit
      }&page=${pagination.currentPage}${
        params ? `&order=${params}` : "&order=date_updated"
      }${searchInput.length > 0 ? `&title=${searchInput}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    ).then((res) => res.json());

    return data;
  };

  const { data: closedJobsData, isLoading: isClosedJobsLoading } = useQuery(
    ["closed_jobs", pagination.currentPage, searchInput],
    getClosedJobs
  );

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      totalPosts: closedJobsData?.paginate.rows,
      totalPages: closedJobsData?.paginate.pages,
    }));
    setJobsData(closedJobsData?.result);
  }, [closedJobsData]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "lightblue",
      },
      display: "flex",
      width: "120px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
    }),
  };

  const getClosedJobData = (id: string) => {
    setJobID(id);
    fetch(`${BASE_URL}${CLOSE_JOB_EMPLOYER}?job_id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setJobData(data.result);
          navigate("/applicants/view-job-post");
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSearch = (e: string) => {
    setSearchInput(e);
  };
  const optimisedSearch = useCallback(debounce(handleSearch), []);

  return (
    <>
      <div className=" flex justify-between mt-8 items-center px-6">
        <div
          className="flex items-center mt-6 gap-2 fill-azulBonico cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <GoBackButton />
        </div>
      </div>
      <div className=" bg-white border rounded mt-12  ">
        <div className="flex w-full  px-8 py-8 justify-between border-b border-blue-100">
          <div className="relative w-1/2">
            <input
              className="px-2 border-slate-200 hover:border-blue-100 border text-sm w-full rounded h-10 outline-none focus:border-blue-100  transition duration-300"
              placeholder={`${t(
                "employer.alljobs.searchjobpostingsPlaceholder"
              )}`}
              onChange={(e) => {
                setPagination((prev) => ({
                  ...prev,
                  currentPage: 1,
                  // title: e.target.value,
                }));
                optimisedSearch(e.target.value);
              }}
            ></input>
            <Search className="h-5 absolute fill-slate-500 right-[0.5rem] top-[10px] cursor-pointer dark:fill-whiteish " />
          </div>
          <div className="flex items-center gap-3">
            <p className="text-sm ">
              {t("employer.alljobs.sortbyDropdown.sortby")}:
            </p>
            <Select
              styles={colourStyles}
              options={sortOptions}
              className="text-sm"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              defaultValue={{
                label: sortOptions[0].label,
                value: sortOptions[0].value,
              }}
              onChange={(e: any) => {
                setPagination((prev) => ({ ...prev, currentPage: 1 }));
                // getClosedJobs(e.param);
                setParams(e.param);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col border-t border-blue-100  ">
          <>
            {isClosedJobsLoading && (
              <div className="flex items-center justify-between border-b border-border bg-white py-4 px-8">
                <div className="flex flex-col gap-2">
                  <Skeleton className="h-4 w-20" />
                  <Skeleton className="h-4 w-24" />
                  <Skeleton className="h-4 w-14" />
                </div>
                <Skeleton className="h-4 w-20" />
              </div>
            )}
            {!isClosedJobsLoading &&
              jobsData?.length > 0 &&
              jobsData
                ?.filter((job) => job.date_closed !== null)
                ?.map((job: JobTypes) => {
                  return (
                    <ClosedJobsSearchCard
                      key={job.job_id}
                      job={job}
                      getClosedJobData={getClosedJobData}
                    />
                  );
                })}
            {!isClosedJobsLoading && jobsData?.length === 0 && (
              <div className="flex h-52 flex-col items-center justify-center text-center bg-white ">
                <img src={postings} alt="drafts" className="w-28 mb-4" />
                <h1 className="text-base font-semibold mb-2">
                  {t("employer.drafts.no_searches")}
                </h1>
              </div>
            )}
          </>
        </div>
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          getJobPostsFiltered={getClosedJobs}
        />
      </div>
      <div>&nbsp;</div>
    </>
  );
};

export default ClosedJobsSearch;
