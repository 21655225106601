
export const allLevels = [
    {
        value: "Basic",
        label: "Basic",
    },
    {
        value: "Conversational",
        label: "Conversational",
    },
    {
        value: "Fluent",
        label: "Fluent",
    },
    {
        value: "Native or Bilingual",
        label: "Native or Bilingual",
    },
];