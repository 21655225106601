import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { ReactComponent as Dot } from "../../assets/img/dot.svg";
import { HiOutlineExclamation } from "react-icons/hi";
import { GrDocumentLocked } from "react-icons/gr";

import React, { SetStateAction, useEffect, useRef, useState } from "react";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { InitialStateJobType } from "../InitialClientForm/JobPostEdit";

interface Props {
  setShowModalDeletePost: React.Dispatch<SetStateAction<boolean>>;
  showModalDeletePost: boolean;
  jobInfo: InitialStateJobType;
  deletePost: (id: string) => void;
}

const ModalDeletePost = ({
  showModalDeletePost,
  setShowModalDeletePost,
  jobInfo,
  deletePost,
}: Props) => {
  const [transition, setTransition] = useState(false);
  const [deleteFurtherConfirmation, setDeleteFurtherConfirmation] =
    useState(false);
  const [deleteFinalConfirmation, setDeleteFinalConfirmation] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorInput, setErrorInput] = useState(false);
  const [animationModal, setAnimationModal] = useState(false);
  const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const { t } = useTranslation();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showModalDeletePost &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModalDeletePost]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModalDeletePost(false);
    }, 150);
  };

  useEffect(() => {
    if (inputRef.current?.value !== "") {
      setErrorInput(true);
    }
  }, []);

  const handleChange = (e: string) => {
    if (e === jobInfo.title) {
      setErrorInput(false);
    } else if (e !== jobInfo.title) {
      setErrorInput(true);
    }
  };

  useEffect(() => {
    if (showModalDeletePost) {
      setAnimationModal(true);
    } else {
      setAnimationModal(false);
    }
  }, [showModalDeletePost]);

  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-black/10 text-black z-50">
        <div
          ref={ref}
          className={`fixed z-50 w-[80%] lg:w-[450px] left-[50%] transition-all transform duration-150 ease-in-out cursor-default translate-x-[-50%] border rounded top-[45%] translate-y-[-50%] bg-white
          
          ${
            animationModal
              ? "scale-20 opacity-100"
              : "scale-50 -translate-y-2 opacity-0"
          }
          
          `}
        >
          <div className="py-2 px-4 flex justify-between">
            <h1 className="text-sm font-medium">
              {t("employer.modal_delete.delete")} {jobInfo.title} /{" "}
              {userInfo.first_name}
            </h1>
            <div
              onClick={() => setShowModalDeletePost(false)}
              className="h-5 w-5 items-center justify-center flex cursor-pointer hover:bg-gray-200 rounded transition-all transform duration-150 ease-in-out"
            >
              <Cross className=" h-3 w-3 text-gray-500" />
            </div>
          </div>
          <div className="py-5 flex flex-col items-center justify-center border-t border-b ">
            <div className="py-2">
              <GrDocumentLocked size={20} color="#6E7781" />
            </div>
            <h1 className="font-medium">
              {userInfo.first_name} / {jobInfo.title}
            </h1>
            {/* <div className="flex items-center gap-6 text-gray-500">
              <div className="flex items-center space-x-1">
                <StarIcon className="h-4 text-gray-600" />
                <span className="text-[13px]">
                  {jobData.employer_score.totalScores} stars
                </span>
              </div>
              <div className="flex items-center space-x-1">
                <EyeIcon className="h-4 text-gray-600" />
                <span className="text-[13px]">
                  {jobData.employer_score.totalConnections} connections
                </span>
              </div>
            </div> */}
          </div>
          {deleteFurtherConfirmation && !deleteFinalConfirmation && (
            <div className="px-4 py-2 border-b">
              <div className="flex items-center px-4 py-4 bg-yellow-100 border-orange-200 border rounded">
                <HiOutlineExclamation
                  size={20}
                  color="red"
                  className="h-5 w-5"
                />
                <p className="text-[13px] ml-4 text-gray-600">
                  {t("employer.modal_delete.warning")}
                </p>
              </div>
              <div className="relative my-4 ">
                <Dot className="h-2 w-2 fill-gray-400 absolute left-[5px] z-10 top-2" />
                <p className="text-[13px] text-gray-500 before:content-[''] before:bottom-0 before:left-2 before:block before:absolute before:top-0 before:w-[2px] before:bg-gray-300 pl-6">
                  {t("employer.modal_delete.warning2.part1")}{" "}
                  <span className="font-medium">{jobInfo.title}</span>{" "}
                  {t("employer.modal_delete.warning2.part2")}.
                </p>
              </div>
            </div>
          )}
          {deleteFinalConfirmation && (
            <div className="px-4 py-2 mt-2">
              <div>
                <label className="text-[13px] font-medium cursor-default">
                  {t("employer.modal_delete.warning2.delete_final.type.part1")}{" "}
                  &apos;{jobInfo.title}&apos;{" "}
                  {t("employer.modal_delete.warning2.delete_final.type.part2")}
                </label>
                <input
                  ref={inputRef}
                  onChange={(e) => handleChange(e.target.value)}
                  className={`${
                    errorInput ? "ring-[1px] ring-red-400" : ""
                  } border w-full rounded focus:ring-[1px] outline-none px-2 py-[2px] text-[13px]`}
                />
              </div>
            </div>
          )}
          <div className="flex flex-col items-center justify-center py-2 px-4  ">
            {!deleteFurtherConfirmation && !deleteFinalConfirmation && (
              <button
                onClick={() =>
                  setDeleteFurtherConfirmation(!deleteFurtherConfirmation)
                }
                className="bg-gray-100  w-full text-[13px] font-medium border rounded px-4 py-1  hover:bg-gray-200  transition-all transform duration-150 ease-in-out  border-red-200"
              >
                {t("employer.modal_delete.warning2.button_delete")}
              </button>
            )}
            {deleteFurtherConfirmation && !deleteFinalConfirmation && (
              <>
                <div></div>
                <Button
                  onClick={() => {
                    setDeleteFinalConfirmation(!deleteFinalConfirmation);
                    setDeleteFurtherConfirmation(false);
                  }}
                  // onClick={() => deletePost(jobInfo.job_id)}
                  className="bg-gray-100  w-full text-[13px]  border rounded px-4 py-1  hover:bg-gray-200 border-red-200 mt-2"
                >
                  {t("employer.modal_delete.warning2.understand_effects")}
                </Button>
              </>
            )}
            {deleteFinalConfirmation && (
              <>
                <div></div>
                <button
                  onClick={() => deletePost(jobInfo.job_id)}
                  disabled={errorInput}
                  className={`bg-gray-100  w-full text-[13px]  border rounded px-4 py-1   border-red-200 ${
                    errorInput
                      ? "text-gray-600/40 cursor-not-allowed"
                      : "text-gray-600 hover:bg-red-700 hover:text-white"
                  } `}
                >
                  {t("employer.modal_delete.warning2.delete_post")}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDeletePost;
