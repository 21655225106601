import React, { useRef } from "react";
import Modal from "react-modal";
import { usePaymentStore } from "src/Hooks/usePaymentSuccessful";
import { StylesModal } from "src/Utils/stylesModal";
import { cn } from "src/lib/utils";
import { Button } from "../ui/Button";
import { AiOutlineClose } from "react-icons/ai";
import Successful from "src/assets/img/icons8-cuenta-verificada-100.png";
import Failed from "src/assets/img/icons8-cancelar-100.png";
import { useClientCredits } from "src/Hooks/useClientCredits";
import { useNavigate } from "react-router-dom";
const ModalPaymentPurchase = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { clientCreditsData, refetchClientCredits } = useClientCredits();
  const navigate = useNavigate();
  const modal = usePaymentStore();
  return (
    <Modal isOpen={modal.isOpen} style={StylesModal}>
      <div
        className={cn(
          "fixed z-10 transition duration-150 w-[90%] lg:w-[500px] left-[50%] translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]"
        )}
      >
        <div className="flex items-center  justify-between px-8 py-6">
          <h1 className="text-lg font-medium">Payment Status</h1>
          <Button
            onClick={() => {
              modal.onClose();
              navigate("/dashboard");
            }}
            className="h-6 w-6 rounded p-0 bg-gray-200 flex justify-center items-center"
          >
            <AiOutlineClose className="h-4 w-4" />
          </Button>
        </div>
        <div className="flex flex-col items-center justify-center py-4">
          {modal.type === "success" && (
            <div className="flex flex-col items-center">
              <img src={Successful} className="h-16 w-16 object-cover" />
              <p className="text-sm mb-4 border-b w-full text-center my-4 pb-2 font-medium">
                Payment Successful
              </p>
              <p className="text-xs text-gray-400">
                {modal.data.credits} Credits Successful Purchase
              </p>
            </div>
          )}
          {modal.type === "failure" && (
            <div className="flex flex-col items-center ">
              <img src={Failed} className="h-16 w-16 object-cover" />

              <p className="text-sm mb-4 border-b w-full text-center pb-2 font-medium">
                Payment failed
              </p>
              <p className="text-xs text-gray-400">
                {modal.data.credits} Credits Failed Purchase
              </p>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalPaymentPurchase;
