import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, DRAFTS } from "../../Config/api.config";

import ModalEdit from "./ModalEdit";
import { ReactComponent as ArrowUp } from "../../assets/img/arrow-up-solid.svg";

import { ReactComponent as Check } from "../../assets/img/check.svg";
import edit from "../../assets/img/edit.png";
import ModalEditSchedule from "../Modals/ModalEditSchedule";
import AvailableDays from "../Time/AvailableDays";
import exclamation from "../../assets/img/exclamation.png";
import { useTranslation } from "react-i18next";
import Confetti from "react-confetti";
import autoAnimate from "@formkit/auto-animate";
import { Button } from "../ui/Button";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import FlexiCoin from "src/assets/img/flexicoin.png";
import { useQuery } from "react-query";
import { useCategories } from "src/Hooks/useCategories";
import {
  useActionTypesStore,
  userProfileStore,
} from "src/Routing/useProfileStore";
import { decryptArray } from "src/Hooks/decryptString";

export default function JobPostReview() {
  const { t, i18n } = useTranslation();
  const [activateConfetti, setActivateConfetti] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [selectedCategory, setSelectedCategory] = useLocalStorage(
    "selectedCategory",
    ""
  );
  const [jobDescription, setJobDescription] = useLocalStorage(
    "jobDescription",
    ""
  );
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState();
  const [categoryID, setCategoryID] = useLocalStorage("jobCategoryID", "");
  const [jobTitle, setJobTitle] = useLocalStorage("jobTitle", "");
  const [budget, setBudget] = useLocalStorage("budget", "");
  const [clientSkills, setClientSkills] = useLocalStorage("clientSkills", []);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [jobType, setJobType] = useLocalStorage("jobType", "");
  const [clientSkillsID, setClientSkillsID] = useLocalStorage(
    "clientSkillsID",
    []
  );
  const [selected, setSelected] = useLocalStorage("selected", "");
  const [jobLocation, setJobLocation] = useLocalStorage("jobLocation", "");
  const [allCategories, setAllCategories] = useLocalStorage(
    "allCategories",
    ""
  );
  const [jobLocationID, setJobLocationID] = useLocalStorage(
    "jobLocationID",
    ""
  );
  const [showModalEditSchedule, setShowModalEditSchedule] = useState(false);
  const [allChosenTimes, setAllChosenTimes] = useLocalStorage("workTimes", "");
  const [draftId, setDraftId] = useLocalStorage("draft_id", "");
  const [showModalJobType, setShowModalJobType] = useState(false);
  const [netPay, setNetPay] = useState("");
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModalSkills, setShowModalSkills] = useState(false);
  const [showModalBudget, setShowModalBudget] = useState(false);
  const [sending, setSending] = useLocalStorage("dataToUpdate", "");
  const [postcode, setPostcode] = useLocalStorage("postcode", "");
  const [country, setCountry] = useState();
  const [postcodeID, setPostcodeID] = useState("");
  const [showAdvancedPreferences, setShowAdvancedPreferences] = useState(false);

  const [workersNeeded, setWorkersNeeded] = useState("01");
  const [warning, setWarning] = useState(false);
  const [jobDate, setJobDate] = useState({ start: null, end: null });
  const [checkErrors, setCheckErrors] = useState<{
    title: boolean;
    details: boolean;
  }>({ title: false, details: false });
  const parent = useRef(null);
  const actions = useActionTypesStore((s: any) => s.actions);

  const inputRefTitle = useRef<HTMLInputElement>(null);
  const inputRefDetails = useRef<HTMLTextAreaElement>(null);
  const topRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const { updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.name === "Post job"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.name === "Post job"
    )[0]?.id
  );
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const navigate = useNavigate();
  const data = new FormData();

  const curr = new Date();
  curr.setDate(curr.getDate());
  const date = curr.toISOString().substring(0, 10);
  const profileType = userProfileStore((s) => s.profileTypeToken);

  useEffect(() => {
    if (!sending) {
      setSending((prev: any) => ({
        ...prev,
        title: jobTitle,
        details: jobDescription,
        category: selectedCategory,
        job_type: jobType,
        location: jobLocation,
        postcode: postcode,
        budget: budget,
        og_skills: clientSkills?.map(
          (skill: { skill_id: string }) => skill.skill_id
        ),
      }));
    }
  }, [
    jobTitle,
    jobDescription,
    selectedCategory,
    jobType,
    jobLocation,
    postcode,
    budget,
    clientSkills,
    sending,
  ]);

  const checkAppendings = (props: string) => {
    if (sending.title !== jobTitle) {
      data.append("title", jobTitle);
    }
    if (sending.details !== jobDescription) {
      data.append("details", jobDescription);
    }
    if (sending.category !== selectedCategory) {
      data.append("category_id", whatID(selectedCategory));
    }
    if (
      JSON.stringify(sending.og_skills) !=
      JSON.stringify(
        clientSkills?.map((skill: { skill_id: string }) => {
          return skill.skill_id;
        })
      )
    ) {
      data.append(
        "skills_list",
        clientSkills?.map((skill: { skill_id: string }) => skill.skill_id)
      );
    }
    if (sending.job_type != jobType) {
      if (jobType == 2) {
        data.append("job_type", jobType);
      } else {
        data.append("job_type", jobType);
        data.append("city_id", jobLocationID);
        data.append("post_code_id", postcode?.id ? postcode?.id : postcodeID);
        data.append("country_code", "ES");
      }
    } else {
      if (sending.location !== jobLocation) {
        data.append("location_id", jobLocationID);
      }
      if (sending.postcode !== postcode) {
        data.append("post_code_id", postcodeID);
      }
    }

    if (sending.budget !== budget) {
      data.append("budget", budget);
    }
    if (props === "post") {
      // PAARA PASAR DE DRAFT A POST
      data.append("_method", "PUT");
      data.append("client_id", userID);
      data.append("draft_id", draftId);
      data.append("job_status_id", "1");
      data.append(
        "workers_needed",
        workersNeeded === "01" ? "1" : workersNeeded
      );
      data.append("net_pay", netPay);
      if (jobDate.end) data.append("date_end", jobDate.end);
      if (jobDate.start) data.append("date_start", jobDate.start);
      jobPosting(props);
    } else if (
      sending.budget !== budget ||
      sending.job_type != jobType ||
      sending.location !== jobLocation ||
      JSON.stringify(sending.og_skills) !=
        JSON.stringify(
          clientSkills?.map((skill: { skill_id: string }) => {
            return skill.skill_id;
          })
        ) ||
      sending.category !== selectedCategory ||
      // sending.details !== jobDescription ||
      sending.title !== jobTitle ||
      sending.details !== jobDescription
    ) {
      data.append("currency_code", "EUR");
      data.append("_method", "PUT");
      data.append("client_id", userID);
      data.append("workers_needed", workersNeeded);

      data.append("draft_id", draftId);
      data.append("net_pay", netPay);

      jobPosting(props);
    }
    // whatLocationID();
    setSelected();
    setPostcodeID("");
    setJobTitle();
    setCategoryID();
    setJobType();
    setBudget();
    setClientSkills();
    setClientSkillsID();
    setJobDescription();
    setJobLocation();
    setJobLocationID();
    setSelectedCategory();
    setAllChosenTimes();
    setDraftId();
    setSending();
    setPostcode();
    if (props === "post") {
      setTimeout(() => {
        if (profileType === "employer") {
          navigate("/dashboard");
        } else {
          navigate("/dashboard-worker");
        }
      }, 3000);
    } else {
      setTimeout(() => {
        if (profileType === "employer") {
          navigate("/dashboard");
        } else {
          navigate("/dashboard-worker");
        }
      }, 500);
    }
  };

  const { categories, refetchCategories } = useCategories();

  useEffect(() => {
    setAllCategories(categories);
  }, [categories]);

  const whatID = (category: string) => {
    for (let i = 0; i <= allCategories?.length; i++) {
      if (category === allCategories[i]?.name) {
        return allCategories[i].category_id;
      }
    }
  };

  const creditsData = new FormData();
  creditsData.append("_method", "POST");
  creditsData.append("client_id", userID);
  creditsData.append(
    "credits",
    `-${
      decryptArray(actions)?.filter(
        (action: { id: string; name: string; credits: string }) =>
          action.name === "Post job"
      )[0]?.credits
    }`
  );

  const jobPosting = (props: string) => {
    fetch(`${BASE_URL}${DRAFTS}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok" && props === "post") {
          setActivateConfetti(true);
          updateCredits();
        }
      })
      .catch((err) => console.log(err));
  };

  const scrollToSection = (e: any) => {
    e.current.scrollIntoView({ behavior: "smooth" });
  };

  const getDrafts = async () => {
    setLoading(true);
    const data = await fetch(`${BASE_URL}${DRAFTS}?draft_id=${draftId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
    })
      .then((res) => res.json())

      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });

    return data.result;
  };

  const { data: drafts } = useQuery(["drafts", draftId], getDrafts);

  useEffect(() => {
    if (jobType !== 2) setCountry(drafts?.data[0].country_code);
    if (clientSkills?.length == 0) setClientSkills(drafts?.skills);
    setPostcodeID(drafts?.data[0].post_code_id);
    setJobTitle(drafts?.data[0].title);
    setSelectedCategory(drafts?.data[0].category_name);
    setCategoryID(drafts?.data[0].category_id);
    setNetPay(drafts?.data[0].netpay);
    setJobType(drafts?.data[0].job_type);
    setBudget(drafts?.data[0].budget);
  }, [drafts]);

  useEffect(() => {
    if (jobDate.end && jobDate.start) {
      if (jobDate.end < jobDate.start) {
        setWarning(true);
      } else {
        setWarning(false);
      }
    }
  }, [jobDate]);

  useEffect(() => {
    if (jobTitle?.length >= 8)
      setCheckErrors((prev: any) => ({ ...prev, title: false }));
    if (jobDescription?.length >= 50)
      setCheckErrors((prev: any) => ({ ...prev, details: false }));
  }, [jobTitle, jobDescription]);

  return (
    <>
      {activateConfetti && (
        <>
          <Confetti
            numberOfPieces={300}
            gravity={0.3}
            initialVelocityY={150}
            initialVelocityX={10}
          />
          <div
            className={`flex justify-center transition-opacity duration-700 ${
              !activateConfetti ? "opacity-0 " : "opacity-100 "
            }`}
          >
            <div className="bg-green-600 text-white dark:text-whiteish font-bold text-xl rounded-lg py-3.5 tracking-wide px-6 mt-12 fixed items-start border">
              <div className="flex justify-between gap-[78px] items-center">
                <div className="flex gap-4 items-center">
                  <Check className="h-8 fill-white" />
                  <p>{t("employer.job_posting_created")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!activateConfetti && (
        <div className="flex flex-col mt-20 lg:mt-10 bg-white dark:bg-background dark:text-whiteish  rounded my-10 border dark:border dark:border-border">
          <div className="flex flex-col lg:flex-row items-start pl-8 lg:pl-0  border-b border-blue-100 w-full justify-between dark:border-border dark:border">
            <h1
              ref={topRef}
              className="lg:p-8 text-3xl text-[#343a47] font-bold dark:text-whiteish"
              onClick={() => setActivateConfetti(false)}
            >
              {t("review.jobpostreview.title")}
            </h1>
            <Button
              className="px-2 group w-40 lg:m-8  text-white my-4  bg-azulBonico hover:bg-azulOscuro  mr-8 py-1 relative"
              onClick={() => {
                if (jobTitle?.length >= 8 && jobDescription?.length >= 50) {
                  checkAppendings("post");
                } else {
                  if (jobDescription?.length < 50 || !jobDescription) {
                    inputRefDetails.current?.focus();
                    setCheckErrors((prev: any) => ({ ...prev, details: true }));
                  }
                  if (jobTitle?.length < 8) {
                    inputRefTitle.current?.focus();
                    setCheckErrors((prev: any) => ({ ...prev, title: true }));
                  }
                }
                scrollToSection(topRef);
              }}
            >
              {t("review.jobpostreview.rightBtn-Up")}
              <span className="group-hover:opacity-100 opacity-0 text-[#333] bg-gray-50 rounded-full border flex items-center text-xs absolute -top-8 right-0 px-2 py-1 ">
                {
                  decryptArray(actions)?.filter(
                    (action: { id: string; name: string; credits: string }) =>
                      action.name === "Post job"
                  )[0]?.credits
                }
                <img src={FlexiCoin} className="h-5 w-5 ml-2" />
              </span>
            </Button>
          </div>
          <div className="flex flex-col py-4 px-8 border-b-[0.5px] border-blue-100 dark:border-border dark:border">
            <p className="mb-4 font-bold">
              {t("review.jobpostreview.body.card1.subtitle1")}
            </p>
            <input
              ref={inputRefTitle}
              className="border h-10 pl-4 dark:border-border outline-none rounded dark:bg-input hover:border-azulBonico focus:border-azulBonico transition duration-300 w-full lg:w-2/3"
              defaultValue={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            ></input>
            {checkErrors.title && (
              <div className=" flex align-center text-[#9b211b] text-xs mt-4">
                <img className="exclamation" src={exclamation} alt="hey" />{" "}
                {t("employer.post_review.larger_title")} (min. 8 characters)
              </div>
            )}
          </div>
          <div className="flex flex-col py-4 px-8 border-b border-blue-100 dark:border-border dark:border">
            <p className="font-bold mb-4">
              {t("review.jobpostreview.body.card2.subtitle2")}
            </p>
            <p className="text-sm mb-2">
              {t("review.jobpostreview.body.card2.subtitle2.text1")}
            </p>
            <p className="text-sm mb-4">
              {t("review.jobpostreview.body.card2.subtitle2.text2.1")}
              <span className="text-azulBonico">
                {" "}
                {t("review.jobpostreview.body.card2.subtitle2.text2.1.link")}
              </span>
              &nbsp;{t("review.jobpostreview.body.card2.subtitle2.text2.2")}
            </p>
            <form>
              <div className="flex flex-col w-full">
                <textarea
                  ref={inputRefDetails}
                  value={jobDescription}
                  placeholder={`${t(
                    "review.jobpostreview.body.card2.subtitle2.describejobplaceholder"
                  )}`}
                  onChange={(e) => setJobDescription(e.target.value)}
                  className="w-full lg:w-2/3 h-40 p-2 px-4 text-sm border outline-none dark:bg-input dark:border-border dark:border border-blue-100 rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
                />
                {jobDescription?.length < 50 && (
                  <p className="text-xs flex w-full mt-2 lg:mt-0 lg:w-2/3 justify-end">
                    {i18n.language === "en" ? (
                      <>
                        At least {50 - jobDescription?.length} characters more
                      </>
                    ) : (
                      <>Faltan {50 - jobDescription?.length} caracteres más</>
                    )}
                  </p>
                )}
              </div>
            </form>
            {checkErrors.details && (
              <div className=" flex align-center text-[#9b211b] text-xs mt-4">
                <img className="exclamation" src={exclamation} alt="hey" />{" "}
                {t("employer.post_review.larger_description")}
              </div>
            )}
          </div>
          <div className="flex flex-col border-b border-blue-100 dark:border-border dark:border">
            <div className="flex flex-col p-4 pb-3 px-8">
              <p className="font-bold mb-4">
                {t("review.jobpostreview.body.card3.subtitle3")}
              </p>
              <div className="flex items-center">
                <p className="text-sm mr-4">{selectedCategory}</p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 cursor-pointer"
                  onClick={() => setShowModalCategory(true)}
                ></img>
              </div>
              {showModalCategory === true && (
                <ModalEdit
                  setShowModal={setShowModalCategory}
                  title={`${t("employer.job_post_edit.category")}`}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  categoryID={categoryID}
                  setCategoryID={setCategoryID}
                />
              )}
            </div>
            <div className="flex flex-col p-3 px-8">
              <p className="font-bold mb-4">
                {t("review.jobpostreview.body.card3.subtitle4")}
              </p>
              <div className="flex items-center flex-wrap">
                {clientSkills?.map((skill: { skill_name: string }) => {
                  return (
                    <p
                      className="mr-2 bg-[#4d5669] hover:bg-[#292e38] text-white rounded-full px-2 py-1 text-xs cursor-default mb-1"
                      key={Math.random()}
                    >
                      {skill.skill_name}
                    </p>
                  );
                })}

                <img
                  src={edit}
                  alt="edit"
                  className="h-3 ml-4 cursor-pointer"
                  onClick={() => setShowModalSkills(true)}
                ></img>
              </div>
              {showModalSkills === true && (
                <ModalEdit
                  setShowModal={setShowModalSkills}
                  title={t("employer.job_post_edit.skills")}
                  clientSkills={clientSkills}
                  setClientSkills={setClientSkills}
                />
              )}
            </div>
            <div className="flex flex-col p-3 px-8">
              <p className="font-bold mb-4">
                {t("review.jobpostreview.body.card3.subtitle5")}
              </p>
              <div className="flex items-center">
                <p className="text-sm mr-4">
                  {(jobType === 1 || jobType === "1") && jobLocation && (
                    <>
                      <p> {t("employer.job_posting.in_person")}</p>
                      <p className="font-semibold">
                        {jobLocation?.name
                          ? jobLocation.name
                          : jobLocation.label}
                      </p>
                      <p className="font-semibold">
                        {" "}
                        {postcode}, {country}
                      </p>
                    </>
                  )}
                  {(jobType === 2 || jobType === "2") && "Remote"}
                  {(jobType === 3 || jobType === "3") && jobLocation && (
                    <>
                      <p>{t("employer.job_posting.hybrid")}</p>
                      <p className="font-semibold">
                        {jobLocation?.name
                          ? jobLocation.name
                          : jobLocation.label}
                      </p>
                      <p className="font-semibold">
                        {" "}
                        {postcode}, {country}
                      </p>
                    </>
                  )}
                </p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 cursor-pointer"
                  onClick={() => setShowModalJobType(true)}
                />
              </div>
              {showModalJobType === true && (
                <ModalEdit
                  setShowModal={setShowModalJobType}
                  title={`${t("employer.job_post_edit.type_job")}`}
                  setJobType={setJobType}
                  jobType={jobType}
                  setJobLocation={setJobLocation}
                  jobLocation={jobLocation}
                  // setJobInfo={setJobInfo}
                  setJobLocationID={setJobLocationID}
                  setPostcodeID={setPostcodeID}
                  postcode={postcode}
                  setPostcode={setPostcode}
                  country={country}
                  setCountry={setCountry}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </div>
            <div className="flex flex-col p-3 px-8">
              <p className="font-bold mb-4">
                {t("review.jobpostreview.body.card3.subtitle6")}
              </p>
              <div className="flex items-center">
                <p className="text-sm mr-4">
                  {budget} € {netPay === "0" ? "brutos" : "netos"} / h
                </p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 cursor-pointer"
                  onClick={() => setShowModalBudget(true)}
                ></img>
              </div>
              {showModalBudget === true && (
                <ModalEdit
                  setShowModal={setShowModalBudget}
                  title={`${t("employer.job_post_edit.budget")}`}
                  showModal={showModalBudget}
                  budget={budget}
                  setBudget={setBudget}
                  setSelectedCurrencyCode={setSelectedCurrencyCode}
                  setNetPay={setNetPay}
                />
              )}
            </div>
            <div className="flex flex-col p-3 px-8 mb-3">
              <div className="flex items-center gap-4 mb-4">
                <p className="font-bold">
                  {t("review.jobpostreview.body.card3.subtitle7")}
                </p>
                <img
                  src={edit}
                  alt="edit"
                  className="h-3 cursor-pointer"
                  onClick={() => setShowModalEditSchedule(true)}
                ></img>
              </div>
              {showModalEditSchedule && (
                <ModalEditSchedule
                  // profileType={"employer"}
                  setShowModal={setShowModalEditSchedule}
                  showModal={showModalEditSchedule}
                  allChosenTimes={allChosenTimes}
                  draftId={draftId}
                  setAllChosenTimes={setAllChosenTimes}
                />
              )}
              <AvailableDays allChosenTimes={allChosenTimes} />
            </div>
            <div ref={parent}>
              <div
                className="border-t border-blue-100 cursor-pointer flex justify-between items-center px-8 py-6 dark:border-border dark:border "
                onClick={() =>
                  setShowAdvancedPreferences(!showAdvancedPreferences)
                }
              >
                <p className="text-base font-bold">
                  {t("review.jobpostreview.body.card4.subtitle8")}
                </p>
                {/* {showAdvancedPreferences ? ( */}
                <ArrowUp
                  className={`h-3 fill-black dark:fill-whiteish ${
                    showAdvancedPreferences ? "rotate-180" : "rotate-0"
                  } transform transition-all duration-150 ease-in-out`}
                />
                {/* // ) : (
                //   <ArrowDown className="h-3 fill-black" />
                // )} */}
              </div>
              {showAdvancedPreferences && (
                <div
                  className={`text-sm px-8 flex flex-col pb-6 gap-12 transition-height `}
                >
                  <div className=" flex items-center gap-8">
                    <div className="flex flex-col gap-1">
                      <p className="font-bold">
                        {t("review.jobpostreview.body.card4.left.subtitle9")}
                      </p>
                      <input
                        type="date"
                        min={date}
                        className="border-2 border-slate-200 w-fit px-4 py-1 rounded-lg text-sm text-slate-400 cursor-pointer outline-none"
                        onChange={(e) => {
                          setJobDate((prev: any) => ({
                            ...prev,
                            start: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className=" flex flex-col gap-1">
                      <p className="font-bold">
                        {t("review.jobpostreview.body.card4.right.subtitle9")}
                      </p>
                      <input
                        type="date"
                        min={jobDate.start!}
                        className="border-2 border-slate-200 w-fit px-4 py-1 rounded-lg text-sm text-slate-400 cursor-pointer outline-none"
                        onChange={(e) =>
                          setJobDate((prev: any) => ({
                            ...prev,
                            end: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className={`mt-4 ${!warning && "hidden"}`}>
                      <div className="flex items-center">
                        <img
                          className="exclamation"
                          src={exclamation}
                          alt="hey"
                        />
                        <p className="text-sm text-[#9b211b] font-bold">
                          {t("employer.job_posting.end_date_higher")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex items-center gap-24">
                    <div className="flex flex-col gap-2">
                      <p className="font-bold mb-1">
                        {t("review.jobpostreview.body.card4.subtitle10")}
                      </p>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="01"
                          checked={workersNeeded === "01"}
                          onChange={() => setWorkersNeeded("01")}
                        />
                        {t("review.jobpostreview.body.card4.subtitle10.check1")}
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="More"
                          checked={workersNeeded !== "01"}
                          onChange={() => setWorkersNeeded("2")}
                        />
                        {t("review.jobpostreview.body.card4.subtitle10.check2")}
                      </label>
                      {workersNeeded !== "01" && (
                        <input
                          type="number"
                          className="w-full outline-none border-2 dark:bg-input dark:border-border dark:border border-blue-100 hover:border-blue-200 rounded h-7 px-2 focus:border-blue-200"
                          defaultValue={workersNeeded}
                          onChange={(e) => setWorkersNeeded(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end px-8 py-6">
            <div className="flex">
              <Button
                className="mr-6 text-[13px] lg:text-[15px] text-azulBonico hover:underline hover:text-azulOscuro px-4 bg-[#f8f8f8]"
                onClick={() => checkAppendings("draft")}
              >
                {t("review.jobpostreview.footer.card5.subtitle.leftlink")}
              </Button>
              <Button
                className="px-6 group relative font-bold h-10 text-[13px] lg:text-[15px] text-white bg-azulBonico hover:bg-azulOscuro rounded-full"
                onClick={() => {
                  if (jobTitle?.length >= 8 && jobDescription?.length >= 50) {
                    checkAppendings("post");
                  } else {
                    if (jobDescription?.length < 50 || !jobDescription) {
                      inputRefDetails.current?.focus();
                      setCheckErrors((prev) => ({ ...prev, details: true }));
                    }
                    if (jobTitle?.length < 8) {
                      inputRefTitle.current?.focus();

                      setCheckErrors((prev) => ({ ...prev, title: true }));
                    }
                  }
                  scrollToSection(topRef);
                }}
              >
                {t("review.jobpostreview.footer.card5.subtitle.rightBtn-Down")}
                <span className="group-hover:opacity-100 opacity-0 text-[#333] bg-gray-50 rounded-full border flex items-center text-xs absolute -top-7 right-0 px-2 py-1 ">
                  {
                    decryptArray(actions)?.filter(
                      (action: { id: string; name: string; credits: string }) =>
                        action.name === "Post job"
                    )[0]?.credits
                  }
                  <img src={FlexiCoin} className="h-5 w-5 ml-2" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
