import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { ReactComponent as Trash } from "src/assets/img/trash-can.svg";
import { ReactComponent as Edit } from "src/assets/img/edit.svg";
import { ReactComponent as Check } from "src/assets/img/check-solid.svg";
import { ReactComponent as Cross } from "src/assets/img/crosszwei.svg";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL } from "../../Config/api.config";
import { hoursInitial } from "../../Utils/content/Hours";
import { toast } from "react-hot-toast";
import LoaderSmall from "../Worker/Registration/LoaderSmall";
import { useTranslation } from "react-i18next";
import ModalDuplicateHour from "../Modals/ModalDuplicateHour";
import { IoCopyOutline } from "react-icons/io5";
import { useAtom } from "jotai";
import { wantsToReplicate } from "../../Atoms/jotaiAtoms";
import { HourDaysTypes } from "../../types/types";
import autoAnimate from "@formkit/auto-animate";

interface ScheduleTimesProps {
  draftId: string;
  profileType: string;
  hour: HourDaysTypes;
  handleDelete: (id: string) => void;
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  refetchTimes: () => void;
  day: string;
}

const ScheduleTimes = ({
  hour,
  handleDelete,
  day,
  setUpdate,
  profileType,
  draftId,
  refetchTimes,
}: ScheduleTimesProps) => {
  const [hours, setHours] = useState(hoursInitial);
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [startingHours, setStartingHours] = useState<any>();
  const [startingHour, setStartingHour] = useState<any>(
    hours.find((obj) => {
      return obj.value === hour.hour_from?.slice(0, 5);
    })
  );
  const parent = useRef(null);
  const [endingHours, setEndingHours] = useState<any>();
  const [showEditPopover, setShowEditPopover] = useState(false);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [isBeingReplicated, setIsBeingReplicated] = useAtom(wantsToReplicate);

  const [endingHour, setEndingHour] = useState<any>(
    hours.find((obj) => {
      return obj.value === hour.hour_to?.slice(0, 5);
    })
  );
  const [showEditable, setShowEditable] = useState(false);
  // const [error, setError] = useState();
  const [preStateFrom, setPreStateFrom] = useState(
    hours.find((obj) => {
      return obj.value === hour.hour_from?.slice(0, 5);
    })
  );
  const [preStateTo, setPreStateTo] = useState(
    hours.find((obj) => {
      return obj.value === hour.hour_to?.slice(0, 5);
    })
  );
  const [loading, setLoading] = useState(false);
  const [modalReplicate, setModalReplicate] = useState(false);
  const [dayToReplicateTo, setDayToReplicateTo] = useState();
  const { t, i18n } = useTranslation();

  const putData = new FormData();
  putData.append("_method", "PUT");
  putData.append("hour_from", startingHour?.label);
  putData.append("hour_to", endingHour?.label);
  putData.append("enabled", "1");
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const updateTime = (id: string) => {
    setLoading(true);
    putData.append("dayname", day);
    if (profileType === "worker") {
      putData.append("worker_time_profile_id", id);
      putData.append("client_id", userID);
    } else {
      putData.append("job_time_profile_id", id);
      putData.append("job_id", draftId);
    }
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: putData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast.success("Hours Updated!");
          setShowEditable(false);
          setUpdate(true);
          // setError(false);
        } else {
          // setError(data.result.error_msg);
          toast.error(data.result.error_msg);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const replicateData = new FormData();
  replicateData.append("_method", "POST");
  replicateData.append("hour_from", startingHour.label);
  replicateData.append("hour_to", endingHour.label);

  const deleteIfReplicatedFull = (day: string) => {
    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile?${
        profileType === "worker" ? "client_id" : "job_id"
      }=${profileType === "worker" ? userID : draftId}&dayname=${day}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          refetchTimes();
        }
      });
  };

  const replicateTimeAnotherDay = (day: string) => {
    if (startingHour.value === "00:00" && endingHour.value === "23:59") {
      deleteIfReplicatedFull(day);
    }
    if (profileType === "worker") {
      replicateData.append(
        "worker_time_profile_id",
        hour.worker_time_profile_id
      );
      replicateData.append("client_id", userID);
      replicateData.append("dayname", day);
    } else if (profileType === "employer") {
      replicateData.append("job_time_profile_id", hour.job_time_profile_id);
      replicateData.append("job_id", draftId);
      replicateData.append("dayname", day);
    } else {
      return null;
    }

    fetch(
      `${BASE_URL}${profileType === "worker" ? "worker" : "job"}.time.profile`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
        body: replicateData,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          toast.success(
            i18n.language === "en"
              ? `Hour ${startingHour.value} - ${endingHour.value} replicated in ${day} `
              : `Horario de ${startingHour.value}-${endingHour.value} replicado en ${day}`
          );

          refetchTimes();
          setModalReplicate(false);
        }
      });
  };

  useEffect(() => {
    if (showEditable) {
      setStartingHour(
        hours.find((obj) => {
          return obj.value === hour.hour_from?.slice(0, 5);
        })
      );
      setEndingHour(
        hours.find((obj) => {
          return obj.value === hour.hour_to?.slice(0, 5);
        })
      );
    }
  }, [showEditable]);

  const handleStartingHourLimit = () => {
    const append = hours?.map(function (row) {
      if (row.id < endingHour?.id) {
        return {
          label: row.label,
          value: row.value,
          id: row.id,
        };
      } else {
        return {
          label: "",
          value: "",
          id: "",
        };
      }
    });
    setStartingHours(append.filter((item) => item.id !== ""));
  };
  const handleEndingHourLimit = () => {
    const append = hours?.map(function (row) {
      if (row.id > startingHour?.id) {
        return {
          label: row.label,
          value: row.value,
          id: row.id,
        };
      } else {
        return {
          label: "",
          value: "",
          id: "",
        };
      }
    });
    setEndingHours(append.filter((item) => item.id !== ""));
  };

  useEffect(() => {
    handleStartingHourLimit();
  }, [endingHour]);
  useEffect(() => {
    handleEndingHourLimit();
  }, [startingHour]);

  const handleMouseEnter = (props: string) => {
    if (props === "edit") {
      setShowEditPopover(true);
    } else if (props === "delete") {
      setShowDeletePopover(true);
    }
  };
  const handleMouseLeave = (props: string) => {
    if (props === "edit") {
      setShowEditPopover(false);
    } else if (props === "delete") {
      setShowDeletePopover(false);
    }
  };

  const colourStyles = {
    control: () => {
      return {
        // ...styles,
        border: "1px solid grey",
        "&:hover": {
          borderColor: "#2abeeb",
        },
        display: "flex",
        width: "150px",
        padding: "0rem",
        fontColor: "#000",
        borderRadius: "5px",
        height: "40px",
        fontSize: "14px",
      };
    },
    menuPortal: (base: any) => ({
      ...base,
      zIndex: 9999,
      fontSize: "14px",
    }),
  };

  return (
    <main className="relative">
      {loading && (
        <div className="flex items-center mx-auto my-3 w-full justify-center h-10">
          <LoaderSmall />
        </div>
      )}
      {!loading && (
        <div className="flex flex-col items-end">
          <div
            className="flex items-center mx-auto gap-8 my-1 py-2 pl-1 border-b-[0.5px]"
            onClick={(e) => e.stopPropagation()}
          >
            <Select
              isDisabled={!showEditable === false ? false : true}
              options={startingHours}
              className="text-sm"
              menuPortalTarget={document.querySelector("body")}
              styles={colourStyles}
              value={{
                label: startingHour?.label,
                value: startingHour?.value,
              }}
              onChange={(e) => {
                setStartingHour(e);
              }}
            />
            <p className="text-sm text-slate-400">
              {t("welcome-client.rightcard.dayweek.monday.textBtwPlaceholders")}
            </p>
            <Select
              isDisabled={!showEditable === false ? false : true}
              options={endingHours}
              className="text-sm "
              menuPortalTarget={document.querySelector("body")}
              styles={colourStyles}
              value={{
                label: endingHour?.label,
                value: endingHour?.value,
              }}
              onChange={(e) => {
                setEndingHour(e);
              }}
            />
          </div>
          <div ref={parent} className="flex items-center  transition">
            {!showEditable ? (
              <div className="flex flex-col justify-between transition">
                <div className="flex items-center justify-around mt-5">
                  <div className="relative">
                    <Edit
                      className="h-[15px] w-full hover:fill-green-600 fill-green-500 cursor-pointer transition-all transform duration-150 ease-in-out"
                      onMouseEnter={() => handleMouseEnter("edit")}
                      onMouseLeave={() => handleMouseLeave("edit")}
                      onClick={() => {
                        setShowEditable(true);
                        setShowEditPopover(false);
                      }}
                    />
                    <div
                      className={`inline-block absolute -top-[44.4px] z-10 -left-3 w-10 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 border dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                        showEditPopover === false
                          ? "opacity-0 transition-opacity duration-300 -z-10"
                          : " opacity-1 transition-opacity duration-300"
                      }
                `}
                    >
                      <div className="py-1 px-[3px]">
                        <p className="text-sm">
                          {t(
                            "createprofile.submit.edityourAvailableHours.card.pencilIcon"
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`bg-white z-50 border-r-2 border-b-2 w-3 h-3 rotate-45 absolute -top-[20px] left-0.5 ${
                        showEditPopover === false
                          ? "opacity-0 transition-opacity duration-300 -z-10"
                          : " opacity-1  transition-opacity duration-300"
                      } `}
                    ></div>
                  </div>
                  <div className="relative">
                    <Trash
                      className="h-[15px] hover:fill-black fill-slate-500 cursor-pointer"
                      onMouseEnter={() => handleMouseEnter("delete")}
                      onMouseLeave={() => handleMouseLeave("delete")}
                      onClick={() => {
                        if (profileType === "worker") {
                          handleDelete(hour.worker_time_profile_id);
                        } else {
                          handleDelete(hour.job_time_profile_id);
                        }

                        // setSelectedHours(
                        //   selectedHours.filter((item) => item.id !== hour.id)
                        // );
                      }}
                    />
                    <div
                      className={`inline-block absolute -top-[44.4px] z-10 -left-3 w-13 text-sm font-light text-gray-500 bg-white rounded-lg border border-gray-200 border dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${
                        showDeletePopover === false
                          ? "opacity-0 transition-opacity duration-300 -z-10"
                          : " opacity-1 transition-opacity duration-300"
                      }
                `}
                    >
                      <div className="py-1 px-[7px]">
                        <p className="text-sm">
                          {t(
                            "createprofile.submit.edityourAvailableHours.card.trashIcon"
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={`bg-white z-50 border-r-2 border-b-2 w-3 h-3 rotate-45 absolute -top-[20px] left-0.5 ${
                        showDeletePopover === false
                          ? "opacity-0 transition-opacity duration-300 -z-10"
                          : " opacity-1  transition-opacity duration-300"
                      } `}
                    ></div>
                  </div>
                </div>

                <div className="group relative mr-1">
                  <button
                    onClick={() => {
                      setModalReplicate(!modalReplicate);

                      setIsBeingReplicated(!isBeingReplicated);
                    }}
                    className="flex items-center border mt-2 px-2 py-1 rounded hover:bg-gray-100 transition dark:text-whiteish"
                  >
                    <IoCopyOutline className="text-azulBonico" />
                    <p className="text-[11px] ml-1 ">
                      {t(
                        "createprofile.submit.edityourAvailableHours.card.replicateBtn"
                      )}
                    </p>
                  </button>
                  {/* </div> */}
                </div>
              </div>
            ) : (
              <div className="flex items-center w-14 justify-between transition">
                <div
                  onClick={() => {
                    setStartingHour(preStateFrom);
                    setEndingHour(preStateTo);
                    setShowEditable(false);
                  }}
                  className="hover:bg-gray-100 transition h-6 flex items-center justify-center w-6 rounded cursor-pointer"
                >
                  <Cross className="h-3 fill-red-500 cursor-pointer" />
                </div>
                <div
                  onClick={() => {
                    if (profileType === "worker") {
                      updateTime(hour.worker_time_profile_id);
                    } else {
                      updateTime(hour.job_time_profile_id);
                    }
                  }}
                  className="hover:bg-gray-100 transition h-6 flex items-center justify-center w-6 rounded cursor-pointer"
                >
                  <Check className="h-4 fill-green-500 " />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {modalReplicate && (
        <ModalDuplicateHour
          modalReplicate={modalReplicate}
          setModalReplicate={setModalReplicate}
          daySelected={day}
          startingHour={startingHour}
          endingHour={endingHour}
          replicateTimeAnotherDay={replicateTimeAnotherDay}
        />
      )}
    </main>
  );
};

export default ScheduleTimes;
