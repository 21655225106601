import { BASE_URL } from "src/Config/api.config"
import useLocalStorage from "./UseLocalStorage";
import { useState } from "react";
import { useCreditsActions } from "src/Routing/useCreditsActions";

const useUpdateCredits = (price: string, actionId: string) => {
    const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
    const [userID, setUserId] = useLocalStorage("IDUser", "");
    const [creditsDataInfo, setCreditsDataInfo] = useState("")
    const [creditsActions, setCreditsActions] = useCreditsActions((state) => [
        state.actionTypes,
        state.setActionTypes
    ])


    const creditsData = new FormData();
    creditsData.append("_method", "POST");
    creditsData.append("client_id", userID);
    creditsData.append("action_id", actionId)

    const updateCredits = async (numberOfTimes?: number) => {
        if (numberOfTimes) {

            creditsData.append("credits", `-${(Number(price) * Number(numberOfTimes))?.toString()}`);
        } else {

            creditsData.append("credits", `-${((price))?.toString()}`);
        }
        const data = await fetch(`${BASE_URL}clients.wallet`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + loggedInUserUid
            },
            body: creditsData
        }).then((res) => res.json()).then((data) => setCreditsDataInfo(data.result)).catch((err) => console.log(err))

    }

    return { updateCredits, creditsDataInfo }

}
export default useUpdateCredits

