import React, {
  useEffect,
  useState,
  useRef,
  lazy,
  Dispatch,
  SetStateAction,
  ElementRef,
} from "react";
import { v4 as uniqueID } from "uuid";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import YearPicker from "../Time/YearPicker";
import MonthPicker from "../Time/MonthPicker";
import CountryPicker from "../Worker/Registration/CountryPicker";
import { BASE_URL, EXPERIENCE_WORKER } from "../../Config/api.config";
const Loader = lazy(() => import("src/Components/Loader/Loader"));

import exclamation from "../../assets/img/exclamation.png";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

interface ModalEditEmploymentProps {
  setShowEditModal: Dispatch<SetStateAction<boolean>>;
  showEditJobModal: boolean;
}

export default function ModalEditEmployment({
  setShowEditModal,
  showEditJobModal,
}: ModalEditEmploymentProps) {
  const { t } = useTranslation();
  const [checking, setChecking] = useState(false);
  const [job, setJob] = useLocalStorage("dataJobEdit", []);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [startingMonth, setStartingMonth] = useState<string>("");
  const [startingYear, setStartingYear] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [endingMonth, setEndingMonth] = useState<any>();
  const [endingYear, setEndingYear] = useState<any>();
  const unique_id = uniqueID();
  const [checked, setChecked] = useState(false);
  const [transition, setTransition] = useState(false);
  const handleChecked = () => {
    setChecked(!checked);
  };
  const ref = useRef<ElementRef<"div">>(null);
  const data = new FormData();
  const editExperience = () => {
    data.append("_method", "PUT");
    data.append("company_name", job.company_name);
    data.append("city", job.city);
    data.append("country_code", "ES");
    data.append("job_title", job.job_title);
    data.append("description", job.description);
    data.append("experience_id", job.id);
    fetch(`${BASE_URL}${EXPERIENCE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };
  const handleCurrently = () => {
    if (job.currently === "1") {
      setChecked(true);
    }
  };
  const handleEndDate = () => {
    if (endingMonth && endingYear) {
      data.append("end_date", `${endingYear}-${endingMonth}-01`);
    } else if (endingMonth && !endingYear) {
      data.append(
        "end_date",
        `${job.end_date.substring(0, 4)}-${endingMonth}-01`
      );
    } else if (!endingMonth && endingYear) {
      data.append(
        "end_date",
        `${endingYear}-${job.end_date.substring(5, 7)}-01`
      );
    } else if (!endingMonth && !endingYear) {
      data.append("end_date", job.end_date);
    }
    if (checked === false) {
      data.append("currently", "0");
    } else if (checked === true) {
      data.append("currently", "1");
      data.append("end_date", "");
    }
  };
  const handleStartDate = () => {
    if (startingMonth && startingYear) {
      data.append("start_date", `${startingYear}-${startingMonth}-01`);
    } else if (startingMonth && !startingYear) {
      data.append(
        "start_date",
        `${job.start_date.substring(0, 4)}-${startingMonth}-01`
      );
    } else if (!startingMonth && startingYear) {
      data.append(
        "start_date",
        `${startingYear}-${job.start_date.substring(5, 7)}-01`
      );
    } else if (!startingMonth && !startingYear) {
      data.append("start_date", job.start_date);
    }
  };
  useEffect(() => {
    handleCurrently();
  }, []);

  const handleEditJob = () => {
    if (
      job.job_title &&
      job.city &&
      job.company_name &&
      job.country_code &&
      job.start_date
    ) {
      if ((endingMonth && endingYear) || job.end_date) {
        handleStartDate();
        handleEndDate();
        editExperience();
        setTimeout(() => {
          setShowEditModal(false);
          window.location.reload();
        }, 100);
      } else if ((!endingMonth || !endingYear) && checked === false) {
        if (!job.end_date) {
          setChecking(true);
        }
      } else {
        handleStartDate();
        handleEndDate();
        editExperience();
        setTimeout(() => {
          setShowEditModal(false);
          window.location.reload();
        }, 100);
      }
    } else {
      setChecking(true);
    }
  };

  const handleDescription = (e: any) => {
    setJob((job: any) => ({ ...job, description: e.target.value }));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showEditJobModal && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditJobModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal isOpen={showEditJobModal} style={StylesModal} ariaHideApp={false}>
      {" "}
      {/* {loading === false ? ( */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[700px] left-[50%] max-h-[90vh] overflow-y-auto border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edityourworkexperience.title.card")}
        </div>
        <form>
          <div className="flex flex-col">
            <div className="text-sm px-8 pt-4">
              <p className="font-bold">
                {t(
                  "createprofile.submit.edityourworkexperience.subtitle1.card"
                )}{" "}
                *
              </p>
              <input
                className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded transition duration-300 ${
                  checking === true && !job.job_title
                    ? "border-[#A4211B]"
                    : "hover:border-azulBonico focus:border-azulBonico"
                }`}
                placeholder={`${t(
                  "createprofile.submit.edityourworkexperience.subtitle1.card.placeholder"
                )}`}
                value={job?.job_title}
                onChange={(e) => {
                  setJob((job: any) => ({
                    ...job,
                    job_title: e.target.value,
                  }));
                }}
              ></input>
              {job?.job_title?.length === 0 && checking === true && (
                <div className="wrong-container text-sm">
                  <img className="exclamation" src={exclamation} alt="hey" />{" "}
                  {t("worker.modal_add_employment.add_job_title")}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-sm px-8 pt-4">
              <p className="font-bold">
                {t(
                  "createprofile.submit.edityourworkexperience.subtitle2.card"
                )}{" "}
                *
              </p>
              <div className="flex gap-4">
                <div className="flex flex-col flex-1">
                  <input
                    className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 ${
                      checking === true && !job.city
                        ? "border-[#A4211B]"
                        : "hover:border-azulBonico focus:border-azulBonico"
                    }`}
                    placeholder={`${t(
                      "createprofile.submit.edityourworkexperience.subtitle2.card.placeholder"
                    )}`}
                    value={job?.city}
                    onChange={(e) =>
                      setJob((job: any) => ({
                        ...job,
                        city: e.target.value,
                      }))
                    }
                  />
                  {job?.city?.length === 0 && checking === true && (
                    <div className="wrong-container">
                      <img
                        className="exclamation"
                        src={exclamation}
                        alt="hey"
                      />{" "}
                      {t("worker.modal_add_employment.add_location")}
                    </div>
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <CountryPicker
                    job={job}
                    setJob={setJob}
                    checking={checking}
                  />
                  {job?.country_code?.length === 0 && checking === true && (
                    <div className="wrong-container">
                      <img
                        className="exclamation"
                        src={exclamation}
                        alt="hey"
                      />{" "}
                      {t("worker.modal_add_employment.add_country")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-sm px-8 pt-4">
              <p className="font-bold">
                {t(
                  "createprofile.submit.edityourworkexperience.subtitle3.card"
                )}{" "}
                *
              </p>
              <input
                className={`w-full border h-10 pl-4 mt-2 outline-none text-sm rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 ${
                  checking === true && !job.company_name
                    ? "border-[#A4211B]"
                    : "hover:border-azulBonico focus:border-azulBonico"
                }`}
                placeholder={`${t(
                  "createprofile.submit.edityourworkexperience.subtitle3.card.placeholder"
                )}`}
                value={job?.company_name}
                onChange={(e) =>
                  setJob((job: any) => ({
                    ...job,
                    company_name: e.target.value,
                  }))
                }
              ></input>
              {job?.company_name?.length === 0 && checking === true && (
                <div className="wrong-container">
                  <img className="exclamation" src={exclamation} alt="hey" />{" "}
                  {t("worker.modal_add_employment.who_you_work_for")}
                </div>
              )}
            </label>
          </div>

          <div className="flex flex-col">
            <div className="text-sm px-8 pt-4">
              <p className="font-bold">
                {t(
                  "createprofile.submit.edityourworkexperience.subtitle4-1.card"
                )}{" "}
                *
              </p>
              <div className="flex gap-4 mt-2">
                <div className="flex flex-col flex-1">
                  <MonthPicker
                    job={job?.start_date}
                    setMonth={setStartingMonth}
                    month={startingMonth}
                    checking
                  />
                  {!job?.start_date && checking === true && (
                    <div className="wrong-container text-sm">
                      <img
                        className="exclamation"
                        src={exclamation}
                        alt="hey"
                      />{" "}
                      {t("worker.modal_add_employment.add_working_period")}
                    </div>
                  )}
                </div>
                <div className="flex flex-col flex-1">
                  <YearPicker
                    job={job?.start_date}
                    setYear={setStartingYear}
                    year={startingYear}
                    timespan={"From"}
                  />
                </div>
              </div>
              {checked === false ? (
                <>
                  <p className="text-[#6e7081] mt-2">
                    {t(
                      "createprofile.submit.edityourworkexperience.subtitle4-2.card"
                    )}
                  </p>
                  <div className="flex gap-4 mt-2">
                    <div className="flex flex-col flex-1">
                      <MonthPicker
                        job={job?.end_date}
                        setMonth={setEndingMonth}
                        month={endingMonth}
                        checking
                      />
                      {(!endingMonth || !endingYear) &&
                        !job?.end_date &&
                        checking === true && (
                          <div className="wrong-container text-sm">
                            <img
                              className="exclamation"
                              src={exclamation}
                              alt="hey"
                            />
                            {t(
                              "worker.modal_add_employment.add_working_period"
                            )}
                          </div>
                        )}
                    </div>
                    <div className="flex flex-col flex-1">
                      <YearPicker
                        job={job?.end_date}
                        setYear={setEndingYear}
                        year={endingYear}
                        timespan={"Through"}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <label className="flex items-center cursor-pointer mt-4 w-fit">
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={handleChecked}
                  className="mr-3"
                />
                {t(
                  "createprofile.submit.edityourworkexperience.subtitle4-3.card"
                )}
              </label>
            </div>
          </div>
          <div className="px-8">
            <p className="text-sm mb-3 pt-4 font-bold">
              {t("createprofile.submit.edityourworkexperience.subtitle5.card")}
            </p>
            <textarea
              className="w-full h-40 p-2 px-4 text-sm border outline-none border-blue-100 rounded hover:border-azulBonico focus:border-azulBonico transition duration-300"
              value={job?.description}
              onChange={(e) => {
                handleDescription(e);
              }}
            ></textarea>
          </div>
        </form>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1"
            onClick={() => {
              setJob();
              closeModal();
            }}
          >
            {t("createprofile.submit.edityourworkexperience.leftBtn")}
          </Button>
          <Button
            className={` border-none  text-white w-[110px] bg-azulBonico hover:bg-azulOscuro rounded-[20px] text-[0.9rem] px-[1rem]   h-10 `}
            onClick={() => {
              handleEditJob();
            }}
          >
            {t("createprofile.submit.edityourworkexperience.rightBtn")}
          </Button>
        </div>
      </div>
      {/* // ) : (
      //   <Suspense fallback={null}>
      //     <Loader />
      //   </Suspense>
      // )} */}
    </Modal>
  );
}
