import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import { cn } from "src/lib/utils";

import { StylesModal } from "src/Utils/stylesModal";
import Celebrating from "../../assets/img/celebrating.png";
import { useModalStore } from "src/Hooks/useModalStore";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import useLocalStorage from "src/Hooks/UseLocalStorage";
import { useTranslation } from "react-i18next";

interface ModalNewOfferProps {
  jobOffer: {
    client_id: string;
    client_id_worker: string;
    cover_letter: string;
    date_expire_job: string;
    date_expire_offer: string;
    date_received: string;
    job_id: string;
    job_status_id: string;
    job_status_name: string;
    received_ago: string;
    title: string;
  };
}

const ModalNewOffer = ({ jobOffer }: ModalNewOfferProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [transition, setTransition] = useState(false);
  const [showNewOffer, setShowNewOffer] = useModalStore((state) => [
    state.showNewOffer,
    state.setShowNewOffer,
  ]);
  const navigate = useNavigate();
  const [activateConfetti, setActivateConfetti] = useState(false);
  const [newOfferedView, setNewOfferedView] = useLocalStorage(
    "new_offer",
    false
  );

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowNewOffer(showNewOffer);
    }, 150);
  };

  useEffect(() => {
    setActivateConfetti(true);
    setTimeout(() => {
      setActivateConfetti(false);
    }, 4000);
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (showNewOffer && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showNewOffer]);

  return (
    <Modal isOpen={showNewOffer && !newOfferedView} style={StylesModal}>
      {activateConfetti && (
        <div className="fixed top-0 left-0">
          <Confetti
            numberOfPieces={300}
            gravity={0.3}
            initialVelocityX={10}
            initialVelocityY={150}
          />
        </div>
      )}
      <div
        className={cn(
          `fixed z-50 ${
            transition === false ? "scale-y-0" : "scale-y-100"
          } transition duration-150 w-full lg:w-[700px] lg:left-[20%] border  lg:translate-x-[50%] rounded top-[50%] right-0 translate-y-[-50%] bg-white border-[0.5px] `
        )}
        ref={ref}
      >
        <div className="flex items-center justify-between ">
          <div className="flex items-center space-x-2 pl-5">
            <img
              src={Celebrating}
              className="h-32 object-cover cursor-pointer "
            />

            <div className="py-4 pl-10">
              <h1 className="text-4xl font-bold">
                {t("worker.modal.new_job_offer.title")}
              </h1>
              <p className="text-gray-400 font-medium">
                {t("worker.modal.new_job_offer.subtitle")}
              </p>
            </div>
          </div>
          <div className="border border-border py-4 flex flex-col justify-between ">
            <div className="w-28 h-16 cursor-pointer flex items-center justify-center">
              <p
                onClick={() => {
                  setNewOfferedView(true);
                  navigate(`/job-offer/${jobOffer.job_id}`);
                }}
                className="text-center mb-4 uppercase text-azulBonico font-medium "
              >
                {t("worker.modal.new_job_offer.first_button")}
              </p>
            </div>
            <div className="border-t border-border w-full" />
            <div className="w-28 h-16 cursor-pointer flex items-center justify-center">
              <p
                onClick={() => {
                  setNewOfferedView(true);
                  setShowNewOffer(showNewOffer);
                }}
                className="text-center mt-4 uppercase text-rositaOscuro font-medium "
              >
                {t("worker.modal.new_job_offer.second_button")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNewOffer;
