import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, PROFILE_WORKER } from "../../Config/api.config";
import Modal from "react-modal";
import exclamation from "../../assets/img/exclamation.png";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";

const currencyCode = [{ value: "EUR", label: "EUR" }];
const SalaryType = [
  { value: "Bruto", label: "Bruto" },
  { value: "Neto", label: "Neto" },
];

interface ModalEditBudgetProps {
  setShowEditBudgetModal: React.Dispatch<React.SetStateAction<boolean>>;
  workerDataBudget: string | undefined;
  showEditBudgetModal: boolean;
  refetchData: () => void;
}

function ModalEditBudget({
  setShowEditBudgetModal,
  workerDataBudget,
  showEditBudgetModal,
  refetchData,
}: ModalEditBudgetProps) {
  const { t } = useTranslation();
  const [validation, setValidation] = useState(false);
  const [budget, setBudget] = useState<any>("");
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState("");
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [loading, setLoading] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  data.append("budget", budget);
  const updateBudget = () => {
    data.append("currency_code", "EUR");
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          refetchData();
          setShowEditBudgetModal(false);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setBudget(workerDataBudget);
  }, [workerDataBudget]);

  function isNumber(str: any) {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  }

  const handleBudget = (e: any) => {
    setBudget(e.target.value);
    if (!isNumber(e.target.value)) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditBudgetModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditBudgetModal]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightgrey",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      width: "60px",
      padding: "0rem",
      borderRadius: "5px",
      height: "40px",
      fontSize: "14px",
    }),
  };

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditBudgetModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);
  return (
    <Modal
      isOpen={showEditBudgetModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.35)",
        },
        content: {
          position: "absolute",
          top: "-20px",
          left: "40px",
          right: "40px",
          bottom: "0px",
          border: "none",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          backgroundColor: "transparent",
        },
      }}
      ariaHideApp={false}
    >
      {" "}
      {/* <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-50"> */}
      {/* {loading === false ? ( */}
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[580px] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          {t("createprofile.submit.edit_changesalary.title")}
        </div>
        <p className="px-8 py-4 text-sm">
          {t("createprofile.submit.edit_changesalary.subtitle")}
        </p>
        <div className="flex flex-col lg:flex-row justify-between w-full py-4 px-8 mb-8">
          <div className="flex flex-col text-sm w-full whitespace-nowrap">
            <p className="font-bold tracking-wide">
              {t("createprofile.budget.subTitle2")}
            </p>
            <p>{t("createprofile.budget.subTitle2.text")}</p>
          </div>
          <div className="flex items-center w-full justify-end mt-4 lg:mt-0">
            <input
              value={budget}
              className="border h-10 pl-4 pr-4 text-right text-sm outline-none rounded hover:border-azulBonico focus:border-azulBonico transition duration-300 w-1/3"
              placeholder="00.00"
              maxLength={8}
              onChange={(e) => handleBudget(e)}
            ></input>{" "}
            <Select
              options={currencyCode}
              styles={colourStyles}
              isDisabled={true}
              defaultValue={{
                label: "EUR",
                value: "EUR",
              }}
              onChange={(e: any) => setSelectedCurrencyCode(e.value)}
              className="text-sm cursor-pointer"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            <Select
              options={SalaryType}
              styles={colourStyles}
              // isDisabled={true}
              defaultValue={{
                label: "Bruto",
                value: "Bruto",
              }}
              onChange={(e: any) => setSelectedCurrencyCode(e.value)}
              className="text-sm cursor-pointer"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            {/* <Dollar
                  className="h-5 absolute left-3 top-[11px]"
                  fill={"#1d90b3"}
                /> */}
            {validation === true && (
              <div className="wrong-container absolute right-10 bottom-24">
                <img className="exclamation" src={exclamation} alt="hey" />
                {t("worker.profile.modal_edit_budget")}
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 mt-2 justify-end border-t border-blue-100">
          <Button
            className="bg-none text-[0.9rem]  w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro cursor-pointer mt-1"
            onClick={() => {
              closeModal();
            }}
          >
            {t("createprofile.submit.edit_changesalary.leftBtn")}
          </Button>
          <Button
            className={` border-none  w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] h-10 ${
              !budget
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white cursor-pointer"
            } `}
            disabled={!budget}
            onClick={(e) => {
              updateBudget();
              setLoading(true);
              // setTimeout(() => {
              //   window.location.reload(false);
              // }, 100);
            }}
          >
            {t("createprofile.submit.edit_changesalary.rightBtn")}
          </Button>
        </div>
      </div>
      {/* // ) : (
      //   <Suspense fallback={null}>
      //     <Loader />
      //   </Suspense>
      // )} */}
    </Modal>
  );
}

export default ModalEditBudget;
