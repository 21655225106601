import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLocalStorage from "./UseLocalStorage";

export const languages = [
  { value: "en", flag: "🇬🇧", label: "English" }, // English
  { value: "es", flag: "🇪🇸", label: "Español" }, // Spanish
  { value: "pt", flag: "🇵🇹", label: "Português" }, // Spanish
].sort((a, b) => a.label.localeCompare(b.label));

export const useLanguages = () => {
  const { i18n } = useTranslation();
  const options = [...languages];
  const [localLanguage, setLocalLanguage] = useLocalStorage(
    "language",
    "Español"
  );

  useEffect(() => {
    getParsedLanguage();
  }, [i18n]);

  const getParsedLanguage = () => {
    if (i18n.language === "es" || i18n.language === "es-ES") {
      return "Español";
    } else if (i18n.language === "en") {
      return "English";
    } else if (i18n.language === "pt") {
      return "Português";
    }
  };

  const languageChosen = getParsedLanguage();
  return { options, languageChosen };
};
