import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  navBarSettingsWorker,
  showEditableCompany,
} from "../../../Atoms/jotaiAtoms";
import ContactInfo from "../../../Components/WorkerSettingsBoard/ContactInfo";
import WorkerSettingsNavBar from "../../../Components/WorkerSettingsBoard/WorkerSettingsNavBar";
import NotificationSettings from "../../../Components/WorkerSettingsBoard/NotificationSettings";
import PasswordSecurity from "../../../Components/WorkerSettingsBoard/PasswordSecurity";
import { useAtom } from "jotai";

import { ReactComponent as Warning } from "../../../assets/img/check-solid.svg";

import { useTranslation } from "react-i18next";
import autoAnimate from "@formkit/auto-animate";
import WalletInfo from "src/Components/WorkerSettingsBoard/WalletInfo";
import Subscriptions from "src/Components/WorkerSettingsBoard/Subscriptions";
import GoBackButton from "src/Components/ui/GoBack";
import BillingPayments from "src/Components/WorkerSettingsBoard/BillingPayments";
import IdentityVerification from "src/Components/WorkerSettingsBoard/IdentityVerification";
import SubscriptionPlan from "src/Components/WorkerSettingsBoard/SubscriptionPlan";
import { userProfileStore } from "src/Routing/useProfileStore";

const Settings = () => {
  const profileType = userProfileStore((s) => s.profileTypeToken);

  const [decorationBar, setDecorationBar] = useAtom(navBarSettingsWorker);
  const [showEditCompany, setShowEditCompany] = useAtom(showEditableCompany);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const { t } = useTranslation();

  useEffect(() => {
    if (decorationBar !== "info") setShowEditCompany(false);
  }, [decorationBar]);

  return (
    <div>
      <div
        className="flex items-center mx-12 mt-6 gap-2 fill-azulBonico cursor-pointer text-azulBonico hover:underline"
        onClick={() => {
          setShowEditCompany(false);
          navigate(-1);
        }}
      >
        <GoBackButton />
        {/* <GoBack className="h-3" />
          <p className="ml-1 text-sm">{t("employer.notifications.linkback")}</p> */}
      </div>
      <div
        className={`flex justify-center transition-opacity duration-700 ${
          !showAlert ? "opacity-0 " : "opacity-100 "
        }`}
      >
        <div className="bg-green-600 text-white font-bold text-sm rounded-lg py-3.5 tracking-wide px-6 mt-6 fixed items-start border">
          <div className="flex justify-between gap-[78px] items-center">
            <div className="flex gap-4 items-center">
              <Warning className="h-4 fill-white" />
              <p>{t("settings.password_been_changed")}</p>
            </div>
          </div>
        </div>
      </div>{" "}
      <h1 className="my-8 ml-8 mt-20 lg:mt-8 text-xl lg:text-3xl font-bold">
        {t("settings.title")}
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-6 ">
        <div className="col-span-2 ">
          <WorkerSettingsNavBar
            setDecorationBar={setDecorationBar}
            decorationBar={decorationBar}
          />
        </div>
        <div className="col-span-4">
          {decorationBar === "info" && (
            <ContactInfo
              setDecorationBar={setDecorationBar}
              decorationBar={decorationBar}
            />
          )}
          {decorationBar === "wallet" && profileType !== "worker" && (
            <WalletInfo />
          )}
          {decorationBar === "subscriptions" && (
            <Subscriptions
              setDecorationBar={setDecorationBar}
              decorationBar={decorationBar}
            />
          )}
          {decorationBar === "notifications" && <NotificationSettings />}
          {decorationBar === "password" && (
            <PasswordSecurity
              showAlert={showAlert}
              setShowAlert={setShowAlert}
              setDecorationBar={setDecorationBar}
            />
          )}

          {decorationBar === "plan" && <SubscriptionPlan />}

          {decorationBar === "billing" && <BillingPayments />}

          {decorationBar === "identity" && <IdentityVerification />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
