import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import Modal from "react-modal";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { BASE_URL, PROFILE_WORKER } from "../../Config/api.config";
import { StylesModal } from "../../Utils/stylesModal";
import { useTranslation } from "react-i18next";
import { Button } from "../ui/Button";
import { useDropzone } from "react-dropzone";

interface ModalAddProfilePhotoProps {
  setShowAddProfilePhotoModal: Dispatch<SetStateAction<boolean>>;
  showAddProfilePhotoModal: boolean;
  company?: any;
  setCompanyLogoFile?: any;
  refetchWorkerInfo: () => void;
}

function ModalAddProfilePhoto({
  setShowAddProfilePhotoModal,
  showAddProfilePhotoModal,
  company,
  setCompanyLogoFile,
  refetchWorkerInfo,
}: ModalAddProfilePhotoProps) {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [preview, setPreview] = useLocalStorage("profile_img", "");
  const [profileImg, setProfileImg] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>("");
  const [transition, setTransition] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<any>([]);
  const [imagePreview, setImagePreview] = useState<
    { preview: string; type: string }[]
  >([]);
  const [fileDrop, setFileDrop] = useState<any>();

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
    setImages(acceptedFiles);
    setImagePreview(
      acceptedFiles?.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    // setPreview()
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const data = new FormData();
  data.append("_method", "PUT");
  data.append("client_id", userID);
  // data.append("profile_image", images[0]);
  // data.append("profile_image", file);
  data.append("profile_image", fileDrop!);

  const uploadPhoto = () => {
    fetch(`${BASE_URL}${PROFILE_WORKER}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "ok") {
          setShowAddProfilePhotoModal(false);
          refetchWorkerInfo();
        }
      })
      .catch((err) => console.log(err));
  };

  const onClose = () => {
    setPreview();
  };
  const onCrop = (preview: any) => {
    setPreview(preview);
  };

  const urlToFile = async (url: any, filename: any, mimeType?: any) => {
    mimeType = mimeType || (url?.match(/^data:([^;]+);/) || "")[1];
    const res = await fetch(url);
    const buf = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
  };

  useEffect(() => {
    if (company === "company") {
      setTimeout(() => {
        setProfileImg(
          urlToFile(
            preview,
            `company_logo_${userID}.${file?.type?.split("/")[1]}`
          ).then(function (file) {
            setFile(file);
          })
        );
      }, 100);

      // setTimeout(()=>{
      //   setProfileImg(
      //     urlToFile(
      //       imagePreview,
      //       `company`
      //     )

      //   )
      // })

      // setTimeout(()=>{
      //   setProfileImg(urlToFile())
      // },100)
    } else {
      setTimeout(() => {
        setProfileImg(
          urlToFile(
            preview,
            `profile_img_${userID}.${file?.type?.split("/")[1]}`
          ).then(function (file) {
            setFile(file);
          })
        );
      }, 100);
    }
  }, [preview]);

  useEffect(() => {
    if (company === "company") {
      setTimeout(() => {
        setProfileImg(
          urlToFile(
            imagePreview?.[0]?.preview,
            `company_logo_${userID}.${images[0]?.type?.split("/")[1]}`,
            imagePreview?.[0]?.type
          ).then(function (file) {
            setFileDrop(file);
          })
        );
      }, 100);
    } else {
      setTimeout(() => {
        setProfileImg(
          urlToFile(
            imagePreview?.[0]?.preview,
            `profile_img_${userID}.${images[0]?.type?.split("/")[1]}`,
            imagePreview?.[0]?.type
          ).then(function (file) {
            setFileDrop(file);
          })
        );
      });
    }
  }, [imagePreview]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showAddProfilePhotoModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showAddProfilePhotoModal]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowAddProfilePhotoModal(false);
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <Modal
      isOpen={showAddProfilePhotoModal}
      style={StylesModal}
      ariaHideApp={false}
    >
      <div
        className={`fixed z-50 ${
          transition === false ? "scale-x-0" : "scale-x-100"
        } transition duration-150 w-[90%] lg:w-[700px] max-w-auto max-h-auto left-[50%] border translate-x-[-50%] rounded top-[40%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b  px-8 py-6">
          {t("modal_edit_photo.title")}
        </div>
        <div className="w-full items-center justify-center flex flex-col lg:flex-row mt-4">
          <div
            className="flex  items-center h-52 flex-col w-[70%]  lg:w-[40%] border border-azulOscuro border-dashed justify-center p-4 rounded"
            {...getRootProps()}
          >
            <input className="  " {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>Drag 'n' drop some files here, or click to select files</p>
            )}
          </div>
          {imagePreview?.length > 0 &&
            imagePreview?.map((file: { preview: string }) => (
              <div
                key={file.preview}
                className=" mt-4 lg:ml-10 lg:absolute lg:right-10"
              >
                <img
                  className="h-24 w-24 rounded-full object-cover"
                  // className="h-40 w-[70%] rounded-full object-cover"
                  src={file.preview}
                />
              </div>
            ))}
        </div>
        <div className="p-4 flex flex-col">
          {/* <div
            className={`w-full m-auto flex justify-center max-h-[600px] ${
              preview ? "bg-slate-100" : ""
            } `}
          >
            <Avatar
              imageHeight={500}
              onCrop={onCrop}
              onClose={onClose}
              label={t("modal_edit_photo.ul_list_title")}
              width={0}
              height={0}
            />
          </div> */}
          <p className="font-medium tracking-wide">
            {t("modal_edit_photo.ul_list_desc")}*
          </p>
          {/* <div className="flex flex-col text-sm p-4 mt-6">
            <p className="font-bold tracking-wide">
              {t("modal_edit_photo.ul_list_desc")}
            </p>
            <ul className="list-disc pl-8">
              <li className="mt-3">{t("modal_edit_photo.ul_list_text1")}</li>
              <li className="mt-2">{t("modal_edit_photo.ul_list_text2")}</li>
              <li className="mt-2">{t("modal_edit_photo.ul_list_text3")}</li>
              <li className="mt-2">{t("modal_edit_photo.ul_list_text4")}</li>
            </ul>
          </div> */}
        </div>
        <div className="flex w-full h-full items-end p-4 gap-2 pr-4 mt-2 justify-end border-t border-blue-100">
          <Button
            className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico  hover:bg-blancoGrisClaro  mt-1 "
            onClick={() => {
              closeModal();
            }}
          >
            {t("modal_edit_photo.cancel")}
          </Button>
          <Button
            className={`border-none w-[110px] rounded-[20px] text-[0.9rem] px-[1rem]  h-10 ${
              !profileImg
                ? "cursor-not-allowed bg-blancoGris text-slate-300 disabled"
                : "bg-azulBonico hover:bg-azulOscuro text-white "
            } `}
            disabled={!profileImg}
            onClick={(e) => {
              if (company === "company") {
                // uploadCompanyPhoto();
                // setLoading(true);
                // setTimeout(() => {
                //   window.location.reload(false);
                // }, 500);

                //CHANGED
                // setCompanyLogoFile(file);
                setCompanyLogoFile(images[0]);
                setShowAddProfilePhotoModal(false);
                uploadPhoto();
              } else {
                uploadPhoto();
                // setLoading(true);
                // setTimeout(() => {
                //   window.location.reload();
                // }, 500);
              }
            }}
          >
            {t("modal_edit_photo.save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalAddProfilePhoto;
