import React, { useState, useRef } from "react";
import { useEffect } from "react";
import Modal from "react-modal";
import { BASE_URL, DISCOVERIES } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { ApplicantTypes } from "../../types/types";
import { useTranslation } from "react-i18next";
import { StylesModal } from "../../Utils/stylesModal";
import { Button } from "../ui/Button";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutDiscover from "../CheckoutDiscover";
import useUpdateCredits from "src/Hooks/useUpdateCredits";
import { decryptArray } from "src/Hooks/decryptString";
import { useActionTypesStore } from "src/Routing/useProfileStore";

interface ModalDiscoverWorkerProps {
  setShowModal: any;
  showModal: { discover: boolean };
  worker: ApplicantTypes;
  setWorkerInfo: any;
  // applicantsData: ApplicantTypes[];
  // setApplicantsData: any;
  refetch: () => void;
  discoverValue: string;
}

export const ModalDiscoverWorker = ({
  setShowModal,
  showModal,
  worker,
  refetch,
  discoverValue,
}: ModalDiscoverWorkerProps) => {
  const [transition, setTransition] = useState(false);
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [userID, setUserId] = useLocalStorage("IDUser", "");

  const ref = useRef<HTMLDivElement>(null);
  const [clientSecret, setClientSecret] = useState("");
  const { t } = useTranslation();
  const stripePromise = loadStripe(
    "pk_test_51QKjbIKfPqiMx5RCWGXOWNbSaKJbYzPjhlrIH7bjSn8xEa5ndo5CxqIrQZ3SuRqfeBf23jT6QXyBPLFjUunO0Yb300iS1pZJQx"
  );
  // const stripePromise = loadStripe(
  //   "pk_test_51Ko0cvIbATEeXV2RrbmqpvSq0bBPhpArQw3FQ4Mfe9uQBfzHpTcObrd5QXFkQQGisgD7pkXkRDS3DGiZg5qLE2Mz003UcxTS2u"
  // );
  const actions = useActionTypesStore((s: any) => s.actions);

  const { updateCredits } = useUpdateCredits(
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "17"
    )[0]?.credits,
    decryptArray(actions)?.filter(
      (action: { id: string; name: string; credits: string }) =>
        action.id === "17"
    )[0]?.id
  );
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showModal.discover &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showModal.discover]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowModal((prev: any) => ({ ...prev, discover: false }));
    }, 150);
  };
  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  const createPaymentData = new FormData();
  createPaymentData.append("amount", "200");

  const discoverData = new FormData();
  discoverData.append("_method", "POST");
  discoverData.append("client_id", userID);
  discoverData.append("client_id_discovered", worker?.client_id_worker);

  const discoverProfile = async () => {
    const response = await fetch(`${BASE_URL}${DISCOVERIES}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${loggedInUserUid}`,
      },
      body: discoverData,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.result.rowAdded === "ok") {
          setShowModal((prev: any) => ({ ...prev, discover: false }));
          refetch();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return response;
  };
  const appearance = {
    theme: "stripe",
  };
  const options: any = {
    clientSecret,
    appearance,
  };
  const discoverWorker = async () => {
    discoverProfile();
    updateCredits();
  };
  return (
    <Modal isOpen={showModal.discover} style={StylesModal} ariaHideApp={false}>
      <div
        className={`fixed ${
          transition === false ? "scale-y-0" : "scale-y-100"
        } transition duration-150 w-[90%] lg:w-[500px]  border translate-x-[-50%] left-[50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
        ref={ref}
      >
        <div className="text-xl font-bold border-b border-blue-100 px-8 py-6">
          Discover Worker
        </div>
        {clientSecret ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutDiscover discoverProfile={discoverProfile} />
          </Elements>
        ) : (
          <div>
            <div className="px-8 p-4">
              <p className="text-gray-600 text-sm mb-4">
                You’re about to unlock more details about this worker’s profile.
                This action will allow you to view additional information to
                help you make an informed decision.
              </p>
              <p className="text-gray-800 text-base font-medium mb-4">
                Unlocking this profile costs{" "}
                <span className="text-azulBonico font-bold">
                  {discoverValue} Flexicoins
                </span>
                .
              </p>
            </div>

            <div className="flex w-full h-full items-end p-4 gap-2 pr-4 justify-end ">
              <Button
                className="bg-none text-[0.9rem] w-24 h-10 border border-azulBonico text-azulBonico rounded-3xl hover:bg-blancoGrisClaro  mt-1"
                onClick={() => closeModal()}
              >
                {t("worker.modal_inappropiate.cancel")}
              </Button>
              <Button
                className={`border-none w-[110px] rounded-[20px] text-[0.9rem] px-[1rem] h-10 bg-azulBonico hover:bg-azulOscuro text-white  `}
                onClick={() => discoverWorker()}
              >
                Discover
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
