import React from "react";
import { ReviewTypes } from "../../types/types";
import { useAtom } from "jotai";
import { loadingFeedback } from "../../Atoms/jotaiAtoms";
import { ReactComponent as Ratings } from "../../assets/img/ratings.svg";

import { useTranslation } from "react-i18next";
import CardSkeleton from "../ui/skeleton/CardSkeleton";
import ReviewsCard from "./ReviewsCard.";

interface ReviewsReceivedProps {
  filteredReviews: ReviewTypes[];
  view: string;
}

function ReviewsReceived({ filteredReviews, view }: ReviewsReceivedProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useAtom(loadingFeedback);

  return (
    <div className="flex flex-col  mx-2 w-full">
      {filteredReviews.length === 0 ? (
        <div className="mx-8 mt-4 mb-2 flex flex-col items-center ">
          <Ratings className="h-[104px]" />
          <p className="text-lg font-bold tracking-wide mt-4">
            {t("employer.myhires.feedback.noreceivedreviews.Text")}
          </p>
        </div>
      ) : (
        <div className="flex flex-col w-full">
          {filteredReviews.length > 0 &&
            filteredReviews
              .sort((a, b) => b.date_start.localeCompare(a.date_start))
              ?.map((review) => {
                if (loading) {
                  return <CardSkeleton key={review.connection_id} />;
                } else {
                  return (
                    <ReviewsCard
                      key={review.connection_id}
                      review={review}
                      view={view}
                    />
                  );
                }
              })}
        </div>
      )}
    </div>
  );
}

export default ReviewsReceived;
