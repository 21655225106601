import { ChevronDownIcon } from "@heroicons/react/24/solid";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { cn } from "src/lib/utils";
import { Wallet } from "src/types/types";
import Select from "react-select";
import { useAtom } from "jotai";
import { filterActionType } from "src/Atoms/jotaiAtoms";
import { decryptArray } from "src/Hooks/decryptString";
import { useActionTypesStore } from "src/Routing/useProfileStore";

interface FilterCreditsProps {
  walletInfo: Wallet[];
  setFinalData: Dispatch<SetStateAction<Wallet[]>>;
}

const FilterCredits = ({ walletInfo, setFinalData }: FilterCreditsProps) => {
  const { t } = useTranslation();
  const [filterGain, setFilterGain] = useState(false);
  const [filterLose, setFilterLose] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  const [selectedActionType, setSelectedActionType] = useAtom(filterActionType);

  const actions = useActionTypesStore((s: any) => s.actions);
  const [sort, setSort] = useState(false);

  const sortedWalletActions = useMemo(() => {
    return sort
      ? [...walletInfo]?.sort((a, b) =>
          a.date_created.localeCompare(b.date_created)
        )
      : walletInfo;
  }, [sort, walletInfo]);

  const filterWalletByGain = useMemo(() => {
    return filterGain
      ? sortedWalletActions.filter((mov) => Number(mov.credits) > 0)
      : sortedWalletActions;
  }, [filterGain]);

  const filterWalletByLoss = useMemo(() => {
    return filterLose
      ? sortedWalletActions.filter((mov) => Number(mov.credits) < 0)
      : sortedWalletActions;
  }, [filterLose]);

  useEffect(() => {
    setFinalData(sortedWalletActions);
  }, [sort]);

  useEffect(() => {
    setFinalData(filterWalletByGain);
  }, [filterGain]);

  useEffect(() => {
    setFinalData(filterWalletByLoss);
  }, [filterLose]);

  const colourStyles = {
    control: () => ({
      border: "1px solid lightblue",
      "&:hover": {
        borderColor: "#2abeeb",
      },
      display: "flex",
      height: "34px",
      width: "140px",
      padding: "0rem",
      borderRadius: "5px",
      fontSize: "12px",
    }),
  };

  const convertToLanguage = (name: string) => {
    if (name === "Chat Job") {
      return `${t("wallet.actions_types.chat_job")}`;
    } else if (name === "Clone Job") {
      return `${t("wallet.actions_types.clone_job")}`;
    } else if (name === "Feature User") {
      return `${t("wallet.actions_types.feature_user")}`;
    } else if (name === "Invite Job") {
      return `${t("wallet.actions_types.invite_job")}`;
    } else if (name === "Post job") {
      return `${t("wallet.actions_types.post_job")}`;
    } else if (name === "Purchase") {
      return `${t("wallet.actions_types.purchase")}`;
    } else if (name === "View Profile Job") {
      return `${t("wallet.actions_types.view_profile")}`;
    } else if (name === "Discover") {
      return `Discover`;
    } else if (name === "Welcome Gift") {
      return `Welcome Gift`;
    } else if (name === "Shine profile") {
      return `Feature Profile`;
    } else if (name === "Shuine Post") {
      return `Feature Post`;
    } else if (name === "Discovery") {
      return `Discovery`;
    } else if (name === "boost") {
      return `Boost`;
    } else if (name === "Badge") {
      return `Badge`;
    }
  };

  const availableActions = decryptArray(actions)?.map(
    (option: { id: string; name: string }) => ({
      value: option.id,
      label: <div className="text-sm">{convertToLanguage(option.name)}</div>,
    })
  );

  console.log(decryptArray(actions));

  return (
    <div className="bg-white dark:bg-background dark:text-whiteish py-10">
      <div
        className={cn(
          `flex items-center ml-3 scale-0 opacity-0 transition mt-2 `,
          showFilters && "opacity-100 scale-105"
        )}
      >
        <div
          className="ml-8 flex mb-2 border rounded  justify-between cursor-pointer  items-center px-2 py-1 h-[34px] w-[140px]"
          onClick={() => setSort(!sort)}
        >
          <p className="text-sm">
            {sort
              ? t("employer.credits.wallet_history.sort.oldest")
              : t("employer.credits.wallet_history.sort.newest")}
          </p>
          <ChevronDownIcon
            className={cn(`h-4 w-4 ml-1 transition`, sort && "rotate-180")}
          />
        </div>
        {availableActions?.length > 0 && (
          <div className="relative mb-2 ml-10">
            <Select
              placeholder="Select Action"
              styles={colourStyles}
              options={availableActions}
              value={{
                value: selectedActionType.value,
                label:
                  selectedActionType.label !== ""
                    ? selectedActionType.label
                    : "Select Action",
              }}
              onChange={(e: any) => setSelectedActionType(e)}
            />
          </div>
        )}
        <div
          // onClick={() => filterByGain()}
          onClick={() => {
            setFilterGain(!filterGain);
            setFilterLose(false);
          }}
          className={cn(
            `ml-8 flex mb-2  rounded cursor-pointer items-center px-2 py-1 `
          )}
        >
          <input
            checked={filterGain}
            type="checkbox"
            className="accent-black h-4 w-4"
          />
          <p className="text-sm ml-1">{t("employer.wallet_info.income")}</p>
        </div>
        <div
          onClick={() => {
            setFilterLose(!filterLose);
            setFilterGain(false);
          }}
          className={cn(
            `ml-8 flex mb-2 rounded cursor-pointer transition items-center px-2 py-1`
          )}
        >
          <input
            checked={filterLose}
            type="checkbox"
            className="accent-black h-4 w-4"
          />
          <p className="text-sm ml-1">{t("employer.wallet_info.expense")}</p>
        </div>
      </div>
    </div>
  );
};

export default FilterCredits;
