import React from "react";

import WorkerCards from "./WorkerCards";
import { useTranslation } from "react-i18next";
import { ApplicantTypes } from "../../types/types";

interface AllApplicantsProps {
  applicantsData: ApplicantTypes[];
  setApplicantsData: any;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
  maxPages: any;
  currentPage: any;
  setCurrentPage: any;
  refetch: () => void;
}

const AllApplicants = ({
  applicantsData,
  setApplicantsData,
  setRefreshData,
  maxPages,
  currentPage,
  setCurrentPage,
  refetch,
}: AllApplicantsProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <WorkerCards
        refetch={refetch}
        applicantsData={applicantsData}
        setApplicantsData={setApplicantsData}
        setRefreshData={setRefreshData}
      />
    </div>
  );
};

export default AllApplicants;
