import React from "react";

import { useTranslation } from "react-i18next";
import ReviewsGiven from "./ReviewsGiven";
import { useFiltersFeedback } from "../../Hooks/useFiltersFeedback";
import ReviewsReceived from "./ReviewsReceived";

import { ReviewTypes } from "../../types/types";
import { cn } from "../../lib/utils";
import { useAtom } from "jotai";
import { selectedFeedbackSection } from "src/Atoms/jotaiAtoms";
import { Button } from "../ui/Button";
import { userProfileStore } from "src/Routing/useProfileStore";

type ReviewsProps = {
  given: ReviewTypes[];
  received: ReviewTypes[];
};

interface ReviewsScreenProps {
  reviews: ReviewsProps;
  view?: string;
}

const ReviewsScreen = ({ reviews }: ReviewsScreenProps) => {
  const profileType = userProfileStore((s) => s.profileTypeToken);

  // const [profileType, setProfileType] = useLocalStorage("profileType", "");

  const [selectedSection, setSelectedSection] = useAtom(
    selectedFeedbackSection
  );
  const { filteredReviews: filteredGivenReviews } = useFiltersFeedback(
    reviews?.given
  );
  const { filteredReviews: filteredReceivedReviews } = useFiltersFeedback(
    reviews?.received
  );

  const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="mb-6 mt-6 flex justify-center">
        <div className="relative">
          <div className="flex h-[35px] w-[250px]  justify-between rounded border border-gray-300 dark:border-border dark:border">
            <div
              className={`absolute top-0 transition duration-200 ${
                selectedSection === "recibidas"
                  ? "translate-x-0"
                  : "translate-x-[125px]"
              } h-[35px] w-[125px] rounded bg-azulBonico`}
            ></div>
            <Button
              className={cn(
                `  z-30 flex w-full items-center justify-center
              
              `,
                selectedSection === "recibidas"
                  ? "text-white"
                  : "text-black dark:text-whiteish"
              )}
              onClick={() => setSelectedSection("recibidas")}
            >
              <p className="text-center">
                {t("worker.findwork.body.card.layer4.leftBtn")}
              </p>
            </Button>
            <Button
              className={cn(
                `z-30 flex w-full items-center justify-center `,
                selectedSection === "realizadas"
                  ? "text-white"
                  : "text-black dark:text-whiteish"
              )}
              onClick={() => setSelectedSection("realizadas")}
            >
              <p className="w-full text-center">
                {" "}
                {t("worker.findwork.body.card.layer4.rightBtn")}
              </p>
            </Button>
          </div>
        </div>
      </div>

      <div className="flex items-start py-2">
        {/* <FiltersFeedback /> */}
        {selectedSection === "recibidas" && (
          <ReviewsReceived
            filteredReviews={filteredReceivedReviews}
            view={profileType === "employer" ? "worker" : "employer"}
          />
        )}
        {selectedSection === "realizadas" && (
          <ReviewsGiven
            filteredReviews={filteredGivenReviews}
            view={profileType === "employer" ? "employer" : "worker"}
          />
        )}
      </div>
    </div>
  );
};

export default ReviewsScreen;
