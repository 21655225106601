import React, { Dispatch, ElementRef, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { BASE_URL, SAVED_JOB_SEARCH } from "../../Config/api.config";
import useLocalStorage from "../../Hooks/UseLocalStorage";
import { allFiltersAppliedToJobSearch } from "../../Atoms/jotaiAtoms";
import { ReactComponent as Trash } from "../../assets/img/trash-can.svg";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { Button } from "../ui/Button";
import { t } from "i18next";

interface ModalEditSavedJobsSearchProps {
  savedSearchedJobsData: any;
  getSavedJobsSearch: () => void;
  showEditSavedJobsSearch: boolean;
  setShowEditSavedJobsSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalEditSavedJobsSearch = ({
  savedSearchedJobsData,
  setShowEditSavedJobsSearch,
  getSavedJobsSearch,
  showEditSavedJobsSearch,
}: ModalEditSavedJobsSearchProps) => {
  const [userID, setUserId] = useLocalStorage("IDUser", "");
  const [loggedInUserUid, setLoggedInUserUid] = useLocalStorage("UIDUser", "");
  const [clickedSearch, setClickedSearch] = useState<{
    search_name: string;
    saved_search_id: string;
  }>({
    search_name: "",
    saved_search_id: "",
  });
  const [showDeleteBanner, setShowDeleteBanner] = useState(false);
  const [transition, setTransition] = useState(false);
  const [savedSearchesFilters, setSavedSearchesFilters] = useAtom(
    allFiltersAppliedToJobSearch
  );
  const navigate = useNavigate();
  const ref = useRef<ElementRef<"div">>(null);
  const handleSavedSearchDeletion = (item: { saved_search_id: string }) => {
    fetch(
      `${BASE_URL}${SAVED_JOB_SEARCH}?client_id=${userID}&saved_search_id=${item.saved_search_id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${loggedInUserUid}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        showEditSavedJobsSearch &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.addEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showEditSavedJobsSearch]);

  const closeModal = () => {
    setTransition(false);
    setTimeout(() => {
      setShowEditSavedJobsSearch(false);
    }, 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }, []);

  return (
    <div className="absolute">
      {showDeleteBanner && (
        <div className="relative z-50 ">
          <div className="fixed top-0 left-0 w-full h-full bg-[#031c23]/50 !text-black z-30">
            <div
              className={`fixed z-50 ${
                transition === false ? "scale-x-0" : "scale-x-100"
              } transition duration-150  w-[300px] left-[50%] border translate-x-[-50%] py-4 px-3 rounded-2xl top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
              ref={ref}
            >
              <div className="flex flex-col">
                <p className="tracking-wide">
                  {t("modal.edit_saved.jobs_search.title")} "
                  {clickedSearch?.search_name}" ?
                </p>
                <div className="flex items-center justify-around mt-2">
                  <Button
                    className="px-4 py-1 text-azulBonico hover:bg-[#f7f4f4] border border-azulBonico  bg-white"
                    onClick={() => {
                      setShowDeleteBanner(false);
                    }}
                  >
                    {t("modal.edit_saved.jobs_search.cancel_button")}
                  </Button>
                  <Button
                    className="px-12 py-[5px]  tracking-wider text-white bg-azulBonico hover:bg-azulOscuro "
                    onClick={() => {
                      handleSavedSearchDeletion(clickedSearch);
                      setTimeout(() => {
                        getSavedJobsSearch();

                        setShowDeleteBanner(false);
                      }, 150);
                    }}
                  >
                    {t("modal.edit_saved.jobs_search.delete_button")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="fixed top-0 left-0 w-full h-full bg-black/50 !text-black z-30">
        <div
          className={`fixed z-50 ${
            transition === false ? "scale-x-0" : "scale-x-100"
          } transition duration-150 w-[90%] lg:w-[28%] left-[50%] border translate-x-[-50%] rounded top-[50%] translate-y-[-50%] bg-white border-[0.5px]`}
          ref={ref}
        >
          <div className="flex justify-between items-center border-b border-blue-100 px-8 py-6">
            <div className="text-xl font-bold ">
              {t("modal.edit_saved.jobs_search.saved_searches")}{" "}
            </div>
            <Cross
              className="h-4 cursor-pointer"
              onClick={() => setShowEditSavedJobsSearch(false)}
            />
          </div>
          {savedSearchedJobsData?.map(
            (item: {
              params_json: string;
              search_name: string;
              saved_search_id: string;
            }) => {
              return (
                <div
                  key={item.search_name}
                  className="flex px-8 items-center justify-between py-5 border-b border-blue-100"
                >
                  <p
                    className="font-bold text-azulBonico tracking-wide hover:underline hover:text-azulOscuro cursor-pointer"
                    onClick={() => {
                      setSavedSearchesFilters(JSON.parse(item.params_json));
                      navigate("/jobs-search");
                    }}
                  >
                    {item.search_name}
                  </p>
                  <Trash
                    className="h-3 cursor-pointer"
                    fill={"#c9c9c9"}
                    onClick={() => {
                      setClickedSearch(item);
                      setTimeout(() => {
                        setShowDeleteBanner(true);
                      }, 100);
                    }}
                  />
                </div>
              );
            }
          )}
          <div className="py-5 w-full flex justify-end">
            <button
              className="px-8 py-2 flex items-end mr-6 text-white bg-azulBonico hover:bg-azulOscuro  rounded-full transition duration-300"
              onClick={() => setShowEditSavedJobsSearch(false)}
            >
              {t("modal.edit_saved.jobs_search.done_button")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditSavedJobsSearch;
