import React, { useEffect, useState } from "react";
import VerticalHeaderWorker from "../../../Components/HeaderWorker/VerticalHeaderWorker";
import { userProfileStore } from "../../../Routing/useProfileStore";
import HeaderUser from "../../../Components/HeaderUser/HeaderUser";
import HeaderWorker from "../../../Components/HeaderWorker/HeaderWorker";
import VerticalHeaderUser from "../../../Components/HeaderUser/VerticalHeaderUser";
import { cn } from "src/lib/utils";
import { useThemeStore } from "src/Routing/useThemeStore";
import { useModalStore } from "src/Hooks/useModalStore";
import { useWalletSore } from "src/Hooks/useWalletStore";
import WalletCredits from "src/Pages/Employer/Dashboard/WalletCredits";
import { usePaymentStore } from "src/Hooks/usePaymentSuccessful";
import ModalPaymentPurchase from "src/Components/Modals/ModalPaymentPurchase";

interface VerticalLayoutProps {
  children?: React.ReactNode;
}

export const VerticalLayout = ({ children }: VerticalLayoutProps) => {
  const profileType = userProfileStore((s) => s.profileTypeToken);
  const [noScroll, setNoScroll] = useState(false);
  const theme = useThemeStore((state) => state.themeMode);
  const [showModalOpenChat, setShowModalOpenChat] = useModalStore((state) => [
    state.showModalOpenChat,
    state.setShowModalOpenChat,
  ]);
  const [showWalletMenu, setShowWalletMenu] = useModalStore((state) => [
    state.showWalletMenu,
    state.setShowWalletMenu,
  ]);
  const modal = useWalletSore();
  const modalPayment = usePaymentStore();

  useEffect(() => {
    if (
      window.location.pathname === "/messages" ||
      window.location.pathname === "/messages-em"
    ) {
      setNoScroll(true);
    }
  }, []);
  // ese margen top en laptop hace que en mensajes no se pegue arriba del todo
  return (
    <div className={theme === "dark" ? "dark" : "light"}>
      <div className={cn(`w-screen flex dark:bg-background bg-[#fafafa]`)}>
        <aside className="hidden laptop:block ">
          {profileType === "employer" ? (
            <VerticalHeaderUser />
          ) : (
            <VerticalHeaderWorker />
          )}
        </aside>
        {modal.isOpen && <WalletCredits />}
        {/* {modalPayment.isOpen && <ModalPaymentPurchase />} */}
        <ModalPaymentPurchase />
        <header className="block laptop:hidden">
          {profileType === "employer" ? <HeaderUser /> : <HeaderWorker />}
        </header>
        <main
          className={`h-screen w-full lg:w-[90%] mx-auto ${
            noScroll ? "lg:overflow-y-hidden" : "overflow-y-auto"
          } jobpostWider pb-20`}
        >
          <div className="max-w-6xl mx-auto px-10">{children}</div>

          {/* <SmallFooter marginRight={true} /> */}
        </main>
      </div>
    </div>
  );
};
