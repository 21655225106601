import React from "react";
import { useTranslation } from "react-i18next";

const IdentityVerification = () => {
  const { t } = useTranslation();
  return (
    <div className="px-4 py-2">
      <div className="bg-white rounded-lg border pb-6 dark:bg-background dark:text-whiteish dark:border-border dark:border">
        <div className="flex flex-col">
          <div className="flex items-center justify-between p-8">
            <h1 className="text-lg lg:text-2xl font-semibold mb-4">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.identityverification"
              )}
            </h1>
          </div>
          <div className="border border-border p-8 ">
            <h1 className="text-lg lg:text-xl font-medium ">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.ID_identityverification.body.item_1_title1"
              )}
            </h1>
            <h3 className="mt-2">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.ID_identityverification.body.item_1_subtitle1"
              )}
            </h3>
            <h3 className="text-azulOscuro underline">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.ID_identityverification.body.item_1_subtitle1_link"
              )}
            </h3>
          </div>
          <div className="border border-border p-8 ">
            <h1 className="text-lg lg:text-xl font-medium ">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.locationverification.body.item_2_title2"
              )}
            </h1>
            <h3 className="mt-2">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.locationverification.body.item_2_Subtitle2"
              )}
            </h3>
          </div>
          <div className="border border-border p-8 ">
            <h1 className="text-lg lg:text-xl font-medium ">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.visualverification.body.item_3_title3"
              )}
            </h1>
            <h3 className="mt-2">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.visualverification.body.item_3_Subtitle3"
              )}
            </h3>
            <h3 className="text-azulOscuro underline">
              {t(
                "worker.leftbar.menu.search_jobs.my_profile.configuration.settings.ID_identityverification.body.item_3_Subtitle3_link"
              )}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdentityVerification;
