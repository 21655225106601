import React, { useEffect, useState } from "react";
import useLocalStorage from "../../../Hooks/UseLocalStorage";

import ProgressBarOffer from "../../../Components/ViewJobPost/ProgressBarOffer";
import { ReactComponent as Resume } from "../../../assets/img/resume.svg";
import WorkerCards from "../../../Components/ViewJobPost/WorkerCards";
import { useTranslation } from "react-i18next";

import {
  useApplicationProcessJob,
  useApplicationProcessOffers,
} from "src/Hooks/useApplicationProcess";
import { Skeleton } from "src/Components/ui/skeleton";

const OffersApplicants = () => {
  const [jobData, setJobData] = useLocalStorage("jobData", "");
  const [refreshData, setRefreshData] = useState(false);
  const [counters, setCounters] = useState();
  const [offeredApplicants, setOfferedApplicants] = useState([]);

  const { t } = useTranslation();
  const { isLoading, offersData, refetchOffers } =
    useApplicationProcessOffers();
  const { jobsData } = useApplicationProcessJob();

  useEffect(() => {
    setOfferedApplicants(offersData?.hiredApplicantsByStatus);
    setCounters((prev: any) => ({
      ...prev,
      offered: offersData?.totalHiredApplicants,
    }));
  }, [offersData]);

  useEffect(() => {
    setJobData(jobsData);
  }, [jobsData]);

  const refetch = () => {
    refetchOffers();
  };

  return (
    <>
      <ProgressBarOffer
        props={"offers"}
        jobData={jobData && jobData.data?.[0].job_status}
      />
      <div className="flex flex-col w-full bg-white mt-8 rounded border relative dark:bg-background dark:text-whiteish dark:border dark:border-border  ">
        <div>
          {!isLoading && offeredApplicants?.length === 0 && (
            <div className="flex flex-col w-full items-center my-12 ">
              <Resume className="h-32 mb-6" />
              <p className="text-lg font-bold tracking-wide">
                {t("offer_applicants.no_offers")}
              </p>
              <p className="text-sm mt-4">
                {t("offer_applicants.no_offers.desc")}
              </p>
            </div>
          )}

          {!isLoading && offeredApplicants?.length > 0 && (
            <div>
              <div>
                <p className="text-sm px-8 py-6">
                  {t("offer_applicants.offers")}
                </p>
                <WorkerCards
                  refetch={refetch}
                  applicantsData={offeredApplicants}
                  setApplicantsData={setOfferedApplicants}
                  setRefreshData={setRefreshData}
                />
              </div>
            </div>
          )}
          {isLoading && (
            <div className="flex items-start justify-between w-full p-8">
              <Skeleton className="h-20 w-20 rounded-full  flex-shrink-0" />
              <div className="flex flex-col space-y-4 ml-10 w-full">
                <Skeleton className="h-6 w-40 rounded" />
                <Skeleton className="h-6 w-60 rounded" />
                <Skeleton className="h-4 w-full rounded" />
                <Skeleton className="h-4 w-full rounded" />
                <div className="flex items-center space-x-2">
                  <Skeleton className="h-6 w-40 rounded-full" />
                  <Skeleton className="h-6 w-40 rounded-full" />
                </div>
              </div>
              <div className="">
                <Skeleton className="w-10 h-6 rounded-full" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OffersApplicants;
